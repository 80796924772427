/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './DeletePrivateStationStaffModal.css';
// import { useParams } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { closeModal, largeFinish } from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import OrgService from '../../../services/OrgService';

interface DeletePrivateStationStaffModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  title: string;
  description: string;
  staff: string | undefined;
  accessOption: string;
  successDeleteMessage: string;
}

const DeletePrivateStationStaffModal: React.FC<DeletePrivateStationStaffModalProps> = ({
  onClose, isModalOpen, title, description, staff, accessOption, successDeleteMessage
}) => {
  const { station } = useParams<{ station: string }>();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (station) {
        // console.log('123');
      }
    } catch (error) {
      setShowSuccessMessage(false);
      // console.error('Ошибка при отправке запроса:', error);
    }
  };

  return (
    <div className={`deletePrivateStationStaff-modal-background
      ${isModalOpen ? '' : 'deletePrivateStationStaff-modal-hidden'}`}>
      <div className="deletePrivateStationStaff-modal-content">
        <h1>{title}</h1>
        <div className="deletePrivateStationStaff-modal-close" onClick={onClose}>{closeModal}</div>
        {showSuccessMessage && (
            <div className='transferCar-modal-notification-container'>
            {largeFinish}
            <h3 className='transferCar-modal-notification-title'>{successDeleteMessage}</h3>
          </div>
        )}
        {!showSuccessMessage && <form className='deletePrivateStationStaff-modal-form' onSubmit={handleSubmit}>
          <div className='deletePrivateStationStaff-modal-form-description'>
          <span>
            {description} <span style={{ fontWeight: '500' }}>{staff}</span>?
          </span>
          </div>
          <div className='deletePrivateStationStaff-modal-buttons'>
            <MediumButton text={accessOption} color={'--MyWhite'} type="submit" backgroundColor={'--MyPrimary'} />
            <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
          </div>
        </form>}
      </div>
    </div>
  );
};

export default DeletePrivateStationStaffModal;
