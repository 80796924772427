/* eslint-disable max-len */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import './UpdateRateDetailedModal.css';
import {
  arrowHeader, clearInput, closeModal, largeLoading, largeError, largeFinish,
  plus,
  plusAdd,
  bucket32
} from '../../../Icons/Icons';
import 'dayjs/locale/ru';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import MediumButton from '../../buttons/MediumButton';
import LargeButtonWithIcon from '../../buttons/LargeButtonWithIcon';
import ChargeService from '../../../services/ChargeService';
import { RateElement } from '../../../models/response/RateResponse';

interface UpdateRateDetailedModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  title: string;
  periods: RateElement[];
  rateId: string;
}

const UpdateRateDetailedModal: React.FC<UpdateRateDetailedModalProps> = ({
  onClose, isModalOpen, title, periods, rateId
}) => {
  const [org, setOrg] = useState('');
  const [start, setStart] = useState('');
  const [parking, setParking] = useState('');
  const [rateName, setRateName] = useState((title || ''));
  const [elements, setElements] = useState<any[]>([]);

  const [firstTimer1, setFirstTimer1] = useState(periods[0]?.restrictions.start_time || '');
  const [firstTimer2, setFirstTimer2] = useState(periods[0]?.restrictions.end_time || '');
  const [firstTimerPrice, setFirstTimerPrice] = useState(periods[0]?.price_components[0].price || '');

  const [secondTimer1, setSecondTimer1] = useState(periods[1]?.restrictions.start_time || '');
  const [secondTimer2, setSecondTimer2] = useState(periods[1]?.restrictions.end_time || '');
  const [secondTimerPrice, setSecondTimerPrice] = useState(periods[1]?.price_components[0].price || '');

  const [showAdditionalBlock, setShowAdditionalBlock] = useState(!!periods[1]);
  const [blocksCount, setBlocksCount] = useState(periods[1] ? 2 : 1);

  const [submitting, setSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const [isOrgDropdownOpen, setIsOrgDropdownOpen] = useState(false);

  const modelDropdownRef = useRef(null);

  const handleAddBlock = () => {
    setShowAdditionalBlock(true);
    setBlocksCount((prev) => prev + 1);
  };

  const handleDeleteBlock = () => {
    setShowAdditionalBlock(false);
    setBlocksCount((prev) => prev - 1);
    setSecondTimer1('00:00');
    setSecondTimer2('00:00');
    setSecondTimerPrice('0');
  };

  // Org

  enum OrgNames {
    org1 = 'Организация №1',
    org2 = 'Организация №2',
    org3 = 'Организация №3'
  }

  const handleOrgInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrg(e.target.value);
  };

  const handleOrgInputClick = () => {
    setIsOrgDropdownOpen((prevIsModelDropdownOpen) => !prevIsModelDropdownOpen);
  };

  const handleOrgSelect = (selectedModel: string) => {
    setOrg(selectedModel);
    setIsOrgDropdownOpen(false);
  };

  // Time Rate

  const inputRef = useRef<HTMLInputElement>(null);

  const handleFocusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    const check = () => {
      const newElements = [
        {
          price_components: [
            {
              type: 'ENERGY',
              price: firstTimerPrice,
              step_size: 2
            }
          ],
          restrictions: {
            start_time: firstTimer1,
            end_time: firstTimer2,
            start_date: moment(Date.now()).format('YYYY-MM-DD'),
            end_date: moment(Date.now()).format('YYYY-MM-DD'),
          }
        },
        {
          price_components: [
            {
              type: 'ENERGY',
              price: secondTimerPrice,
              step_size: 2
            }
          ],
          restrictions: {
            start_time: secondTimer1,
            end_time: secondTimer2,
            start_date: moment(Date.now()).format('YYYY-MM-DD'),
            end_date: moment(Date.now()).format('YYYY-MM-DD'),
          }
        }
      ];
      // if (typeof secondTimerPrice === 'string' && secondTimerPrice.trim() !== '') {
      //   newElements.push({
      //     price_components: [
      //       {
      //         type: 'ENERGY',
      //         price: (secondTimerPrice.trim() !== '' ? secondTimerPrice : ''),
      //         step_size: 2
      //       }
      //     ],
      //     restrictions: {
      //       start_time: secondTimer1,
      //       end_time: secondTimer2,
      //       start_date: moment(Date.now()).format('YYYY-MM-DD'),
      //       end_date: moment(Date.now()).format('YYYY-MM-DD'),
      //     }
      //   });
      // }
      setElements(newElements);
    };
    check();
  }, [firstTimerPrice, firstTimer1, firstTimer2, secondTimer1, secondTimer2, secondTimerPrice, rateName]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const requestBody = {
        title: rateName,
        elements
      };

      setSubmitting(true);
      setStatusMessage('Добавляем тариф');
      const response = await ChargeService.updateRate(requestBody, rateId);
      if (response.status === 200) {
        setStatusMessage('Тариф успешно изменен');
        setSubmitting(false);
      }
    } catch (error: any) {
      setStatusMessage('Произошла ошибка при изменении тарифа');
      setSubmitting(false);
    }
  };

  const handleRetry = () => {
    setStatusMessage('');
  };

  const handleClearInput = (setState: React.Dispatch<React.SetStateAction<string>>) => () => {
    setState('');
  };

  return (
    <div className={`addRateDetailed-modal-background ${isModalOpen ? '' : 'addRateDetailed-modal-hidden'}`}>
      <div className="addRateDetailed-modal-content">
        <h1>Редактировать тариф</h1>
        <div className="addRateDetailed-modal-close" onClick={onClose}>{closeModal}</div>
        {submitting && (
          <div className='addRateDetailed-modal-notification-container'>
            {largeLoading}
            <h3 className='addRateDetailed-modal-notification-title'>Изменяем тариф...</h3>
          </div>
        )}
        {!submitting && statusMessage && (
          <div>
            {statusMessage === 'Произошла ошибка при изменении тарифа' ? (
              <div className='addRateDetailed-modal-notification-container'>
                {largeError}
                <h3 className='addRateDetailed-modal-notification-title'>{statusMessage}</h3>
                <div className='addRateDetailed-modal-error-buttons-container'>
                  <MediumButton text={'Попробовать снова'}
                  onClick={handleRetry} backgroundColor={'--MyPrimary'} color={'--MyWhite'}/>
                  <MediumButton text={'Отмена'} color={'--MyPrimary'}
                  backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
                </div>
              </div>
            ) : (
              <div className='addRateDetailed-modal-notification-container'>
                {largeFinish}
                <h3 className='addRateDetailed-modal-notification-title'>Тариф успешно изменен</h3>
              </div>
            )}
          </div>
        )}
        {!statusMessage && (
        <form className='addRateDetailed-modal-form' onSubmit={handleSubmit}>
        <div className='addRateDetailed-modal-form-inputs' >
          <label className='addRateDetailed-modal-label'>
          <span className='addRateDetailed-modal-label-title'>
            Название<span style={{ color: '#4495D1' }}>*</span>
          </span>
            <input placeholder='Введите название'
            type="text" value={rateName} onChange={(e) => setRateName(e.target.value)} />
          {rateName && (
            <div className='addRateDetailed-modal-label-clear' onClick={handleClearInput(setRateName)}>
              {clearInput}
            </div>
          )}
          </label>
          <div className='addRateDetailed-modal-timeCost-container'>
            <div className='addRateDetailed-modal-time-container'>
              <span className='addRateDetailed-modal-time-container-title'>Время</span>
              <div className='addRateDetailed-modal-time-inputs-container'>
                <div className="addRateDetailed-modal-time-input-container" onClick={handleFocusInput}>
                  <span className='addRateDetailed-modal-time-title-label'>C</span>
                  <div className='addRateDetailed-modal-time-input'>
                    <TimePicker
                      format={'HH:mm'}
                      placeholder='00:00'
                      showSecond={false}
                      onChange={(time) => {
                        setFirstTimer1(time?.format('HH:mm') || '');
                      }}
                      defaultValue={moment().startOf('day')}
                      defaultOpenValue={firstTimer1 ? moment(firstTimer1) : moment().startOf('day')}
                    />
                  </div>
                </div>
                <div className="addRateDetailed-modal-time-input-container" onClick={handleFocusInput}>
                  <span className='addRateDetailed-modal-time-title-label'>До</span>
                  <div className='addRateDetailed-modal-time-input'>
                    <TimePicker
                      placeholder='00:00'
                      format={'HH:mm'}
                      showSecond={false}
                      onChange={(time) => {
                        setFirstTimer2(time?.format('HH:mm') || '');
                      }}
                      defaultValue={firstTimer2 ? moment(firstTimer2, 'HH:mm') : moment().startOf('day')}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='addRateDetailed-modal-cost-container'>
              <span className='addRateDetailed-modal-time-container-title'>Цена тарифа</span>
              <div className='addRateDetailed-modal-time-inputs-container'>
                <div className="addRateDetailed-modal-time-input-container" onClick={handleFocusInput}>
                  <span className='addRateDetailed-modal-time-title-label'>Цена</span>
                  <div className='addRateDetailed-modal-time-input'>
                    <input placeholder='0 ₽/кВт•ч'
                    type="text" value={firstTimerPrice} onChange={(e) => {
                      const { value } = e.target;
                      if (value.length <= 5) {
                        if (/^\d*\.?\d*$/.test(value)) {
                          setFirstTimerPrice(value);
                        }
                      }
                    }}
                    maxLength={5} />
                  </div>
                </div>
              </div>
            </div>
            {/* {blocksCount !== 2 &&
              <LargeButtonWithIcon
                  LeftIcon={plusAdd}
                  height='44px'
                  width='44px'
                  text={''}
                  backgroundColor={'--MySecondaryBlue'}
                  onClick={handleAddBlock}
                  color={'--MyWhite'}
              />} */}
          </div>
          {showAdditionalBlock && <div className='addRateDetailed-modal-timeCost-container'>
            <div className='addRateDetailed-modal-time-container'>
              <span className='addRateDetailed-modal-time-container-title'>Время</span>
              <div className='addRateDetailed-modal-time-inputs-container'>
                <div className="addRateDetailed-modal-time-input-container" onClick={handleFocusInput}>
                  <span className='addRateDetailed-modal-time-title-label'>C</span>
                  <div className='addRateDetailed-modal-time-input'>
                    <TimePicker
                      disabled
                      format={'HH:mm'}
                      placeholder='00:00'
                      showSecond={false}
                      value={moment(firstTimer2, 'HH:mm')}
                      // onChange={(time) => {
                      //   setFirstTimer2(time?.format('HH:mm') || '');
                      // }}
                      defaultValue={firstTimer2 ? moment(firstTimer2, 'HH:mm') : moment().startOf('day')}
                    />
                  </div>
                </div>
                <div className="addRateDetailed-modal-time-input-container" onClick={handleFocusInput}>
                  <span className='addRateDetailed-modal-time-title-label'>До</span>
                  <div className='addRateDetailed-modal-time-input'>
                    <TimePicker
                      disabled
                      placeholder='00:00'
                      format={'HH:mm'}
                      showSecond={false}
                      onChange={(time) => {
                        setSecondTimer2(time?.format('HH:mm') || '');
                      }}
                      defaultValue={secondTimer2 ? moment(secondTimer2, 'HH:mm') : moment().startOf('day')}
                      />
                  </div>
                </div>
              </div>
            </div>
            <div className='addRateDetailed-modal-cost-container'>
              <span className='addRateDetailed-modal-time-container-title'>Цена тарифа</span>
              <div className='addRateDetailed-modal-time-inputs-container'>
                <div className="addRateDetailed-modal-time-input-container" onClick={handleFocusInput}>
                  <span className='addRateDetailed-modal-time-title-label'>Цена</span>
                  <div className='addRateDetailed-modal-time-input'>
                    <input placeholder='0 ₽/кВт•ч'
                    type="text" value={secondTimerPrice}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.length <= 5) {
                        if (/^\d*\.?\d*$/.test(value)) {
                          setSecondTimerPrice(value);
                        }
                      }
                    }}
                    maxLength={5} />
                  </div>
                </div>
              </div>
            </div>
            {/* <LargeButtonWithIcon
                LeftIcon={bucket32}
                height='44px'
                width='44px'
                text={''}
                backgroundColor={'--MySecondaryBlue'}
                onClick={handleDeleteBlock}
                color={'--MyWhite'}
              /> */}
          </div>}
          {isOrgDropdownOpen && (
              <div className="addRateDetailed-modal-dropdown addRateDetailed-modal-dropdown-long"
                style={{ display: isOrgDropdownOpen ? 'block' : 'none' }}
                ref={modelDropdownRef}
              >
                {Object.values(OrgNames).map((carName) => (
                  <div key={carName} onClick={() => handleOrgSelect(carName)}>{carName}</div>
                ))}
              </div>
          )}
        </div>
          <div className='addRateDetailed-modal-footer'>
            <div className='addRateDetailed-modal-buttons'>
                <MediumButton
                  // disabled={!isFormValid}
                  text={'Сохранить изменения'}
                  color={'--MyWhite'}
                  type='submit'
                  backgroundColor={'--MyPrimary'}
                />
              <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
            </div>
            <span><span style={{ color: '#4495D1' }}>*</span> Обязательные поля</span>
          </div>
        </form>)}
      </div>
    </div>
  );
};

export default UpdateRateDetailedModal;
