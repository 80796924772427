/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import './AddRateToConnectorModal.css';
import { useParams } from 'react-router-dom';
import {
  arrowHeader, clearInput, closeModal, largeLoading, largeError, largeFinish
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import ChargeService from '../../../services/ChargeService';
import { RateInfo } from '../../../models/response/RateResponse';

interface AddRateToConnectorModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  connectorId: string;
  ratesIds: string[];
  chargeStationId: string;
}

const AddRateToConnectorModal: React.FC<AddRateToConnectorModalProps> = ({
  onClose, isModalOpen, connectorId, ratesIds, chargeStationId
}) => {
  const { chargePointId } = useParams<{ chargePointId: string }>();
  const actualChargePointId = chargePointId || '';
  const [rate, setRate] = useState('');
  const [rateIdList, setRateIdList] = useState(ratesIds);

  const [submitting, setSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const [ratesEnum, setRatesEnum] = useState<RateInfo[] | []>([]);

  const [isFormValid, setIsFormValid] = useState(false);

  const modelDropdownRef = useRef(null);

  // Car Model

  const handleModelInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRate(e.target.value);
  };

  const handleModelInputClick = () => {
    setIsModelDropdownOpen((prevIsModelDropdownOpen) => !prevIsModelDropdownOpen);
  };

  const handleModelSelect = (selectedRate: RateInfo) => {
    setRate(selectedRate.title);
    setIsModelDropdownOpen(false);

    if (!rateIdList.includes(selectedRate.id)) {
      setRateIdList((prevRateIdList) => [...prevRateIdList, selectedRate.id]);
    }
  };

  const handleRetry = () => {
    setStatusMessage('');
  };

  const handleClearInput = (setState: React.Dispatch<React.SetStateAction<string>>) => () => {
    setState('');
  };

  const fetchRates = async () => {
    try {
      const response = await ChargeService.getRates([{
        key: 'CHARGER_ID',
        values: [
          actualChargePointId
        ]
      }
      ]);
      const rates = response.data.rows;
      const filteredRates = rates.filter((filteredRate: RateInfo) => !filteredRate.groupId);
      setRatesEnum(filteredRates);
    } catch {
      // console.log('error');
    }
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      setStatusMessage('Добавляем тариф');
      const response = await ChargeService.updateRateToConnector(rateIdList, actualChargePointId, connectorId);
      if (response.status === 200) {
        setStatusMessage('Тариф успешно добавлен');
        setSubmitting(false);
      }
    } catch (error: any) {
      setStatusMessage('Произошла ошибка при добавлении тарифа');
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const isAllFieldsFilled =
      rate.trim() !== '';

    setIsFormValid(isAllFieldsFilled);
  }, [rate]);

  useEffect(() => {
    fetchRates();
  }, []);

  return (
    <div className={`addRateToConnector-modal-background ${isModalOpen ? '' : 'addRateToConnector-modal-hidden'}`}>
      <div className="addRateToConnector-modal-content">
        <h1>Добавить тариф</h1>
        <div className="addRateToConnector-modal-close" onClick={onClose}>{closeModal}</div>
        {submitting && (
          <div className='addRateToConnector-modal-notification-container'>
            {largeLoading}
            <h3 className='addRateToConnector-modal-notification-title'>Добавляем тариф...</h3>
          </div>
        )}
        {!submitting && statusMessage && (
          <div>
            {statusMessage === 'Произошла ошибка при добавлении тарифа' ? (
              <div className='addRateToConnector-modal-notification-container'>
                {largeError}
                <h3 className='addRateToConnector-modal-notification-title'>{statusMessage}</h3>
                <div className='addRateToConnector-modal-error-buttons-container'>
                  <MediumButton
                    text={'Попробовать снова'}
                    onClick={handleRetry}
                    backgroundColor={'--MyPrimary'}
                    color={'--MyWhite'}
                  />
                  <MediumButton
                    text={'Отмена'}
                    color={'--MyPrimary'}
                    backgroundColor={'--MySecondaryBlue'}
                    onClick={onClose}
                  />
                </div>
              </div>
            ) : (
              <div className='addRateToConnector-modal-notification-container'>
                {largeFinish}
                <h3 className='addRateToConnector-modal-notification-title'>Тариф успешно добавлен</h3>
              </div>
            )}
          </div>
        )}
        {!statusMessage && (
        <form className='addRateToConnector-modal-form' onSubmit={handleSubmit}>
        <div className='addRateToConnector-modal-form-inputs' >
            <label className='addRateToConnector-modal-label addRateToConnector-modal-label-dropdown'>
              <span className='addRateToConnector-modal-label-title'>Тариф</span>
              <input
                readOnly
                placeholder='Не выбрано'
                type="text"
                value={rate}
                onChange={handleModelInputChange}
                onClick={handleModelInputClick}
                // onBlur={() => setIsModelDropdownOpen(false)}
              />
              {!rate ? (
                <div className='addRateToConnector-modal-label-arrow-down'>
                  {arrowHeader}
                </div>
              ) : (
                <div className='addRateToConnector-modal-label-clear' onClick={handleClearInput(setRate)}>
                  {clearInput}
                </div>
              )}
            </label>
              {isModelDropdownOpen && (
                <div className="addRateToConnector-modal-dropdown addRateToConnector-modal-dropdown-long"
                  style={{ display: isModelDropdownOpen ? 'block' : 'none' }}
                  ref={modelDropdownRef}
                >
                  {ratesEnum
                    .filter((rateTitle) => !ratesIds.includes(rateTitle.id))
                    .map((rateTitle, index) => (
                      <div key={index} onClick={() => handleModelSelect(rateTitle)}>{rateTitle.title}</div>
                    ))
                  }
                </div>
              )}
        </div>
          <div className='addRateToConnector-modal-footer'>
            <div className='addRateToConnector-modal-buttons'>
              <MediumButton disabled={!isFormValid} text={'Добавить тариф'} color={'--MyWhite'} type='submit'
              backgroundColor={'--MyPrimary'}/>
              <MediumButton text={'Отмена'} color={'--MyPrimary'}
              backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
            </div>
          </div>
        </form>)}
      </div>
    </div>
  );
};

export default AddRateToConnectorModal;
