/* eslint-disable no-lone-blocks */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import './AddStationModal.css';
import {
  clearInput, closeModal, largeLoading, largeError, largeFinish,
  plusAdd,
  connectors28pxIconsForChargeCreate,
  minus,
  arrowHeader
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import LargeButtonWithIcon from '../../buttons/LargeButtonWithIcon';
import { ChargeVoltage, ChargeSocketType, ChargerCreate } from '../../../models/response/ChargeResponse';
import { OCPIConnectorsMapReversed } from '../../Table/MyTable';
import ChargeService from '../../../services/ChargeService';

interface AddStationModalModalProps {
  onClose: () => void;
  isModalOpen: boolean;
}

const AddStationModal: React.FC<AddStationModalModalProps> = ({ onClose, isModalOpen }) => {
  const [stationId, setStationId] = useState<string>('');
  const [stationAddress, setStationAddress] = useState<string>('');
  const [stationName, setStationName] = useState<string>('');
  const [stationModel, setStationModel] = useState<string>('');
  const [stationVendor, setStationVendor] = useState<string>('');
  const [stationPrice, setStationPrice] = useState<string>('');
  const [stationCity, setStationCity] = useState<string>('');
  const [stationLong, setStationLong] = useState<string>('');
  const [stationLat, setStationLat] = useState<string>('');

  const [EVSECount, setEVSECount] = useState<number>(1);
  const [connectorCounts, setConnectorCounts] = useState<Record<number, number>>({ 0: 1 });
  const [selectedConnectors, setSelectedConnectors] = useState<Record<number, string | null>>({});
  const [isConnectorDropdownOpen, setIsConnectorDropdownOpen] = useState(false);
  const [isConnectorVoltageDropdownOpen, setIsConnectorVoltageDropdownOpen] = useState(false);
  const [isConnectorSocketTypeDropdownOpen, setIsConnectorSocketTypeDropdownOpen] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const [isFormValid, setIsFormValid] = useState(false);
  const connectorDropdownRef = useRef(null);
  const connectorVoltageDropdownRef = useRef(null);
  const connectorSocketTypeDropdownRef = useRef(null);

  const [connectorPowers, setConnectorPowers] = useState<Record<number, string[]>>({ 0: [''] });
  const [connectorTypes, setConnectorTypes] = useState<string[][]>([['']]);
  const [connectorVoltageType, setConnectorVoltageType] = useState<string[][]>([['']]);
  const [connectorSocketType, setConnectorSocketType] = useState<string[][]>([['']]);
  const [selectedEvseIndex, setSelectedEvseIndex] = useState<number | null>(null);
  const [selectedConnectorIndex, setSelectedConnectorIndex] = useState<number | null>(null);

  const handlePowerChange = (evseIndex: number, connectorIndex: number, value: string) => {
    const sanitizedValue = value.replace(/[^0-9.-]/g, '');

    setConnectorPowers((prevPowers) => ({
      ...prevPowers,
      [evseIndex]: prevPowers[evseIndex].map((power, index) => (index === connectorIndex ? sanitizedValue : power)),
    }));
  };

  const handleConnectorInputClick = (evseIndex: number, connectorIndex: number) => {
    setSelectedEvseIndex(evseIndex);
    setSelectedConnectorIndex(connectorIndex);
    setIsConnectorDropdownOpen((prevIsOpen) => !prevIsOpen);
    setIsConnectorVoltageDropdownOpen(false);
    setIsConnectorSocketTypeDropdownOpen(false);
  };

  const handleVoltageInputClick = (evseIndex: number, connectorIndex: number) => {
    setSelectedEvseIndex(evseIndex);
    setSelectedConnectorIndex(connectorIndex);
    setIsConnectorVoltageDropdownOpen((prevIsOpen) => !prevIsOpen);
    setIsConnectorDropdownOpen(false);
    setIsConnectorSocketTypeDropdownOpen(false);
  };

  const handleSocketTypeInputClick = (evseIndex: number, connectorIndex: number) => {
    setSelectedEvseIndex(evseIndex);
    setSelectedConnectorIndex(connectorIndex);
    setIsConnectorSocketTypeDropdownOpen((prevIsOpen) => !prevIsOpen);
    setIsConnectorDropdownOpen(false);
    setIsConnectorVoltageDropdownOpen(false);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    const station: ChargerCreate = {
      evses: [...Array(EVSECount)].map((_, evseIndex) => ({
        connectors: [...Array(connectorCounts[evseIndex] || 1)].map((empty, connectorIndex) => {
          const connectorType = connectorTypes[evseIndex][connectorIndex];
          const standard = OCPIConnectorsMapReversed[connectorType as keyof typeof OCPIConnectorsMapReversed];

          return {
            standard: connectorTypes[evseIndex][connectorIndex],
            max_electric_power: Number(connectorPowers[evseIndex][connectorIndex]),
            power_type: connectorVoltageType[evseIndex][connectorIndex],
            format: connectorSocketType[evseIndex][connectorIndex],
          };
        })
      })),
      coordinates: {
        latitude: parseFloat(stationLat),
        longitude: parseFloat(stationLong)
      },
      model: stationModel,
      vendor: stationVendor,
      identifier: stationId,
      city: stationCity,
      name: stationName,
      address: stationAddress,
      incomePrice: stationPrice
    };

    try {
      const response = await ChargeService.addStation(station);
      if (response.status === 200 || response.status === 201) {
        setStatusMessage('Станция добавлена');
        setSubmitting(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (error) {
      setStatusMessage('Произошла ошибка при добавлении станции');
      setSubmitting(false);
    }
  };

  const handleRetry = () => {
    setStatusMessage('');
  };

  const handleClearInput = (setState: React.Dispatch<React.SetStateAction<string>>) => () => {
    setState('');
  };

  // Коннекторы

  const handleConnectorClick = (key: string, rowIndex: number) => {
    setSelectedConnectors((prevState) => ({
      ...prevState,
      [rowIndex]: key === prevState[rowIndex] ? null : key,
    }));
  };

  const handleAddEVSE = () => {
    setEVSECount((prevCount) => {
      const newEVSECount = prevCount + 1;

      setConnectorCounts((prevCounts) => ({
        ...prevCounts,
        [newEVSECount - 1]: 1,
      }));

      setConnectorPowers((prevPowers) => ({
        ...prevPowers,
        [newEVSECount - 1]: [''],
      }));

      setConnectorTypes((prevTypes) => {
        const newTypes = [...prevTypes];
        newTypes[newEVSECount - 1] = [''];
        return newTypes;
      });

      setConnectorVoltageType((prevVoltageTypes) => {
        const newVoltageTypes = [...prevVoltageTypes];
        newVoltageTypes[newEVSECount - 1] = [''];
        return newVoltageTypes;
      });

      setConnectorSocketType((prevSocketTypes) => {
        const newSocketTypes = [...prevSocketTypes];
        newSocketTypes[newEVSECount - 1] = [''];
        return newSocketTypes;
      });

      return newEVSECount;
    });
  };

  const handleAddConnector = (evseIndex: number) => {
    setConnectorCounts((prevCounts) => ({
      ...prevCounts,
      [evseIndex]: (prevCounts[evseIndex] || 1) + 1,
    }));

    setConnectorPowers((prevPowers) => {
      const newPowers = Array.isArray(prevPowers[evseIndex]) ? [...prevPowers[evseIndex]] : [];
      newPowers.push('');
      return {
        ...prevPowers,
        [evseIndex]: newPowers,
      };
    });

    setConnectorTypes((prevTypes) => {
      const newTypes = Array.isArray(prevTypes) ? [...prevTypes] : [];
      if (!Array.isArray(newTypes[evseIndex])) {
        newTypes[evseIndex] = [];
      }
      newTypes[evseIndex].push('');
      return newTypes;
    });

    setConnectorSocketType((prevSocketType) => {
      const newSocketTypes = Array.isArray(prevSocketType) ? [...prevSocketType] : [];
      if (!Array.isArray(newSocketTypes[evseIndex])) {
        newSocketTypes[evseIndex] = [];
      }
      newSocketTypes[evseIndex].push('');
      return newSocketTypes;
    });

    setConnectorVoltageType((prevVoltageType) => {
      const newVoltageTypes = Array.isArray(prevVoltageType) ? [...prevVoltageType] : [];
      if (!Array.isArray(newVoltageTypes[evseIndex])) {
        newVoltageTypes[evseIndex] = [];
      }
      newVoltageTypes[evseIndex].push('');
      return newVoltageTypes;
    });
  };

  const handleRemoveConnector = (evseIndex: number, connectorIndex: number) => {
    setConnectorCounts((prevCounts) => {
      const newCount = (prevCounts[evseIndex] || 1) - 1;
      if (newCount <= 0) {
        const { [evseIndex]: _, ...restCounts } = prevCounts;
        return restCounts;
      }
      return {
        ...prevCounts,
        [evseIndex]: newCount,
      };
    });

    setConnectorPowers((prevPowers) => {
      const newPowers = Array.isArray(prevPowers[evseIndex]) ? [...prevPowers[evseIndex]] : [];
      if (connectorIndex < newPowers.length) {
        newPowers.splice(connectorIndex, 1);
      }
      return {
        ...prevPowers,
        [evseIndex]: newPowers,
      };
    });

    setConnectorTypes((prevTypes) => {
      const newTypes = Array.isArray(prevTypes) ? [...prevTypes] : [];
      if (Array.isArray(newTypes[evseIndex])) {
        newTypes[evseIndex].splice(connectorIndex, 1);
      }
      return newTypes;
    });

    setConnectorSocketType((prevSocketType) => {
      const newSocketTypes = Array.isArray(prevSocketType) ? [...prevSocketType] : [];
      if (Array.isArray(newSocketTypes[evseIndex])) {
        newSocketTypes[evseIndex].splice(connectorIndex, 1);
      }
      return newSocketTypes;
    });

    setConnectorVoltageType((prevVoltageType) => {
      const newVoltageTypes = Array.isArray(prevVoltageType) ? [...prevVoltageType] : [];
      if (Array.isArray(newVoltageTypes[evseIndex])) {
        newVoltageTypes[evseIndex].splice(connectorIndex, 1);
      }
      return newVoltageTypes;
    });
  };

  const handleRemoveEVSE = (evseIndex: any) => {
    if (EVSECount > 1) {
      setEVSECount((prevCount) => prevCount - 1);

      setConnectorCounts((prevCounts) => {
        const updatedCounts = { ...prevCounts };
        delete updatedCounts[evseIndex];

        for (let i = evseIndex; i < Object.keys(updatedCounts).length; i++) {
          updatedCounts[i] = updatedCounts[i + 1];
        }

        delete updatedCounts[Object.keys(updatedCounts).length];
        return updatedCounts;
      });

      setConnectorPowers((prevPowers) => {
        const updatedPowers = { ...prevPowers };
        delete updatedPowers[evseIndex];

        for (let i = evseIndex; i < Object.keys(updatedPowers).length; i++) {
          updatedPowers[i] = updatedPowers[i + 1];
        }

        delete updatedPowers[Object.keys(updatedPowers).length];
        return updatedPowers;
      });

      setConnectorTypes((prevTypes) => {
        const updatedTypes = [...prevTypes];
        updatedTypes.splice(evseIndex, 1);

        return updatedTypes;
      });

      setConnectorSocketType((prevTypes) => {
        const updatedTypes = [...prevTypes];
        updatedTypes.splice(evseIndex, 1);

        return updatedTypes;
      });

      setConnectorVoltageType((prevTypes) => {
        const updatedTypes = [...prevTypes];
        updatedTypes.splice(evseIndex, 1);

        return updatedTypes;
      });
    }
  };

  const handleConnectorTypeSelect = (evseIndex: number, connectorIndex: number, type: string) => {
    setConnectorTypes((prev) => {
      const newConnectorTypes = [...prev];
      if (!newConnectorTypes[evseIndex]) {
        newConnectorTypes[evseIndex] = [];
      }
      if (connectorIndex >= newConnectorTypes[evseIndex].length) {
        newConnectorTypes[evseIndex].length = connectorIndex + 1;
      }
      newConnectorTypes[evseIndex][connectorIndex] = type;
      return newConnectorTypes;
    });
    setIsConnectorDropdownOpen(false);
  };

  const handleSocketTypeSelect = (evseIndex: number, connectorIndex: number, type: string) => {
    setConnectorSocketType((prev) => {
      const newSocketTypes = [...prev];
      if (!newSocketTypes[evseIndex]) {
        newSocketTypes[evseIndex] = [];
      }
      if (connectorIndex >= newSocketTypes[evseIndex].length) {
        newSocketTypes[evseIndex].length = connectorIndex + 1;
      }
      newSocketTypes[evseIndex][connectorIndex] = type;
      return newSocketTypes;
    });
    setIsConnectorSocketTypeDropdownOpen(false);
  };

  const handleVoltageTypeSelect = (evseIndex: number, connectorIndex: number, type: string) => {
    setConnectorVoltageType((prev) => {
      const newVoltageTypes = [...prev];
      if (!newVoltageTypes[evseIndex]) {
        newVoltageTypes[evseIndex] = [];
      }
      if (connectorIndex >= newVoltageTypes[evseIndex].length) {
        newVoltageTypes[evseIndex].length = connectorIndex + 1;
      }
      newVoltageTypes[evseIndex][connectorIndex] = type;
      return newVoltageTypes;
    });
    setIsConnectorVoltageDropdownOpen(false);
  };

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const sanitizedValue = value.replace(/[^0-9.-]/g, '');
    setter(sanitizedValue);
  };

  useEffect(() => {
    const isAllFieldsFilled = stationAddress.trim() !== '' &&
      stationCity.trim() !== '' &&
      stationLat.trim() !== '' &&
      stationLong.trim() !== '' &&
      stationName.trim() !== '' &&
      stationId.trim() !== '' &&
      stationModel.trim() !== '' &&
      stationVendor.trim() !== '' &&
      stationPrice.trim() !== '' &&
      Object.values(connectorPowers).flat().every((power) => power.trim() !== '') &&
      Object.values(connectorTypes).flat().every((type) => type.trim() !== '') &&
      Object.values(connectorSocketType).flat().every((socket) => socket.trim() !== '') &&
      Object.values(connectorVoltageType).flat().every((voltage) => voltage.trim() !== '');

    setIsFormValid(isAllFieldsFilled);
  }, [
    stationAddress,
    stationCity,
    stationLat,
    stationLong,
    stationName,
    stationId,
    stationModel,
    stationVendor,
    stationPrice,
    connectorPowers,
    connectorTypes,
    connectorSocketType,
    connectorVoltageType,
  ]);

  return (
    <div className={`addStation-modal-background ${isModalOpen ? '' : 'addStation-modal-hidden'}`}>
      <div className='addStation-modal'>
        <div className="addStation-modal-content">
          <h2>Добавить станцию</h2>
          <div className="addStation-modal-close" onClick={onClose}>{closeModal}</div>
          {submitting && (
            <div className='addStation-modal-notification-container'>
              {largeLoading}
              <h3 className='addStation-modal-notification-title'>Добавляем станцию</h3>
            </div>
          )}
          {!submitting && statusMessage && (
            <div>
              {statusMessage === 'Произошла ошибка при добавлении станции' ? (
                <div className='addStation-modal-notification-container'>
                  {largeError}
                  <h3 className='addStation-modal-notification-title'>{statusMessage}</h3>
                  <div className='addStation-modal-error-buttons-container'>
                    <MediumButton text={'Попробовать снова'} onClick={handleRetry} backgroundColor={'--MyPrimary'} color={'--MyWhite'} />
                    <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
                  </div>
                </div>
              ) : (
                <div className='addStation-modal-notification-container'>
                  {largeFinish}
                  <h3 className='addStation-modal-notification-title'>Станция добавлена</h3>
                </div>
              )}
            </div>
          )}
          {!statusMessage && !submitting && (
            <form className='addStation-modal-form' onSubmit={handleSubmit}>
              <div className='addStation-modal-form-inputs'>
                <label className='addStation-modal-label'>
                  <span className='addStation-modal-label-title'>Адрес</span>
                  <input
                    placeholder='Введите адрес'
                    type="text"
                    value={stationAddress}
                    onChange={(e) => setStationAddress(e.target.value)}
                  />
                  {stationAddress && (
                    <div className='addStation-modal-label-clear' onClick={handleClearInput(setStationAddress)}>
                      {clearInput}
                    </div>
                  )}
                </label>
                <label className='addStation-modal-label'>
                  <span className='addStation-modal-label-title'>Город</span>
                  <input
                    placeholder='Введите город'
                    type="text"
                    value={stationCity}
                    onChange={(e) => setStationCity(e.target.value)}
                  />
                  {stationCity && (
                    <div className='addStation-modal-label-clear' onClick={handleClearInput(setStationCity)}>
                      {clearInput}
                    </div>
                  )}
                </label>
                <div className='addStation-modal-label-row'>
                  <label className='addStation-modal-label addStation-modal-label-short'>
                    <span className='addStation-modal-label-title'>
                      Координаты
                    </span>
                  </label>
                  <div className='addStation-modal-label-CoordinatesRow'>
                    <label className='addStation-modal-labelWithDes'>
                      <span className='addStation-modal-labelWithDes-title'>Долгота</span>
                      <span className='addStation-modal-labelWithDes-value'>
                        <input
                          placeholder='00.0000'
                          type="text"
                          value={stationLat}
                          onChange={handleInputChange(setStationLat)}
                        />
                      </span>
                    </label>
                    <span>-</span>
                    <label className='addStation-modal-labelWithDes'>
                      <span className='addStation-modal-labelWithDes-title'>Широта</span>
                      <span className='addStation-modal-labelWithDes-value'>
                        <input
                          placeholder='00.0000'
                          type="text"
                          value={stationLong}
                          onChange={handleInputChange(setStationLong)}
                        />
                      </span>
                    </label>
                  </div>
                </div>
                <label className='addStation-modal-label'>
                  <span className='addStation-modal-label-title'>Название станции</span>
                  <input
                    placeholder='Введите название'
                    type="text"
                    value={stationName}
                    onChange={(e) => setStationName(e.target.value)}
                  />
                  {stationName && (
                    <div className='addStation-modal-label-clear' onClick={handleClearInput(setStationName)}>
                      {clearInput}
                    </div>
                  )}
                </label>
                <label className='addStation-modal-label'>
                  <span className='addStation-modal-label-title'>Серийный номер</span>
                  <input
                    placeholder='Введите номер'
                    type="text"
                    value={stationId}
                    onChange={(e) => setStationId(e.target.value)}
                  />
                  {stationId && (
                    <div className='addStation-modal-label-clear' onClick={handleClearInput(setStationId)}>
                      {clearInput}
                    </div>
                  )}
                </label>
                <label className='addStation-modal-label'>
                  <span className='addStation-modal-label-title'>Модель</span>
                  <input
                    placeholder='Введите модель'
                    type="text"
                    value={stationModel}
                    onChange={(e) => setStationModel(e.target.value)}
                  />
                  {stationModel && (
                    <div className='addStation-modal-label-clear' onClick={handleClearInput(setStationModel)}>
                      {clearInput}
                    </div>
                  )}
                </label>
                <label className='addStation-modal-label'>
                  <span className='addStation-modal-label-title'>Вендор</span>
                  <input
                    placeholder='Введите вендор'
                    type="text"
                    value={stationVendor}
                    onChange={(e) => setStationVendor(e.target.value)}
                  />
                  {stationVendor && (
                    <div className='addStation-modal-label-clear' onClick={handleClearInput(setStationVendor)}>
                      {clearInput}
                    </div>
                  )}
                </label>
                <label className='addStation-modal-label'>
                  <span className='addStation-modal-label-title'>Цена, ₽</span>
                  <input
                    placeholder='Введите цену за электричество'
                    type="number"
                    value={stationPrice}
                    onChange={(e) => setStationPrice(e.target.value)}
                  />
                  {stationPrice && (
                    <div className='addStation-modal-label-clear' onClick={handleClearInput(setStationPrice)}>
                      {clearInput}
                    </div>
                  )}
                </label>
                <div className='addStation-modal-label-row-connectors'>
                {[...Array(EVSECount)].map((_, evseIndex) => (
                  <div key={`evse-${evseIndex}`} className='addStation-modal-label-row'>
                    <div className='addStation-modal-label-grid-container-short'>
                      <label className='addStation-modal-label addStation-modal-label-short'>
                        <span className='addStation-modal-label-title'>
                          EVSE {evseIndex + 1}
                        </span>
                      </label>
                      {EVSECount > 1 && (
                        <LargeButtonWithIcon
                          text={'Удалить EVSE'}
                          backgroundColor={'--MySecondaryBlue'}
                          color={'--MyPrimary'}
                          fontSize='12px'
                          height='32px'
                          onClick={() => handleRemoveEVSE(evseIndex)}
                          type='button'
                          padding='8px 12px'
                        />
                      )}
                    </div>
                    <div className='addStation-modal-label-connectors-grid-list'>
                      {[...Array(connectorCounts[evseIndex] || 1)].map((connectorCount, connectorIndex) => (
                        <div key={`connector-${evseIndex}-${connectorIndex}`} className='addStation-modal-label-connectors-grid'>
                          <span className='addStation-modal-label-connector-index'>Коннектор {connectorIndex + 1}</span>
                          <div className='addStation-modal-label-connectors-grid-container'>
                            <label className='addStation-modal-labelWithDes'>
                              <span className='addStation-modal-labelWithDes-title'>Мощность, кВт</span>
                              <span className='addStation-modal-labelWithDes-value'>
                                <input
                                  placeholder='кВт'
                                  type="text"
                                  value={connectorPowers[evseIndex][connectorIndex]}
                                  onChange={(e) => handlePowerChange(evseIndex, connectorIndex, e.target.value)}
                                />
                              </span>
                            </label>
                            <div className='addStation-modal-label-connectors-grid'>
                              <label className='addStation-modal-label addStation-modal-label-dropdown'>
                                {connectorTypes[evseIndex][connectorIndex] && (
                                  <span dangerouslySetInnerHTML={{ __html: connectors28pxIconsForChargeCreate[connectorTypes[evseIndex][connectorIndex]] }} style={{ height: '28px' }} />
                                )}
                                <input
                                  readOnly
                                  placeholder='Коннектор'
                                  type="text"
                                  value={connectorTypes[evseIndex][connectorIndex]}
                                  onClick={() => handleConnectorInputClick(evseIndex, connectorIndex)}
                                />
                                <div className='addStation-modal-label-arrow-down'>
                                  {arrowHeader}
                                </div>
                              </label>
                              {isConnectorDropdownOpen && selectedEvseIndex === evseIndex && selectedConnectorIndex === connectorIndex && (
                                <div
                                  className="addStation-modal-dropdown-container"
                                  style={{ display: isConnectorDropdownOpen ? 'block' : 'none' }}
                                  ref={connectorDropdownRef}
                                >
                                  <div className='addStation-modal-dropdown-content'>
                                    {Object.keys(connectors28pxIconsForChargeCreate).map((type) => (
                                      <div
                                        key={`dropdown-${type}`}
                                        className='addStation-modal-dropdown-option'
                                        onClick={() => handleConnectorTypeSelect(evseIndex, connectorIndex, type)}
                                      >
                                        <span dangerouslySetInnerHTML={{ __html: connectors28pxIconsForChargeCreate[type] }} />
                                        <span>{type}</span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='addStation-modal-label-connectors-grid-container'>
                            <div className='addStation-modal-label-grid-container-short'>
                              <label className='addStation-modal-labelWithDes addStation-modal-labelWithDes-dropDown'>
                                <input
                                  readOnly
                                  placeholder='Тип'
                                  type="text"
                                  value={connectorSocketType[evseIndex][connectorIndex]}
                                  onClick={() => handleSocketTypeInputClick(evseIndex, connectorIndex)}
                                />
                                <div className='addStation-modal-label-arrow-down'>
                                  {arrowHeader}
                                </div>
                              </label>
                              {isConnectorSocketTypeDropdownOpen && selectedEvseIndex === evseIndex && selectedConnectorIndex === connectorIndex && (
                                <div
                                  className="addStation-modal-dropdown-container-labelWithDes"
                                  style={{ display: isConnectorSocketTypeDropdownOpen ? 'block' : 'none' }}
                                  ref={connectorSocketTypeDropdownRef}
                                >
                                  <div className='addStation-modal-dropdown-content'>
                                    {Object.keys(ChargeSocketType).map((type) => (
                                      <div
                                        key={`dropdown-${type}`}
                                        className='addStation-modal-dropdown-option'
                                        onClick={() => handleSocketTypeSelect(evseIndex, connectorIndex, type)}
                                      >
                                        <span>{type}</span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className='addStation-modal-label-connectors-grid'>
                              <label className='addStation-modal-label addStation-modal-label-dropdown'>
                                <input
                                  readOnly
                                  placeholder='Тип напряжения'
                                  type="text"
                                  value={connectorVoltageType[evseIndex][connectorIndex]}
                                  onClick={() => handleVoltageInputClick(evseIndex, connectorIndex)}
                                />
                                <div className='addStation-modal-label-arrow-down'>
                                  {arrowHeader}
                                </div>
                              </label>
                              {isConnectorVoltageDropdownOpen && selectedEvseIndex === evseIndex && selectedConnectorIndex === connectorIndex && (
                                <div
                                  className="addStation-modal-dropdown-container"
                                  style={{ display: isConnectorVoltageDropdownOpen ? 'block' : 'none' }}
                                  ref={connectorVoltageDropdownRef}
                                >
                                  <div className='addStation-modal-dropdown-content'>
                                    {Object.keys(ChargeVoltage).map((type) => (
                                      <div
                                        key={`dropdown-${type}`}
                                        className='addStation-modal-dropdown-option'
                                        onClick={() => handleVoltageTypeSelect(evseIndex, connectorIndex, type)}
                                      >
                                        <span>{type}</span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {connectorCounts[evseIndex] > 1 && (
                            <LargeButtonWithIcon
                              text={'Удалить коннектор'}
                              backgroundColor={'--MySecondaryBlue'}
                              color={'--MyPrimary'}
                              fontSize='12px'
                              height='32px'
                              onClick={() => handleRemoveConnector(evseIndex, connectorIndex)}
                              type='button'
                              padding='8px 12px'
                            />
                          )}
                        </div>
                      ))}
                      <div className='addStation-modal-addConnector-buttons'>
                        <LargeButtonWithIcon
                          text={'Добавить коннектор'}
                          LeftIcon={plusAdd}
                          iconMarginRight='8px'
                          backgroundColor={'--MySecondaryBlue'}
                          color={'--MyPrimary'}
                          fontSize='12px'
                          height='32px'
                          onClick={() => handleAddConnector(evseIndex)}
                          type='button'
                          padding='8px 12px'
                        />
                      </div>
                    </div>
                  </div>
                ))}
                  <div className='addStation-modal-addButton'>
                    <LargeButtonWithIcon
                      text={'Добавить EVSE'}
                      LeftIcon={plusAdd}
                      iconMarginRight='8px'
                      backgroundColor={'--MySecondaryBlue'}
                      color={'--MyPrimary'}
                      fontSize='12px'
                      height='32px'
                      onClick={handleAddEVSE}
                      type='button'
                      padding='8px 12px'
                    />
                  </div>
                </div>
              </div>
              <div className='addStation-modal-footer'>
                <div className='addStation-modal-buttons'>
                  <MediumButton
                    height='36px'
                    disabled={!isFormValid}
                    text={'Создать станцию'}
                    color={'--MyWhite'}
                    type='submit'
                    backgroundColor={'--MyPrimary'}
                  />
                  <MediumButton
                    height='36px'
                    text={'Отмена'}
                    color={'--MyPrimary'}
                    backgroundColor={'--MySecondaryBlue'}
                    onClick={onClose}
                  />
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddStationModal;
