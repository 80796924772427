/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './AddCarTestModal.css';
// import { useParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  clearInput,
  closeModal, largeError, largeFinish, largeLoading
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import AuthService from '../../../services/AuthService';
import MyCheckbox from '../../Checkboxes/Checkbox';

interface AddCarTestProps {
  onClose: () => void;
  isModalOpen: boolean;
  carModel: string | null;
}

const AddCarTestModal: React.FC<AddCarTestProps> = ({
  onClose, isModalOpen, carModel
}) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [comment, setComment] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({
    block1: false,
    block2: false,
    block3: false,
    block4: false,
    block5: false,
    block6: false
  });

  const handleCheckboxChange = (blockName: string) => {
    setCheckedItems((prev) => ({
      ...prev,
      [blockName]: !prev[blockName],
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowSuccessMessage(true);
    try {
      // if (carModel) {
      // }
    } catch (error) {
      setSubmitting(false);
      setStatusMessage('Возникла проблема при тестировании');
      setShowSuccessMessage(false);
    }
  };

  const handleRetry = () => {
    setStatusMessage('');
  };

  return (
    <div className={`addCarTest-modal-background ${isModalOpen ? '' : 'addCarTest-modal-hidden'}`}>
      <div className="addCarTest-modal-content">
        <div className="addCarTest-modal-close" onClick={onClose}>{closeModal}</div>
        {statusMessage && !showSuccessMessage && (
          <div>
            {!showSuccessMessage && statusMessage === 'Возникла проблема при тестировании' ? (
              <div className='addCarTest-modal-notification-container'>
                {largeError}
                <h3 className='addCarTest-modal-notification-title'>{statusMessage}</h3>
                <div className='addCarTest-modal-error-buttons-container'>
                  <MediumButton text={'Попробовать снова'} onClick={handleRetry} backgroundColor={'--MyPrimary'} color={'--MyWhite'}/>
                  <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
                </div>
              </div>
            ) : (
              <div className='addCarTest-modal-notification-container'>
                {largeFinish}
                <h3 className='addCarTest-modal-notification-title'>Тестирование завершено</h3>
              </div>
            )}
          </div>
        )}
        {!statusMessage && !showSuccessMessage && (
          <>
            <div className='addCarTest-modal-form-header'>
              <h2>Тестирование</h2>
              <span>Отметьте пункты, которые успешно прошли проверку</span>
            </div>
            <form className='addCarTest-modal-form' onSubmit={handleSubmit}>
              <div className='addCarTest-modal-form-container'>
                <div className='addCarTest-modal-form-block'>
                  <span className='addCarTest-modal-form-title'>Монтажная часть</span>
                  <div className='addCarTest-modal-form-checkList-container'>
                    <div className={`addCarTest-modal-form-checkList-block ${checkedItems.block1 ? 'checked' : 'unchecked'}`}>
                      <MyCheckbox
                        checked={checkedItems.block1}
                        onCheckboxChange={() => handleCheckboxChange('block1')}
                      />
                      <div className='addCarTest-modal-form-checkList-block-container'>
                        <div className='addCarTest-modal-form-checkList-block-container-title'>
                          Световая индикация блока телематики Вега
                        </div>
                        <div className='addCarTest-modal-form-checkList-block-container-options'>
                          <ul>
                            <li>Красный светодиод – горит</li>
                            <li>Синий светодиод – горит или мигает</li>
                            <li>Зеленый светодиод – горит или мигает</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`addCarTest-modal-form-checkList-block ${checkedItems.block2 ? 'checked' : 'unchecked'}`}>
                    <MyCheckbox
                      checked={checkedItems.block2}
                      onCheckboxChange={() => handleCheckboxChange('block2')}
                    />
                    <div className='addCarTest-modal-form-checkList-block-container'>
                      <div className='addCarTest-modal-form-checkList-block-container-title'>
                        Уровень сигнала модема блока телематики Вега
                      </div>
                      <div className='addCarTest-modal-form-checkList-block-container-options'>
                        <ul>
                          <label>Состояние → Сеть</label>
                          <li>Уровень сигнала не равен 99</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className={`addCarTest-modal-form-checkList-block ${checkedItems.block3 ? 'checked' : 'unchecked'}`}>
                    <MyCheckbox
                      checked={checkedItems.block3}
                      onCheckboxChange={() => handleCheckboxChange('block3')}
                    />
                    <div className='addCarTest-modal-form-checkList-block-container'>
                      <div className='addCarTest-modal-form-checkList-block-container-title'>
                        Корректность подключения кан-шин
                      </div>
                      <div className='addCarTest-modal-form-checkList-block-container-options'>
                        <ul>
                          <label>Состояние → CAN-Датчики</label>
                          <li>Ready to start – актуальное значение</li>
                          <li>Заряд АКБ (%) – актуальное значение</li>
                          <li>Запас хода на АКБ (км) – актуальное значение</li>
                        </ul>
                        <ul>
                          <label>Состояние → CAN-Сканер → Запустить</label>
                          <li>Отсутствуют сообщения last error: form error либо last error: stuff error</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className={`addCarTest-modal-form-checkList-block ${checkedItems.block4 ? 'checked' : 'unchecked'}`}>
                    <MyCheckbox
                      checked={checkedItems.block4}
                      onCheckboxChange={() => handleCheckboxChange('block4')}
                    />
                    <div className='addCarTest-modal-form-checkList-block-container'>
                      <div className='addCarTest-modal-form-checkList-block-container-title'>
                        Корректность обучения иммобилайзера
                      </div>
                      <div className='addCarTest-modal-form-checkList-block-container-options'>
                        <ul>
                          <label>На закрытом центральном замке Состояние → Блок расширения → Цифровой выход 1: ВКЛ</label>
                          <li>Автомобиль запустится без ключа</li>
                          <label>После проверки выполните Цифровой выход 1: ВЫКЛ</label>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='addCarTest-modal-form-block'>
                  <span className='addCarTest-modal-form-title'>Проверка приложения Evolute</span>
                  <div className='addCarTest-modal-form-checkList-container'>
                    <div className={`addCarTest-modal-form-checkList-block ${checkedItems.block5 ? 'checked' : 'unchecked'}`}>
                    <MyCheckbox
                      checked={checkedItems.block5}
                      onCheckboxChange={() => handleCheckboxChange('block5')}
                    />
                      <div className='addCarTest-modal-form-checkList-block-container'>
                        <div className='addCarTest-modal-form-checkList-block-container-title'>
                          Функционал приложения
                        </div>
                        <div className='addCarTest-modal-form-checkList-block-container-options'>
                          <ul>
                            <li>Центральный замок</li>
                            <div>
                              {carModel} ставится и снимается с охранной сигнализации. Открывается и закрывается центральный замок.
                            </div>
                            <li>Прогрев</li>
                            <span>
                              {carModel}, находясь на охране, включит зажигание и климат в режим HI.
                            </span>
                            <li>Охлаждение</li>
                            <span>
                              {carModel}, находясь на охране, включит зажигание и климат в режим LO.
                            </span>
                            <li>Поиск</li>
                            <span>
                              {carModel} кратко задействует внешние осветительные приборы и клаксон.
                            </span>
                            <li>Открыть багажник</li>
                            <span>
                              {carModel} откроет и закроет багажник.
                            </span>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`addCarTest-modal-form-checkList-block ${checkedItems.block6 ? 'checked' : 'unchecked'}`}>
                    <MyCheckbox
                      checked={checkedItems.block6}
                      onCheckboxChange={() => handleCheckboxChange('block6')}
                    />
                    <div className='addCarTest-modal-form-checkList-block-container'>
                      <div className='addCarTest-modal-form-checkList-block-container-title'>
                        Телематические данные при включенном режиме Реди
                      </div>
                      <div className='addCarTest-modal-form-checkList-block-container-options'>
                        <ul>
                          <li>Процент тяговой батареи – отображается корректное значение</li>
                          <li>Запас хода от тяговой батареи – отображается корректное значение</li>
                          <li>Температура охлаждающей жидкости – отображается корректное значение</li>
                          <li>Температура батареи – отображается корректное значение</li>
                          <li>Напряжение 12В АКБ – отображается корректное значение</li>
                          <li>Одометр – отображается корректное значение</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='addCarTest-modal-form-block'>
                  <span className='addCarTest-modal-form-title'>Комментарий к тестированию</span>
                  <div className='addCarTest-modal-form-comments-container'>
                  <label className='addCarTest-modal-label'>
                    <span className='addCarTest-modal-label-title'>Комментарий</span>
                      <textarea
                        placeholder='Введите текст'
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                  </label>
                  </div>
                </div>
              </div>
              <div className='addCarTest-modal-buttons'>
                <MediumButton text={'Завершить тестирование'} color={'--MyWhite'} type="submit" backgroundColor={'--MyPrimary'} />
                <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
              </div>
            </form>
          </>)}
      </div>
    </div>
  );
};

export default AddCarTestModal;
