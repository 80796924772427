/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './DeleteGroupMemberModal.css';
// import { useParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  closeModal, largeError, largeFinish, largeLoading
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import AuthService from '../../../services/AuthService';
import { GroupMember } from '../../../models/response/AuthResponse';

interface DeleteGroupMemberModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  title: string;
  description: string;
  groupId: string;
  groupMember: GroupMember;
  accessOption: string;
}

const DeleteGroupMemberModal: React.FC<DeleteGroupMemberModalProps> = ({
  onClose, isModalOpen, title, description, accessOption, groupId, groupMember
}) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowSuccessMessage(true);
    try {
      if (groupId && groupMember) {
        setSubmitting(true);

        const response = await AuthService.deleteGroupsMembers(groupId, groupMember.userId);
        if (response.status === 200) {
          setSubmitting(false);
          setStatusMessage('Участник группы успешно удалён');
          setShowSuccessMessage(false);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      }
    } catch (error) {
      setSubmitting(false);
      setStatusMessage('Произошла ошибка при удалении участника группы');
      setShowSuccessMessage(false);
    }
  };

  const handleRetry = () => {
    setStatusMessage('');
  };

  return (
    <div className={`deleteGroupMember-modal-background ${isModalOpen ? '' : 'deleteGroupMember-modal-hidden'}`}>
      <div className="deleteGroupMember-modal-content">
        <h1>{title}</h1>
        <div className="deleteGroupMember-modal-close" onClick={onClose}>{closeModal}</div>
        {showSuccessMessage && (
          <div className='deleteGroupMember-modal-notification-container'>
            {largeLoading}
            <h3 className='deleteGroupMember-modal-notification-title'>Удаляем уастника группы</h3>
          </div>
        )}
        {statusMessage && !showSuccessMessage && (
          <div>
            {!showSuccessMessage && statusMessage === 'Произошла ошибка при удалении уастника группы' ? (
              <div className='deleteGroupMember-modal-notification-container'>
                {largeError}
                <h3 className='deleteGroupMember-modal-notification-title'>{statusMessage}</h3>
                <div className='deleteGroupMember-modal-error-buttons-container'>
                  <MediumButton text={'Попробовать снова'} onClick={handleRetry} backgroundColor={'--MyPrimary'} color={'--MyWhite'}/>
                  <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
                </div>
              </div>
            ) : (
              <div className='deleteGroupMember-modal-notification-container'>
                {largeFinish}
                <h3 className='deleteGroupMember-modal-notification-title'>Участник группы успешно удалён</h3>
              </div>
            )}
          </div>
        )}
        {!statusMessage && !showSuccessMessage && (<form className='deleteGroupMember-modal-form' onSubmit={handleSubmit}>
          <div className='deleteGroupMember-modal-form-description'>
            <span>
              {description} <span style={{ fontWeight: 500 }}>{groupMember.firstName}</span>?
            </span>
          </div>
          <div className='deleteGroupMember-modal-buttons'>
            <MediumButton text={accessOption} color={'--MyWhite'} type="submit" backgroundColor={'--MyPrimary'} />
            <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
          </div>
        </form>)}
      </div>
    </div>
  );
};

export default DeleteGroupMemberModal;
