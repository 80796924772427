/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import './AddCarModal.css';
import { parsePhoneNumber } from 'libphonenumber-js';
import CarService from '../../../services/CarService';
import {
  arrowHeader, clearInput, closeModal, largeLoading, largeError, largeFinish
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import PhoneInput from '../../Inputs/LoginInput/PhoneInput';

interface AddCarModalProps {
  onClose: () => void;
  isModalOpen: boolean;
}

enum CarNames {
  // evolute
  ipro = 'i-PRO',
  ijoy = 'i-JOY',
  isky = 'i-SKY',
  ijet = 'i-JET',
  ivan = 'i-VAN',
  ispace = 'i-SPACE',

  // dfm
  ix5 = 'IX5',
  ix7 = 'IX7',
  df500 = 'DF500',
  df600 = 'DF600',
  shineMax = 'ShineMax'
}

const AddCarModal: React.FC<AddCarModalProps> = ({ onClose, isModalOpen }) => {
  const [model, setModel] = useState('');
  const [color, setColor] = useState('');
  const [licensePlate, setLicensePlate] = useState('');
  const [imei, setImei] = useState('');
  const [vin, setVin] = useState('');
  const [year, setYear] = useState('');
  const [heater, setHeater] = useState('');
  const [ready, setReady] = useState('');
  const [hiddenKey, setHiddenKey] = useState('');
  const [simPhone, setSimPhone] = useState('');
  const [simICC, setSimICC] = useState('');

  const [submitting, setSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const [isHeaterDropdownOpen, setIsHeaterDropdownOpen] = useState(false);
  const [isReadyDropdownOpen, setIsReadyDropdownOpen] = useState(false);
  const [isHiddenKeyDropdownOpen, setIsHiddenKeyDropdownOpen] = useState(false);
  const [carsEnum, setCarsEnum] = useState<CarNames[]>([]);

  const [isFormValid, setIsFormValid] = useState(false);

  const modelDropdownRef = useRef(null);
  const heaterDropdownRef = useRef(null);
  const readyDropdownRef = useRef(null);
  const hiddenKeyDropdownRef = useRef(null);

  // Car Model

  const handleModelInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModel(e.target.value);
  };

  const handleModelInputClick = () => {
    setIsModelDropdownOpen((prevIsModelDropdownOpen) => !prevIsModelDropdownOpen);
    setIsReadyDropdownOpen(false);
    setIsHiddenKeyDropdownOpen(false);
    setIsHeaterDropdownOpen(false);
  };

  const handleModelSelect = (selectedModel: string) => {
    setModel(selectedModel);
    setIsModelDropdownOpen(true);
  };

  // Heater

  const handleHeaterInputClick = () => {
    setIsHeaterDropdownOpen((prevIsHeaterDropdownOpen) => !prevIsHeaterDropdownOpen);
    setIsModelDropdownOpen(false);
    setIsReadyDropdownOpen(false);
    setIsHiddenKeyDropdownOpen(false);
  };

  const handleHeaterSelect = (selected: string) => {
    setHeater(selected);
    setIsHeaterDropdownOpen(true);
  };

  const handleHeaterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHeater(e.target.value);
  };

  // Ready

  const handleReadyInputClick = () => {
    setIsReadyDropdownOpen((prevIsReadyDropdownOpen) => !prevIsReadyDropdownOpen);
    setIsModelDropdownOpen(false);
    setIsHiddenKeyDropdownOpen(false);
    setIsHeaterDropdownOpen(false);
  };

  const handleReadySelect = (selected: string) => {
    setReady(selected);
    setIsReadyDropdownOpen(true);
  };

  const handleReadyInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReady(e.target.value);
  };

  // HiddenKey

  const handleHiddenKeyInputClick = () => {
    setIsHiddenKeyDropdownOpen((prevIsReadyDropdownOpen) => !prevIsReadyDropdownOpen);
    setIsModelDropdownOpen(false);
    setIsReadyDropdownOpen(false);
    setIsHeaterDropdownOpen(false);
  };

  const handleHiddenKeySelect = (selected: string) => {
    setHiddenKey(selected);
    setIsHiddenKeyDropdownOpen(true);
  };

  const handleHiddenKeyInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHiddenKey(e.target.value);
  };

  // simPhone

  const simPhoneChange = (number: string) => {
    setSimPhone(number);
  };

  const handleSubmit = async () => {
    let parsePhone;
    if (simPhone) {
      parsePhone = parsePhoneNumber(simPhone)?.number.replace('+', '');
    }
    try {
      const heaterValue = heater === 'Да';
      const readyValue = ready === 'Да';
      const hiddenKeyValue = hiddenKey === 'Да';
      const requestBody = {
        vin,
        imei,
        carModel: {
          name: model,
          color,
          modelYear: parseInt(year, 10),
          options: {
            hiddenKey: hiddenKeyValue,
            heater: heaterValue,
            readyMode: readyValue,
          }
        },
        licensePlate: licensePlate || undefined,
        icc: simICC || undefined,
        sim: parsePhone || undefined,
      };
      setSubmitting(true);
      setStatusMessage('Добавляем автомобиль');
      const response = await CarService.addcar(requestBody);
      if (response.status === 200) {
        setStatusMessage('Автомобиль успешно добавлен');
        setSubmitting(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (error: any) {
      setStatusMessage('Произошла ошибка при создании автомобиля');
      setSubmitting(false);
    }
  };

  const handleRetry = () => {
    setStatusMessage('');
  };

  const handleClearInput = (setState: React.Dispatch<React.SetStateAction<string>>) => () => {
    setState('');
  };

  const handleClick = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  // domain useEffect

  useEffect(() => {
    const isAllFieldsFilled =
      model.trim() !== '' &&
      heater.trim() !== '' &&
      ready.trim() !== '' &&
      hiddenKey.trim() !== '' &&
      color.trim() !== '' &&
      imei.trim() !== '' &&
      vin.trim() !== '' &&
      year.trim() !== '';

    setIsFormValid(isAllFieldsFilled);
  }, [model, color, licensePlate, imei, vin, year, ready, hiddenKey]);

  useEffect(() => {
    const domainName = window.location.hostname;

    const getFilteredCars = () => {
      if (domainName.includes('dfm-assist')) {
        return [
          CarNames.ix5,
          CarNames.ix7,
          CarNames.df500,
          CarNames.df600,
          CarNames.shineMax,
        ];
      }
      return [
        CarNames.ipro,
        CarNames.ijoy,
        CarNames.isky,
        CarNames.ijet,
        CarNames.ivan,
        CarNames.ispace,
      ];
    };

    const cars = getFilteredCars();
    setCarsEnum(cars);
  }, []);

  return (
    <div className={`addCar-modal-background ${isModalOpen ? '' : 'addCar-modal-hidden'}`}>
      <div className="addCar-modal-content">
        <h1>Добавить автомобиль</h1>
        <div className="addCar-modal-close" onClick={onClose}>{closeModal}</div>
        {submitting && (
          <div className='addCar-modal-notification-container'>
            {largeLoading}
            <h3 className='addCar-modal-notification-title'>Добавляем автомобиль...</h3>
          </div>
        )}
        {!submitting && statusMessage && (
          <div>
            {statusMessage === 'Произошла ошибка при создании автомобиля' ? (
              <div className='addCar-modal-notification-container'>
                {largeError}
                <h3 className='addCar-modal-notification-title'>{statusMessage}</h3>
                <div className='addCar-modal-error-buttons-container'>
                  <MediumButton text={'Попробовать снова'} onClick={handleRetry}
                  backgroundColor={'--MyPrimary'} color={'--MyWhite'}/>
                  <MediumButton text={'Отмена'} color={'--MyPrimary'}
                  backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
                </div>
              </div>
            ) : (
              <div className='addCar-modal-notification-container'>
                {largeFinish}
                <h3 className='addCar-modal-notification-title'>Автомобиль успешно добавлен</h3>
              </div>
            )}
          </div>
        )}
        {!statusMessage && (
        <form className='addCar-modal-form' onSubmit={() => handleClick()}>
        <div className='addCar-modal-form-inputs' >
            <label className='addCar-modal-label addCar-modal-label-dropdown'>
              <span className='addCar-modal-label-title'>Модель<span style={{ color: '#4495D1' }}>*</span></span>
              <input
                readOnly
                placeholder='Не выбрано'
                type="text"
                value={model}
                onChange={handleModelInputChange}
                onClick={handleModelInputClick}
                // onBlur={() => setIsModelDropdownOpen(false)}
              />
              {isModelDropdownOpen && (
                <div className="addCar-modal-dropdown addCar-modal-dropdown-long"
                  style={{ display: isModelDropdownOpen ? 'block' : 'none' }}
                  ref={modelDropdownRef}
                >
                  {carsEnum.map((carName) => (
                    <div key={carName} onClick={() => handleModelSelect(carName)}>{carName}</div>
                  ))}
                </div>
              )}
              {!model ? (
                <div className='addCar-modal-label-arrow-down'>
                  {arrowHeader}
                </div>
              ) : (
                <div className='addCar-modal-label-clear' onClick={handleClearInput(setModel)}>
                  {clearInput}
                </div>
              )}
            </label>
            <label className='addCar-modal-label addCar-modal-label-dropdown'>
                <span className='addCar-modal-label-title addCar-modal-label-longString'>Установлен отопитель
                  <span style={{ color: '#4495D1' }}>*</span>
                </span>
                <input
                  readOnly
                  placeholder='Не выбрано'
                  type="text"
                  value={heater}
                  onChange={handleHeaterInputChange}
                  onClick={handleHeaterInputClick}
                  // onBlur={() => {
                  //   setIsHeaterDropdownOpen(false);
                  // }}
                />
                {isHeaterDropdownOpen && (
                  <div
                    className="addCar-modal-dropdown"
                    style={{ display: isHeaterDropdownOpen ? 'block' : 'none' }}
                    ref={heaterDropdownRef}
                  >
                    <div onClick={() => handleHeaterSelect('Да')}>Да</div>
                    <div onClick={() => handleHeaterSelect('Нет')}>Нет</div>
                  </div>
                )}
                {!heater ? (
                  <div className='addCar-modal-label-arrow-down'>
                    {arrowHeader}
                  </div>
                ) : (
                  <div className='addCar-modal-label-clear' onClick={handleClearInput(setHeater)}>
                    {clearInput}
                  </div>
                )}
            </label>
            <label className='addCar-modal-label addCar-modal-label-dropdown'>
                <span className='addCar-modal-label-title'>Ready доступен
                  <span style={{ color: '#4495D1' }}>*</span>
                </span>
                <input
                  readOnly
                  placeholder='Не выбрано'
                  type="text"
                  value={ready}
                  onChange={handleReadyInputChange}
                  onClick={handleReadyInputClick}
                  // onBlur={() => setIsReadyDropdownOpen(false)}
                />
                {isReadyDropdownOpen && (
                  <div className="addCar-modal-dropdown" style={{ display: isReadyDropdownOpen ? 'block' : 'none' }}
                    ref={readyDropdownRef}>
                    <div onClick={() => handleReadySelect('Да')}>Да</div>
                    <div onClick={() => handleReadySelect('Нет')}>Нет</div>
                  </div>
                )}
                {!ready ? (
                  <div className='addCar-modal-label-arrow-down'>
                    {arrowHeader}
                  </div>
                ) : (
                  <div className='addCar-modal-label-clear' onClick={handleClearInput(setReady)}>
                    {clearInput}
                  </div>
                )}
            </label>
            <label className='addCar-modal-label addCar-modal-label-dropdown'>
                <span className='addCar-modal-label-title'>Вшитый ключ
                  <span style={{ color: '#4495D1' }}>*</span>
                </span>
                <input
                  readOnly
                  placeholder='Не выбрано'
                  type="text"
                  value={hiddenKey}
                  onChange={handleHiddenKeyInputChange}
                  onClick={handleHiddenKeyInputClick}
                  // onBlur={() => setIsHiddenKeyDropdownOpen(false)}
                />
                {isHiddenKeyDropdownOpen && (
                  <div
                    className="addCar-modal-dropdown"
                    style={{ display: isHiddenKeyDropdownOpen ? 'block' : 'none' }}
                    ref={hiddenKeyDropdownRef}
                  >
                    <div onClick={() => handleHiddenKeySelect('Да')}>Да</div>
                    <div onClick={() => handleHiddenKeySelect('Нет')}>Нет</div>
                  </div>
                )}
                {!hiddenKey ? (
                  <div className='addCar-modal-label-arrow-down'>
                    {arrowHeader}
                  </div>
                ) : (
                  <div className='addCar-modal-label-clear' onClick={handleClearInput(setHiddenKey)}>
                    {clearInput}
                  </div>
                )}
            </label>
            <label className='addCar-modal-label'>
            <span className='addCar-modal-label-title'>Год выпуска<span style={{ color: '#4495D1' }}>*</span></span>
              <input placeholder='Не указано'
              type="text" value={year} onChange={(e) => setYear(e.target.value)} />
            {year && (
                <div className='addCar-modal-label-clear' onClick={handleClearInput(setYear)}>
                  {clearInput}
                </div>
            )}
            </label>
            <label className='addCar-modal-label'>
              <span className='addCar-modal-label-title'>Цвет<span style={{ color: '#4495D1' }}>*</span></span>
              <input placeholder='Не указано'
                type="text" value={color} onChange={(e) => setColor(e.target.value)} />
              {color && (
                <div className='addCar-modal-label-clear' onClick={handleClearInput(setColor)}>
                  {clearInput}
                </div>
              )}
            </label>
            <label className='addCar-modal-label'>
              <span className='addCar-modal-label-title'>Гос. номер</span>
                <input placeholder='Не указано'
                type="text" value={licensePlate} onChange={(e) => setLicensePlate(e.target.value)} />
                {licensePlate && (
                <div className='addCar-modal-label-clear' onClick={handleClearInput(setLicensePlate)}>
                  {clearInput}
                </div>
                )}
            </label>
            <label className='addCar-modal-label'>
              <span className='addCar-modal-label-title'>IMEI<span style={{ color: '#4495D1' }}>*</span></span>
              <input placeholder='Не указано' type="text" value={imei} onChange={(e) => setImei(e.target.value)} />
              {imei && (
                <div className='addCar-modal-label-clear' onClick={handleClearInput(setImei)}>
                  {clearInput}
                </div>
              )}
            </label>
            <label className='addCar-modal-label'>
              <span className='addCar-modal-label-title'>VIN<span style={{ color: '#4495D1' }}>*</span></span>
              <input placeholder='Не указано' type="text" value={vin} onChange={(e) => setVin(e.target.value)} />
              {vin && (
                <div className='addCar-modal-label-clear' onClick={handleClearInput(setVin)}>
                  {clearInput}
                </div>
              )}
            </label>
            <label className='addCar-modal-label'>
              <span className='addCar-modal-label-title'>ICC симкарты</span>
              <input placeholder='Не указано' type="text" value={simICC} onChange={(e) => setSimICC(e.target.value)} />
              {simICC && (
                <div className='addCar-modal-label-clear' onClick={handleClearInput(setSimICC)}>
                  {clearInput}
                </div>
              )}
            </label>
            <label className='addCar-modal-label'>
            <span className='addCar-modal-label-title addCar-modal-label-longString'>
                Номер телефона симкарты
            </span>
              <PhoneInput
              padding='0px'
              value={simPhone}
              onChange={simPhoneChange}
              placeholder='+7 (___) ___-__-__'
            />
              {simPhone && (
                <div className='addCar-modal-label-clear' onClick={handleClearInput(setSimPhone)}>
                  {clearInput}
                </div>
              )}
            </label>
          </div>
          <div className='addCar-modal-footer'>
            <div className='addCar-modal-buttons'>
              <MediumButton disabled={!isFormValid} text={'Добавить автомобиль'} color={'--MyWhite'} type='submit'
              backgroundColor={'--MyPrimary'}/>
              <MediumButton text={'Отмена'} color={'--MyPrimary'}
              backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
            </div>
            <span><span style={{ color: '#4495D1' }}>*</span> Обязательные поля</span>
          </div>
        </form>)}
      </div>
    </div>
  );
};

export default AddCarModal;
