/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import './EditChargeGroupModal.css';
import { useParams } from 'react-router-dom';
import {
  arrowHeader, clearInput, closeModal, largeLoading, largeError, largeFinish
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import ChargeService from '../../../services/ChargeService';
import { RateInfo } from '../../../models/response/RateResponse';
import MySwitch from '../../Switch/Switch';
import AuthService from '../../../services/AuthService';

interface EditChargeGroupModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  groupId: string;
  description: string;
  name: string;
  active: boolean;
}

const EditChargeGroupModal: React.FC<EditChargeGroupModalProps> = ({
  onClose, isModalOpen, active, name, description, groupId
}) => {
  const { chargePointId } = useParams<{ chargePointId: string }>();
  const actualChargePointId = chargePointId || '';

  const [groupName, setGroupName] = useState(name);
  const [groupDescription, setGroupDescription] = useState(description);
  const [rate, setRate] = useState('');
  const [rateId, setRateId] = useState('');
  const [activeStatus, setActiveStatus] = useState(active);

  const [submitting, setSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const [isRateDropdownOpen, setIsRateDropdownOpen] = useState(false);
  const [ratesEnum, setRatesEnum] = useState<RateInfo[] | []>([]);

  const [isFormValid, setIsFormValid] = useState(false);

  const modelDropdownRef = useRef(null);
  const initialGroupName = useRef(name);
  const initialGroupDescription = useRef(description);
  const initialRate = useRef('');
  const initialActiveStatus = useRef(active);

  const handleModelInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRate(e.target.value);
  };

  const handleModelInputClick = () => {
    const filteredRates = ratesEnum.filter((rateInfo) => rateInfo.title !== rate && (!rateInfo.groupId || rateInfo.groupId === groupId));
    if (filteredRates.length > 0) {
      setIsRateDropdownOpen((prevIsRateDropdownOpen) => !prevIsRateDropdownOpen);
    }
  };

  const handleRateSelect = (selectedRate: RateInfo) => {
    setRate(selectedRate.title);
    setRateId(selectedRate.id);
    setIsRateDropdownOpen(false);
  };

  const handleRetry = () => {
    setStatusMessage('');
  };

  const handleClearInput = (setState: React.Dispatch<React.SetStateAction<string>>) => () => {
    setState('');
  };

  const fetchRates = async () => {
    try {
      const response = await ChargeService.getRates([
        {
          key: 'GROUP_ID',
          values: [groupId]
        }
      ]);
      const rates = response.data.rows;
      setRatesEnum(rates);
      const matchedRate = rates.find((filteredRate: any) => filteredRate.groupId === groupId);
      if (matchedRate) {
        setRate(matchedRate.title);
        setRateId(matchedRate.id);
      }
    } catch (error) {
      // console.error('Error fetching rates', error);
    }
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      setStatusMessage('Изменяем группу');
      const response = await AuthService.updateGroup(groupId, groupName, groupDescription, activeStatus);
      if (response.status === 200) {
        if (rateId) {
          const upgradeRate = await ChargeService.updateRate({ groupId }, rateId);
          if (upgradeRate && upgradeRate.status === 200) {
            setStatusMessage('Группа успешно изменена');
            setSubmitting(false);
            setTimeout(() => {
              onClose();
            }, 500);
          }
        } else {
          setStatusMessage('Группа успешно изменена');
          setSubmitting(false);
          setTimeout(() => {
            onClose();
          }, 500);
        }
      }
    } catch (error: any) {
      setStatusMessage('Произошла ошибка при изменении группы');
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const isFormChanged = () => (
      groupName.trim() !== initialGroupName.current ||
        groupDescription.trim() !== initialGroupDescription.current ||
        rate !== initialRate.current ||
        activeStatus !== initialActiveStatus.current
    );
    const isAllFieldsFilled =
      groupName.trim() !== '' &&
      groupDescription.trim() !== '';

    setIsFormValid(isAllFieldsFilled && isFormChanged());
  }, [groupName, groupDescription, rate, activeStatus]);

  useEffect(() => {
    fetchRates();
  }, []);

  return (
    <div className={`addChargeGroup-modal-background ${isModalOpen ? '' : 'addChargeGroup-modal-hidden'}`}>
      <div className="addChargeGroup-modal-content">
        <h1>Изменить группу</h1>
        <div className="addChargeGroup-modal-close" onClick={onClose}>{closeModal}</div>
        {submitting && (
          <div className='addChargeGroup-modal-notification-container'>
            {largeLoading}
            <h3 className='addChargeGroup-modal-notification-title'>Изменяем группу...</h3>
          </div>
        )}
        {!submitting && statusMessage && (
          <div>
            {statusMessage === 'Произошла ошибка при изменении группы' ? (
              <div className='addChargeGroup-modal-notification-container'>
                {largeError}
                <h3 className='addChargeGroup-modal-notification-title'>{statusMessage}</h3>
                <div className='addChargeGroup-modal-error-buttons-container'>
                  <MediumButton
                    text={'Попробовать снова'}
                    onClick={handleRetry}
                    backgroundColor={'--MyPrimary'}
                    color={'--MyWhite'}
                  />
                  <MediumButton
                    text={'Отмена'}
                    color={'--MyPrimary'}
                    backgroundColor={'--MySecondaryBlue'}
                    onClick={onClose}
                  />
                </div>
              </div>
            ) : (
              <div className='addChargeGroup-modal-notification-container'>
                {largeFinish}
                <h3 className='addChargeGroup-modal-notification-title'>Группа успешно изменена</h3>
              </div>
            )}
          </div>
        )}
        {!statusMessage && (
        <form className='addChargeGroup-modal-form' onSubmit={handleSubmit}>
          <div className='addChargeGroup-modal-form-inputs'>
            <label className='addChargeGroup-modal-label'>
              <span className='addChargeGroup-modal-label-title'>Название</span>
              <input
                placeholder='Введите название'
                type="text"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
              {groupName && (
              <div className='addChargeGroup-modal-label-clear' onClick={handleClearInput(setGroupName)}>
                {clearInput}
              </div>
              )}
            </label>
            <label className='addChargeGroup-modal-label'>
              <span className='addChargeGroup-modal-label-title'>Описание</span>
              <input
                placeholder='Введите описание'
                type="text"
                value={groupDescription}
                onChange={(e) => setGroupDescription(e.target.value)}
              />
              {groupDescription && (
              <div className='addChargeGroup-modal-label-clear' onClick={handleClearInput(setGroupDescription)}>
                {clearInput}
              </div>
              )}
            </label>
            <label className='addChargeGroup-modal-label addChargeGroup-modal-label-dropdown'>
              <span className='addChargeGroup-modal-label-title'>Тариф</span>
              <input
                disabled={ratesEnum.filter((rateInfo) => rateInfo.title !== rate && (!rateInfo.groupId || rateInfo.groupId === groupId)).length < 1}
                readOnly
                placeholder={ratesEnum.filter((rateInfo) => rateInfo.title !== rate && (!rateInfo.groupId || rateInfo.groupId === groupId)).length > 1 ? 'Не выбрано' : 'Нет тарифов'}
                type="text"
                value={rate}
                onChange={handleModelInputChange}
                onClick={handleModelInputClick}
                // onBlur={() => setIsRateDropdownOpen(false)}
              />
              {!rate ? (
                <div className='addChargeGroup-modal-label-arrow-down'>
                  {arrowHeader}
                </div>
              ) : (
                <div className='addChargeGroup-modal-label-clear' onClick={handleClearInput(setRate)}>
                  {clearInput}
                </div>
              )}
            </label>
              {isRateDropdownOpen && (
                <div className="addChargeGroup-modal-dropdown addChargeGroup-modal-dropdown-long"
                  style={{ display: isRateDropdownOpen ? 'block' : 'none' }}
                  ref={modelDropdownRef}
                >
                  {ratesEnum
                    .filter((rateInfo) => rateInfo.title !== rate && (!rateInfo.groupId || rateInfo.groupId === groupId))
                    .map((rateTitle, index) => (
                      <div key={index} onClick={() => handleRateSelect(rateTitle)}>{rateTitle.title}</div>
                    ))
                  }
                </div>
              )}
          </div>
          <div className='addChargeGroup-switch-container'>
            <span>Активный статус</span>
            <MySwitch checked={activeStatus} onChange={() => setActiveStatus((prev) => !prev)}/>
          </div>
          <div className='addChargeGroup-modal-footer'>
            <div className='addChargeGroup-modal-buttons'>
              <MediumButton disabled={!isFormValid} text={'Сохранить изменения'} color={'--MyWhite'} type='submit'
              backgroundColor={'--MyPrimary'}/>
              <MediumButton text={'Отмена'} color={'--MyPrimary'}
              backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
            </div>
          </div>
        </form>)}
      </div>
    </div>
  );
};

export default EditChargeGroupModal;
