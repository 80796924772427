// eslint-disable-next-line import/no-extraneous-dependencies
import { AxiosResponse } from 'axios';
import { API_CAR } from '../http/MyApi';
import { createAxiosInstance } from '../http/http';
import {
  AddCarRequest, FilterResponse, CarIdResponse,
  CarUpdateRequestBody, DriversResponse, ControlOptions, AutomationsList,
  CarsGeoPosition,
  TravelsResponse,
  Coordinates
} from '../models/response/CarResponse';

const $api = createAxiosInstance(API_CAR);

export default class CarService {
  // Машины
  static async getCars(filters?: object[], limit?: number, offset?: number):
    Promise<AxiosResponse> {
    return $api.post('/car/v2/search', {
      filters, limit, offset, addSensors: true
    });
  }

  static async getCar(carId: string): Promise<AxiosResponse<CarIdResponse>> {
    return $api.get(`/car/v2/${carId}`);
  }

  static async getFilters(): Promise<AxiosResponse<FilterResponse>> {
    return $api.post('/car/v2/filters');
  }

  static async addcar(data: AddCarRequest): Promise<AxiosResponse<AddCarRequest>> {
    return $api.post('/car/v2', data);
  }

  static async transferCarRequest(carId: string | string[], phone: string): Promise<AxiosResponse> {
    return $api.post('/car/v2/ownerSuggest', { carId, phone });
  }

  static async transferINNCarRequest(carId: string | string[], inn: string): Promise<AxiosResponse> {
    return $api.post('/car/v2/ownerSuggest', { carId, inn });
  }

  static async transferCar(code: string): Promise<AxiosResponse> {
    return $api.post('/car/v2/owner', { code });
  }

  static async updateCarOption(carId: string, updatedField: Partial<CarUpdateRequestBody>):
    Promise<AxiosResponse<CarUpdateRequestBody>> {
    return $api.put(`/car/v2/${carId}`, updatedField);
  }

  // Геопозиция

  static async geoPositionCars(filters?: object[], limit?: number, offset?: number):
    Promise<AxiosResponse<CarsGeoPosition>> {
    return $api.post('/car/search-geo', { filters, limit, offset });
  }

  // Кнопки управления

  static async getManagementOptions(carId: string): Promise<AxiosResponse<ControlOptions>> {
    return $api.get(`/tbox/${carId}/info`);
  }

  static async getCarOnlineStatus(carId: string): Promise<AxiosResponse> {
    return $api.get(`/tbox/${carId}/online`);
  }

  static async updateManagementOption(carId: string, functionName: string, value?: number): Promise<AxiosResponse> {
    return $api.post(`/tbox/${carId}/${functionName}`, { value });
  }

  static async getAutomations(carId: string): Promise<AxiosResponse<AutomationsList>> {
    return $api.get(`/tbox/${carId}/automations`);
  }

  static async toggleAutomations(carId: string, automationName: string): Promise<AxiosResponse> {
    return $api.get(`/tbox/${carId}/automations/${automationName}`);
  }

  // Водители

  static async driversWithOwner(carId: string): Promise<AxiosResponse<DriversResponse>> {
    return $api.get(`/car/v2/${carId}/driversWithOwner`);
  }

  static async blockDriver(carId: string, driverId: string): Promise<AxiosResponse> {
    return $api.post(`/car/v2/${carId}/block_driver`, { driverId });
  }

  static async unblockDriver(carId: string, driverId: string): Promise<AxiosResponse> {
    return $api.post(`/car/v2/${carId}/unblock_driver`, { driverId });
  }

  static async addDriver(carId: string, phone: string): Promise<AxiosResponse> {
    return $api.post(`/car/v2/${carId}/drivers`, { phone });
  }

  static async deleteDriver(carId: string, driverId: string): Promise<AxiosResponse> {
    return $api.delete(`/car/v2/${carId}/drivers/${driverId}`);
  }

  // Поездки

  static async getTravels(carId: string, filters: object[], limit: number, offset: number):
    Promise<AxiosResponse<TravelsResponse>> {
    return $api.post(`/travels/search/${carId}`, { filters, limit, offset });
  }

  static async getTravelsDetails(carId: string, rideId: number):
    Promise<AxiosResponse<Coordinates>> {
    return $api.get(`/travels/details/${carId}/${rideId}`);
  }
}
