/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Range } from 'react-range';
import {
  YMaps, Map, Placemark
} from '@pbe/react-yandex-maps';
import moment from 'moment';
import MediumButton from '../../components/buttons/MediumButton';
import './CarInfoPage.css';
import {
  AutomationToggle,
  BlowDirectionButton, Button, CarIdResponse, Chip, DriversResponse, PositionData, RenderThumbProps, RenderTrackProps, SensorsData
} from '../../models/response/CarResponse';
import CarService from '../../services/CarService';
import TransferCarModal from '../../components/Modal/TransferCar/TransferCarModal';
import {
  bucket, bucket32, climateFan, edit, FuelIcon, fuelIcon, lockClose, lockOpen, mapPin, plus, plusAdd, plusAddAccessesPrimary
} from '../../Icons/Icons';
import EditCarOptionsModal from '../../components/Modal/EditCarOptionsModal/EditCarOptionsModal';
import EditCarOptionsDropdownModal
  from '../../components/Modal/EditCarOptionsDropdownModal/EditCarOptionsDropdownModal';
import { Context } from '../..';
import AccessesDriversModal from '../../components/Modal/AccessesDrivers/AccessesDriversModal';
import DeleteDriversModal from '../../components/Modal/DeleteDriver/DeleteDriverModal';
import AddDriverModal from '../../components/Modal/AddDriver/AddDriverModal';
import MySwitch from '../../components/Switch/Switch';
import Spinner from '../../components/Spinner/Spinner';
import LargeButtonIcon from '../../components/buttons/LargeButtonIcon';
import LargeButtonWithIcon from '../../components/buttons/LargeButtonWithIcon';
import carsPng from '../../Icons/cars-icons';
import ProgressBar from '../../components/Modal/StatusBar/ProgressBar';
import AddCarTestModal from '../../components/Modal/AddCarTest/AddCarTestModal';

const CarInfoPage = () => {
  const { carId } = useParams<{ carId: string }>();
  const actualCarId = carId || '';
  const navigate = useNavigate();

  const [carInfo, setCarInfo] = useState<CarIdResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const { store } = useContext(Context);

  // Раздел об автомобиле

  const [isOptionsModalOpen, setOptionsIsModalOpen] = useState(false);

  const [selectedField, setSelectedField] = useState('');
  const [fieldValue, setFieldValue] = useState('');

  const fetchCarInfo = async () => {
    try {
      const response = await CarService.getCar(actualCarId);
      setCarInfo(response.data);
    } catch (error) {
      // console.error('Ошибка при получении информации о машине:', error);
    }
  };

  const handleUpdateField = async (fieldTitle: string, value: string) => {
    try {
      let updatedField: any = {};

      if (fieldTitle === 'Гос. номер') {
        updatedField = { licensePlate: value };
      } else if (fieldTitle === 'VIN') {
        updatedField = { vin: value };
      } else if (fieldTitle === 'IMEI') {
        updatedField = { imei: value };
      } else if (fieldTitle === 'Цвет') {
        updatedField = { carModel: { color: value } };
      } else if (fieldTitle === 'Ready доступен') {
        updatedField = { carModel: { options: { readyMode: value === 'Да' } } };
      } else if (fieldTitle === 'Установлен отопитель') {
        updatedField = { carModel: { options: { heater: value === 'Да' } } };
      }

      await CarService.updateCarOption(actualCarId, updatedField);
    } catch (error) {
      // console.error('Ошибка при отправке PUT-запроса:', error);
    } finally {
      fetchCarInfo();
    }
  };

  let carImage;
  if (carInfo?.carModel.name.includes('i-JOY')) {
    carImage = carsPng.iJoy;
  } else if (carInfo?.carModel.name.includes('i-SKY')) {
    carImage = carsPng.iSky;
  } else if (carInfo?.carModel.name.includes('i-JET')) {
    carImage = carsPng.iJet;
  } else if (carInfo?.carModel.name.includes('i-VAN')) {
    carImage = carsPng.iVan;
  } else if (carInfo?.carModel.name.includes('i-SPACE')) {
    carImage = carsPng.iSpace;
  } else if (carInfo?.carModel.name.includes('i-PRO')) {
    carImage = carsPng.iPro;
  } else if (carInfo?.carModel.name.includes('DF580') || carInfo?.carModel.name.includes('DF500')) {
    carImage = carsPng.DF580;
  } else if (carInfo?.carModel.name.includes('DF600')) {
    carImage = carsPng.DF600;
  } else if (carInfo?.carModel.name.includes('IX5')) {
    carImage = carsPng.IX5;
  } else if (carInfo?.carModel.name.includes('IX7')) {
    carImage = carsPng.IX7;
  } else if (carInfo?.carModel.name.includes('ShineMax') || carInfo?.carModel.name.includes('SHINE_MAX')) {
    carImage = carsPng.ShineMax;
  }

  // Модалка добавления опций машины

  const handleOptionsModalOpen = () => {
    setOptionsIsModalOpen(true);
  };

  const handleOptionsModalClose = () => {
    setOptionsIsModalOpen(false);
  };

  const handleEditField = (field: string, value: string) => {
    setSelectedField(field);
    setFieldValue(value);
    handleOptionsModalOpen();
  };

  useEffect(() => {
    fetchCarInfo();
  }, [carId,
    carInfo?.carModel.name,
    carInfo?.licensePlate, carInfo?.carModel.color,
    carInfo?.carModel.options.readyMode,
    isOptionsModalOpen
  ]);

  // Раздел Доступы

  const [drivers, setDrivers] = useState<DriversResponse[]>([]);
  const [owner, setOwner] = useState<DriversResponse>({});
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState<DriversResponse | null>(null);

  const [isAccessModalOpen, setIsAccessModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddDriverModalOpen, setIsAddDriverModalOpen] = useState(false);

  function formatPhoneNumber(phoneNumber: string | undefined) {
    const cleaned = (`${phoneNumber}`).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
    }
    return phoneNumber;
  }

  useEffect(() => {
    const getDrivers = async () => {
      try {
        const response = await CarService.driversWithOwner(actualCarId);
        if (Array.isArray(response.data)) {
          const driverData = response.data.filter((driver) => driver.kind === 'driver');
          setDrivers(driverData);
          const ownerDriver = response.data.filter((driver) => driver.kind === 'owner');
          if (ownerDriver) {
            setOwner(ownerDriver[0]);
          }
        }
      } catch (error) {
        // console.error('Ошибка при получении информации о водителях:', error);
      }
    };

    getDrivers();
  }, [isAccessModalOpen, isDeleteModalOpen, isAddDriverModalOpen]);

  // Модалка передачи машины

  const handleTransferModalOpen = () => {
    setIsTransferModalOpen(true);
  };

  const handleTransferModalClose = () => {
    setIsTransferModalOpen(false);
  };

  // Модалка Разблокировки доступа

  const handleAccessesModalOpen = (driver: DriversResponse) => {
    setSelectedDriver(driver);
    setIsAccessModalOpen(true);
  };

  const handleAccessesModalClose = () => {
    setSelectedDriver(null);
    setIsAccessModalOpen(false);
  };

  // Модалка удаления водителя

  const handleDeleteModalOpen = (driver: DriversResponse) => {
    setSelectedDriver(driver);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setSelectedDriver(null);
    setIsDeleteModalOpen(false);
  };

  // Модалка добавления водителя

  const handleAddDriverModalOpen = () => {
    setIsAddDriverModalOpen(true);
  };

  const handleAddDriverModalClose = () => {
    setIsAddDriverModalOpen(false);
  };

  // Раздел управление

  const [controlMainButtons, setControlMainButtons] = useState<Button[]>([]);
  const [chipStatus, setChipStatus] = useState<Chip | null>(null);
  const [sensorsData, setSensorsData] = useState<SensorsData | null>(null);
  const [positionData, setPositionData] = useState<PositionData | null>(null);
  const [carOnlineStatus, setCarOnlineStatus] = useState<boolean>(false);
  const [isLoadingButton, setIsLoadingButton] = useState<Record<string, boolean>>({});

  const [automations, setAutomations] = useState<AutomationToggle[]>([]);

  const [controlClimateButtons, setControlClimateButtons] = useState<Button[]>([]);
  const [blowDirectionButtons, setBlowDirectionButtons] = useState<BlowDirectionButton[]>([]);
  const [climateTargetTemp, setClimateTargetTemp] = useState([20]);
  const [climateFanSpeed, setClimateFanSpeed] = useState([1]);

  const [rangeTempArray, setRangeTempArray] = useState<string[]>([]);
  const [rangeBlowingArray, setRangeBlowingArray] = useState<string[]>([]);
  const [inBoardTemp, setInBoardTemp] = useState('');
  const [outsideTemp, setOutsideTemp] = useState('');
  const [tempArray, setTempArray] = useState<Button[] | undefined>([]);
  const [tempRangeMinMax, setTempRangeMinMax] = useState<{ min: number; max: number }>({
    min: -1,
    max: 50,
  });
  const [blowSpeedArray, setBlowSpeedArray] = useState<Button[] | undefined>([]);
  const [blowRangeMinMax, setBlowRangeMinMax] = useState<{ min: number; max: number }>({
    min: -1,
    max: 50,
  });

  const getOnlineStatus = async () => {
    try {
      const response = await CarService.getCarOnlineStatus(actualCarId);
      setCarOnlineStatus(response.data);
    } catch (error) {
      // console.log(error);
    }
  };

  const getManagementOptions = async () => {
    setLoading(true);
    try {
      const response = await CarService.getManagementOptions(actualCarId);

      if (response.data.buttons) {
        // Кнопки управления
        setControlMainButtons(response.data.buttons.main);

        // Кнопки климата
        const climateButtons = response.data.buttons.climate.filter(
          (button: Button) => button.type !== 'slider' && button.type !== 'select'
        );
        setControlClimateButtons(climateButtons);

        // Кнопки обдува
        const blowButtons = response.data.buttons.climate.filter(
          (button: Button) => button.options
        );
        const blowDirections = blowButtons.flatMap((button: Button) => button.options);
        setBlowDirectionButtons(blowDirections);

        // Управление температурой в салоне
        const tempArrayFiltered = response.data.buttons.climate.filter(
          (button: Button) => button.kind === 'temp'
        );
        setTempArray(tempArrayFiltered);
        if (tempArrayFiltered.length > 0) {
          const minTemp = Number(tempArrayFiltered[0].range[0]);
          const maxTemp = Number(tempArrayFiltered[0].range[tempArrayFiltered[0].range.length - 1]);
          setTempRangeMinMax({ min: minTemp, max: maxTemp });
          setRangeTempArray(tempArrayFiltered[0].range);
        }

        // Управление скоростью обдува
        const blowSpeedArrayFiltered = response.data.buttons.climate.filter(
          (button: Button) => button.kind === 'fan'
        );
        setBlowSpeedArray(blowSpeedArrayFiltered);
        if (blowSpeedArrayFiltered.length > 0) {
          const minBlow = Number(blowSpeedArrayFiltered[0].range[0]);
          const maxBlow = Number(blowSpeedArrayFiltered[0].range[blowSpeedArrayFiltered[0].range.length - 1]);
          setRangeBlowingArray(blowSpeedArrayFiltered[0].range);
          setBlowRangeMinMax({ min: minBlow, max: maxBlow });
        }

        // Климат
        setInBoardTemp(response.data.sensors.sensorsData.inBoardTemp);
        setOutsideTemp(response.data.sensors.sensorsData.outsideTemp);
        setClimateTargetTemp([Number(response.data.sensors.sensorsData.climateTargetTemp)]);
        setClimateFanSpeed([Number(response.data.sensors.sensorsData.climateFanSpeed)]);
      }

      // Чип
      setChipStatus(response.data.sensors.chip);

      // Сенсоры
      setSensorsData(response.data.sensors.sensorsData);

      // Карта
      setPositionData(response.data.sensors.positionData);
    } catch (error) {
      // console.error('Ошибка при получении данных:', error);
    } finally {
      setLoading(false);
    }
  };

  const getAutomations = async () => {
    const response = await CarService.getAutomations(actualCarId);
    if (response.data) {
      setAutomations(response.data);
    }
  };

  const handleUpdateOption = async (functionName: string, value?: number) => {
    try {
      await CarService.updateManagementOption(actualCarId, functionName, value);
      getManagementOptions();
    } catch (error) {
      // console.error('Ошибка при отправке POST-запроса', error);
    } finally {
      setIsLoadingButton((prevState) => ({ ...prevState, [functionName]: false }));
    }
  };

  const handleUpdateClimate = async (value: number) => {
    try {
      await CarService.updateManagementOption(actualCarId, 'climateTargetTemp', value);
      getManagementOptions();
    } catch (error) {
      // console.error('Ошибка при отправке POST-запроса', error);
    }
  };

  const handleUpdateFanSpeed = async (value: number) => {
    try {
      await CarService.updateManagementOption(actualCarId, 'climateFanSpeed', value);
      getManagementOptions();
    } catch (error) {
      // console.error('Ошибка при отправке POST-запроса', error);
    }
  };

  const toggleAutomations = async (option: string) => {
    try {
      await CarService.toggleAutomations(actualCarId, option);
      getManagementOptions();
    } catch (error) {
      // console.error('Ошибка при отправке POST-запроса', error);
    }
  };

  // Раздел поездки

  const [selectedTripFilter, setSelectedTripFilter] = useState<string>('all');
  const [travels, setTravels] = useState<number>(0);

  const handleClick = (filter: string) => {
    setSelectedTripFilter(filter);
  };

  const getTravels = async () => {
    let filters: any = [];
    if (selectedTripFilter === 'all') {
      const response = await CarService.getTravels(actualCarId, [], 0, 0);
      setTravels(response.data.total);
    }
    if (selectedTripFilter === 'today') {
      filters = [
        {
          values: [
            moment().startOf('day').toISOString()
          ],
          key: 'DATE_START'
        }
      ];
      const response = await CarService.getTravels(actualCarId, filters, 0, 0);
      setTravels(response.data.total);
    }
    if (selectedTripFilter === 'yesterday') {
      filters = [
        {
          values: [
            moment().subtract(1, 'days').startOf('day').toISOString()
          ],
          key: 'DATE_START'
        },
        {
          values: [
            moment().subtract(1, 'days').endOf('day').toISOString()
          ],
          key: 'DATE_END'
        }
      ];
      const response = await CarService.getTravels(actualCarId, filters, 0, 0);
      setTravels(response.data.total);
    }
    if (selectedTripFilter === 'week') {
      filters = [
        {
          values: [
            moment().subtract(1, 'week').startOf('day').toISOString()
          ],
          key: 'DATE_START'
        },
        {
          values: [
            moment().endOf('day').toISOString()
          ],
          key: 'DATE_END'
        }
      ];
      const response = await CarService.getTravels(actualCarId, filters, 0, 0);
      setTravels(response.data.total);
    }
    if (selectedTripFilter === 'month') {
      filters = [
        {
          values: [
            moment().subtract(1, 'month').startOf('day').toISOString()
          ],
          key: 'DATE_START'
        },
        {
          values: [
            moment().endOf('day').toISOString()
          ],
          key: 'DATE_END'
        }
      ];
      const response = await CarService.getTravels(actualCarId, filters, 0, 0);
      setTravels(response.data.total);
    }
  };

  // Раздел тестирования

  const [isCarTestModalOpen, setIsCarTestModalOpen] = useState(false);

  const handleAddCarTestModalOpen = () => {
    setIsCarTestModalOpen(true);
  };

  const handleAddCarTestModalClose = () => {
    setIsCarTestModalOpen(false);
  };

  // Общее

  useEffect(() => {
    getAutomations();
    getOnlineStatus();
    getManagementOptions();
    store.selectedIds = [];
    store.selectedCars = [];
  }, []);

  useEffect(() => {
    if (store.user.buttons?.travelsVisible) {
      getTravels();
    }
  }, [selectedTripFilter]);

  useEffect(() => {
    const interval = setInterval(() => {
      getOnlineStatus();
      getManagementOptions();
      getAutomations();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  if (!carInfo) {
    return <p>Загрузка информации о машине...</p>;
  }

  return (
    <div className='car-page-info'>
      <div className='car-page-info-container'>
        <div className='car-info-management-container'>
          {/* car-info-general */}
          <div className='car-info car-info-container'>
            <div className='car-info-general'>
              <div className='car-info-general-header-title'>
                <div className='car-info-general-header-titleWithDots'>
                  <h2>{carInfo.carModel.name}</h2>
                  <span className='car-info-indications-status-dot' style={{ backgroundColor: (carOnlineStatus ? '#5CBF63' : '#9A99A2') }}></span>
                </div>
                <span className='car-info-indications-status' style={{ backgroundColor: `${chipStatus?.color}` }}>
                  {chipStatus?.title}
                  <span style={{ display: 'flex', alignItems: 'center' }} dangerouslySetInnerHTML={{ __html: chipStatus?.svg || '' }}>
                  </span>
                </span>
              </div>
              <div className='car-info-general-battery-container'>
                <div className='car-info-general-indication-container'>
                  <div className='car-info-general-battery-container-value'>
                    <h3>{sensorsData?.batteryPercentage ? `${sensorsData.batteryPercentage}%` : '-'}</h3>
                    <h3 style={{ color: '#D1D1D1' }}>/</h3>
                    <h3>{sensorsData?.remainsMileage ? `${sensorsData.remainsMileage} км` : '-'}</h3>
                  </div>
                  <span className='car-info-general-battery-container-title'>батарея</span>
                </div>
                <div className='car-info-general-progressBar-container'>
                  <ProgressBar
                    step={sensorsData?.batteryPercentage && !Number.isNaN(Number(sensorsData?.batteryPercentage)) ? (100 - Number(sensorsData?.batteryPercentage)) : 100}
                    steps={100}
                    height={4}
                    filledColor={(sensorsData?.batteryPercentage && (Number(sensorsData.batteryPercentage) > 50 || Number(sensorsData.batteryPercentage) === 100))
                      ? '#4495D1'
                      : (Number(sensorsData?.batteryPercentage) < 20)
                        ? '#EA4B4B'
                        : '#E88B48'}
                    icon={<FuelIcon fillProp={
                      sensorsData?.batteryPercentage === undefined
                        ? '#B5B5B540'
                        : (Number(sensorsData.batteryPercentage) > 50)
                          ? '#4495D1'
                          : (Number(sensorsData.batteryPercentage) < 20)
                            ? '#EA4B4B'
                            : '#E88B48'
                    } />}
                  />
                </div>
              </div>
              <div className='car-info-general-indications-list'>
                <div className='car-info-general-indication-container'>
                  <div className='car-info-general-battery-container-value'>
                    <h3>{sensorsData?.coolantTemp ? `${sensorsData.coolantTemp}°C` : '-'}</h3>
                  </div>
                  <span className='car-info-general-battery-container-title'>охлаждающая <br/>жидкость</span>
                </div>
                <div className='car-info-general-indication-container'>
                  <div className='car-info-general-battery-container-value'>
                    <h3>{sensorsData?.batteryTemp ? `${sensorsData.batteryTemp}°C` : '-'}</h3>
                  </div>
                  <span className='car-info-general-battery-container-title'>температура <br/>батареи</span>
                </div>
                <div className='car-info-general-indication-container'>
                  <div className='car-info-general-battery-container-value'>
                    <h3>{Number.isNaN(Number(sensorsData?.['12VBatteryVoltage'])) ? '-' : (`${Number(sensorsData?.['12VBatteryVoltage']).toFixed(2)} В`)}</h3>
                  </div>
                  <span className='car-info-general-battery-container-title'>напряжение <br/>АКБ</span>
                </div>
                <div className='car-info-general-indication-container'>
                  <div className='car-info-general-battery-container-value'>
                    <h3>{Number.isNaN(Number(sensorsData?.odometer)) ? '-' : (`${Number(sensorsData?.odometer)} км`)}</h3>
                  </div>
                  <span className='car-info-general-battery-container-title'>одометр</span>
                </div>
              </div>
            </div>
            <div className='car-info-general-img'>
              <img src={carImage} alt='i-pro' />
            </div>
          </div>
            <div className='car-info-container-columns'>
              <div className='car-info-container-grid'>
              {/* car-info-about */}
              <div className='car-info-container-small'>
                <div className='car-info-container'>
                  <div className='car-info-indications-container'>
                    <div className='car-info-indications-header'>
                      <div className='car-info-indications-title-container'>
                        <h3 className='car-info-indications-header-title'>Об автомобиле</h3>
                      </div>
                    </div>
                    <div className='car-info-general-settings-column1'>
                      {(carInfo.licensePlate || carInfo.licensePlate !== undefined) && <><div className='car-info-general-settings-cell'>
                        <span className='car-info-general-settings-cell-title'>Гос. номер</span>
                        <span className='car-info-general-settings-cell-value'>
                          <span>{carInfo.licensePlate}</span>
                          <span
                            className='car-info-general-settings-cell-editIcon'
                            onClick={() => handleEditField('Гос. номер', carInfo.licensePlate)}
                          >
                            {edit}
                          </span>
                          {isOptionsModalOpen && selectedField === 'Гос. номер' && (
                            <EditCarOptionsModal
                              onClose={handleOptionsModalClose}
                              isModalOpen={isOptionsModalOpen}
                              field={selectedField}
                              fieldValue={fieldValue}
                              onUpdateField={handleUpdateField} />
                          )}
                        </span>
                      </div><div className='car-info-indications-separator'></div></>}
                      {(carInfo.vin || carInfo.vin !== undefined) && <><div className='car-info-general-settings-cell'>
                        <span className='car-info-general-settings-cell-title'>VIN</span>
                        <span className='car-info-general-settings-cell-value'>
                          <span>{carInfo.vin}</span>
                          {store.user.buttons?.vinEdit &&
                            <span
                              className='car-info-general-settings-cell-editIcon'
                              onClick={() => handleEditField('VIN', carInfo.vin)}
                            >
                              {edit}
                            </span>}
                          {isOptionsModalOpen && selectedField === 'VIN' && (
                            <EditCarOptionsModal
                              onClose={handleOptionsModalClose}
                              isModalOpen={isOptionsModalOpen}
                              field={selectedField}
                              fieldValue={fieldValue}
                              onUpdateField={handleUpdateField} />
                          )}
                        </span>
                      </div><div className='car-info-indications-separator'></div></>}
                      {carInfo.carModel.name && <><div className='car-info-general-settings-cell'>
                        <span className='car-info-general-settings-cell-title'>Модель</span>
                        <div className='car-info-general-settings-cell-value'>
                          <span>{carInfo.carModel.name}</span>
                        </div>
                      </div><div className='car-info-indications-separator'></div></>}
                      {carInfo.carModel.options.heater !== undefined && <><div className='car-info-general-settings-cell'>
                        <span className='car-info-general-settings-cell-title'>Установлен отопитель:</span>
                        <span className='car-info-general-settings-cell-value'>
                          <span>{carInfo.carModel.options.heater ? 'Да' : 'Нет'}</span>
                          {store.user.buttons?.vinEdit && <span
                            className='car-info-general-settings-cell-editIcon'
                            onClick={() => handleEditField('Установлен отопитель', String(carInfo.carModel.options.heater))}
                          >
                            {edit}
                          </span>}
                          {isOptionsModalOpen && selectedField === 'Установлен отопитель' && (
                            <EditCarOptionsDropdownModal
                              labelTitle='Установлен отопитель:'
                              onClose={handleOptionsModalClose}
                              isModalOpen={isOptionsModalOpen}
                              field={String(selectedField)}
                              fieldValue={String(fieldValue) === 'false' ? 'Нет' : 'Да'}
                              onUpdateField={handleUpdateField} />
                          )}
                        </span>
                      </div><div className='car-info-indications-separator'></div></>}
                      {(carInfo.carModel.color || carInfo.carModel.color !== undefined) && <><div className='car-info-general-settings-cell'>
                        <span className='car-info-general-settings-cell-title'>Цвет</span>
                        <span className='car-info-general-settings-cell-value'>
                          <span>{carInfo.carModel.color}</span>
                        </span>
                      </div><div className='car-info-indications-separator'></div></>}
                      {(store.user.buttons?.readyMode && carInfo.carModel.options.readyMode !== undefined) && <><div className='car-info-general-settings-cell'>
                        <span className='car-info-general-settings-cell-title'>Ready доступен:</span>
                        <span className='car-info-general-settings-cell-value'>
                          <span>{carInfo.carModel.options.readyMode ? 'Да' : 'Нет'}</span>
                          <span
                            className='car-info-general-settings-cell-editIcon'
                            onClick={() => handleEditField('Ready доступен', String(carInfo.carModel.options.readyMode))}
                          >
                            {edit}
                          </span>
                          {isOptionsModalOpen && selectedField === 'Ready доступен' && (
                            <EditCarOptionsDropdownModal
                              labelTitle='Ready доступен:'
                              onClose={handleOptionsModalClose}
                              isModalOpen={isOptionsModalOpen}
                              field={String(selectedField)}
                              fieldValue={String(fieldValue) === 'false' ? 'Нет' : 'Да'}
                              onUpdateField={handleUpdateField} />
                          )}
                        </span>
                      </div><div className='car-info-indications-separator'></div></>}
                      {(carInfo.imei || carInfo.imei !== undefined) && <><div className='car-info-general-settings-cell'>
                        <span className='car-info-general-settings-cell-title'>IMEI</span>
                        <span className='car-info-general-settings-cell-value'>
                        <span>{carInfo.imei}</span>
                        {store.user.buttons?.canEditImei &&
                            <span
                              className='car-info-general-settings-cell-editIcon'
                              onClick={() => handleEditField('IMEI', carInfo.imei)}
                            >
                              {edit}
                            </span>
                        }
                        {isOptionsModalOpen && selectedField === 'IMEI' && (
                          <EditCarOptionsModal
                            onClose={handleOptionsModalClose}
                            isModalOpen={isOptionsModalOpen}
                            field={selectedField}
                            fieldValue={fieldValue}
                            onUpdateField={handleUpdateField} />
                        )}
                        </span>
                      </div><div className='car-info-indications-separator'></div></>}
                      {(carInfo.schemeName || carInfo.schemeName !== undefined) && <><div className='car-info-general-settings-cell'>
                        <span className='car-info-general-settings-cell-title'>Схема</span>
                        <span className='car-info-general-settings-cell-value'>
                          <span>{carInfo.schemeName}</span>
                        </span>
                      </div></>}
                      {(sensorsData?.errors || sensorsData?.errors !== undefined) &&
                      <>
                        <div className='car-info-indications-separator'></div>
                        <div className='car-info-general-settings-cell'>
                          <span className='car-info-general-settings-cell-title'>Коды ошибок</span>
                          <span className='car-info-general-settings-cell-value'>
                            <span>{sensorsData?.errors.trim().split(' ').join(', ')}</span>
                          </span>
                        </div>
                      </>}
                    </div>
                  </div>
                </div>
              </div>
              {/* car-info-map */}
              <div className='car-info-container-small'>
                <div className='car-info-map-container'>
                <h3 className='car-info-map-header'>Местоположение</h3>
                  <div className='car-info-map-opacity-container'>
                  </div>
                  {/* <span className='car-info-map-location'>{mapPin} {address || 'Неизвестно'}</span> */}
                  <YMaps>
                    <Map
                    defaultState={{ center: [(Number(positionData?.lat) || 55.75), (Number(positionData?.lon) || 37.57)], zoom: 8 }}
                    width={'416px'}
                    height={'258px'}
                    options={{ exitFullscreenByEsc: false }}
                    modules={['templateLayoutFactory', 'layout.ImageWithContent', 'geocode']}
                    >
                      <Placemark
                      defaultGeometry={[positionData?.lat, positionData?.lon]}
                      options={{
                        iconLayout: 'default#image',
                        iconImageHref: carsPng.carChargeMore50,
                        iconImageSize: [16, 28],
                      }}
                      />
                    </Map>
                    {!positionData?.lat && !positionData?.lon &&
                    <div className='car-info-map-location'>
                      {mapPin}
                      <span>Неизвестно</span>
                    </div>}
                  </YMaps>
                </div>
              </div>
              {/* car-test */}
              <div className='car-info-container-small'>
                <div className='car-info-container'>
                  <div className='car-info-indications-container'>
                    <div className='car-info-indications-header'>
                      <div className='car-info-indications-title-container'>
                        <h3 className='car-info-indications-header-title'>Тестирование</h3>
                      </div>
                    </div>
                    <div className='car-info-indications-list'>
                    <div className='car-info-indications-header'>
                      <span className='car-info-indication-title'>Отчет по тесту</span>
                      <LargeButtonWithIcon
                        text={'Создать'}
                        color={'--MyWhite'}
                        backgroundColor={'--MyPrimary'}
                        LeftIcon={plus}
                        iconMarginRight='8px'
                        padding='12px 8px'
                        onClick={handleAddCarTestModalOpen}/>
                        {isCarTestModalOpen && (
                        <AddCarTestModal
                          onClose={handleAddCarTestModalClose}
                          isModalOpen={isCarTestModalOpen}
                          carModel={carInfo.carModel.name}
                        />)}
                    </div>
                      <div className='car-info-indication'>
                        <span className='car-info-indication-value'>19.05.2023 13:34:09</span>
                        <span className='car-info-indication-value'>Успешно</span>
                      </div>
                      <div className='car-info-indications-separator'></div>
                      <div className='car-info-indication'>
                        <span className='car-info-indication-value'>19.05.2023 13:34:09</span>
                        <span className='car-info-indication-value'>Неуспешно</span>
                      </div>
                      <div className='car-info-indications-separator'></div>
                      <div className='car-info-indication'>
                        <span className='car-info-indication-value'>19.05.2023 13:34:09</span>
                        <span className='car-info-indication-value'>Частично успешно</span>
                      </div>
                      <div className='car-info-indications-separator'></div>
                      <span className='car-info-indications-showMore' onClick={() => navigate(`/fleet/car/${actualCarId}/test`)}>Показать все</span>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div className='car-info-container-grid'>
              {/* car-info-trip */}
              <div className='car-info-container-small'>
                <div className='car-info-container'>
                  <div className='car-info-trip-container'>
                    <div className='car-info-indications-header'>
                      <div className='car-info-indications-title-container'>
                        <h3 className='car-info-indications-header-title'>Поездки</h3>
                      </div>
                      <span className='car-info-trip-filter-select' onClick={() => navigate(`/fleet/car/${actualCarId}/trip`)}>
                        Все поездки
                      </span>
                    </div>
                    <div className='car-info-trip-filters'>
                      <ul>
                      <li className={selectedTripFilter === 'all' ? 'selected-filter' : ''} onClick={() => handleClick('all')}>Все</li>
                      <li className={selectedTripFilter === 'today' ? 'selected-filter' : ''} onClick={() => handleClick('today')}>Сегодня</li>
                      <li className={selectedTripFilter === 'yesterday' ? 'selected-filter' : ''} onClick={() => handleClick('yesterday')}>Вчера</li>
                      <li className={selectedTripFilter === 'week' ? 'selected-filter' : ''} onClick={() => handleClick('week')}>Неделя</li>
                      <li className={selectedTripFilter === 'month' ? 'selected-filter' : ''} onClick={() => handleClick('month')}>Месяц</li>
                      </ul>
                    </div>
                    <div className='car-info-trip-list'>
                    {travels && travels !== 0 ?
                      <div className='car-info-indication'>
                        <span>Всего поездок</span>
                        <span>{travels}</span>
                      </div> :
                      <div className='car-info-empty'>
                      <span>Нет данных</span>
                    </div>}
                      {/* <div className='car-info-indications-separator'></div>
                      <div className='car-info-indication'>
                        <span>Пробег</span>
                        <span>-</span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* car-info-accesses */}
              <div className='car-info-container-small'>
                <div className='car-info-container'>
                  <div className='car-info-accesses-container'>
                    <div className='car-info-accesses-header'>
                      <h3 className='car-info-accesses-header-title'>Доступы</h3>
                      {(store.user.orgId === owner._id || store.user.buttons?.canEditDrivers || store.user._id === owner._id) && <div className='car-info-accesses-add-button' >
                        <LargeButtonWithIcon text={''} color={'--MyWhite'} backgroundColor={'--MySecondaryBlue'} LeftIcon={plusAdd} onClick={handleAddDriverModalOpen} width='32px' height='32px'/>
                      </div>}
                        {isAddDriverModalOpen && (<AddDriverModal onClose={handleAddDriverModalClose} isModalOpen={isAddDriverModalOpen}/>)}
                    </div>
                    {/* <div className='car-info-accesses-autoPark'>
                        <span className='car-info-accesses-autoPark-title'>Автопарк</span>
                        <span className='car-info-accesses-autoPark-value'>Evolute</span>
                    </div> */}
                    {/* <div className='car-info-accesses-separator'></div> */}
                    <div className='car-info-accesses-carOwner'>
                      <span className='car-info-accesses-autoPark-title'>Владелец</span>
                      <div className='car-info-accesses-autoPark-value'>
                        <span>
                          {
                            (owner.lastName && owner.firstName)
                              ? `${owner.lastName} ${owner.firstName.charAt(0)}.`
                              : (owner.firstName || owner.lastName)
                                ? `${owner.lastName || owner.firstName}`
                                : 'Без имени'
                          }
                        </span>
                        <span className='car-info-accesses-driver-number'>
                          {owner.phone ? formatPhoneNumber(owner.phone) : owner.inn}
                        </span>
                      </div>
                    </div>
                    <div className='car-info-accesses-separator'></div>
                    <div className='car-info-accesses-drivers'>
                      <div className='car-info-accesses-autoPark-header'>
                        <span className='car-info-accesses-autoPark-title'>Водители</span>
                        {drivers.length === 0 ? (
                          <span className='car-info-accesses-autoPark-title'>-</span>) : null}
                      </div>
                        <div className='car-info-accesses-drivers-list'>
                          {(drivers.map((driver: DriversResponse) => (
                          <div key={driver._id} className='car-info-accesses-driver'>
                            <div className='car-info-accesses-driver-container'>
                              <div className='car-info-accesses-driver-name-container'>
                              <span className='car-info-accesses-driver-name'>
                              {
                                (driver.lastName && driver.firstName)
                                  ? `${driver.lastName} ${driver.firstName.charAt(0)}.`
                                  : (driver.firstName || driver.lastName)
                                    ? `${driver.lastName || driver.firstName}`
                                    : 'Водитель'
                              }
                              </span>
                              <span className='car-info-accesses-driver-number'>
                                {driver.phone ? formatPhoneNumber(driver.phone) : ''}
                              </span>
                              </div>
                              <span className={`car-info-accesses-driver-status
                                ${driver.blocked === false ? 'color-status-driver-active' : 'color-status-driver-blocked'}`}>
                                {driver.blocked === false ? 'Активен' : 'Заблокирован'}
                              </span>
                            </div>
                            {(store.user?.orgId === owner._id || store.user.buttons?.canEditDrivers || store.user._id === owner._id) && <div className='car-info-accesses-driver-options'>
                            <span onClick={() => handleAccessesModalOpen(driver)}>
                              {driver.blocked === false ? lockClose : lockOpen}
                            </span>
                            {isAccessModalOpen && selectedDriver && selectedDriver._id === driver._id && (
                              <AccessesDriversModal
                                onClose={handleAccessesModalClose}
                                isModalOpen={isAccessModalOpen}
                                title={selectedDriver.blocked === false ? 'Блокировка доступа' : 'Разблокировка доступа'}
                                description={`Вы уверены, что хотите ${
                                  selectedDriver.blocked === false ? 'заблокировать' : 'разблокировать'
                                } доступ для `}
                                driver={selectedDriver}
                                accessOption={selectedDriver.blocked === false ? 'Да, заблокировать' : 'Да, разблокировать'}
                              />
                            )}
                              <span onClick={() => handleDeleteModalOpen(driver)}>{bucket32}</span>
                              {isDeleteModalOpen && selectedDriver && selectedDriver._id === driver._id && (
                                <DeleteDriversModal
                                onClose={handleDeleteModalClose}
                                isModalOpen={isDeleteModalOpen}
                                title={'Удаление доступа'}
                                description={'Вы уверены, что хотите удалить доступ для '}
                                driver={selectedDriver}
                                accessOption={'Да, удалить'}/>
                              )}
                            </div>}
                          </div>
                          )))}
                        </div>
                    </div>
                    {(store.user?.orgId === owner._id || store.user.buttons?.canEditDrivers || store.user._id === owner._id) &&
                      <div className='car-info-accesses-driver-transferBtn'>
                        <MediumButton
                          text={'Сменить владельца'}
                          color={'--MyPrimary'}
                          backgroundColor={'--MySecondaryBlue'}
                          onClick={handleTransferModalOpen}
                          width='100%'
                          fontSize='14px'
                        />
                      </div>}
                      <TransferCarModal
                        successTransferMessage={'Автомобиль успешно передан'}
                        onClose={handleTransferModalClose}
                        isModalOpen={isTransferModalOpen}
                        title={'Сменить владельца'}
                      />
                  </div>
                </div>
              </div>
              {/* car-TO */}
              {/* <div className='car-info-container-small'>
                <div className='car-info-container'>
                  <div className='car-info-indications-container'>
                    <div className='car-info-indications-header'>
                      <div className='car-info-indications-title-container'>
                        <h3 className='car-info-indications-header-title'>Техническое обслуживание</h3>
                      </div>
                    </div>
                    <div className='car-info-indications-list'>
                    <div className='car-info-indication'>
                      <span className='car-info-indication-title'>Обязательное ТО</span>
                      <span className='car-info-indication-value'>23.10.2024</span>
                    </div>
                      <div className='car-info-indications-separator'></div>
                      <div className='car-info-indication'>
                        <span className='car-info-indication-title'>Рекомендуемое ТО</span>
                        <span className='car-info-indication-value'>10 000 км / 23.10.2024</span>
                      </div>
                      <div className='car-info-indications-separator'></div>
                      <div className='car-info-indication' style={{ alignItems: 'flex-start' }}>
                        <span className='car-info-indication-title'>История ТО</span>
                        <div className='car-info-indication-value-list'>
                          <span className='car-info-indication-value'>14.09.2023 (20 000 км)</span>
                          <span className='car-info-indication-value'>14.09.2023 (20 000 км)</span>
                        </div>
                      </div>
                      <div className='car-info-indications-separator'></div>
                      <div className='car-info-indication'>
                        <span className='car-info-indication-title'>Номер дилера</span>
                        <span className='car-info-indication-value'>12345</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* car-info-control */}
        {controlMainButtons && <div className='car-info-container-small'>
          <div className='car-info-container'>
            <div className='car-info-control-header'>
              <h3 className='car-info-control-header-title'>Управление</h3>
            </div>
            <div className='car-info-control-options-buttons-container'>
              <span className='car-info-control-options-buttons-header'>Автомобиль</span>
              <div className='car-info-control-options-buttons-list'>
              {controlMainButtons.map((button: Button) => (
                <button
                  key={button.title}
                  className={`car-info-control-options-button${
                    (button.state === true || button.state === 1) && carOnlineStatus
                      ? ' car-info-control-options-button-active'
                      : ''
                  }`}
                  onClick={() => {
                    if (carOnlineStatus) {
                      setIsLoadingButton((prevState) => ({ ...prevState, [button.command]: true }));
                      handleUpdateOption(button.command);
                    }
                  }}
                  disabled={!carOnlineStatus}
                >
                  <div className='car-info-control-option'>
                    <div className='car-info-control-option-panel'>
                      <span className='car-info-control-option-title'>{button.title}</span>
                      <span className='car-info-control-option-label'>{button.label}</span>
                    </div>
                    {isLoadingButton[button.command] ? (
                    <span className='car-info-control-option-icon'>
                    <Spinner borderTop={`2px solid ${isLoadingButton[button.command] && (button.state === true || button.state === 1) ? '#FFFFFF' : '#4495D1'}`}/>
                  </span>
                    ) : (
                    <span
                      className='car-info-control-option-icon'
                      dangerouslySetInnerHTML={{ __html: button.icon?.light || '' }}
                    />
                    )}
                  </div>
                </button>
              ))}
              </div>
              {automations !== undefined && <div className='car-info-automations-list'>
                {automations !== undefined ? automations.map((option, index) => (
                    <React.Fragment key={option.name}>
                      <div className='car-info-indication-container'>
                        <div className='car-info-indication'>
                          <span>{option.title}</span>
                          <MySwitch
                            disabled={!carOnlineStatus}
                            onChange={() => toggleAutomations(option.name)}
                            checked={option.active}
                          />
                        </div>
                        <span className='car-info-indication-description'>{option.description}</span>
                      </div>
                      {index < automations.length - 1 && <div className='car-info-indications-separator'></div>}
                    </React.Fragment>
                )) : null}
              </div>}
              <div className='car-info-separator'></div>
              <span className='car-info-control-options-buttons-header'>Климат контроль</span>
              <div className='car-info-control-options-temp-indicators'>
                <div className='car-temp-indicator-container'>
                  <span className='car-temp-indicator-title'>В салоне</span>
                  <span className='car-temp-indicator-value'>{inBoardTemp || '-'}°С</span>
                </div>
                <div className='outside-temp-indicator-container'>
                  <span className='outside-temp-indicator-title'>На улице</span>
                  <span className='outside-temp-indicator-value'>{outsideTemp || '-'}°С</span>
                </div>
              </div>
              {<div className='car-info-range-container'>
              <div className='car-info-range-temp'>
              <output id="output" style={{ marginBottom: '4px', display: 'flex', justifyContent: 'center' }}>
                <h3>{!Number.isNaN(climateTargetTemp[0]) && tempArray && tempArray.length > 0 ? climateTargetTemp : '-'}°С</h3>
              </output>
                <div style={{
                  margin: '0 21.5px 12px 21.5px', fontSize: '10px', display: 'flex', justifyContent: 'space-between'
                }}>
                  <span style={{ color: '#4495D1' }}>low</span>
                  <span style={{ color: '#EA4B4B' }}>high</span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    margin: '0 21.5px'
                  }}
                >
                  <Range
                  disabled={!carOnlineStatus}
                  values={[climateTargetTemp[0]]}
                  step={1}
                  max={tempRangeMinMax.max}
                  min={tempRangeMinMax.min}
                  onChange={(values) => setClimateTargetTemp(values)}
                    renderTrack={({ props, children }: RenderTrackProps) => (
                      <div
                        onMouseUp={() => handleUpdateClimate(climateTargetTemp[0])}
                        onTouchEnd={() => handleUpdateClimate(climateTargetTemp[0])}
                          style={{
                            ...props.style,
                            height: '4px',
                            display: 'flex',
                            width: '100%',
                            background: 'linear-gradient(to right, #4793CE, #D14244)',
                            borderRadius: '100px',
                          }}
                      >
                        <div
                          ref={props.ref}
                          style={{
                            height: '5px',
                            width: '100%',
                            borderRadius: '4px',
                            alignSelf: 'center'
                          }}
                        >
                          {climateTargetTemp[0] >= tempRangeMinMax.min &&
                            tempRangeMinMax.max > tempRangeMinMax.min &&
                            tempRangeMinMax.min &&
                            tempRangeMinMax.max &&
                            rangeTempArray &&
                            carOnlineStatus &&
                            tempArray && tempArray.length > 0 &&
                            children}
                        </div>
                      </div>
                    )}
                    renderThumb={({ props }: RenderThumbProps) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: '28px',
                          width: '28px',
                          borderRadius: '100px',
                          backgroundColor: '#FFF',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          boxShadow: '0px 2px 6px #AAA',
                          outline: 'none'
                        }}
                      >
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className='car-info-range-blowing'>
                <output style={{
                  marginBottom: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                }} id="output">
                  <h3>{!Number.isNaN(climateFanSpeed[0]) && climateFanSpeed[0] !== 0 && blowSpeedArray && blowSpeedArray.length > 0 ? climateFanSpeed : '-'}</h3> {climateFan}
                </output>
                <div style={{
                  margin: '0 21.5px 12px 21.5px', fontSize: '10px', display: 'flex', justifyContent: 'space-between'
                }}>
                  <span style={{ color: '#9A99A2' }}>{!Number.isNaN(climateFanSpeed[0]) && blowSpeedArray && blowSpeedArray.length > 0 ? Number(rangeBlowingArray[0]) : '-'}</span>
                  <span style={{ color: '#9A99A2' }}>{!Number.isNaN(climateFanSpeed[0]) && blowSpeedArray && blowSpeedArray.length > 0 ? Number(rangeBlowingArray[rangeBlowingArray.length - 1]) : '-'}</span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    margin: '0 21.5px'
                  }}
                >
                  <Range
                    disabled={!carOnlineStatus}
                    values={[climateFanSpeed[0]]}
                    step={1}
                    max={blowRangeMinMax.max}
                    min={blowRangeMinMax.min}
                    onChange={(values) => setClimateFanSpeed(values)}
                    renderTrack={({ props, children }: RenderTrackProps) => (
                      <div
                        onMouseUp={() => handleUpdateFanSpeed(climateFanSpeed[0])}
                        onTouchEnd={() => handleUpdateFanSpeed(climateFanSpeed[0])}
                        style={{
                          ...props.style,
                          height: '4px',
                          display: 'flex',
                          width: '100%',
                          background: 'linear-gradient(to right, #9A99A233, #9A99A2)',
                          borderRadius: '100px',
                        }}
                      >
                        <div
                          ref={props.ref}
                          style={{
                            height: '5px',
                            width: '100%',
                            borderRadius: '4px',
                            alignSelf: 'center'
                          }}
                        >
                          {climateFanSpeed[0] >= blowRangeMinMax.min &&
                            blowRangeMinMax.min &&
                            blowRangeMinMax.max > blowRangeMinMax.min &&
                            blowRangeMinMax.max &&
                            rangeBlowingArray && rangeBlowingArray.length > 0 &&
                            children}
                        </div>
                      </div>
                    )}
                    renderThumb={({ props }: RenderThumbProps) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: '28px',
                          width: '28px',
                          borderRadius: '100px',
                          backgroundColor: '#FFF',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          boxShadow: '0px 2px 6px #AAA',
                          outline: 'none'
                        }}
                      >
                      </div>
                    )}
                  />
                </div>
              </div>
              </div>}
              <div className='car-info-blow-direction-buttons-list'>
                {blowDirectionButtons.map((button: BlowDirectionButton) => (
                  <button
                    key={button.command}
                    className={`car-info-blow-direction-button${button.state
                      ? ' car-info-blow-direction-button-active' : ''}`}
                    onClick={() => handleUpdateOption(button.command)}
                    disabled={!carOnlineStatus}
                  >
                    <span
                      className='car-info-blow-direction-option-icon'
                      dangerouslySetInnerHTML={{ __html: button.svg.light || '' }}
                    />
                  </button>
                ))}
              </div>
              <div className='car-info-control-options-buttons-list'>
              {controlClimateButtons.map((button: Button) => (
                <button
                  key={button.title}
                  className={`car-info-control-options-button${
                    button.state === true || button.state === 1
                      ? ' car-info-control-options-button-active'
                      : ''
                  }`}
                  onClick={() => {
                    if (carOnlineStatus) {
                      setIsLoadingButton((prevState) => ({ ...prevState, [button.command]: true }));
                      handleUpdateOption(button.command);
                    }
                  }}
                  disabled={!carOnlineStatus}
                >
                  <div className='car-info-control-option'>
                    <div className='car-info-control-option-panel'>
                      <span className='car-info-control-option-title'>{button.title}</span>
                      <span className='car-info-control-option-label'>{button.label}</span>
                    </div>
                    {isLoadingButton[button.command] ? (
                      <span className='car-info-control-option-icon'>
                      <Spinner borderTop={`2px solid ${isLoadingButton[button.command] && (button.state === true || button.state === 1) ? '#FFFFFF' : '#4495D1'}`}/>
                    </span>
                    ) : (
                      <span
                        className='car-info-control-option-icon'
                        dangerouslySetInnerHTML={{ __html: button.icon?.light || '' }}
                      />
                    )}
                  </div>
                </button>
              ))}
              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default observer(CarInfoPage);
