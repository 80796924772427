/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './DeleteOrgStaffModal.css';
// import { useParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  closeModal, largeError, largeFinish, largeLoading
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import OrgService from '../../../services/OrgService';
import { OrgStaffUser } from '../../../models/response/OrgResponse';

interface DeleteOrgStaffModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  title: string;
  description: string;
  staffId: OrgStaffUser | null;
  accessOption: string;
}

function formatPhoneNumber(phoneNumber: string) {
  const cleaned = (`${phoneNumber}`).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
  }
  return phoneNumber;
}

const DeleteOrgStaffModal: React.FC<DeleteOrgStaffModalProps> = ({
  onClose, isModalOpen, title, description, staffId, accessOption
}) => {
  const { orgId } = useParams<{ orgId: string }>();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowSuccessMessage(true);
    try {
      if (staffId && orgId) {
        setSubmitting(true);
        const response = await OrgService.deleteOrgStaff(orgId, staffId.userId);
        if (response.status === 200) {
          setSubmitting(false);
          setStatusMessage('Сотрудник успешно удален');
          setShowSuccessMessage(false);
        }
      }
    } catch (error) {
      setSubmitting(false);
      setStatusMessage('Произошла ошибка при удалении сотрудника');
      setShowSuccessMessage(false);
    }
  };

  const handleRetry = () => {
    setStatusMessage('');
  };

  return (
    <div className={`deleteOrgStaff-modal-background ${isModalOpen ? '' : 'deleteOrgStaff-modal-hidden'}`}>
      <div className="deleteOrgStaff-modal-content">
        <h1>{title}</h1>
        <div className="deleteOrgStaff-modal-close" onClick={onClose}>{closeModal}</div>
        {showSuccessMessage && (
          <div className='addDriver-modal-notification-container'>
            {largeLoading}
            <h3 className='addDriver-modal-notification-title'>Удаляем сотрудника</h3>
          </div>
        )}
        {statusMessage && !showSuccessMessage && (
          <div>
            {!showSuccessMessage && statusMessage === 'Произошла ошибка при удалении сотрудника' ? (
              <div className='addCar-modal-notification-container'>
                {largeError}
                <h3 className='addCar-modal-notification-title'>{statusMessage}</h3>
                <div className='addCar-modal-error-buttons-container'>
                  <MediumButton text={'Попробовать снова'} onClick={handleRetry} backgroundColor={'--MyPrimary'} color={'--MyWhite'}/>
                  <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
                </div>
              </div>
            ) : (
              <div className='addCar-modal-notification-container'>
                {largeFinish}
                <h3 className='addCar-modal-notification-title'>Сотрудник успешно удален</h3>
              </div>
            )}
          </div>
        )}
        {!statusMessage && !showSuccessMessage && (<form className='deleteOrgStaff-modal-form' onSubmit={handleSubmit}>
          <div className='deleteOrgStaff-modal-form-description'>
          <span>
            {description} <span style={{ fontWeight: 500 }}>{staffId?.lastName ? staffId.lastName : 'сотрудника'} {staffId?.firstName ? staffId.firstName : ''}
            {staffId?.phone ? ` ${formatPhoneNumber(staffId.phone)}` : ' '}</span>?
          </span>
          </div>
          <div className='deleteOrgStaff-modal-buttons'>
            <MediumButton text={accessOption} color={'--MyWhite'} type="submit" backgroundColor={'--MyPrimary'} />
            <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
          </div>
        </form>)}
      </div>
    </div>
  );
};

export default DeleteOrgStaffModal;
