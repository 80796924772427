/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import './TransferCarModal.css';
import { useNavigate, useParams } from 'react-router-dom';
import { parsePhoneNumber } from 'libphonenumber-js';
import CarService from '../../../services/CarService';
import {
  clearInput, closeModal, largeFinish
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import MediumCircleButton from '../../buttons/MediumCircleButton';
import { Context } from '../../..';
import SegmentedInput from '../../Inputs/CodeInput/CodeInput';
import CountdownTimer from '../../CountdownTimer/CountdownTimer';
import PhoneInput from '../../Inputs/LoginInput/PhoneInput';

interface TransferCarModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  title: string;
  successTransferMessage: string;
}

const TransferCarModal: React.FC<TransferCarModalProps> = ({
  onClose, isModalOpen, title, successTransferMessage
}) => {
  const { carId } = useParams<{ carId: string }>();
  const actualCarId = carId || '';
  const [activeSubMenu, setActiveSubMenu] = useState<number>(1);
  const [showSmsInput, setShowSmsInput] = useState(false);
  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [smsCode, setSmsCode] = useState<string>('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { store } = useContext(Context);
  const navigate = useNavigate();

  // Form 1

  const [phoneNumberForm1, setPhoneNumberForm1] = useState('');
  // const [nameForm1, setNameForm1] = useState('');
  // const [lastNameForm1, setLastNameForm1] = useState('');
  // const [surnameForm1, setSurnameForm1] = useState('');
  // const [emailForm1, setEmailForm1] = useState('');

  const [isForm1Valid, setIsForm1Valid] = useState(false);

  // const defaultPhoneFormatOptions = {
  //   regExp: /(.*)(\d{3})(\d{3})(\d{2})(\d{2})$/,
  //   format: '$1 ($2) $3-$4-$5',
  // };

  // const clearPhoneNumber =  (valueFormat: string) => (valueFormat ?? '').replace(/[^\\+0-9]/g, '');

  // const phoneFormat = (valueFormat: string, options = defaultPhoneFormatOptions) => {
  //   const { regExp, format } = options;
  //   return clearPhoneNumber(valueFormat).replace(regExp, format);
  // };

  // const handlePhoneNumberChangeForm1 = (number: React.ChangeEvent<HTMLInputElement>) => {
  //   const inputValue = number.target.value;
  //   const formattedValue = phoneFormat(inputValue);
  //   setPhoneNumberForm1(formattedValue);
  //   setPhoneNumberForm1(number);
  // };

  const handlePhoneNumberChangeForm1 = (number: string) => {
    setPhoneNumberForm1(number);
  };

  const handleClearInput = (setState: React.Dispatch<React.SetStateAction<string>>) => () => {
    setState('');
  };

  const handleSubmitForm1 = async (e: any) => {
    e.preventDefault();
    try {
      const parsePhone = parsePhoneNumber(phoneNumberForm1)?.number.replace('+', '');
      if (store.selectedCars.length !== 0 && store.selectedIds.length !== 0) {
        const response = await CarService.transferCarRequest(store.selectedIds, parsePhone);
        if (response && (response.status === 201 || response.status === 200)) {
          setShowSmsInput(true);
        }
      }
      if (store.selectedCars.length === 0 && store.selectedIds.length === 0) {
        const response = await CarService.transferCarRequest(actualCarId, parsePhone);
        if (response && (response.status === 201 || response.status === 200)) {
          setShowSmsInput(true);
        }
      }
    } catch (error) {
      // console.error('Ошибка при отправке формы:', error);
    }
  };

  useEffect(() => {
    const isAllFieldsFilled =
      phoneNumberForm1.trim() !== '';
      // nameForm1.trim() !== '' &&
      // lastNameForm1.trim() !== '' &&
      // surnameForm1.trim() !== '' &&
      // emailForm1.trim() !== '';

    setIsForm1Valid(isAllFieldsFilled);
  // }, [emailForm1, surnameForm1, lastNameForm1, nameForm1, phoneNumberForm1]);
  }, [phoneNumberForm1]);

  // Form 2

  const [innForm2, setInnForm2] = useState('');
  const [isForm2Valid, setIsForm2Valid] = useState(false);

  const handleSubmitForm2 = async (e: any) => {
    e.preventDefault();
    try {
      if (store.selectedCars.length !== 0 && store.selectedIds.length !== 0) {
        const response = await CarService.transferINNCarRequest(store.selectedIds, innForm2);
        if (response) {
          setShowSmsInput(true);
        }
      } if (store.selectedCars.length === 0 && store.selectedIds.length === 0) {
        const response = await CarService.transferINNCarRequest(actualCarId, innForm2);
        if (response && (response.status === 201 || response.status === 200)) {
          setShowSmsInput(true);
        }
      }
    } catch (error) {
      // console.error('Ошибка при отправке формы:', error);
    }
  };

  useEffect(() => {
    const isAllFieldsFilled =
      innForm2.trim() !== '';

    setIsForm2Valid(isAllFieldsFilled);
  }, [innForm2]);

  // Form 3
  // const [businessGroup, setbusinessGroup] = useState('');
  // const [isGroupDropdownOpen, setisGroupDropdownOpen] = useState(false);

  // const [isForm3Valid, setIsForm3Valid] = useState(false);

  // const handleGroupInputClick = () => {
  //   setisGroupDropdownOpen((previsGroupDropdownOpen) => !previsGroupDropdownOpen);
  // };

  // const handleGroupSelect = (selectedModel: string) => {
  //   setbusinessGroup(selectedModel);
  //   setisGroupDropdownOpen(true);
  // };

  // const handleGroupInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setbusinessGroup(e.target.value);
  // };

  // const handleSubmitForm3 = async (e: any) => {
  //   e.preventDefault();
  //   try {
  //     console.log('Отправка формы 3');
  //     onClose();
  //   } catch (error) {
  //     console.error('Ошибка при отправке запроса:', error);
  //   }
  //   onClose();
  // };

  // useEffect(() => {
  //   const isAllFieldsFilled = businessGroup.trim() !== '';
  //   setIsForm3Valid(isAllFieldsFilled);
  // }, [businessGroup]);

  // Общее

  const resendSmsCode = async () => {
    try {
      if (activeSubMenu === 1) {
        const parsePhone = parsePhoneNumber(phoneNumberForm1)?.number.replace('+', '');
        if (store.selectedCars.length !== 0 && store.selectedIds.length !== 0) {
          const response = await CarService.transferCarRequest(store.selectedIds, parsePhone);
          if (response && (response.status === 201 || response.status === 200)) {
            setShowSmsInput(true);
          }
        }
        if (store.selectedCars.length === 0 && store.selectedIds.length === 0) {
          const response = await CarService.transferCarRequest(actualCarId, parsePhone);
          if (response && (response.status === 201 || response.status === 200)) {
            setShowSmsInput(true);
          }
        }
      }
      if (activeSubMenu === 2) {
        await CarService.transferINNCarRequest(actualCarId, innForm2);
      }
      setShowSmsInput(true);
    } catch (error) {
      // console.error('Ошибка при отправке кода:', error);
    }
  };

  const transferCar = async () => {
    try {
      const response = await CarService.transferCar(smsCode);
      if (response && (response.status === 201 || response.status === 200)) {
        setShowSmsInput(false);
        setShowSuccessMessage(true);
        setTimeout(() => {
          navigate('/fleet/car');
          window.location.reload();
        }, 500);
      } else {
        setErrorCode('Неверный код, попробуйте еще раз');
      }
    } catch {
      setErrorCode('Неверный код, попробуйте еще раз');
    }
  };

  useEffect(() => {
    if (/^\d{4}$/.test(smsCode)) {
      transferCar();
    }
    setErrorCode('');
  }, [smsCode]);

  const handleSmsCodeChange = (code: string) => {
    setSmsCode(code);
  };

  const handleRetry = () => {
    setShowSmsInput(false);
    setErrorCode('');
  };

  const buttonStyles = {
    activeButton: 'activeButton',
    inactiveButton: 'inactiveButton',
  };

  return (
    <div className={`transferCar-modal-background ${isModalOpen ? '' : 'transferCar-modal-hidden'}`}>
      <div className="transferCar-modal-content">
        <h1>{title}</h1>
        <div className="transferCar-modal-close"
          onClick={() => { onClose(); handleRetry(); setPhoneNumberForm1(''); setInnForm2(''); }}
        >
          {closeModal}
        </div>
        {showSuccessMessage && (
            <div className='transferCar-modal-notification-container'>
            {largeFinish}
            <h3 className='transferCar-modal-notification-title'>{successTransferMessage}</h3>
          </div>
        )}
        {!showSuccessMessage && (showSmsInput ? (
      <div className='transferCar-modal-codeInput'>
        <SegmentedInput value={smsCode} onChange={handleSmsCodeChange} />
        {errorCode && <div className="error-message">{errorCode}</div>}
        <CountdownTimer onResendClick={resendSmsCode}/>
        <div style={{ marginBottom: '40px' }}></div>
        <MediumButton
          text={'Отмена'}
          color={'--MyPrimary'}
          backgroundColor={'--MySecondaryBlue'}
          onClick={handleRetry}
        />
      </div>
        ) : (
          <>
          <div className="transferCar-modal-header">
          <MediumCircleButton
            className={activeSubMenu === 1 ? buttonStyles.activeButton : buttonStyles.inactiveButton}
            color={'--MyBlack'}
            backgroundColor={'--MySecondaryBlue'}
            width='100%'
            text={'Физическому лицу'}
            onClick={() => setActiveSubMenu(1)}
          />
          <MediumCircleButton
            className={activeSubMenu === 2 ? buttonStyles.activeButton : buttonStyles.inactiveButton}
            color={'--MyBlack'}
            backgroundColor={'--MySecondaryBlue'}
            width='100%'
            text={'Юридическому лицу'}
            onClick={() => setActiveSubMenu(2)}
          />
          {/* <MediumCircleButton
            style={activeSubMenu === 3 ? buttonStyles.activeButton : buttonStyles.inactiveButton}
            color='black'
            backgroundColor='#F8F9FF' width='100%'
            text={'Платформе'}
            onClick={() => setActiveSubMenu(3)}
          /> */}
        </div>
        {activeSubMenu === 1 && (
          <div className="sub-menu">
        <form className='transferCar-modal-form' onSubmit={handleSubmitForm1}>
        <div className='transferCar-modal-form-inputs'>
            <label className='transferCar-modal-label'>
              <span className='transferCar-modal-label-title'>Номер телефона:</span>
              <PhoneInput
              value={phoneNumberForm1}
              onChange={handlePhoneNumberChangeForm1}
              placeholder='+7 (___) ___-__-__'
            />
              {phoneNumberForm1 && (
                <div className='transferCar-modal-label-clear' onClick={handleClearInput(setPhoneNumberForm1)}>
                  {clearInput}
                </div>
              )}
            </label>
          </div>
          <div className='transferCar-modal-buttons'>
            <MediumButton
              disabled={!isForm1Valid}
              text={'Передать'}
              color={'--MyWhite'}
              type="submit"
              backgroundColor={'--MyPrimary'}
              />
            <MediumButton
              text={'Отмена'}
              color={'--MyPrimary'}
              backgroundColor={'--MySecondaryBlue'}
              onClick={() => { onClose(); handleRetry(); setPhoneNumberForm1(''); setInnForm2(''); }}
            />
          </div>
        </form>
          </div>
        )}
        {activeSubMenu === 2 && (
          <div className="sub-menu">
        <form className='transferCar-modal-form' onSubmit={handleSubmitForm2}>
        <div className='transferCar-modal-form-inputs'>
            <label className='transferCar-modal-label transferCar-modal-label-inn'>
              <span className='transferCar-modal-label-title'>ИНН:</span>
              <input placeholder='Введите ИНН' type="text" value={innForm2}
              onChange={(e) => setInnForm2(e.target.value)} />
              {innForm2 && (
                <div className='transferCar-modal-label-clear' onClick={handleClearInput(setInnForm2)}>
                  {clearInput}
                </div>
              )}
            </label>
          </div>
          <div className='transferCar-modal-buttons'>
            <MediumButton
              disabled={!isForm2Valid}
              text={'Передать'}
              color={'--MyWhite'}
              type="submit"
              backgroundColor={'--MyPrimary'}
            />
            <MediumButton
              text={'Отмена'}
              color={'--MyPrimary'}
              backgroundColor={'--MySecondaryBlue'}
              onClick={() => { onClose(); setInnForm2(''); setPhoneNumberForm1(''); }}
            />
          </div>
        </form>
          </div>
        )}
        {/* {activeSubMenu === 3 && (
          <div className="sub-menu">
        <form className='transferCar-modal-form' onSubmit={handleSubmitForm3}>
        <div className='transferCar-modal-form-inputs'>
            <label className='transferCar-modal-label'>
              <span className='transferCar-modal-label-title'>Бизнес-группа:</span>
              <input
                placeholder='Не выбрано'
                type="text"
                value={businessGroup}
                onChange={handleGroupInputChange}
                onClick={handleGroupInputClick}
              />
              {isGroupDropdownOpen && (
                <div className="transferCar-modal-dropdown" style={{ display: isGroupDropdownOpen ? 'block' : 'none' }}>
                  <div onClick={() => handleGroupSelect('Группа 1')}>Группа 1</div>
                  <div onClick={() => handleGroupSelect('Группа 2')}>Группа 2</div>
                  <div onClick={() => handleGroupSelect('Группа 3')}>Группа 3</div>
                </div>
              )}
              {!businessGroup ? (
                <div className='transferCar-modal-label-arrow-down'>
                  {arrowHeader}
                </div>
              ) : (
                <div className='transferCar-modal-label-clear' onClick={handleClearInput(setbusinessGroup)}>
                  {clearInput}
                </div>
              )}
            </label>
          </div>
          <div className='transferCar-modal-buttons'>
            <MediumButton disabled={!isForm3Valid} text={'Передать автомобиль'} color='white' type="submit"/>
            <MediumButton text={'Отмена'} color='#4495D1' backgroundColor='#F8F9FF' onClick={onClose} />
          </div>
        </form>
          </div>
        )} */}
          </>
        )
        )}
      </div>
    </div>
  );
};

export default TransferCarModal;
