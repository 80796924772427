/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import './TestCarInfoPage.css';
import { useParams } from 'react-router-dom';
import { Context } from '../..';
import MediumButton from '../../components/buttons/MediumButton';
import AddCarTestModal from '../../components/Modal/AddCarTest/AddCarTestModal';
import CarService from '../../services/CarService';
import { CarIdResponse } from '../../models/response/CarResponse';

const TestCarInfoPage = () => {
  const { carId } = useParams<{ carId: string }>();
  const actualCarId = carId || '';
  const { store } = useContext(Context);

  const [loading, setLoading] = useState(false);
  const [carInfo, setCarInfo] = useState<CarIdResponse | null>(null);
  const [isCarTestModalOpen, setIsCarTestModalOpen] = useState(false);

  const fetchCarInfo = async () => {
    try {
      const response = await CarService.getCar(actualCarId);
      setCarInfo(response.data);
    } catch (error) {
      // console.error('Ошибка при получении информации о машине:', error);
    }
  };

  // Модалка

  // Добавление сотрудника

  const handleAddTestModalOpen = () => {
    setIsCarTestModalOpen(true);
  };

  const handleAddTestModalClose = () => {
    setIsCarTestModalOpen(false);
  };

  useEffect(() => {
    fetchCarInfo();
  }, [isCarTestModalOpen]);

  useEffect(() => {
    store.setCarId(actualCarId);
  }, []);

  return (
    <>
      <div className="testCarInfo-topLine">
        <div className='testCarInfo-topLine-info'>
          <div className='testCarInfo-topLine-title'>
            <h2>Evolute</h2>
          </div>
        </div>
      </div>
      <div className='testCarInfo-staff-list-container'>
        <div className='testCarInfo-staff-list-topLine'>
          <div className='testCarInfo-staff-list-title'>
            <h3>Отчет по тесту</h3>
          </div>
          <MediumButton
            text={'Создать'}
            onClick={handleAddTestModalOpen}
            color={'--MyWhite'}
            backgroundColor={'--MyPrimary'}/>
          {isCarTestModalOpen &&
            <AddCarTestModal
            onClose={handleAddTestModalClose}
            isModalOpen={isCarTestModalOpen}
            carModel={null}
          />}
        </div>
        <div className='testCarInfo-staff-list'>
          <span>Нет тестов</span>
        </div>
      </div>
    </>
  );
};

export default TestCarInfoPage;
