/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import './App.css';
import {
  Navigate, Route, Routes, useParams
} from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import LoginPage from './pages/LoginPage/LoginPage';
import RequireAuth from './components/hoc/RequireAuth';
import CarsPage from './pages/CarsPage/CarsPage';
import Layout from './components/Layout/Layout';
import ChargingStationsPage from './pages/СhargingStationsPage/СhargingStationsPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import CarInfoPage from './pages/CarInfoPage/CarInfoPage';
import { Context } from '.';
import ErrorNotificationModal from './components/Modal/Notifications/ErrorNotificationModal';
import ChargeStationInfo from './pages/ChargeStationInfo/ChargeStationInfo';
import MapPage from './pages/Map/MapPage';
import TripPage from './pages/TripPage/TripPage';
import OrganizationsPage from './pages/OrganizationsPage/OrganizationsPage';
import OrganizationsPageInfo from './pages/OrganizationsPageInfo/OrganizationsPageInfo';
import RatePage from './pages/RatePage/RatePage';
import ChargeSessionsPage from './pages/ChargeSessionsPage/ChargeSessionsPage';
import ChargeSessionInfoPage from './pages/ChargeSessionInfoPage/ChargeSessionInfoPage';
import ChargeStationLogs from './pages/ChargeStationLogs/ChargeStationLogs';
import ChargeGroupPage from './pages/ChargingGroupPage/ChargeGroupPage';
import ChargingGroupMembersPage from './pages/ChargingGroupMembersPage/ChargingGroupMembersPage';
import AnalyticsChargePage from './pages/AnalitycsChargePage/AnalyticsChargePage';
import TestCarInfoPage from './pages/TestCarInfoPage/TestCarInfoPage';

const App: React.FC = () => {
  const { store } = useContext(Context);
  const [errorData, setErrorData] = useState<
    Array<{ id: number; name: string | null; message: string | null }>
  >([]);
  const timersRef = useRef<Record<number, number>>({});
  const maxNotifications = 5;

  const handleCloseError = (id: number) => {
    setErrorData((prevData) => prevData.filter((error) => error.id !== id));
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setErrorData([]);
    }, 10000);

    return () => clearTimeout(timeout);
  }, [errorData]);

  useEffect(() => {
    const checkNotifications = () => {
      if (errorData.length > maxNotifications) {
        const oldestId = errorData[0]?.id;
        if (oldestId) {
          setErrorData((prevData) => prevData.filter((error) => error.id !== oldestId));
          clearTimeout(timersRef.current[oldestId]);
          delete timersRef.current[oldestId];
        }
      }
    };

    checkNotifications();
  }, [errorData, maxNotifications]);

  useEffect(() => {
    if (store.errorName && store.errorMessage) {
      const id = Date.now();
      setErrorData((prevData) => [
        ...prevData,
        { id, name: store.errorName, message: store.errorMessage }
      ]);

      timersRef.current[id] = window.setTimeout(() => {
        setErrorData((prevData) => prevData.filter((error) => error.id !== id));
        store.setErrorName(null);
        store.setErrorMessage(null);
        delete timersRef.current[id];
      }, 10000);

      return () => {
        clearTimeout(timersRef.current[id]);
        delete timersRef.current[id];
      };
    }
  }, [store.errorName, store.errorMessage]);

  return (
    <>
    <div className='notification-container'>
      {errorData.map((error, index) => (
        <div key={error.id} className='notification'>
          <ErrorNotificationModal
            key={error.id}
            name={error.name || 'Ошибка'}
            message={error.message || 'Неизвестная ошибка'}
            onClose={() => handleCloseError(error.id)}
          />
        </div>
      ))}
      </div>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/*"
          element={
            <RequireAuth>
              <Layout>
                <Routes>
                  <Route path="*" element={<NotFoundPage />} />
                  <Route path="/" element={<Navigate to="/fleet/car" />} />
                  <Route path="fleet/car" element={<CarsPage />} />
                  <Route path='fleet/car/:carId' element={<CarInfoPage/>} />
                  <Route path='fleet/car/:carId/trip' element={<TripPage/>} />
                  <Route path='fleet/car/:carId/test' element={<TestCarInfoPage/>} />
                  <Route path="map" element={<MapPage />} />
                  <Route path='charging' element={<ChargingStationsPage />} />
                  <Route path='charging/:chargePointId' element={<ChargeStationInfo/>} />
                  <Route path='charging/:chargePointId/logs' element={<ChargeStationLogs/>} />
                  <Route path='groups' element={<ChargeGroupPage/>} />
                  <Route path='groups/:groupId' element={<ChargingGroupMembersPage/>} />
                  <Route path="rate" element={<RatePage />} />
                  <Route path="chargingSessions" element={<ChargeSessionsPage />} />
                  <Route path="chargingSessions/:chargingSessionId" element={<ChargeSessionInfoPage />} />
                  <Route path="organizations" element={<OrganizationsPage />} />
                  <Route path="organizations/:orgId" element={<OrganizationsPageInfo />} />
                  <Route path="analytics" element={<AnalyticsChargePage />} />
                </Routes>
              </Layout>
            </RequireAuth>
          }
        />
      </Routes>
    </>
  );
};

export default observer(App);
