/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-prototype-builtins */
import React, {
  useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import './MyTable.css';
import { observer } from 'mobx-react-lite';
import InfiniteScroll from 'react-infinite-scroll-component';
import { StickyTableHeader } from 'vh-sticky-table-header';
import { Skeleton } from 'antd';
import dayjs from 'dayjs';
import {
  bucket, connectors28pxIcons, edit, filterArrows
} from '../../Icons/Icons';
import MyCheckbox20 from '../Checkboxes/Checkbox20px';
import { Context } from '../..';

export enum OCPIConnectorsMapReversed {
  'CHAdeMO' = 'CHADEMO',
  'Type 1' = 'IEC_62196_T1',
  'CCS Combo 1' = 'IEC_62196_T1_COMBO',
  'Type 2' = 'IEC_62196_T2',
  'CCS Combo 2' = 'IEC_62196_T2_COMBO',
  'GBT (AC)' = 'GBT_AC',
  'GBT (DC)' = 'GBT_DC',
  'Tesla Roadster' = 'TESLA_R',
  'Tesla' = 'TESLA_S',
  'ChaoJi' = 'CHAOJI',
  'Type "A", NEMA 1-15, 2 pins' = 'DOMESTIC_A',
  'Type "B", NEMA 5-15, 3 pins' = 'DOMESTIC_B',
  'Type "C", CEE 7/17, 2 pins' = 'DOMESTIC_C',
  'Type "D", 3 pin' = 'DOMESTIC_D',
  'Type "E", CEE 7/5 3 pins' = 'DOMESTIC_E',
  'Type "F"' = 'DOMESTIC_F',
  'Type "G", BS 1363, Commonwealth, 3 pins' = 'DOMESTIC_G',
  'Type "H", SI-32, 3 pins' = 'DOMESTIC_H',
  'Type "I", AS 3112, 3 pins' = 'DOMESTIC_I',
  'Type "J", SEV 1011, 3 pins' = 'DOMESTIC_J',
  'Type "K", DS 60884-2-D1, 3 pins' = 'DOMESTIC_K',
  'Type "L", CEI 23-16-VII, 3 pins' = 'DOMESTIC_L',
  'Type "M", BS 546, 3 pins' = 'DOMESTIC_M',
  'Type "N", NBR 14136, 3 pins' = 'DOMESTIC_N',
  'Type "O", TIS 166-2549, 3 pins' = 'DOMESTIC_O',
  'IEC 60309-2, одна фаза, 16А' = 'IEC_60309_2_single_16',
  'IEC 60309-2, три фазы, 16А' = 'IEC_60309_2_three_16',
  'IEC 60309-2, три фазы, 32А' = 'IEC_60309_2_three_32',
  'IEC 60309-2, три фазы, 64А' = 'IEC_60309_2_three_64',
  'IEC 62196 Type 3A' = 'IEC_62196_T3A',
  'IEC 62196 Type 3C "Scame"' = 'IEC_62196_T3C',
  'NEMA 5-20, 3 pins' = 'NEMA_5_20',
  'NEMA 6-30, 3 pins' = 'NEMA_6_30',
  'NEMA 6-50, 3 pins' = 'NEMA_6_50',
  'NEMA 10-30, 3 pins' = 'NEMA_10_30',
  'NEMA 10-50, 3 pins' = 'NEMA_10_50',
  'NEMA 14-30, 3 pins' = 'NEMA_14_30',
  'NEMA 14-50, 3 pins' = 'NEMA_14_50',
  'Пантограф "снизу - вверх"' = 'PANTOGRAPH_BOTTOM_UP',
  'Пантограф "сверху - вниз"' = 'PANTOGRAPH_TOP_DOWN',
}

export enum OCPIConnectorsMap {
  'CHADEMO' = 'CHAdeMO',
  'IEC_62196_T1' = 'Type 1',
  'IEC_62196_T1_COMBO' = 'CCS Combo 1',
  'IEC_62196_T2' = 'Type 2',
  'IEC_62196_T2_COMBO' = 'CCS Combo 2',
  'GBT_AC' = 'GBT (AC)',
  'GBT_DC' = 'GBT (DC)',
  'TESLA_R' = 'Tesla Roadster',
  'TESLA_S' = 'Tesla',
  'CHAOJI' = 'ChaoJi',
  'DOMESTIC_A' = 'Type "A", NEMA 1-15, 2 pins',
  'DOMESTIC_B' = 'Type "B", NEMA 5-15, 3 pins',
  'DOMESTIC_C' = 'Type "C", CEE 7/17, 2 pins',
  'DOMESTIC_D' = 'Type "D", 3 pin',
  'DOMESTIC_E' = 'Type "E", CEE 7/5 3 pins',
  'DOMESTIC_F' = 'Type "F"',
  'DOMESTIC_G' = 'Type "G", BS 1363, Commonwealth, 3 pins',
  'DOMESTIC_H' = 'Type "H", SI-32, 3 pins',
  'DOMESTIC_I' = 'Type "I", AS 3112, 3 pins',
  'DOMESTIC_J' = 'Type "J", SEV 1011, 3 pins',
  'DOMESTIC_K' = 'Type "K", DS 60884-2-D1, 3 pins',
  'DOMESTIC_L' = 'Type "L", CEI 23-16-VII, 3 pins',
  'DOMESTIC_M' = 'Type "M", BS 546, 3 pins',
  'DOMESTIC_N' = 'Type "N", NBR 14136, 3 pins',
  'DOMESTIC_O' = 'Type "O", TIS 166-2549, 3 pins',
  'IEC_60309_2_single_16' = 'IEC 60309-2, одна фаза, 16А',
  'IEC_60309_2_three_16' = 'IEC 60309-2, три фазы, 16А',
  'IEC_60309_2_three_32' = 'IEC 60309-2, три фазы, 32А',
  'IEC_60309_2_three_64' = 'IEC 60309-2, три фазы, 64А',
  'IEC_62196_T3A' = 'IEC 62196 Type 3A',
  'IEC_62196_T3C' = 'IEC 62196 Type 3C "Scame"',
  'NEMA_5_20' = 'NEMA 5-20, 3 pins',
  'NEMA_6_30' = 'NEMA 6-30, 3 pins',
  'NEMA_6_50' = 'NEMA 6-50, 3 pins',
  'NEMA_10_30' = 'NEMA 10-30, 3 pins',
  'NEMA_10_50' = 'NEMA 10-50, 3 pins',
  'NEMA_14_30' = 'NEMA 14-30, 3 pins',
  'NEMA_14_50' = 'NEMA 14-50, 3 pins',
  'PANTOGRAPH_BOTTOM_UP' = 'Пантограф "снизу - вверх"',
  'PANTOGRAPH_TOP_DOWN' = 'Пантограф "сверху - вниз"',
}

type Connector = {
  _id: string;
  voltage: string;
  icon: string;
  name: string;
  status: string;
  type: keyof typeof OCPIConnectorsMap;
  power: string;
  tariffTitles: string[];
  tariffIds: string[];
};

type Evse = {
  uid: string;
  connectors: Connector[]
}

type Cell = {
  attribute: string;
  value: string | number | boolean;
};

type Row = {
  _id: string;
  cells: Cell[];
  evses: Evse[]
  address: string;
  name: string;
  city: string;
  status: string;
  updatedAt: string;
  sensors?: {
    battery: number,
    v12: number,
    odometer: number,
    remain: number,
    lastSensorsRecieved: string
  },
  partner: string;
  sessionId: string;
  locationId: string;
  connectorType: keyof typeof OCPIConnectorsMapReversed;
  chargingPeriod: {
    startedAt: Date;
    endedAt: Date;
  };
  power: number;
  price: number;
  isOurStation: boolean;
  connectorPower: string;
  active: boolean;
  phone: string;
  consumption: string;
};

type Header = {
  title: string;
  type: string;
  valueKey: string;
  width: number;
};

type MyTableProps = {
  headers?: any[];
  data: any[];
  fetchMore: () => void;
  hasMore?: boolean | undefined;
  scrollLoader?: string;
  selectedAttributes?: string[];
  selectedFilters?: string[];
  filtersSelected?: boolean;
  onRowClick?: (rowData: any, event?: React.MouseEvent) => void;
  onAuxClick?: (rowData: any, event?: React.MouseEvent) => void;
  onCheckboxChange?: (row: any) => void;
  showCheckboxes?: boolean;
  selectedIds?: string[];
  loading?: boolean;
  tableHref?: string | ((rowData: any) => string);
  onOdometerClick?: () => void;
};

function getDeepValue(obj: any, key: string): any {
  const keys = key.split('.');
  let result = obj;
  for (const k of keys) {
    if (result && result.hasOwnProperty(k)) {
      result = result[k];
    } else {
      result = null;
      break;
    }
  }
  return result;
}

export const statusDescriptions: { [key: string]: string } = {
  CHARGING: 'Занят',
  AVAILABLE: 'Свободен',
  OUTOFORDER: 'Выключен',
  INOPERATIVE: 'Выключен',
  UNKNOWN: 'Выключен',
  REMOVED: 'Выключен'
};

export const statusSessionDescriptions: { [key: string]: string } = {
  ACTIVE: 'Активна',
  COMPLETED: 'Завершена',
  INVALID: 'Прервана',
  PENDING: 'Ожидает',
  RESERVATION: 'Зарезервирована',
};

export const statusTextColors: { [key: string]: string } = {
  ACTIVE: '#E88B48',
  CHARGING: '#E88B48',
  AVAILABLE: '#5CBF63',
  FAILED: '#EA4B4B',
  UNKNOWN: '#EA4B4B',
  INVALID: '#EA4B4B',
  PENDING: '#E88B48',
  COMPLETED: '#5CBF63',
  RESERVATION: '#E88B48',
  OUTOFORDER: '#EA4B4B',
  INOPERATIVE: '#EA4B4B',
  REMOVED: '#EA4B4B',
};

export const statusColors: { [key: string]: string } = {
  ACTIVE: '#FDF3ED',
  CHARGING: '#FDF3ED',
  AVAILABLE: '#EFF9EF',
  FAILED: '#FDEDED',
  UNKNOWN: '#FDEDED',
  INVALID: '#FDEDED',
  PENDING: '#FDF3ED',
  COMPLETED: '#EFF9EF',
  RESERVATION: '#FDF3ED',
  OUTOFORDER: '#FDEDED',
  INOPERATIVE: '#FDEDED',
  REMOVED: '#FDEDED',
};

function formatPhoneNumber(phoneNumber: string) {
  const cleaned = (`${phoneNumber}`).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
  }
  return phoneNumber;
}

function renderCellData(row: Row, header: Header) {
  const value = getDeepValue(row, header.valueKey);

  const formatDecimalValue = (valueNumeric: any) => {
    if (valueNumeric && typeof valueNumeric === 'object' && valueNumeric.hasOwnProperty('$numberDecimal')) {
      return parseFloat(valueNumeric.$numberDecimal);
    }
    return valueNumeric;
  };

  if (header.valueKey === 'price') {
    return (
      <div className='connectors-row-power'>
          <div className='connectors-cell'>
            <span>{(row.price).toFixed(2)}</span>
          </div>
      </div>
    );
  }

  if (header.valueKey === 'consumption') {
    return (
      <div className='connectors-row-power'>
          <div className='connectors-cell'>
            <span>{formatDecimalValue(row.consumption)}</span>
          </div>
      </div>
    );
  }

  if (header.valueKey === 'connectors.type') {
    return (
      <div className='connectors-row-type'>
        {row.evses.flatMap((evse, index) => evse.connectors.map((connector) => (
            <div key={connector._id} className='connectors-cell-type-container'>
              <div className='connectors-cell connectors-cell-type'>
                {connectors28pxIcons && connectors28pxIcons[connector.type] && (
                  <span className='connector-icon'
                    dangerouslySetInnerHTML={{
                      __html: connectors28pxIcons[connector.type]
                    }}></span>
                )}
                <span>{OCPIConnectorsMap[connector.type] || connector.type}</span>
              </div>
            </div>
        )))}
      </div>
    );
  }

  if (header.valueKey === 'connectors.tariffTitles') {
    return (
      <div className='connectors-row-tariffs'>
        {row.evses.flatMap((evse) => evse.connectors.map((connector) => (
            <div className='connectors-cell' key={connector._id}>
              {connector.tariffTitles.length > 2 ?
                `${connector.tariffTitles.slice(0, 2).join(', ')} ...` :
                connector.tariffTitles.join(', ')
              }
            </div>
        )))}
      </div>
    );
  }

  if (header.valueKey === 'connectorType') {
    return (
      <div className='connectors-row-type'>
        <div className='connectors-cell-type-container'>
          <div className='connectors-cell connectors-cell-type'>
            {connectors28pxIcons && connectors28pxIcons[row.connectorType] && (
              <span className='connector-icon'
              dangerouslySetInnerHTML={{
                __html:
                connectors28pxIcons[row.connectorType]
              }}></span>
            )}
            <span>{row.connectorType}</span>
          </div>
        </div>
      </div>
    );
  }

  if (header.type === 'date' && header.valueKey === 'sensors.lastSensorsRecieved') {
    if (row.sensors?.lastSensorsRecieved && row.sensors?.lastSensorsRecieved !== undefined) {
      return new Date(row.sensors?.lastSensorsRecieved).toLocaleString();
    }
    return ('-');
  }

  if (header.valueKey === 'chargingPeriod') {
    const startTime = dayjs(row.chargingPeriod.startedAt);
    const endTime = dayjs(row.chargingPeriod.endedAt);
    const timeDifference = endTime.diff(startTime);

    const hours = Math.floor(timeDifference / (60 * 60 * 1000));
    const minutes = Math.floor((timeDifference % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((timeDifference % (60 * 1000)) / 1000);
    return (
      <div className='connectors-row-address'>
        <div className='connectors-row-timesPeriod'>
          <span className='address-cell'>{dayjs(row.chargingPeriod.startedAt).format('DD.MM.YYYY')}</span>
          <span className='address-cell'>
            {dayjs(row.chargingPeriod.startedAt).format('HH:mm:ss')} - {row.chargingPeriod.endedAt ? dayjs(row.chargingPeriod.endedAt).format('HH:mm:ss') : '?'}
          </span>
          <span className='address-cell'>
            всего {row.chargingPeriod.endedAt && row.chargingPeriod.startedAt ? `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}` : '?'}
          </span>
        </div>
      </div>
    );
  }

  if (header.valueKey === 'connectors.power') {
    return (
      <div className='connectors-row-power'>
        {row.evses.flatMap((evse) => evse.connectors.map((connector) => (
            <div className='connectors-cell' key={connector._id}>
              <span className='connectors-cell-power'>{connector.power} кВт</span>
            </div>
        )))}
      </div>
    );
  }

  if ((header.valueKey === 'connectorPower')) {
    return (
      <div className='connectors-row-power'>
          <div className='connectors-cell'>
            <span className='connectors-cell-power'>{row.connectorPower} кВт</span>
          </div>
      </div>
    );
  }

  if ((header.valueKey === 'phone' && header.title === 'Телефон')) {
    return (
      <div className='connectors-row-power'>
          <div className='connectors-cell'>
            <span>{formatPhoneNumber(row.phone)}</span>
          </div>
      </div>
    );
  }

  if (header.valueKey === 'status' && header.title === 'Статус станции') {
    const statusStation: { [key: string]: string } = {
      NotAvailable: 'Недоступна',
      Available: 'Доступна',
    };
    return (
      <span>{statusStation[row.status] || row.status}</span>
    );
  }

  if (header.valueKey === 'connectors.status') {
    return (
      <div className='connectors-row-status'>
        {row.evses.flatMap((evse) => evse.connectors.map((connector) => (
            <div className='connectors-cell' key={connector._id}>
              <span
                className='connectors-cell-status'
                style={{
                  backgroundColor: connector.status ? statusColors[connector.status] : '#FDEDED',
                  color: connector.status ? statusTextColors[connector.status] : '#EA4B4B'
                }}
              >
                {connector.status ? statusDescriptions[connector.status] || connector.status : 'Выключен'}
              </span>
            </div>
        )))}
      </div>
    );
  }

  if (header.valueKey === 'active' && header.title === 'Статус') {
    return (
      <div className='connectors-cell'>
        <span
          className='connectors-cell-status'
          style={{ backgroundColor: row.active ? '#EFF9EF' : '#FDEDED', color: row.active ? '#5CBF63' : '#EA4B4B' }}
        >
          {row.active ? 'Активирован' : 'Не активирован'}
        </span>
      </div>
    );
  }

  if (header.valueKey === 'status' && header.title === 'Статус') {
    return (
        <div className='connectors-cell'>
          <span
            className='connectors-cell-status'
            style={{ backgroundColor: row.status ? statusColors[row.status] : '#FDEDED', color: row.status ? statusTextColors[row.status] : '#EA4B4B' }}
          >
            {statusSessionDescriptions[row.status] || row.status}
          </span>
        </div>
    );
  }

  if (header.valueKey === 'address') {
    if (row.name) {
      return (
        <div style={{ display: 'flex' }}>
          <div className='connectors-row-address'>
            <span className='address-cell'>{row.address}</span>
            <span className='address-cell-name'>{row.name}</span>
          </div>
        </div>
      );
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <span className='address-cell'>{row.address}</span>
          </div>
        </div>
    );
  }

  if (header.title === 'Управление') {
    return (
        <div className='connectors-cell-groupControl' id='buttons'>
          <span data-id="edit-button" className='connectors-cell-editSvg'>
            {edit}
          </span>
          <span data-id="bucket-button" className='connectors-cell-bucketSvg'>
            {bucket}
          </span>
        </div>
    );
  }
  return value;
}

const calculateWidthInVw = (width: number): string => {
  const viewportWidth = window.innerWidth;
  return `${(width / 100) * viewportWidth}vw`;
};

const MyTable: React.FC<MyTableProps> = ({
  headers, data = [], onRowClick, showCheckboxes, onCheckboxChange, fetchMore, scrollLoader, hasMore, loading, onAuxClick, onOdometerClick, tableHref
}) => {
  const navigate = useNavigate();
  const { store } = useContext(Context);
  const tableRef = useRef<HTMLTableElement>(null);
  const headerRef = useRef<HTMLTableRowElement>(null);
  const fixedHeaderRef = useRef<HTMLTableElement>(null);
  const [fixedHeaderVisible, setFixedHeaderVisible] = useState(false);

  const handleRowClick = useCallback(
    (rowData: any, event: any) => {
      if (onRowClick) onRowClick(rowData, event);
    },
    [onRowClick]
  );

  const handleOdometerClick = () => {
    if (onOdometerClick) {
      onOdometerClick();
    }
  };

  const handleCheckboxRowChange = (rowId: string) => {
    if (onCheckboxChange) {
      onCheckboxChange(data.find((r) => r._id === rowId));
    }
  };

  useEffect(() => {
    const observerHeader = new IntersectionObserver(
      ([entry]) => {
        setFixedHeaderVisible(!entry.isIntersecting);
      },
      { threshold: 0 }
    );

    if (headerRef.current) {
      observerHeader.observe(headerRef.current);
    }

    return () => {
      if (headerRef.current) {
        observerHeader.unobserve(headerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (fixedHeaderVisible && tableRef.current && fixedHeaderRef.current) {
      const mainHeaderCells = tableRef.current.querySelectorAll('thead tr th');
      const fixedHeaderCells = fixedHeaderRef.current.querySelectorAll('thead tr th');
      fixedHeaderCells.forEach((cell, index) => {
        const mainCell = mainHeaderCells[index] as HTMLElement;
        const fixedCell = cell as HTMLElement;
        const width = mainCell.offsetWidth;
        fixedCell.style.width = `${width}px`;
      });
    }
  }, [fixedHeaderVisible]);

  return (
    <>
      {fixedHeaderVisible && (
        <table className="fixed-header" ref={fixedHeaderRef}>
          <thead>
            <tr className="table-header">
              {showCheckboxes && <th style={{ padding: '0 16px' }} key="select">Выбрать</th>}
              {headers?.map((header) => (
                <th key={header.title} style={{ width: calculateWidthInVw(header.width) }}>
                  <div className='table-header-cell'>
                    {header.title}
                    {/* {header.title === 'Одометр' && (
                      filterArrows
                    )} */}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table-body" style={{ display: 'none' }}>
              {data.map((row) => (
                <tr key={row._id} className="table-row">
                  {showCheckboxes && (
                    <td className="table-cell">
                      <span className="table-cell-checkbox">
                        <MyCheckbox20
                          checked={store.selectedIds ? store.selectedIds.includes(row._id) : false}
                          onCheckboxChange={() => handleCheckboxRowChange(row._id)}
                        />
                      </span>
                    </td>
                  )}
                  {headers?.map((header) => (
                    <td key={header.valueKey} className="table-cell">
                      {renderCellData(row, header)}
                    </td>
                  ))}
                </tr>
              ))}
              {loading && (
                <tr>
                  {headers?.map((header, index) => (
                    <td key={index} className="table-cell">
                      <div className="skeleton-container">
                        <Skeleton.Input active size="small" />
                      </div>
                    </td>
                  ))}
                </tr>
              )}
          </tbody>
        </table>
      )}
      <InfiniteScroll
        dataLength={data.length}
        next={fetchMore}
        hasMore={hasMore || false}
        loader={
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          {scrollLoader}
        </div>}
      >
        <table className="table" ref={tableRef}>
          <thead>
            <tr className="table-header" ref={headerRef}>
              {showCheckboxes && <th style={{ padding: '0 16px' }} key="select">Выбрать</th>}
              {headers?.map((header) => (
                <th key={header.title} style={{ width: calculateWidthInVw(header.width) }}>
                  {/* <div className='table-header-cell'>
                    {header.title}
                  </div> */}
                  {header.title === 'Одометр' ?
                    <div className='table-header-cell' onClick={handleOdometerClick} style={{ cursor: 'pointer' }}>
                      <button className='table-header-cell-button'>
                        {header.title}
                        {filterArrows}
                      </button>
                    </div> :
                  <div className='table-header-cell'>
                    {header.title}
                  </div>}
                </th>
              ))}
            </tr>
          </thead>
          {data.length > 0 && (
            <tbody className="table-body">
              {data.map((row) => (
                <tr key={row._id} className="table-row">
                  <a
                    onClick={(event) => handleRowClick(row, event)}
                    href={typeof tableHref === 'function' ? tableHref(row) : '#'}
                    style={{ display: 'contents', textDecoration: 'none', color: 'inherit' }}
                  >
                    {showCheckboxes && (
                      <td className="table-cell">
                        <span className="table-cell-checkbox">
                          <MyCheckbox20
                            checked={store.selectedIds ? store.selectedIds.includes(row._id) : false}
                            onCheckboxChange={() => handleCheckboxRowChange(row._id)}
                          />
                        </span>
                      </td>
                    )}
                    {headers?.map((header) => (
                      <td key={header.valueKey} className="table-cell">
                        {renderCellData(row, header)}
                      </td>
                    ))}
                  </a>
                </tr>
              ))}
              {loading && (
                <tr>
                  {headers?.map((header, index) => (
                    <td key={index} className="table-cell">
                      <div className="skeleton-container">
                        <Skeleton.Input active size="small" />
                      </div>
                    </td>
                  ))}
                </tr>
              )}
            </tbody>
          )}
          {data.length <= 0 && (
            <tbody>
              <tr>
                <td colSpan={headers?.length}>
                  <div className="table-notFound-title">Нет данных</div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </InfiniteScroll>
    </>
  );
};

export default observer(MyTable);
