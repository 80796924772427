/* eslint-disable import/order */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useCallback, useContext, useEffect, useState
} from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import LargeButton from '../buttons/LargeButton';
import PhoneInput from '../Inputs/LoginInput/PhoneInput';
import MyCheckbox from '../Checkboxes/Checkbox';
import './LoginForm.css';
import { useNavigate } from 'react-router-dom';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import { arrowForward, dfengLogo, loginLogo } from '../../Icons/Icons';
import Spinner from '../Spinner/Spinner';
import CountdownTimer from '../CountdownTimer/CountdownTimer';
import SegmentedInput from '../Inputs/CodeInput/CodeInput';
import { MyOrgs } from '../../models/response/OrgResponse';
import parsePhoneNumber from 'libphonenumber-js';

const LoginForm: React.FC = () => {
  const { store } = useContext(Context);
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [smsCode, setSmsCode] = useState<string>('');
  const [showCodeForm, setShowPasswordForm] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [orgData, setOrgData] = useState<MyOrgs | null>(null);

  const [yandexToken, setYandexToken] = useState<string>('');
  const [visible, setVisible] = useState(false);
  const [resetCaptcha, setResetCaptcha] = useState(0);

  // const defaultPhoneFormatOptions = {
  //   regExp: /(.*)(\d{3})(\d{3})(\d{2})(\d{2})$/,
  //   format: '$1 ($2) $3-$4-$5',
  // };

  // const clearPhoneNumber =  (valueFormat: string) => (valueFormat ?? '').replace(/[^\\+0-9]/g, '');

  // const phoneFormat = (valueFormat: string, options = defaultPhoneFormatOptions) => {
  //   const { regExp, format } = options;
  //   return clearPhoneNumber(valueFormat).replace(regExp, format);
  // };

  // const handlePhoneNumberChange = (number: string) => {
  //   const formattedValue = phoneFormat(number);
  //   setPhoneNumber(formattedValue);
  //   return clearPhoneNumber(number);
  // };

  const handlePhoneNumberChange = (number: string) => {
    setPhoneNumber(number);
  };

  const handleSmsCodeChange = (code: string) => {
    setSmsCode(code);
  };

  const handleCheckboxChange = async (captcha: any) => {
    setIsChecked(!isChecked);
    setVisible(true);
  };

  const handleChallengeHidden = useCallback(() => setVisible(false), []);

  // const phoneIsValid = (phone: string) => {
  //   const phonePattern = validator.isMobilePhone(phone);
  //   return phonePattern;
  // };

  const handlePhone = async () => {
    if (isChecked) {
      setLoading(true);
      const parsePhone = parsePhoneNumber(phoneNumber)?.number.replace('+', '');
      if (yandexToken && parsePhone) {
        const timeoutId = setTimeout(() => {
          setLoading(false);
        }, 5000);

        await store.registration(parsePhone, yandexToken);
        clearTimeout(timeoutId);
        setResetCaptcha((prev) => prev + 1);
        setPhoneNumber(phoneNumber);
        setShowPasswordForm(true);
      }
    } else {
      setLoading(false);
    }
  };

  const handleOrgClick = async (orgId: string, orgName: string) => {
    try {
      await store.loginOrg(orgId);
      localStorage.setItem('selectedAccount', orgName);
      // localStorage.setItem('selectedMenuItem', 'Автопарк');
      // localStorage.setItem('selectedItem', 'Автопарк');
      // localStorage.setItem('selectedSubItem', 'Автомобили');
      await store.getUser();
      navigate('/fleet/car');
    } catch (error) {
      // console.error('Ошибка при выполнении запроса:', error);
    }
  };

  const handlePersonalAccountClick = async () => {
    try {
      localStorage.setItem('selectedAccount', 'Личный аккаунт');
      // localStorage.setItem('selectedMenuItem', 'Автопарк');
      // localStorage.setItem('selectedItem', 'Автопарк');
      // localStorage.setItem('selectedSubItem', 'Автомобили');
      localStorage.setItem('token', store.token || '');
      await store.getUser();
      navigate('/fleet/car');
    } catch (error) {
      // console.error('Ошибка при выполнении запроса:', error);
    }
  };

  const handleSmsCode = async (code: string) => {
    try {
      const parsePhone = parsePhoneNumber(phoneNumber)?.number.replace('+', '');
      if (parsePhone) {
        await store.login(parsePhone, code);
        store.setUser({ phone: phoneNumber });
      }
      if (store.isAuth) {
        const response = await store.getOrg();
        if (response !== null && response !== undefined) {
          setOrgData(response);
          if (response.total === 0) {
            await handlePersonalAccountClick();
          }
        }
      } else {
        setErrorCode('Неверный код, попробуйте еще раз');
      }
    } catch (error) {
      // console.error('Ошибка входа:', error);
    }
  };

  useEffect(() => {
    if (/^\d{4}$/.test(smsCode)) {
      handleSmsCode(smsCode);
    }
  }, [smsCode]);

  // Domen

  const [currentLoginLogo, setCurrentLoginLogo] = useState<React.ReactNode | null>(null);

  useEffect(() => {
    const getLogoByDomain = () => {
      const domain = window.location.hostname;

      if (domain.includes('dfm-assist')) {
        return dfengLogo;
      }
      return loginLogo;
    };

    const logoPath = getLogoByDomain();
    setCurrentLoginLogo(logoPath);
  }, []);

  return (
    <div className='login-container'>
    <div className='login-logo'>
      {currentLoginLogo}
    </div>
    {orgData?.rows && orgData?.total >= 1 ? (
      <div className='login-container-form'>
        <h1 className='login-title'>Выберите аккаунт</h1>
        <div className='login-org-container'>
          <div>
            <div className='login-org-select' onClick={handlePersonalAccountClick}>
              <span className='login-org-name'>Личный аккаунт</span>
              <span className='login-org-arrow'>{arrowForward}</span>
            </div>
              {orgData?.rows.map((org: any) => (
                <div key={org._id} className='login-org-select' onClick={() => handleOrgClick(org._id, org.name)}>
                  <span className='login-org-name'>{org.name}</span>
                  <span className='login-org-arrow'>{arrowForward}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
    ) : (
      <div className={`login-container-form 
      ${showCodeForm ? 'login-container-form-collapsed' : 'login-container-form-expanded'}`}
      >
        {showCodeForm ? (
        <>
          <h1 className='login-title'>Вход в систему</h1>
          <div className='login-code-container'>
          <SegmentedInput value={smsCode} onChange={handleSmsCodeChange} />
          {errorCode && <div className="error-message">{errorCode}</div>}
          <CountdownTimer onResendClick={handlePhone}/>
          </div>
        </>
        ) : (
          <>
          <h1 className='login-title'>Вход в систему</h1>
          <div className='login-input-container'>
            <PhoneInput
              numberLabel = 'Номер телефона'
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder='+7 (___) ___-__-__'
            />
          </div>
          <div className='login-accepted'>
            <MyCheckbox
              label={
                <span>
                  Даю согласие на{' '}
                  <a target='_blank' href="https://www.evolute.ru/privacy" rel="noreferrer">обработку персональных данных</a>
                </span>
              }
            checked={isChecked}
            onCheckboxChange={handleCheckboxChange}
            marginLeft='6px'
            />
          </div>
              <InvisibleSmartCaptcha
                sitekey={'ysc1_e1r4y9tomfo5okpSJaf2ksgVFdldRmu5bPQuoe9s0c2ffadd'}
                onSuccess={setYandexToken}
                hideShield={true}
                visible={visible}
                onChallengeHidden={handleChallengeHidden}
                key={resetCaptcha}
              />
            <LargeButton
              maxWidth='424px'
              width='100%'
              onClick={handlePhone}
              text={loading ? (
                <>
                  <Spinner />
                  Отправляем код
                </>
              ) : (
                'Получить код по SMS'
              )}
              disabled={loading || !isChecked || phoneNumber.length !== 18}
              backgroundColor={'--MyPrimary'}
              color={'--MyWhite'}
            />
            <div className='login-policy-information'>
              <span>Мы обрабатываем ваши данные с помощью SmartCaptcha.</span>
              <a href='https://yandex.ru/legal/cloud_terms_smartcaptcha/' target='_blank' rel="noreferrer">Условия использования Yandex SmartCaptcha</a>
            </div>
            </>
        )}
      </div>
    )}
    </div>
  );
};

export default observer(LoginForm);
