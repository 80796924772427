/* eslint-disable global-require */
export default {
  // Evo
  iPro: require('./images/cars/i-pro.png'),
  iSky: require('./images/cars/i-SKY.png'),
  iJoy: require('./images/cars/i-Joy white.png'),
  iJet: require('./images/cars/i-JET.png'),
  iVan: require('./images/cars/i-VAN black.png'),
  iSpace: require('./images/cars/i-SPACE black.png'),
  // DFM
  DF580: require('./images/cars/DF580-white.png'),
  DF600: require('./images/cars/DF600-white.png'),
  IX5: require('./images/cars/ix5.png'),
  IX7: require('./images/cars/ix7.png'),
  ShineMax: require('./images/cars/shine-max white.png'),
  // Pins
  carChargeMore50: require('./images/MapCarPins/car_charge_more_50%.png'),
  carCharge50: require('./images/MapCarPins/car_charge_20-50%.png'),
  carChargeLess20: require('./images/MapCarPins/car_charge_less_20%.png'),
  carOffline: require('./images/MapCarPins/car_charge_offline.png')
};
