/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import './NotificationsModal.css';
import { clearInput, errorNotification } from '../../../Icons/Icons';

type Props = {
    name: string;
    message: string | Array<string>;
    onClose: () => void;
  };

const ErrorNotificationModal: React.FC<Props> = ({ name, message, onClose }) => (
  <div className='error-container'>
    <div className='error-body'>
        <div className='notification-header'>
        <div className='error-title'>
          <span className='notification-icon'>{errorNotification}</span>
          <span className='notification-title'>{name}</span>
        </div>
        <span className='notification-close-icon' onClick={onClose}>{clearInput}</span>
        </div>
        <div className='notification-message'>
            {message}
        </div>
    </div>
  </div>
);

export default ErrorNotificationModal;
