/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, {
  ReactNode, useContext, useEffect, useState
} from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import SelectedMenu from '../SelectedMenu/SelectedMenu';
import './Layout.css';
import ScrollToTopButton from '../ScrollToTopButton/ScrollToTopButton';
import { arrowBack } from '../../Icons/Icons';
import { Context } from '../..';
import SideHeader from '../SideHeader/SideHeader';


interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { store } = useContext(Context);

  const isOnCarsPage = /^\/fleet\/car$/.test(location.pathname);
  const isOnCarInfoPage = /^\/fleet\/car\/[\w-]+$/.test(location.pathname);
  const isOnTripPage = /^\/fleet\/car\/[\w-]+\/trip$/.test(location.pathname);
  const isOnTestPage = /^\/fleet\/car\/[\w-]+\/test$/.test(location.pathname);

  const isOnChargePage = /^\/charging$/.test(location.pathname);
  const isOnChargeInfoPage = /^\/charging\/[\w-]+$/.test(location.pathname);
  const isOnChargeLogsPage = /^\/charging\/[\w-]+\/logs$/.test(location.pathname);
  const isOnChargeSessionsInfoPage = /^\/chargingSessions\/[\w-]+$/.test(location.pathname);
  const isOnChargeSessionsPage = /^\/chargingSessions$/.test(location.pathname);
  const isOnChargeGroupPage = /^\/groups$/.test(location.pathname);
  const isOnChargeGroupMembersPage = /^\/groups\/[\w-]+/.test(location.pathname);

  const isOnRatePage = /^\/rate$/.test(location.pathname);
  const isOnOrgPage = /^\/organizations$/.test(location.pathname);
  const isOnOrgInfoPage = /^\/organizations\/[\w-]+$/.test(location.pathname);

  const isOnMapPage = /^\/map$/.test(location.pathname);

  const isOnAnalyticsPage = /^\/analytics$/.test(location.pathname);

  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('');

  useEffect(() => {
    if (isOnCarsPage || isOnCarInfoPage || isOnTripPage || isOnTestPage) {
      setSelectedMenuItem('Автопарк');
    } else if (isOnChargeInfoPage || isOnChargeSessionsInfoPage || isOnChargePage || isOnChargeSessionsPage || isOnRatePage || isOnChargeLogsPage || isOnChargeGroupPage || isOnChargeGroupMembersPage) {
      setSelectedMenuItem('Сеть ЭЗС');
    } else if (isOnOrgPage || isOnOrgInfoPage) {
      setSelectedMenuItem('Организации');
    } else if (isOnAnalyticsPage) {
      setSelectedMenuItem('Аналитика');
    } else setSelectedMenuItem('');
  }, [location.pathname]);

  return (
    <div>
      <div className='header-layout'>
        <SideHeader setSelectedMenuItem={setSelectedMenuItem} />
      </div>
      <div className='bodyPage'
          style={
            isOnMapPage
              ? { marginTop: '20px', padding: '0' }
              : (isOnOrgInfoPage || isOnTestPage)
                ? { width: '585px' }
                : {}
          }
        >
        {isOnChargeInfoPage &&
        <span className='bodyPage-back-button' onClick={() => navigate('/charging')}>
          {arrowBack} к зарядным станциям
        </span>}
        {isOnChargeLogsPage &&
        <span className='bodyPage-back-button' onClick={() => navigate(`/charging/${store.stationId}`)}>
          {arrowBack} к зарядной станции
        </span>}
        {isOnChargeSessionsInfoPage &&
        <span className='bodyPage-back-button' onClick={() => navigate('/chargingSessions')}>
          {arrowBack} к зарядным сессиям
        </span>}
        {(isOnTripPage || isOnTestPage) &&
        <span className='bodyPage-back-button' onClick={() => navigate(`fleet/car/${store.carId}`)}>
          {arrowBack} к автомобилю
        </span>}
        {isOnOrgInfoPage &&
        <span className='bodyPage-back-button' onClick={() => navigate('/organizations')}>
          {arrowBack} ко всем организациям
        </span>}
        {isOnCarInfoPage &&
        <span className='bodyPage-back-button' onClick={() => navigate('/fleet/car')}>
          {arrowBack} ко всем автомобилям
        </span>}
        {isOnChargeGroupMembersPage &&
        <span className='bodyPage-back-button' onClick={() => navigate('/groups')}>
          {arrowBack} к группам
        </span>}
        <div className='header-selectedMenu-layout'>
          <SelectedMenu selectedMenuItem={selectedMenuItem} />
        </div>
        {children}
      </div>
      <ScrollToTopButton/>
    </div>
  );
};

export default observer(Layout);
