/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import './ResetChargeStation.css';
// import { useParams } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import {
  closeModal, largeError, largeFinish, largeLoading
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import OrgService from '../../../services/OrgService';
import ChargeService from '../../../services/ChargeService';

interface ResetChargeStationModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  title: string;
  reset: string;
  station: string | undefined;
  stationSerialNumber: string;
  accessOption: string;
  successDeleteMessage: string;
}

const ResetChargeStationModal: React.FC<ResetChargeStationModalProps> = ({
  onClose, isModalOpen, title, reset, station, accessOption, successDeleteMessage, stationSerialNumber
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (reset === 'hard reset') {
        try {
          setSubmitting(true);
          setStatusMessage(`Делаем ${reset}`);
          const response = await ChargeService.stationReset(stationSerialNumber, 'hard');
          if (response.status === 201 || response.status === 200) {
            setStatusMessage(`${reset} успешно выполнен`);
            setSubmitting(false);
          }
        } catch (error) {
          setStatusMessage(`Произошла ошибка при выполнении ${reset}`);
          setSubmitting(false);
        }
      } else if (reset === 'soft reset') {
        try {
          setSubmitting(true);
          setStatusMessage(`Делаем ${reset}`);
          const response = await ChargeService.stationReset(stationSerialNumber, 'soft');
          if (response.status === 201 || response.status === 200) {
            setStatusMessage(`${reset} успешно выполнен`);
            setSubmitting(false);
          }
        } catch (error) {
          setStatusMessage(`Произошла ошибка при выполнении ${reset}`);
          setSubmitting(false);
        }
      }
    } catch (error) {
      setSubmitting(false);
      setStatusMessage(`Произошла ошибка при выполнении ${reset}`);
    }
  };

  const handleRetry = () => {
    setStatusMessage('');
  };

  return (
    <div className={`deleteDriver-modal-background ${isModalOpen ? '' : 'deleteDriver-modal-hidden'}`}>
      <div className="deleteDriver-modal-content">
        <h2>{title}</h2>
        <div className="deleteDriver-modal-close" onClick={onClose}>{closeModal}</div>
        {submitting && (
          <div className='addRateDetailed-modal-notification-container'>
            {largeLoading}
            <h3 className='addRateDetailed-modal-notification-title'>Делаем {reset}...</h3>
          </div>
        )}
        {!submitting && statusMessage && (
          <div>
            {statusMessage === `Произошла ошибка при выполнении ${reset}` ? (
              <div className='addRateDetailed-modal-notification-container'>
                {largeError}
                <h3 className='addRateDetailed-modal-notification-title'>{statusMessage}</h3>
                <div className='addRateDetailed-modal-error-buttons-container'>
                  <MediumButton text={'Попробовать снова'}
                    onClick={handleRetry}
                    backgroundColor={'--MyPrimary'}
                    color={'--MyWhite'}/>
                  <MediumButton
                    text={'Отмена'}
                    color={'--MyPrimary'}
                    backgroundColor={'--MySecondaryBlue'}
                    onClick={onClose}/>
                </div>
              </div>
            ) : (
              <div className='addRateDetailed-modal-notification-container'>
                {largeFinish}
                <h3 className='addRateDetailed-modal-notification-title'>{reset.charAt(0).toUpperCase() + reset.slice(1)} успешно выполнен</h3>
              </div>
            )}
          </div>
        )}
        {!statusMessage &&
        <form className='deleteDriver-modal-form' onSubmit={handleSubmit}>
          <div className='deleteDriver-modal-form-description'>
          <span>
            Вы уверены, что хотите <span style={{ fontWeight: '500' }}>сделать {reset} для станции {station}</span>?
          </span>
          </div>
          <div className='deleteDriver-modal-buttons'>
            <MediumButton text={accessOption} color={'--MyWhite'} type="submit" backgroundColor={'--MyPrimary'} />
            <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
          </div>
        </form>}
      </div>
    </div>
  );
};

export default ResetChargeStationModal;
