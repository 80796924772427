/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

const RequireAuth = ({ children }: any) => {
  const tokenValue = localStorage.getItem('token');
  if (!tokenValue || tokenValue === undefined || tokenValue === '') {
    return <Navigate to="/login" />;
  }
  return children;
};

export default observer(RequireAuth);
