/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './DeleteDriverModal.css';
// import { useParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { closeModal } from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import { DriversResponse } from '../../../models/response/CarResponse';
import CarService from '../../../services/CarService';
// import CarService from '../../../services/CarService';

interface DeleteDriversModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  title: string;
  description: string;
  driver: DriversResponse;
  accessOption: string;
}

function formatPhoneNumber(phoneNumber: string) {
  const cleaned = (`${phoneNumber}`).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
  }
  return phoneNumber;
}

const DeleteDriversModal: React.FC<DeleteDriversModalProps> = ({
  onClose, isModalOpen, title, description, driver, accessOption
}) => {
  const { carId } = useParams<{ carId: string }>();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (driver._id && carId) {
        await CarService.deleteDriver(carId, driver._id);
        onClose();
      }
    } catch (error) {
      // console.error('Ошибка при отправке запроса:', error);
    }
  };

  return (
    <div className={`deleteDriver-modal-background ${isModalOpen ? '' : 'deleteDriver-modal-hidden'}`}>
      <div className="deleteDriver-modal-content">
        <h1>{title}</h1>
        <div className="deleteDriver-modal-close" onClick={onClose}>{closeModal}</div>
        <form className='deleteDriver-modal-form' onSubmit={handleSubmit}>
          <div className='deleteDriver-modal-form-description'>
          <span>
            {description} {driver.lastName ? driver.lastName : 'водителя'} {driver.firstName ? driver.firstName : ''}
            {driver.phone ? ` ${formatPhoneNumber(driver.phone)}` : ' '}?
          </span>
          </div>
          <div className='deleteDriver-modal-buttons'>
            <MediumButton text={accessOption} color={'--MyWhite'} type="submit" backgroundColor={'--MyPrimary'} />
            <MediumButton text={'Отмена'} color={'--MyPrimary'}
            backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default DeleteDriversModal;
