/* eslint-disable max-len */
// eslint-disable-next-line import/no-extraneous-dependencies
import { AxiosResponse } from 'axios';
import { createAxiosInstance } from '../http/http';
import {
  AuthResponse, GroupData, GroupRow, UserResponse
} from '../models/response/AuthResponse';
import { API_AUTH } from '../http/MyApi';
import { FilterResponse } from '../models/response/CarResponse';

const $api = createAxiosInstance(API_AUTH);

export default class AuthService {
  static async login(phone: string, code: string): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>('/auth/sign-in', { phone, code });
  }

  static async registration(phone: string, capchaToken: string): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>('/auth/sign-up', { phone, capchaToken });
  }

  static async getUser(): Promise<AxiosResponse<UserResponse>> {
    return $api.get<UserResponse>('/user');
  }

  static async logout(): Promise<void> {
    return $api.get('/auth/logout');
  }

  // Groups

  static async getGroups(filters: object[], limit: number, offset: number):
  Promise<AxiosResponse<GroupData>> {
    return $api.post('/group/search', { filters, limit, offset });
  }

  static async getOneGroup(groupId: string): Promise<AxiosResponse> {
    return $api.get<GroupRow>(`/group/getOne/${groupId}`);
  }

  static async addGroup(name: string, description: string, active: boolean):
  Promise<AxiosResponse> {
    return $api.post('/group/create', {
      name, description, active, groupMembers: []
    });
  }

  static async updateGroup(groupId: string, name: string, description: string, active: boolean):
  Promise<AxiosResponse> {
    return $api.put(`/group/update/${groupId}`, {
      name, description, active
    });
  }

  static async deleteGroup(groupId: string): Promise<AxiosResponse> {
    return $api.delete(`/group/deleteOne/${groupId}`);
  }

  static async getGroupMembersFilters(groupId: string): Promise<AxiosResponse> {
    return $api.get<FilterResponse>(`/group/${groupId}/memberFilters`);
  }

  static async updateGroupsMembers(groupId: string, memberId: string, active: boolean, firstName: string, lastName: string):
  Promise<AxiosResponse<GroupData>> {
    return $api.post(`/group/updateUser/${groupId}`, {
      active, memberId, firstName, lastName
    });
  }

  static async addGroupsMembers(groupId: string, active: boolean, firstName: string, lastName: string, phone: string):
  Promise<AxiosResponse<GroupData>> {
    return $api.post(`/group/addUser/${groupId}`, {
      active, phone, firstName, lastName
    });
  }

  static async deleteGroupsMembers(groupId: string, memberId: string):
  Promise<AxiosResponse<GroupData>> {
    return $api.post(`/group/removeUser/${groupId}`, { memberId });
  }

  static async getGroupsMembers(groupId: string, filters: object[], limit: number, offset: number):
  Promise<AxiosResponse<GroupData>> {
    return $api.post(`/group/${groupId}/searchMembers`, { filters, limit, offset });
  }
}
