/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import './OrganizationsPageInfo.css';
import { useParams } from 'react-router-dom';
import LargeButtonWithIcon from '../../components/buttons/LargeButtonWithIcon';
import {
  bucket, bucket28, bucket32, edit, edit28, edit32, plus
} from '../../Icons/Icons';
import OrgService from '../../services/OrgService';
import { OrgInfo, OrgStaffUser } from '../../models/response/OrgResponse';
import MySwitch from '../../components/Switch/Switch';
import MediumButton from '../../components/buttons/MediumButton';
import LargeButtonIcon from '../../components/buttons/LargeButtonIcon';
import DeleteOrgModal from '../../components/Modal/DeleteOrgModal/DeleteOrgModal';
import UpdateOrgModal from '../../components/Modal/UpdateOrg/UpdateOrgModal';
import AddOrgStaffModal from '../../components/Modal/AddOrgStaff/AddOrgStaff';
import DeleteOrgStaffModal from '../../components/Modal/DeleteOrgStaff/DeleteOrgStaffModal';
import EditOrgStaffDropdownModal from '../../components/Modal/EditOrgStaff/EditOrgStaffDropdownModal';
import { Context } from '../..';

const OrganizationsPageInfo = () => {
  const { orgId } = useParams<{ orgId: string }>();
  const actualOrgId = orgId || '';
  const { store } = useContext(Context);

  const [loading, setLoading] = useState(false);
  const [orgInfo, setOrgInfo] = useState<OrgInfo | null>(null);
  const [isDeleteOrgModalOpen, setIsDeleteOrgModalOpen] = useState(false);
  const [isUpdateOrgModalOpen, setIsUpdateOrgModalOpen] = useState(false);
  const [isAddStaffOrgModalOpen, setIsAddStaffOrgModalOpen] = useState(false);
  const [isDeleteStaffOrgModalOpen, setIsDeleteStaffOrgModalOpen] = useState(false);
  const [isEditOrgStaffModalOpen, setIsEditOrgStaffModalOpen] = useState(false);

  const [selectedStaffUser, setSelectedStaffUser] = useState<OrgStaffUser | null>(null);

  const getOrgInfo = async () => {
    setLoading(true);
    try {
      const response = await OrgService.getOrgInfo(actualOrgId);
      if (response.status !== 401) {
        setOrgInfo(response.data);
      }
    } catch (error) {
      // console.error('Ошибка', error);
    } finally {
      setLoading(false);
    }
  };

  function formatPhoneNumber(phoneNumber: string) {
    const cleaned = (`${phoneNumber}`).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
    }
    return phoneNumber;
  }

  const getRoleName = (userGroupsIds: string | string[], roles: any[]) => {
    const role = roles.find((staffRole: { _id: any; }) => userGroupsIds.includes(staffRole._id));
    return role ? role.name_ru : 'Роль не определена';
  };

  // Модалки

  // Добавление сотрудника

  const handleAddStaffOrgModalOpen = () => {
    setIsAddStaffOrgModalOpen(true);
  };

  const handleAddStaffOrgModalClose = () => {
    setIsAddStaffOrgModalOpen(false);
  };

  // Удаление сотрудника

  const handleDeleteStaffOrgModalOpen = (staff: OrgStaffUser) => {
    setSelectedStaffUser(staff);
    setIsDeleteStaffOrgModalOpen(true);
  };

  const handleDeleteStaffOrgModalClose = () => {
    setSelectedStaffUser(null);
    setIsDeleteStaffOrgModalOpen(false);
  };

  // Редактирование сотрудника

  const handleEditOrgStaffModalOpen = (staff: OrgStaffUser) => {
    setSelectedStaffUser(staff);
    setIsEditOrgStaffModalOpen(true);
  };

  const handleEditOrgStaffModalClose = () => {
    setSelectedStaffUser(null);
    setIsEditOrgStaffModalOpen(false);
  };

  // Удаление орг

  const handleDeleteOrgModalOpen = () => {
    setIsDeleteOrgModalOpen(true);
  };

  const handleDeleteOrgModalClose = () => {
    setIsDeleteOrgModalOpen(false);
  };

  // Редактирование орг

  const handleUpdateOrgModalOpen = () => {
    setIsUpdateOrgModalOpen(true);
  };

  const handleUpdateOrgModalClose = () => {
    setIsUpdateOrgModalOpen(false);
  };

  useEffect(() => {
    getOrgInfo();
  }, [isUpdateOrgModalOpen, isEditOrgStaffModalOpen, isDeleteStaffOrgModalOpen, isAddStaffOrgModalOpen]);

  return (
    <>
      <div className="orgsPageInfo-topLine">
        <div className='orgsPageInfo-topLine-info'>
          <div className='orgsPageInfo-topLine-title'>
            <h2>{orgInfo?.name ? orgInfo?.name : 'Без названия'}</h2>
          </div>
          <div className='orgsPageInfo-topLine-extra'>
            <div className='orgsPageInfo-topLine-inn-container'>
              <span className='orgsPageInfo-topLine-extra-title'>ИНН</span>
              <span className='orgsPageInfo-topLine-extra-value'>{orgInfo?.inn || '-'}</span>
            </div>
          </div>
        </div>
        <div className='orgsPageInfo-topLine-buttons-group'>
        {store.user.buttons?.editOrg && <LargeButtonWithIcon
            text={''}
            backgroundColor={'--MySecondaryBlue'}
            LeftIcon={edit28}
            onClick={handleUpdateOrgModalOpen}
            color={'--MyWhite'}
            height='36px'
            width='44px'/>}
          {isUpdateOrgModalOpen &&
            <UpdateOrgModal
              onClose={handleUpdateOrgModalClose}
              isModalOpen={isUpdateOrgModalOpen}
              field1='Название'
              field2='ИНН'
              field1Value={orgInfo?.name}
              field2Value={orgInfo?.inn}
            />
          }
          {store.user.buttons?.editOrg && <LargeButtonWithIcon
            text={''}
            backgroundColor={'--MySecondaryBlue'}
            LeftIcon={bucket28}
            onClick={handleDeleteOrgModalOpen}
            color={'--MyWhite'}
            height='36px'
            width='44px'/>}
          {isDeleteOrgModalOpen &&
            <DeleteOrgModal
              onClose={handleDeleteOrgModalClose}
              isModalOpen={isDeleteOrgModalOpen}
              title={'Удаление организации'}
              description={'Вы уверены, что хотите удалить организацию'}
              org={orgInfo?.name}
              accessOption={'Да, удалить'}
              successDeleteMessage={'Организация удалена'}
            />
          }
        </div>
      </div>
      {/* <div className='orgsPageInfo-staff-filters'>
        <div>
          <span>Доступ к зарядным станциям</span>
          <MySwitch />
        </div>
        <div>
          <span>Доступ к автомобилям</span>
          <MySwitch />
        </div>
      </div> */}
      <div className='orgsPageInfo-staff-list-container'>
        <div className='orgsPageInfo-staff-list-topLine'>
          <div className='orgsPageInfo-staff-list-title'>
            <h2>Сотрудники</h2>
            <h3>{(orgInfo?.staff.length && orgInfo?.staff.length >= 0) ? `(${orgInfo?.staff.length})` : '(0)'}</h3>
          </div>
          {store.user.buttons?.editUsersOrg && <LargeButtonWithIcon
            text={''}
            LeftIcon={plus}
            width='32px'
            height='32px'
            onClick={handleAddStaffOrgModalOpen}
            color={'--MyWhite'}
            backgroundColor={'--MyPrimary'}/>}
          {isAddStaffOrgModalOpen &&
            <AddOrgStaffModal
              onClose={handleAddStaffOrgModalClose}
              isModalOpen={isAddStaffOrgModalOpen}
          />}
        </div>
        <div className='orgsPageInfo-staff-list'>
          {(orgInfo?.staff.length && orgInfo?.staff.length > 0) ? orgInfo?.staff.map((staffUser, index) => (
            <React.Fragment key={index}>
              <div className='orgsPageInfo-staff'>
                <div className='orgsPageInfo-staff-name-container'>
                    <div className='orgsPageInfo-staff-container'>
                      <span className='orgsPageInfo-staff-name'>
                        {staffUser.lastName || staffUser.firstName
                          ? `${staffUser.lastName || ''} ${staffUser.firstName || ''}`
                          : 'Сотрудник'
                        }
                      </span>
                      <span className='orgsPageInfo-staff-job'>
                        {getRoleName(staffUser.userGroupsIds, orgInfo.roles)}
                      </span>
                    </div>
                    <span className='orgsPageInfo-staff-number'>
                      {staffUser.phone ? formatPhoneNumber(staffUser.phone) : 'Нет номера телефона'}
                    </span>
                 </div>
                  {store.user.buttons?.editUsersOrg && <div className='orgsPageInfo-staff-options'>
                    <span onClick={() => handleEditOrgStaffModalOpen(staffUser)}>{edit32}</span>
                    <span onClick={() => handleDeleteStaffOrgModalOpen(staffUser)}>{bucket32}</span>
                  </div>}
                  {isEditOrgStaffModalOpen &&
                    <EditOrgStaffDropdownModal
                      staffId={selectedStaffUser?.userId || ''}
                      onClose={handleEditOrgStaffModalClose}
                      isModalOpen={isEditOrgStaffModalOpen}
                      field1={'Фамилия'}
                      field2={'Имя'}
                      field3={'Отчество'}
                      field4={'Роль'}
                      field1Value={selectedStaffUser?.lastName}
                      field2Value={selectedStaffUser?.firstName}
                      field3Value={undefined}
                      field4Value={getRoleName(selectedStaffUser?.userGroupsIds || [], orgInfo.roles)}
                    />
                    }
                  {isDeleteStaffOrgModalOpen &&
                    <DeleteOrgStaffModal
                      onClose={handleDeleteStaffOrgModalClose}
                      isModalOpen={isDeleteStaffOrgModalOpen}
                      title={'Удаление сотрудника'}
                      description={'Вы уверены, что хотите удалить'}
                      staffId={selectedStaffUser}
                      accessOption={'Да, удалить'}
                    />}
                </div>
                {index !== orgInfo.staff.length - 1 && <div className='orgsPageInfo-staff-separator'></div>}
            </React.Fragment>
          )) :
            <div className='orgsPageInfo-staff-empty'>Нет сотрудников</div>
          }
        </div>
      </div>
    </>
  );
};

export default OrganizationsPageInfo;
