/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import './ChargingGroupMembersPage.css';
import { useNavigate, useParams } from 'react-router-dom';
import { debounce, isEqual } from 'lodash';
import parsePhoneNumber from 'libphonenumber-js';
import LargeButtonWithIcon from '../../components/buttons/LargeButtonWithIcon';
import { plus } from '../../Icons/Icons';
import MyTable from '../../components/Table/MyTable';
import { Context } from '../..';
import { GroupMember, GroupRow } from '../../models/response/AuthResponse';
import { FilterResponse } from '../../models/response/CarResponse';
import Select from '../../components/Select/Select';
import AuthService from '../../services/AuthService';
import DeleteGroupMemberModal from '../../components/Modal/DeleteGroupMember/DeleteGroupMemberModal';
import AddChargeGroupMemberModal from '../../components/Modal/AddChargeGroupMember/AddChargeGroupMemberModal';
import EditChargeGroupMemberModal from '../../components/Modal/EditChargeGroupMember/EditChargeGroupMemberModal';

interface MembersGroupData {
  headers: any[];
  rows: any[];
  total?: number
}

const ChargingGroupMembersPage = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);
  const { groupId } = useParams<{ groupId: string }>();
  const actualGroupId = groupId || '';
  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: string[] }>({});
  const [textValues, setTextValues] = useState<{ [key: string]: string }>({});
  const [booleanValues, setBooleanValues] = useState<{ [key: string]: boolean | undefined}>({});
  const [prevSelectedDates, setPrevSelectedDates] = useState<any[]>([]);
  // const [lastFilteredTime, setLastFilteredTime] = useState(0);


  const [groupData, setGroupData] = useState<GroupRow | null>(null);
  const [groupMembers, setGroupMembers] = useState<MembersGroupData | null>(null);
  const [filteredGroupMembers, setFilteredGroupMembers] = useState<MembersGroupData | null>(null);
  const [groupMembersFiltersResponseData, setGroupMembersFiltersResponseData] = useState<FilterResponse>([]);
  const [loading, setLoading] = useState(false);
  const [prevFilters, setPrevFilters] = useState<any[]>([]);

  const [offset, setOffset] = useState(0);
  const [filteredOffset, setFilteredOffset] = useState(0);

  const handleTextChange = debounce((text: string, key: string) => {
    setTextValues((prevTextValues) => ({
      ...prevTextValues,
      [key]: text,
    }));
  }, 1500);

  const handleOptionSelect = debounce((selected: string[], filterKey: string) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [filterKey]: selected,
    }));
  }, 500);

  const handleBooleanChange = debounce((value: boolean, key: string) => {
    if (booleanValues[key] !== value) {
      setBooleanValues((prevBooleanValues) => ({
        ...prevBooleanValues,
        [key]: value,
      }));
    }
  }, 500);

  const handleClearClick = debounce((filterKey: string) => {
    setTextValues((prevValues) => ({ ...prevValues, [filterKey]: '' }));
  }, 1500);

  const handleBooleanClear = debounce((filterKey: string) => {
    setLoading(true);
    if (booleanValues[filterKey] !== undefined) {
      setBooleanValues((prevValues) => ({
        ...prevValues,
        [filterKey]: undefined,
      }));
    }
    setLoading(false);
  }, 500);

  // Модалки

  const [selectedRowData, setSelectedRowData] = useState<GroupMember | null>(null);
  const [isAddGroupMemberModalOpen, setIsAddGroupMemberModalOpen] = useState(false);
  const [isEditGroupMemberModalOpen, setIsEditGroupMemberModalOpen] = useState(false);
  const [isDeleteGroupMemberModalOpen, setIsDeleteGroupMemberModalOpen] = useState(false);

  // Добавление участника

  const handleAddRateModalOpen = () => {
    setIsAddGroupMemberModalOpen(true);
  };

  const handleAddRateModalClose = () => {
    setIsAddGroupMemberModalOpen(false);
  };

  // Редактирование участника

  const handleEditRateModalOpen = () => {
    setIsEditGroupMemberModalOpen(true);
  };

  const handleEditRateModalClose = () => {
    setIsEditGroupMemberModalOpen(false);
  };

  // Удаление участника

  const handleDeleteRateModalOpen = () => {
    setIsDeleteGroupMemberModalOpen(true);
  };

  const handleDeleteRateModalClose = () => {
    setIsDeleteGroupMemberModalOpen(false);
  };

  // Фильтры

  const fetchFilters = async () => {
    setLoading(true);
    try {
      const response = await AuthService.getGroupMembersFilters(actualGroupId);
      if (response.status !== 401) {
        setGroupMembersFiltersResponseData(response.data);
        // console.log(response.data);
      }
    } catch (error) {
      // console.error('Ошибка при загрузке фильтров:', error);
    } finally {
      setLoading(false);
    }
  };

  const filteredData = async () => {
    setLoading(true);
    try {
      const newFilters: any = [];
      const uniqueFilters = new Set();

      for (const filter of groupMembersFiltersResponseData) {
        if (
          (filter.type === 'MULTISELECT' && selectedOptions[filter.key]?.length > 0) ||
          (filter.type === 'TEXT' && textValues[filter.key]) ||
          (filter.type === 'BOOLEAN' && booleanValues[filter.key] !== undefined)
        ) {
          const filterKey = filter.key;

          if (!uniqueFilters.has(filterKey)) {
            uniqueFilters.add(filterKey);

            let values;
            if (filter.type === 'MULTISELECT') {
              values = selectedOptions[filter.key];
            } else if (filter.type === 'TEXT') {
              const phoneNumber = textValues[filter.key].startsWith('8') ? textValues[filter.key].replace(/^8/, '+7') : textValues[filter.key];
              const parsedNumber = parsePhoneNumber(phoneNumber)?.number.replace('+', '');
              values = parsedNumber ? [parsedNumber] : [phoneNumber];
            } else if (filter.type === 'BOOLEAN') {
              values = [booleanValues[filter.key]];
            }

            newFilters.push({
              key: filter.key,
              values,
            });
          }
        }
      }

      store.setCarFilters(newFilters);
      const filtersChanged = !isEqual(prevFilters, newFilters);

      if (filtersChanged) {
        setFilteredGroupMembers(null);
        setFilteredOffset(0);
      }

      if (newFilters.length > 0) {
        const responseFilter = await AuthService.getGroupsMembers(actualGroupId, newFilters, 50, filtersChanged ? 0 : filteredOffset);
        if (responseFilter.status !== 401) {
          setOffset(filtersChanged ? 0 : offset);
          setGroupMembers(null);
          setFilteredGroupMembers((prevData) => ({
            headers: responseFilter?.data.headers,
            rows: [...(filtersChanged ? [] : prevData?.rows || []), ...responseFilter.data.rows],
            total: responseFilter?.data.total,
          }));
          setFilteredOffset((prev) => prev + 50);
        }
      } else {
        const response = await AuthService.getGroupsMembers(actualGroupId, [], 50, offset);
        if (response.status !== 401) {
          setFilteredOffset(0);
          setFilteredGroupMembers(null);
          setGroupMembers((prevData) => ({
            headers: response?.data.headers,
            rows: [...(prevData?.rows || []), ...response.data.rows],
            total: response?.data.total,
          }));
          setOffset((prev) => prev + 50);
        }
      }
    } catch (error) {
      // console.error('Ошибка при отправке запроса:', error);
    } finally {
      setLoading(false);
    }
  };

  // Инфа о участнике

  const getGroupInfo = async () => {
    setLoading(true);
    try {
      const response = await AuthService.getOneGroup(actualGroupId);
      if (response.status !== 401) {
        setGroupData(response.data);
      }
    } catch (error) {
      // console.error('Ошибка при загрузке фильтров:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPrevFilters(store.carFilters);
  }, [store.carFilters]);

  useEffect(() => {
    getGroupInfo();
    fetchFilters();
    filteredData();
  }, []);

  const handleReset = () => {
    store.setShouldWipeFilters(true);
    setTextValues({});
    setSelectedOptions({});
    setBooleanValues({});
  };

  const debounceFilteredData = debounce(filteredData, 500);

  const areAllSelectedOptionsEmpty = Object.values(selectedOptions).every((array) => array.length === 0);
  const isAnyTextValueNotEmpty = Object.values(textValues).some((value) => value.trim() !== '');
  const isAnyBooleanValueNotEmpty = Object.values(booleanValues).some((value) => value !== undefined && value !== null);

  const shouldShowResetFilters =
  !areAllSelectedOptionsEmpty ||
  isAnyTextValueNotEmpty ||
  isAnyBooleanValueNotEmpty;

  useEffect(() => {
    if (
      (Object.keys(selectedOptions).length > 0 && areAllSelectedOptionsEmpty) ||
      Object.keys(selectedOptions).length > 0 ||
      (Object.keys(textValues).length > 0 && isAnyTextValueNotEmpty) ||
      Object.keys(textValues).length > 0 ||
      (isAnyBooleanValueNotEmpty && Object.keys(booleanValues).length > 0) ||
      Object.keys(booleanValues).length > 0
    ) {
      filteredData();
      store.setShouldWipeFilters(false);
    }
  }, [selectedOptions, textValues, booleanValues]);

  return (
    <div className='groupMembers-page-info'>
      <div className='groupMembers-page-info-header'>
        <h2 className='groupMembers-page-info-title'>Участники группы {groupData?.name}</h2>
        <LargeButtonWithIcon
          height='36px'
          text={'Добавить участника'}
          color={'--MyWhite'}
          LeftIcon={plus}
          iconMarginRight='8px'
          backgroundColor={'--MyPrimary'}
          padding='8px 12px'
          onClick={handleAddRateModalOpen}
        />
      </div>
      {isAddGroupMemberModalOpen &&
          <AddChargeGroupMemberModal
            onClose={handleAddRateModalClose}
            isModalOpen={isAddGroupMemberModalOpen}
            groupId={actualGroupId}
          />
        }
      <div style={{ marginBottom: '30px' }}></div>
      <div style={{ display: (groupMembers?.total !== 0 || filteredGroupMembers?.rows) ? '' : 'none' }} className="carsPage-filters-buttons">
        {groupMembersFiltersResponseData && groupMembersFiltersResponseData.length > 0 && (
          <>
            {groupMembersFiltersResponseData.map((filter, index) => (
              <div key={index}>
                {filter.type === 'MULTISELECT' && (
                  <Select
                    disabled={loading}
                    label={filter.name || filter.key}
                    options={filter.options}
                    selectedOptions={filter.key === 'STATION' && store.stationName ? [store.stationName] : []}
                    onOptionSelect={(selected) => handleOptionSelect(selected, filter.key)}
                    type={filter.type}
                    filterKey={filter.key} />
                )}
                {filter.type === 'TEXT' && (
                  <Select
                    disabled={loading}
                    label={filter.name || filter.key}
                    options={[]}
                    selectedOptions={[]}
                    onClearClick={() => handleClearClick(filter.key)}
                    onOptionSelect={(value: string) => handleTextChange(value, filter.key)}
                    type={filter.type}
                    filterKey={filter.key} />
                )}
                {filter.type === 'BOOLEAN' && (
                  <Select
                    disabled={loading}
                    label={filter.name || filter.key}
                    options={[]}
                    onClearClick={() => handleBooleanClear(filter.key)}
                    onOptionSelect={(value: boolean) => handleBooleanChange(value, filter.key)}
                    type={filter.type}
                    filterKey={filter.key} />
                )}
              </div>
            ))}
          </>
        )}
        {shouldShowResetFilters && (
          <LargeButtonWithIcon
            onClick={handleReset}
            text={'Сбросить фильтры'}
            color={'--MyPrimary'}
            backgroundColor={'--MySecondaryBlue'}
            padding='7px 12px'
          />
        )}
      </div>
      <div style={{ marginBottom: '20px' }}></div>
      <div className='groupMembers-page-info-container'>
        {groupMembers && (
          <div>
            <MyTable
              hasMore={groupMembers.total !== undefined && groupMembers.total > groupMembers.rows.length}
              fetchMore={debounceFilteredData}
              headers={groupMembers.headers}
              data={groupMembers.rows}
              loading={loading}
              onRowClick={(rowData, event) => {
                const target = event?.target as HTMLElement;
                const closestEditButton = target.closest('[data-id="edit-button"]');
                const closestBucketButton = target.closest('[data-id="bucket-button"]');
                if (closestEditButton) {
                  setSelectedRowData(rowData);
                  handleEditRateModalOpen();
                } else if (closestBucketButton) {
                  setSelectedRowData(rowData);
                  handleDeleteRateModalOpen();
                }
              }}
            />
              {isEditGroupMemberModalOpen && selectedRowData && (
                <EditChargeGroupMemberModal
                onClose={handleEditRateModalClose}
                isModalOpen={isEditGroupMemberModalOpen}
                groupId={actualGroupId}
                name={selectedRowData.firstName}
                active={selectedRowData.active}
                lastName={selectedRowData.lastName}
                userId={selectedRowData.userId}/>
              )}
              {isDeleteGroupMemberModalOpen && selectedRowData && (
                <DeleteGroupMemberModal
                  onClose={handleDeleteRateModalClose}
                  isModalOpen={isDeleteGroupMemberModalOpen}
                  title={'Удаление участника группы'}
                  description={'Вы уверены, что хотите удалить участника группы '}
                  accessOption={'Да'}
                  groupId={actualGroupId}
                  groupMember={selectedRowData}
                />
              )}
            <div id="cars-table-bottom-id" style={{ height: '10px' }}></div>
          </div>)}
        {filteredGroupMembers && (
          <div>
            <MyTable
              hasMore={filteredGroupMembers.total !== undefined
                && filteredGroupMembers.total > filteredGroupMembers.rows.length}
              fetchMore={debounceFilteredData}
              headers={filteredGroupMembers.headers}
              data={filteredGroupMembers.rows}
              loading={loading}
              // onRowClick={(rowData) => navigate(`/groups/${rowData._id}`)}
            />
            <div id="cars-table-bottom-id" style={{ height: '10px' }}></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChargingGroupMembersPage;
