/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './EditCarOptionsDropdownModal.css';
import { arrowHeader, clearInput, closeModal } from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';

interface EditCarOptionsDropdownModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  field: string;
  fieldValue: any;
  labelTitle: string;
  onUpdateField?: (field: string, value: string) => void;
}

const EditCarOptionsDropdownModal: React.FC<EditCarOptionsDropdownModalProps> = ({
  onClose, isModalOpen, field, fieldValue, onUpdateField, labelTitle
}) => {
  const [fieldValueState, setFieldValueState] = useState((fieldValue || ''));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (field && fieldValueState !== fieldValue) {
        await onUpdateField?.(field, fieldValueState);
        onClose();
      }
    } catch (error) {
      // console.error('Ошибка при отправке PUT-запроса:', error);
    }
  };

  const handleDropdownInputClick = () => {
    setIsDropdownOpen((prevIsDropdownOpen) => !prevIsDropdownOpen);
  };

  useEffect(() => {
    const isAllFieldsFilled = fieldValueState.trim() !== '';

    setIsFormValid(isAllFieldsFilled);
  }, [fieldValueState]);

  const handleClearInput = () => {
    setFieldValueState('');
  };

  useEffect(() => {
    const isAllFieldsFilled = fieldValueState.trim() !== '';

    setIsFormValid(isAllFieldsFilled);
  }, [fieldValueState]);

  return (
    <div className={`editCarOptions-modal-background ${isModalOpen ? '' : 'editCarOptions-modal-hidden'}`}>
      <div className="editCarOptions-modal-content">
        <h1>Изменить {field}</h1>
        <div className="editCarOptions-modal-close" onClick={onClose}>{closeModal}</div>
        <form className='editCarOptions-modal-form' onSubmit={handleSubmit}>
          <div className='editCarOptions-modal-form-inputs'>
            <label className='editCarOptions-modal-label'>
                <span className='editCarOptions-modal-label-title'>{labelTitle}</span>
                <input
                    readOnly
                    placeholder='Не выбрано'
                    type="text"
                    value={fieldValueState || ''}
                    onChange={(e) => setFieldValueState(e.target.value)}
                    onClick={handleDropdownInputClick}
                />
                {isDropdownOpen && (
                    <div className="editCarOptions-modal-dropdown"
                      style={{ display: isDropdownOpen ? 'block' : 'none' }}>
                    <div onClick={() => setFieldValueState('Да')}>Да</div>
                    <div onClick={() => setFieldValueState('Нет')}>Нет</div>
                    </div>
                )}
                {!fieldValueState ? (
                    <div className='editCarOptions-modal-label-arrow-down'>
                    {arrowHeader}
                    </div>
                ) : (
                    <div className='editCarOptions-modal-label-clear' onClick={handleClearInput}>
                    {clearInput}
                    </div>
                )}
            </label>
          </div>
          <div className='editCarOptions-modal-buttons'>
            <MediumButton disabled={!isFormValid} text={'Сохранить изменения'} color={'--MyWhite'}
            type="submit" backgroundColor={'--MyPrimary'} />
            <MediumButton text={'Отмена'} color={'--MyPrimary'}
            backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditCarOptionsDropdownModal;

