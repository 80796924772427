/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './DeleteOrgModal.css';
// import { useParams } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { closeModal, largeFinish } from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import OrgService from '../../../services/OrgService';
// import CarService from '../../../services/CarService';

interface DeleteOrgModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  title: string;
  description: string;
  org: string | undefined;
  accessOption: string;
  successDeleteMessage: string;
}

const DeleteOrgModal: React.FC<DeleteOrgModalProps> = ({
  onClose, isModalOpen, title, description, org, accessOption, successDeleteMessage
}) => {
  const { orgId } = useParams<{ orgId: string }>();
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (orgId) {
        const response = await OrgService.deleteOrg(orgId);
        if (response && (response.status === 201 || response.status === 200)) {
          setShowSuccessMessage(true);
          setTimeout(() => {
            navigate('/organizations');
          }, 500);
        }
      }
    } catch (error) {
      // console.error('Ошибка при отправке запроса:', error);
    }
  };

  return (
    <div className={`deleteDriver-modal-background ${isModalOpen ? '' : 'deleteDriver-modal-hidden'}`}>
      <div className="deleteDriver-modal-content">
        <h1>{title}</h1>
        <div className="deleteDriver-modal-close" onClick={onClose}>{closeModal}</div>
        {showSuccessMessage && (
            <div className='transferCar-modal-notification-container'>
            {largeFinish}
            <h3 className='transferCar-modal-notification-title'>{successDeleteMessage}</h3>
          </div>
        )}
        {!showSuccessMessage && <form className='deleteDriver-modal-form' onSubmit={handleSubmit}>
          <div className='deleteDriver-modal-form-description'>
          <span>
            {description} <span style={{ fontWeight: '500' }}>{org}</span>?
          </span>
          </div>
          <div className='deleteDriver-modal-buttons'>
            <MediumButton text={accessOption} color={'--MyWhite'} type="submit" backgroundColor={'--MyPrimary'} />
            <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'MySecondaryBlue'} onClick={onClose} />
          </div>
        </form>}
      </div>
    </div>
  );
};

export default DeleteOrgModal;
