/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable global-require */
import React, { useEffect, useState } from 'react';
import './LoginPage.css';
import { Navigate, useLocation } from 'react-router-dom';
import LoginForm from '../../components/LoginForm/LoginForm';

const LoginPage = () => {
  const location = useLocation();
  const EvoPhoto = require('../../Icons/images/LoginPageBackground/EvoPhoto.png');
  const DFPhoto = require('../../Icons/images/LoginPageBackground/DFPhoto.png');

  const [photo, setPhoto] = useState('');

  if (localStorage.getItem('token')) {
    return <Navigate to="/fleet/car" state={{ from: location }} />;
  }

  useEffect(() => {
    const domain = window.location.hostname;

    if (domain.includes('dfm-assist')) {
      setPhoto(`url(${DFPhoto})`);
    } else {
      setPhoto(`url(${EvoPhoto})`);
    }
  }, []);

  return (
    <div className='login-page' style={{ backgroundImage: photo }}>
      <LoginForm />
    </div>
  );
};

export default LoginPage;
