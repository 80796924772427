import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';

interface CountdownTimerProps {
  onResendClick: () => void;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ onResendClick }) => {
  const [seconds, setSeconds] = useState(30);
  const [resendText, setResendText] = useState('Отправить снова');

  const handleResendClick = () => {
    if (seconds <= 0) {
      setTimeout(() => {
        onResendClick();
        setResendText('Отправить снова');
        setSeconds(30);
      }, 2000);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [seconds]);

  return (
    <span className='login-code-waiter'>
      {seconds > 0
        ? `Не получили код? Повторная отправка через ${seconds} сек.`
        : (
          <>
          Не получили код?
          <span
            style={{ color: 'var(--MyPrimary)', cursor: 'pointer', marginLeft: '4px' }}
            onClick={handleResendClick}
          >
            {resendText}
          </span>
          </>
        )}
    </span>
  );
};

export default observer(CountdownTimer);
