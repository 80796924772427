/* eslint-disable prefer-destructuring */
const envApiUrl = process.env.REACT_APP_API_URL;
let apiUrl: string;

if (envApiUrl) {
  apiUrl = envApiUrl;
  // console.log('Get API url from ENV');
} else {
  // @ts-ignore
  apiUrl = (window.location.href || 'https://dev.evyang.com').match(/^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/igm)[0];
  // console.log('Get API url from Window');
}

export const API_AUTH = `${apiUrl}/id-service`;
export const API_CAR = `${apiUrl}/car-service/`;
export const API_STATIONS = `${apiUrl}/charge-service`;
