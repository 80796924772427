/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import './UpdateOrgModal.css';
import { useParams } from 'react-router-dom';
import { clearInput, closeModal } from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import OrgService from '../../../services/OrgService';

interface UpdateOrgModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  field1: string;
  field2: string;
  field1Value: string | undefined;
  field2Value: string | undefined;
}

const UpdateOrgModal: React.FC<UpdateOrgModalProps> = ({
  onClose, isModalOpen, field1, field2, field1Value, field2Value
}) => {
  const { orgId } = useParams<{ orgId: string }>();
  const actualOrgId = orgId || '';
  const [field1ValueState, setField1ValueState] = useState((field1Value || ''));
  const [field2ValueState, setField2ValueState] = useState((field2Value || ''));
  const [isFormChanged, setIsFormChanged] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (field1ValueState !== field1Value && field2ValueState === field2Value) {
        await OrgService.updateOrg(actualOrgId, field1ValueState);
      } else if (field1ValueState === field1Value && field2ValueState !== field2Value) {
        await OrgService.updateOrg(actualOrgId, undefined, field2ValueState);
      } else if (field1ValueState !== field1Value && field2ValueState !== field2Value) {
        await OrgService.updateOrg(actualOrgId, field1ValueState, field2ValueState);
      }
      onClose();
    } catch (error) {
      // console.error('Ошибка при отправке PUT-запроса:', error);
    }
  };

  const handleField1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    setField1ValueState(e.target.value);
    setIsFormChanged(true);
  };

  const handleField2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    setField2ValueState(e.target.value);
    setIsFormChanged(true);
  };

  const handleClearInput1 = () => {
    setField1ValueState('');
  };

  const handleClearInput2 = () => {
    setField2ValueState('');
  };

  return (
    <div className={`editCarOptions-modal-background ${isModalOpen ? '' : 'editCarOptions-modal-hidden'}`}>
      <div className="editCarOptions-modal-content">
        <h1>Редактировать организацию</h1>
        <div className="editCarOptions-modal-close" onClick={onClose}>{closeModal}</div>
        <form className='editCarOptions-modal-form' onSubmit={handleSubmit}>
          <div className='editCarOptions-modal-form-inputs'>
            <label className='editCarOptions-modal-label'>
              <span className='editCarOptions-modal-label-title'>{field1}</span>
              <input
                placeholder='Не указано'
                type="text"
                value={field1ValueState || ''}
                onChange={handleField1Change}
              />
              {field1ValueState && (
                <div className='editCarOptions-modal-label-clear' onClick={handleClearInput1}>
                  {clearInput}
                </div>
              )}
            </label>
            <label className='editCarOptions-modal-label'>
              <span className='editCarOptions-modal-label-title'>{field2}</span>
              <input
                placeholder='Не указано'
                type="text"
                value={field2ValueState || ''}
                onChange={handleField2Change}
              />
              {field2ValueState && (
                <div className='editCarOptions-modal-label-clear' onClick={handleClearInput2}>
                  {clearInput}
                </div>
              )}
            </label>
          </div>
          <div className='editCarOptions-modal-buttons'>
            <MediumButton
              text={'Сохранить изменения'}
              color={'--MyWhite'}
              type="submit"
              disabled={!isFormChanged}
              backgroundColor={'--MyPrimary'}/>
            <MediumButton text={'Отмена'} color='#4495D1' backgroundColor='#F8F9FF' onClick={onClose} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateOrgModal;

