import React, { useContext, useEffect, useState } from 'react';
import { Switch } from 'antd';
import './Switch.css';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

interface MySwitchProps {
  checked?: boolean;
  disabled?: boolean;
  loading?: boolean
  onChange?: (checked: boolean, event: React.MouseEvent<HTMLButtonElement>) => void;
}

const MySwitch: React.FC<MySwitchProps> = ({
  checked,
  disabled,
  onChange
}) => {
  const { store } = useContext(Context);
  const [internalLoading, setInternalLoading] = useState<boolean>(false);
  const [internalChecked, setInternalChecked] = useState<boolean>(checked || false);

  const handleChange = (isChecked: boolean, event: React.MouseEvent<HTMLButtonElement>) => {
    setInternalChecked(isChecked);
    setInternalLoading(true);
    try {
      if (onChange) {
        onChange(isChecked, event);
      }
    } finally {
      setInternalLoading(false);
    }
  };

  useEffect(() => {
    if (store.shouldWipeFilters) {
      setInternalChecked(false);
    }
  }, [store.shouldWipeFilters]);

  return (
    <div>
      {internalLoading ? (
        <Switch
          disabled
          checked={internalChecked}
        />
      ) : (
        <Switch
          checked={internalChecked}
          disabled={disabled}
          onChange={handleChange}
        />
      )}
    </div>
  );
};

export default observer(MySwitch);
