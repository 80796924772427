import React, { ReactNode } from 'react';

interface MediumCircleButtonProps {
  text: string | ReactNode;
  backgroundColor: string;
  color: string;

  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  border?: string;
  width?: string;
  height?: string;
  fontSize?: string;
  borderRadius?: string;
  onClick?: () => void;
  disabled?: boolean;
  display?: string;
  alignItems?: string;
  textAlign?: string;
  style?: React.CSSProperties;
}

const MediumCircleButton: React.FC<MediumCircleButtonProps> = ({
  width,
  className,
  height = '38px',
  fontSize = '15px',
  borderRadius = '100px',
  backgroundColor,
  color,
  display = 'flex',
  border = 'none',
  text,
  onClick,
  type,
  disabled,
  style
}) => {
  const buttonStyle: React.CSSProperties = {
    padding: '11px 16px',
    border,
    width,
    height,
    fontSize,
    borderRadius,
    backgroundColor: `var(${backgroundColor})`,
    color: `var(${color})`,
    display,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    opacity: disabled ? 0.6 : 1,
    pointerEvents: disabled ? 'none' : 'auto',
    ...style
  };

  return (
    <button type={type} style={buttonStyle} onClick={onClick} disabled={disabled} className={className} >
      {text}
    </button>
  );
};

export default MediumCircleButton;
