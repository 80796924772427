import React, { useState } from 'react';
import './EditCarOptionsModal.css';
// import { useParams } from 'react-router-dom';
import { clearInput, closeModal } from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
// import CarService from '../../../services/CarService';

interface EditCarOptionsModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  field: string;
  fieldValue: string;
  onUpdateField?: (field: string, value: string) => void;
}

const EditCarOptionsModal: React.FC<EditCarOptionsModalProps> = ({
  onClose, isModalOpen, field, fieldValue, onUpdateField
}) => {
  const [fieldValueState, setFieldValueState] = useState((fieldValue || ''));

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (field && fieldValueState !== fieldValue) {
        onUpdateField?.(field, fieldValueState);
        onClose();
      }
    } catch (error) {
      // console.error('Ошибка при отправке PUT-запроса:', error);
    }
  };

  const handleClearInput = () => {
    setFieldValueState('');
  };

  return (
    <div className={`editCarOptions-modal-background ${isModalOpen ? '' : 'editCarOptions-modal-hidden'}`}>
      <div className="editCarOptions-modal-content">
        <h1>Изменить {field}</h1>
        <div className="editCarOptions-modal-close" onClick={onClose}>{closeModal}</div>
        <form className='editCarOptions-modal-form' onSubmit={handleSubmit}>
          <div className='editCarOptions-modal-form-inputs'>
            <label className='editCarOptions-modal-label'>
              <span className='editCarOptions-modal-label-title'>{field}:</span>
              <input
                placeholder='Не указано'
                type="text"
                value={fieldValueState || ''}
                onChange={(e) => setFieldValueState(e.target.value)}
              />
              {fieldValueState && (
                <div className='editCarOptions-modal-label-clear' onClick={handleClearInput}>
                  {clearInput}
                </div>
              )}
            </label>
          </div>
          <div className='editCarOptions-modal-buttons'>
            <MediumButton text={'Сохранить изменения'}
            color={'--MyWhite'} type="submit" backgroundColor={'--MyPrimary'} />
            <MediumButton text={'Отмена'} color={'--MyPrimary'}
            backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditCarOptionsModal;

