import React from 'react';
import './Spinner.css';

interface SpinnerProps {
  borderTop?: string;
  border?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ borderTop, border }) => {
  const loaderStyle = {
    border,
    borderTop,
  };

  return <div className="loader" style={loaderStyle} />;
};

export default Spinner;
