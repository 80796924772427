/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import './ChargeGroupPage.css';
import { useNavigate, useParams } from 'react-router-dom';
import { debounce, isEqual } from 'lodash';
import LargeButtonWithIcon from '../../components/buttons/LargeButtonWithIcon';
import { plus } from '../../Icons/Icons';
import AddChargeGroupModal from '../../components/Modal/AddChargeGroup/AddChargeGroupModal';
import MyTable from '../../components/Table/MyTable';
import { GroupData, GroupMember, GroupRow } from '../../models/response/AuthResponse';
import { FilterResponse } from '../../models/response/CarResponse';
import { Context } from '../..';
import AuthService from '../../services/AuthService';
import EditChargeGroupModal from '../../components/Modal/EditChargeGroup/EditChargeGroupModal';
import DeleteGroupModal from '../../components/Modal/DeleteGroup/DeleteGroupModal';

interface GroupResponse {
  headers: any[];
  rows: any[];
  total?: number
}

const ChargeGroupPage = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);

  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: string[] }>({});
  const [textValues, setTextValues] = useState<{ [key: string]: string }>({});
  const [booleanValues, setBooleanValues] = useState<{ [key: string]: boolean | undefined}>({});
  const [showCheckboxColumn, setShowCheckboxColumn] = useState(false);

  const [groupData, setGroupData] = useState<GroupData | null>(null);
  const [filteredGroupData, fsetFlteredGroupData] = useState<GroupResponse | null>(null);
  const [groupFiltersResponseData, setGroupFiltersResponseData] = useState<FilterResponse>([]);
  const [loading, setLoading] = useState(false);
  const [prevFilters, setPrevFilters] = useState<any[]>([]);

  const [offset, setOffset] = useState(0);
  const [filteredOffset, setFilteredOffset] = useState(0);

  // Модалки

  const [selectedRowData, setSelectedRowData] = useState<GroupRow | null>(null);
  const [isAddRateModalOpen, setAddRateModalOpen] = useState(false);
  const [isEditRateModalOpen, setEditRateModalOpen] = useState(false);
  const [isDeleteRateModalOpen, setDeleteRateModalOpen] = useState(false);

  // Добавление группы

  const handleAddRateModalOpen = () => {
    setAddRateModalOpen(true);
  };

  const handleAddRateModalClose = () => {
    setAddRateModalOpen(false);
  };

  // Редактирование группы

  const handleEditRateModalOpen = () => {
    setEditRateModalOpen(true);
  };

  const handleEditRateModalClose = () => {
    setEditRateModalOpen(false);
  };

  // Удаление группы

  const handleDeleteRateModalOpen = () => {
    setDeleteRateModalOpen(true);
  };

  const handleDeleteRateModalClose = () => {
    setDeleteRateModalOpen(false);
  };

  const handleTextChange = debounce((text: string, key: string) => {
    setTextValues((prevTextValues) => ({
      ...prevTextValues,
      [key]: text,
    }));
  }, 1500);

  const handleOptionSelect = debounce((selected: string[], filterKey: string) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [filterKey]: selected,
    }));
  }, 500);

  const handleBooleanChange = debounce((value: boolean, key: string) => {
    if (booleanValues[key] !== value) {
      setBooleanValues((prevBooleanValues) => ({
        ...prevBooleanValues,
        [key]: value,
      }));
    }
  }, 500);

  const handleClearClick = debounce((filterKey: string) => {
    setTextValues((prevValues) => ({ ...prevValues, [filterKey]: '' }));
  }, 1500);

  const handleBooleanClear = debounce((filterKey: string) => {
    if (booleanValues[filterKey] !== undefined) {
      setBooleanValues((prevValues) => ({
        ...prevValues,
        [filterKey]: undefined,
      }));
    }
  }, 500);

  const handleSelectClear = debounce((filterKey: string) => {
    setSelectedOptions((prevValues) => ({
      ...prevValues,
      [filterKey]: [],
    }));
  }, 500);

  const filteredData = async () => {
    setLoading(true);
    try {
      const newFilters: any = [];
      const uniqueFilters = new Set();

      for (const filter of groupFiltersResponseData) {
        if (
          (filter.type === 'MULTISELECT' && selectedOptions[filter.key]?.length > 0) ||
          (filter.type === 'SELECT' && selectedOptions[filter.key]?.length > 0) ||
          (filter.type === 'TEXT' && textValues[filter.key]) ||
          (filter.type === 'BOOLEAN' && booleanValues[filter.key] !== undefined)
        ) {
          const filterKey = filter.key;

          if (!uniqueFilters.has(filterKey)) {
            uniqueFilters.add(filterKey);

            let values;
            if (filter.type === 'MULTISELECT') {
              values = selectedOptions[filter.key];
            } else if (filter.type === 'SELECT') {
              values = selectedOptions[filter.key];
            } else if (filter.type === 'TEXT') {
              values = [textValues[filter.key]];
            } else if (filter.type === 'BOOLEAN') {
              values = [booleanValues[filter.key]];
            }

            newFilters.push({
              key: filter.key,
              values,
            });
          }
        }
      }

      store.setCarFilters(newFilters);
      const filtersChanged = !isEqual(prevFilters, newFilters);

      if (filtersChanged) {
        fsetFlteredGroupData(null);
        setFilteredOffset(0);
      }

      if (newFilters.length > 0) {
        const responseFilter = await AuthService.getGroups(newFilters, 50, filtersChanged ? 0 : filteredOffset);
        if (responseFilter.status !== 401) {
          setOffset(filtersChanged ? 0 : offset);
          setGroupData(null);
          fsetFlteredGroupData((prevData) => ({
            headers: responseFilter?.data.headers,
            rows: [...(filtersChanged ? [] : prevData?.rows || []), ...responseFilter.data.rows],
            total: responseFilter?.data.total,
          }));
          setFilteredOffset((prev) => prev + 50);
        }
      } else {
        setLoading(true);
        const response = await AuthService.getGroups([], 0, 0);
        if (response.status !== 401) {
          setFilteredOffset(0);
          fsetFlteredGroupData(null);
          setGroupData(response.data);
          // setGroupData((prevData) => ({
          //   headers: response?.data.headers,
          //   rows: [...(prevData?.rows || []), ...response.data.rows],
          //   total: response?.data.total,
          // }));
          // setOffset((prev) => prev + 50);
        }
      }
    } catch (error) {
      // console.error('Ошибка при отправке запроса:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPrevFilters(store.carFilters);
  }, [store.carFilters]);

  useEffect(() => {
    // fetchFilters();
    filteredData();
  }, [isAddRateModalOpen, isDeleteRateModalOpen, isEditRateModalOpen]);

  const debounceFilteredData = debounce(filteredData, 500);

  useEffect(() => {
    const areAllSelectedOptionsEmpty = Object.values(selectedOptions).every((array) => array.length === 0);
    const isAnyTextValueNotEmpty = Object.values(textValues).some((value) => value.trim() !== '');
    const isAnyBooleanValueNotEmpty =
      Object.values(booleanValues).some((value) => value !== undefined && value !== null);
    if (
      (Object.keys(selectedOptions).length > 0 && areAllSelectedOptionsEmpty) ||
      Object.keys(selectedOptions).length > 0 ||
      (Object.keys(textValues).length > 0 && isAnyTextValueNotEmpty) ||
      Object.keys(textValues).length > 0 ||
      (isAnyBooleanValueNotEmpty && Object.keys(booleanValues).length > 0) ||
      Object.keys(booleanValues).length > 0
    ) {
      // const now = Date.now();
      // const timeSinceLastFiltered = now - lastFilteredTime;
      // if (timeSinceLastFiltered >= 2000) {
      //   debounceFilteredData();
      //   setLastFilteredTime(now);
      // }
      debounceFilteredData();
    }
  }, [selectedOptions, textValues, booleanValues]);

  return (
    <div className='group-page-info'>
      <div className='group-page-info-header'>
        <div className='group-page-info-topLine-title'>
          <h2 className='group-page-info-title'>Группы</h2>
          {groupData && groupData?.total ? <span>{`(${groupData.total})`}</span> : ''}
        </div>
        <LargeButtonWithIcon
          height='36px'
          text={'Добавить группу'}
          color={'--MyWhite'}
          LeftIcon={plus}
          iconMarginRight='8px'
          backgroundColor={'--MyPrimary'}
          padding='8px 12px'
          onClick={handleAddRateModalOpen}
        />
        {isAddRateModalOpen &&
          <AddChargeGroupModal
          onClose={handleAddRateModalClose}
          isModalOpen={isAddRateModalOpen}
          />
        }
      </div>
      <div className='group-page-info-container'>
        <div className='group-page-table'>
          {groupData && (
            <div>
              <MyTable
                hasMore={groupData.total !== undefined && groupData.total > groupData.rows.length}
                fetchMore={debounceFilteredData}
                headers={groupData.headers}
                data={groupData.rows}
                loading={loading}
                onRowClick={(rowData, event) => {
                  const target = event?.target as HTMLElement;
                  const closestEditButton = target.closest('[data-id="edit-button"]');
                  const closestBucketButton = target.closest('[data-id="bucket-button"]');
                  const href = `/groups/${rowData._id}`;

                  if (closestEditButton) {
                    event?.stopPropagation();
                    event?.preventDefault();
                    setSelectedRowData(rowData);
                    handleEditRateModalOpen();
                    return;
                  }

                  if (closestBucketButton) {
                    event?.stopPropagation();
                    event?.preventDefault();
                    setSelectedRowData(rowData);
                    handleDeleteRateModalOpen();
                    return;
                  }

                  if (event?.ctrlKey || event?.metaKey || event?.button === 1) {
                    event.stopPropagation();
                  } else {
                    event?.preventDefault();
                    navigate(href);
                  }
                }}

                tableHref={(rowData) => `/groups/${rowData._id}`}
              />
                {isEditRateModalOpen && selectedRowData && (
                  <EditChargeGroupModal
                    onClose={handleEditRateModalClose}
                    isModalOpen={isEditRateModalOpen}
                    description={selectedRowData.description}
                    name={selectedRowData.name}
                    active={selectedRowData.active}
                    groupId={selectedRowData._id}
                  />
                )}
                {isDeleteRateModalOpen && selectedRowData && (
                  <DeleteGroupModal
                    onClose={handleDeleteRateModalClose}
                    isModalOpen={isDeleteRateModalOpen}
                    title={'Удаление группы'}
                    description={'Вы уверены, что хотите удалить группу '}
                    groupName={selectedRowData.name}
                    accessOption={'Да'}
                    groupId={selectedRowData._id}
                  />
                )}
              <div id="cars-table-bottom-id" style={{ height: '10px' }}></div>
            </div>)}
          {/* {filteredGroupData && (
            <div>
              <MyTable
                hasMore={filteredGroupData.total !== undefined
                  && filteredGroupData.total > filteredGroupData.rows.length}
                fetchMore={debounceFilteredData}
                headers={filteredGroupData.headers}
                data={filteredGroupData.rows}
                loading={loading}
              />
              <div id="cars-table-bottom-id" style={{ height: '10px' }}></div>
            </div>)} */}
        </div>
      </div>
    </div>
  );
};

export default ChargeGroupPage;
