/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import './AddChargeGroupModal.css';
import { useParams } from 'react-router-dom';
import {
  arrowHeader, clearInput, closeModal, largeLoading, largeError, largeFinish
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import ChargeService from '../../../services/ChargeService';
import { RateInfo } from '../../../models/response/RateResponse';
import MySwitch from '../../Switch/Switch';
import AuthService from '../../../services/AuthService';

interface AddChargeGroupModalProps {
  onClose: () => void;
  isModalOpen: boolean;
}

const AddChargeGroupModal: React.FC<AddChargeGroupModalProps> = ({
  onClose, isModalOpen
}) => {
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [rate, setRate] = useState('');
  const [rateId, setRateId] = useState('');
  const [activeStatus, setActiveStatus] = useState(false);
  const [groupId, setGroupId] = useState('');

  const [submitting, setSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const [isRateDropdownOpen, setIsRateDropdownOpen] = useState(false);
  const [ratesEnum, setRatesEnum] = useState<RateInfo[] | []>([]);

  const [isFormValid, setIsFormValid] = useState(false);

  const modelDropdownRef = useRef(null);

  const handleModelInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRate(e.target.value);
  };

  const handleModelInputClick = () => {
    if (ratesEnum.filter((rateInfo) => !rateInfo.groupId).length > 0) {
      setIsRateDropdownOpen((prevIsRateDropdownOpen) => !prevIsRateDropdownOpen);
    }
  };

  const handleRateSelect = (selectedRate: RateInfo) => {
    setRate(selectedRate.title);
    setRateId(selectedRate.id);
    setIsRateDropdownOpen(false);
  };

  const handleRetry = () => {
    setStatusMessage('');
  };

  const handleClearInput = (setState: React.Dispatch<React.SetStateAction<string>>) => () => {
    setState('');
  };

  const fetchRates = async () => {
    try {
      const response = await ChargeService.getRates([
        {
          key: 'GROUP_ID',
          values: [groupId]
        }
      ]);
      setRatesEnum(response.data.rows);
    } catch {
      // console.log('error');
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!groupId) {
      try {
        const response = await AuthService.addGroup(groupName, groupDescription, activeStatus);
        if (response && response.status === 200) {
          setGroupId(response.data.id);
        }
      } catch (error: any) {
        setStatusMessage('Произошла ошибка при добавлении группы');
        setSubmitting(false);
      }
    } else if (rateId && groupId) {
      setSubmitting(true);
      setStatusMessage('Добавляем группу');
      const upgradeRate = await ChargeService.updateRate({ groupId }, rateId);
      if (upgradeRate && upgradeRate.status === 200) {
        setStatusMessage('Группа успешно добавлена');
        setSubmitting(false);
      }
    } else {
      setStatusMessage('Группа успешно добавлена');
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const isAllFieldsFilled =
      groupName.trim() !== '' &&
      groupDescription.trim() !== '';

    setIsFormValid(isAllFieldsFilled);
  }, [groupName, groupDescription]);

  useEffect(() => {
    if (groupId) {
      fetchRates();
    }
  }, [groupId]);

  return (
    <div className={`addChargeGroup-modal-background ${isModalOpen ? '' : 'addChargeGroup-modal-hidden'}`}>
      <div className="addChargeGroup-modal-content">
        <h1>Добавить группу</h1>
        <div className="addChargeGroup-modal-close" onClick={onClose}>{closeModal}</div>
        {submitting && (
          <div className='addChargeGroup-modal-notification-container'>
            {largeLoading}
            <h3 className='addChargeGroup-modal-notification-title'>Добавляем группу...</h3>
          </div>
        )}
        {!submitting && statusMessage && (
          <div>
            {statusMessage === 'Произошла ошибка при добавлении группы' ? (
              <div className='addChargeGroup-modal-notification-container'>
                {largeError}
                <h3 className='addChargeGroup-modal-notification-title'>{statusMessage}</h3>
                <div className='addChargeGroup-modal-error-buttons-container'>
                  <MediumButton
                    text={'Попробовать снова'}
                    onClick={handleRetry}
                    backgroundColor={'--MyPrimary'}
                    color={'--MyWhite'}
                  />
                  <MediumButton
                    text={'Отмена'}
                    color={'--MyPrimary'}
                    backgroundColor={'--MySecondaryBlue'}
                    onClick={onClose}
                  />
                </div>
              </div>
            ) : (
              <div className='addChargeGroup-modal-notification-container'>
                {largeFinish}
                <h3 className='addChargeGroup-modal-notification-title'>Группа успешно добавлена</h3>
              </div>
            )}
          </div>
        )}
        {!statusMessage && (
          <form className='addChargeGroup-modal-form' onSubmit={handleSubmit}>
            {!groupId && (<><div className='addChargeGroup-modal-form-inputs'>
              <label className='addChargeGroup-modal-label'>
                <span className='addChargeGroup-modal-label-title'>Название</span>
                <input
                  placeholder='Введите название'
                  type="text"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)} />
                {groupName && (
                  <div className='addChargeGroup-modal-label-clear' onClick={handleClearInput(setGroupName)}>
                    {clearInput}
                  </div>
                )}
              </label>
              <label className='addChargeGroup-modal-label'>
                <span className='addChargeGroup-modal-label-title'>Описание</span>
                <input
                  placeholder='Введите описание'
                  type="text"
                  value={groupDescription}
                  onChange={(e) => setGroupDescription(e.target.value)} />
                {groupDescription && (
                  <div className='addChargeGroup-modal-label-clear' onClick={handleClearInput(setGroupDescription)}>
                    {clearInput}
                  </div>
                )}
              </label>
            </div><div className='addChargeGroup-switch-container'>
                <span>Активный статус</span>
                <MySwitch checked={activeStatus} onChange={() => setActiveStatus((prev) => !prev)} />
              </div>
              <div className='addChargeGroup-modal-footer'>
                <div className='addChargeGroup-modal-buttons'>
                  <MediumButton text={'Далее'} color={'--MyWhite'} type='submit' disabled={!isFormValid}
                    backgroundColor={'--MyPrimary'} />
                  <MediumButton text={'Отмена'} color={'--MyPrimary'}
                    backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
              </div>
              </div></>)
            }
            {groupId &&
            <>
            <div className='addChargeGroup-modal-form-inputs'>
              <label className='addChargeGroup-modal-label addChargeGroup-modal-label-dropdown'>
                <span className='addChargeGroup-modal-label-title'>Тариф</span>
                <input
                  readOnly
                  placeholder={ratesEnum.filter((rateInfo) => !rateInfo.groupId).length > 0 ? 'Не выбрано' : 'Нет тарифов'}
                  type="text"
                  value={rate}
                  onChange={handleModelInputChange}
                  onClick={handleModelInputClick}
                  // onBlur={() => setIsRateDropdownOpen(false)}
                />
                {!rate ? (
                  <div className='addChargeGroup-modal-label-arrow-down'>
                    {arrowHeader}
                  </div>
                ) : (
                  <div className='addChargeGroup-modal-label-clear' onClick={handleClearInput(setRate)}>
                    {clearInput}
                  </div>
                )}
              </label>
                {isRateDropdownOpen && (
                  <div className="addChargeGroup-modal-dropdown addChargeGroup-modal-dropdown-long"
                    style={{ display: isRateDropdownOpen ? 'block' : 'none' }}
                    ref={modelDropdownRef}
                  >
                    {ratesEnum
                      .filter((rateInfo) => !rateInfo.groupId)
                      .map((rateTitle, id) => (
                        <div key={id} onClick={() => handleRateSelect(rateTitle)}>{rateTitle.title}</div>
                      ))
                    }
                  </div>
                )}
            </div>
            <div className='addChargeGroup-modal-footer'>
              <div className='addChargeGroup-modal-buttons'>
                <MediumButton disabled={!isFormValid} text={'Добавить'} color={'--MyWhite'} type='submit'
                backgroundColor={'--MyPrimary'}/>
              </div>
            </div>
              </>
              }
          </form>
        )}
      </div>
    </div>
  );
};

export default AddChargeGroupModal;
