import React from 'react';
import { closeModal } from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import './TransferCarsRequest.css';
// import TransferCarModal from '../TransferCar/TransferCarModal';

interface TransferCarsRequestProps {
  onClose: () => void;
  onNext: () => void;
  isModalOpen: boolean;
  title: string;
  description: string;
  accessOption: string;
  selectedCars: { carVin: string; carModel: string }[];
}
const TransferCarsRequestModal: React.FC<TransferCarsRequestProps> = ({
  onClose, onNext, isModalOpen, title, description, accessOption, selectedCars
}) => (
  // const handleOpenTransferCarModal = () => {
  //   console.log('open');
  //   setIsTransferCarsModalOpen(true);
  // };

  // const handleCloseTransferCarModal = () => {
  //   setIsTransferCarsModalOpen(false);
  //   onClose();
  // };
    <div className={`transferCarsRequest-modal-background ${isModalOpen ? '' : 'transferCarsRequest-modal-hidden'}`}>
    <div className="transferCarsRequest-modal-content">
      <h1>{title}</h1>
      <div className="transferCarsRequest-modal-close" onClick={onClose}>{closeModal}</div>
      <div className='transferCarsRequest-modal-form'>
        <div className='transferCarsRequest-modal-form-description'>
          <span className='transferCarsRequest-modal-form-description-title'>{description}</span>
          <div className='transferCarsRequest-modal-form-description-car-list'>
            <ul>
              {selectedCars.map((car, index) => (
                <li key={index}>
                  <span className='transferCarsRequest-modal-form-selectedCar-index'>{index + 1}</span>
                  <span className='transferCarsRequest-modal-form-selectedCar-model-title'>Модель:
                    <span className='transferCarsRequest-modal-form-selectedCar-model-value'> {car.carModel}</span>
                  </span>
                  <span className='transferCarsRequest-modal-form-selectedCar-vin-title'>VIN:
                    <span className='transferCarsRequest-modal-form-selectedCar-vin-value'> {car.carVin}</span>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className='transferCarsRequest-modal-buttons'>
          <MediumButton text={accessOption} color={'--MyWhite'} onClick={onNext} backgroundColor={'--MyPrimary'}/>
          <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
          {/* {isTransferCarsModalOpen && (<TransferCarModal
            onClose={handleCloseTransferCarModal}
            isModalOpen={isTransferCarsModalOpen}
            title={'Передать автомобили'}/>)} */}
        </div>
      </div>
    </div>
  </div>
);
export default TransferCarsRequestModal;
