/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import './ChargeStationLogs.css';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import isEqual from 'lodash/isEqual';
import { Context } from '../..';
import LargeButton from '../../components/buttons/LargeButton';
import ChargeService from '../../services/ChargeService';
import { StationLogsResponse } from '../../models/response/ChargeResponse';

const ChargeStationLogs = () => {
  const { RangePicker } = DatePicker;
  const { chargePointId } = useParams<{ chargePointId: string }>();
  const actualChargePointId = chargePointId || '';
  const { store } = useContext(Context);

  const [chargeLogs, setChargeLogs] = useState<StationLogsResponse | null>(null);
  const [filteredChargeLogsMessages, setFilteredChargeLogsMessages] = useState('');
  const [chargeLogsMessages, setChargeLogsMessages] = useState('');
  const [newChargeLogsMessages, setNewChargeLogsMessages] = useState('');
  const [pageToken, setPageToken] = useState('');
  const [timeStamp, setTimeStamp] = useState('');
  const [prevTimeStamp, setPrevTimeStamp] = useState('');
  const [loading, setLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);

  const [selectedDates, setSelectedDates] = useState<any[]>([]);
  const [prevSelectedDates, setPrevSelectedDates] = useState<any[]>([]);

  const resetState = () => {
    setChargeLogsMessages('');
    setPrevSelectedDates(selectedDates);
    setTimeStamp('');
    setPageToken('');
  };

  const getLogs = async () => {
    const filters: any = [];
    try {
      // const datesChanged = !isEqual(selectedDates, prevSelectedDates);
      // if (datesChanged) {
      //   setChargeLogsMessages('');
      //   setTimeStamp('');
      //   setPageToken('');
      //   setPrevSelectedDates(selectedDates);
      //   console.log(timeStamp);
      // }
      setLoading(true);
      if (pageToken) {
        const responseStation = await ChargeService.getChargePointV2(actualChargePointId);
        if (responseStation.data) {
          filters.push(
            {
              values: [
                responseStation.data.identifier
              ],
              key: 'MESSAGE'
            }
          );
          if (timeStamp && selectedDates.length < 0) {
            filters.push(
              {
                key: 'TIMESTAMP_BEFORE',
                values: [
                  timeStamp
                ]
              }
            );
          }
          if (selectedDates.length === 2 && selectedDates[0].$d && selectedDates[1].$d) {
            const datesChanged = !isEqual(selectedDates, prevSelectedDates);
            if (datesChanged) {
              resetState();
            }
            filters.push(
              {
                values: [
                  selectedDates[0].$d.toISOString()
                ],
                key: 'TIMESTAMP_FROM'
              },
              {
                values: [
                  datesChanged ? selectedDates[1].$d.toISOString() : timeStamp
                ],
                key: 'TIMESTAMP_BEFORE'
              }
            );
            const response = await ChargeService.getStationLogs(filters, 100, 0, datesChanged ? undefined : pageToken);
            setChargeLogs(response.data);
            setChargeLogsMessages((prev) => `${prev}\n${response.data.messages}`);
            setPageToken(response.data.nextPageToken ? response.data.nextPageToken : '');
            setTimeStamp(response.data.timestamp);
            setPrevTimeStamp(response.data.timestamp);
            setNewChargeLogsMessages(response.data.messages);
          } else {
            filters.push(
              {
                key: 'TIMESTAMP_BEFORE',
                values: [
                  timeStamp
                ]
              }
            );
            const response = await ChargeService.getStationLogs(filters, 100, 0, pageToken);
            setChargeLogs(response.data);
            setChargeLogsMessages((prev) => `${prev}\n${response.data.messages}`);
            setPageToken(response.data.nextPageToken ? response.data.nextPageToken : '');
            setTimeStamp(response.data.timestamp);
            setPrevTimeStamp(response.data.timestamp);
            setNewChargeLogsMessages(response.data.messages);
          }
        }
      } else {
        setFirstLoading(true);
        const responseStation = await ChargeService.getChargePointV2(actualChargePointId);
        if (responseStation.data) {
          filters.push(
            {
              values: [
                responseStation.data.identifier
              ],
              key: 'MESSAGE'
            }
          );
          if (timeStamp) {
            filters.push(
              {
                key: 'TIMESTAMP_BEFORE',
                values: [
                  timeStamp
                ]
              }
            );
          }
          const response = await ChargeService.getStationLogs(filters, 100, 0);
          setChargeLogs(response.data);
          setChargeLogsMessages(response.data.messages);
          setPageToken(response.data.nextPageToken ? response.data.nextPageToken : '');
          setTimeStamp(response.data.timestamp);
          setNewChargeLogsMessages(response.data.messages);
        }
      }
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
      setFirstLoading(false);
    }
  };

  // const getLogs = async () => {
  //   const filters: any = [];
  //   try {
  //     setLoading(true);
  //     if (pageToken && timeStamp) {
  //       const responseStation = await ChargeService.getChargePointV2(actualChargePointId);
  //       if (responseStation.data) {
  //         filters.push(
  //           {
  //             values: [
  //               responseStation.data.identifier
  //             ],
  //             key: 'MESSAGE'
  //           },
  //           {
  //             key: 'TIMESTAMP_BEFORE',
  //             values: [
  //               timeStamp
  //             ]
  //           }
  //         );
  //         const response = await ChargeService.getStationLogs(filters, 100, 0, pageToken);
  //         setChargeLogs(response.data);
  //         setChargeLogsMessages((prev) => `${prev}\n${response.data.messages}`);
  //         setPageToken(response.data.nextPageToken ? response.data.nextPageToken : '');
  //         setTimeStamp(response.data.timestamp);
  //         setPrevTimeStamp(response.data.timestamp);
  //         setNewChargeLogsMessages(response.data.messages);
  //       }
  //     } else {
  //       setFirstLoading(true);
  //       const responseStation = await ChargeService.getChargePointV2(actualChargePointId);
  //       if (responseStation.data) {
  //         filters.push(
  //           {
  //             values: [
  //               responseStation.data.identifier
  //             ],
  //             key: 'MESSAGE'
  //           }
  //         );
  //         const response = await ChargeService.getStationLogs(filters, 100, 0);
  //         setChargeLogs(response.data);
  //         setChargeLogsMessages(response.data.messages);
  //         setPageToken(response.data.nextPageToken ? response.data.nextPageToken : '');
  //         setTimeStamp(response.data.timestamp);
  //         setNewChargeLogsMessages(response.data.messages);
  //       }
  //     }
  //   } catch (error) {
  //     // console.log(error);
  //   } finally {
  //     setLoading(false);
  //     setFirstLoading(false);
  //   }
  // };

  const handleDateChange = (dates: any) => {
    if ((dates && dates !== null)) {
      setSelectedDates(dates);
      setPrevSelectedDates(selectedDates);
    } else {
      setSelectedDates([]);
      setPageToken('');
    }
  };

  const formatDate = (date: Date) => format(date, 'd MMMM', { locale: ru });

  const parseMessage = (message: string) => {
    const timestampRegex = /timestamp":"(.*?)"/;
    const timeMatch = message.match(/\[(.*?)\]/);
    const timestampMatch = message.match(timestampRegex);
    let formattedTime = '';
    let formattedDate = '';

    if (timestampMatch) {
      const serverTimestamp = timestampMatch[1];
      const date = new Date(serverTimestamp);
      formattedDate = Number.isNaN(date.getTime()) ? '' : formatDate(date);
      const timePart = serverTimestamp.split('T')[1].split('.')[0];
      formattedTime = `${formattedDate} ${timePart}`;
    } else if (timeMatch) {
      const timestamp = timeMatch[1];
      const [hours, minutes, secondsWithMs] = timestamp.split(':');
      const seconds = secondsWithMs.split('.')[0];
      formattedTime = `${hours}:${minutes}:${seconds}`;
    }

    const messageParts = message.trim();
    return { timestamp: formattedTime, message: messageParts };
  };

  useEffect(() => {
    const areDatesSelected = selectedDates.length === 2 && selectedDates[0] && selectedDates[1];
    const datesChanged = !isEqual(selectedDates, prevSelectedDates);
    if (
      areDatesSelected || datesChanged
    ) {
      getLogs();
    }
  }, [selectedDates]);

  useEffect(() => {
    getLogs();
    store.setStationId(actualChargePointId);
  }, []);

  return (
    <div className='chargePointLogs-container'>
      <div className='chargePointLogs-header'>
        <div className='chargePointLogs-header-title'>
          <h2 className='chargePointLogs-station-name'>Логи OCPP</h2>
        </div>
      </div>
      {<div className='chargePointLogs-rangePicker'>
        <RangePicker
              locale={{
                ...locale,
                lang: {
                  ...locale.lang,
                  ok: 'Применить',
                }
              }}
          size='small'
          onChange={handleDateChange}
          format={'DD.MM.YYYY HH:mm'}
          showTime
          />
      </div>}
      {(firstLoading || (loading && chargeLogsMessages === '')) && <span className='chargePointLogs-empty-rates'>Загрузка логов</span>}
      {chargeLogsMessages && chargeLogsMessages.trim() !== '' && (
        <>
          <div className='chargePointLogs-table-container'>
            <div className='chargePointLogs-table-header'>
              <span className='chargePointLogs-table-time'>Время</span>
              <span className='chargePointLogs-table-message'>Сообщение</span>
            </div>
            <div className='chargePointLogs-table-value-container'>
              {chargeLogsMessages.split('\n').map((operation: string, index: number) => {
                const { timestamp, message } = parseMessage(operation);
                return (
                  <div key={index} className='chargePointLogs-table-value-cell'>
                    <span className='chargePointLogs-table-time'>{timestamp}</span>
                    <span className='chargePointLogs-table-message'>{message}</span>
                  </div>
                );
              })}
            </div>
          </div>
          {(newChargeLogsMessages.split('\n').length === 100 || timeStamp !== prevTimeStamp) && (
            <div className='chargePointLogs-button-next'>
              <LargeButton
                text={loading ? 'Загрузка логов' : 'Загрузить еще 100 логов'}
                backgroundColor={'--MySecondaryBlue'}
                color={'--MyPrimary'}
                height='32px'
                onClick={getLogs}
                disabled={loading}
              />
            </div>
          )}
        </>
      )}
      {chargeLogsMessages.trim() === '' && !loading && <span className='chargePointLogs-empty-rates'>Нет данных</span>}
    </div>
  );
};

export default observer(ChargeStationLogs);
