/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import {
  YMaps, Map,
  GeoObject
} from '@pbe/react-yandex-maps';
import { DatePicker, Skeleton } from 'antd';
import 'dayjs/locale/ru';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import InfiniteScroll from 'react-infinite-scroll-component';
import debounce from 'lodash/debounce';
import { Context } from '../..';
import './TripPage.css';
import CarService from '../../services/CarService';
import { Coordinates, TravelsResponse, TravelsRow } from '../../models/response/CarResponse';

const TripPage = () => {
  const { carId } = useParams<{ carId: string }>();
  const actualCarId = carId || '';
  const { store } = useContext(Context);
  const { RangePicker } = DatePicker;

  const [selectedFilter, setSelectedFilter] = useState<string>('все время');
  const [previousFilter, setPreviousFilter] = useState<string>('все время');
  const [travels, setTravels] = useState<TravelsResponse | null>(null);
  const [travelsCount, setTravelsCount] = useState(0);
  const [coordinatesMap, setCoordinatesMap] = useState<{ [key: string]: number[][] }>({});
  const [coordinatesFullData, setCoordinatesFullData] = useState<Coordinates | null>(null);
  const [selectedDates, setSelectedDates] = useState<any[]>([]);

  const handleDateChange = (dates: any) => {
    if ((dates !== null)) {
      setSelectedDates(dates);
    } else {
      setSelectedDates([]);
      setSelectedFilter('все время');
    }
  };

  const getNoun = (number: number, one: any, two: any, five: any) => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
      return five;
    }
    n %= 10;
    if (n === 1) {
      return one;
    }
    if (n >= 2 && n <= 4) {
      return two;
    }
    return five;
  };

  const fetchTripsDetails = async (tripId: number) => {
    try {
      const response = await CarService.getTravelsDetails(actualCarId, tripId);
      setCoordinatesFullData(response.data);
      const coordinatesPoints = response.data.points.map((point) => [point.lat, point.lon]);
      setCoordinatesMap((prevState) => ({
        ...prevState,
        [tripId]: coordinatesPoints
      }));
    } catch (err) {
      // console.log(err);
    }
  };

  const fetchTrips = async () => {
    if (selectedFilter !== previousFilter) {
      setTravels(null);
      store.setTripOffset(0);
      setPreviousFilter(selectedFilter);
    }
    try {
      let filters: any = [];
      if (selectedFilter === 'все время') {
        const response = await CarService.getTravels(actualCarId, filters, 10, store.tripOffset);
        setTravels((prevData) => ({
          headers: response?.data.headers,
          rows: [...(prevData?.rows || []), ...response.data.rows],
          total: response?.data.total,
        }));
        setTravelsCount(response.data.total);
        response.data.rows.forEach((trip: TravelsRow) => {
          fetchTripsDetails(trip.id);
        });
        store.setTripOffset(store.tripOffset + 10);
        setPreviousFilter(selectedFilter);
      }
      if (selectedFilter === 'сегодня') {
        filters = [
          {
            values: [
              moment().startOf('day').toISOString()
            ],
            key: 'DATE_START'
          }
        ];
        const response = await CarService.getTravels(actualCarId, filters, 10, store.tripOffset);
        setTravels((prevData) => ({
          headers: response?.data.headers,
          rows: [...(prevData?.rows || []), ...response.data.rows],
          total: response?.data.total,
        }));
        setTravelsCount(response.data.total);
        response.data.rows.forEach((trip: TravelsRow) => {
          fetchTripsDetails(trip.id);
        });
        store.setTripOffset(store.tripOffset + 10);
        setPreviousFilter(selectedFilter);
      }
      if (selectedFilter === 'вчера') {
        filters = [
          {
            values: [
              moment().subtract(1, 'days').startOf('day').toISOString()
            ],
            key: 'DATE_START'
          },
          {
            values: [
              moment().subtract(1, 'days').endOf('day').toISOString()
            ],
            key: 'DATE_END'
          }
        ];
        const response = await CarService.getTravels(actualCarId, filters, 10, store.tripOffset);
        setTravels((prevData) => ({
          headers: response?.data.headers,
          rows: [...(prevData?.rows || []), ...response.data.rows],
          total: response?.data.total,
        }));
        setTravelsCount(response.data.total);
        response.data.rows.forEach((trip: TravelsRow) => {
          fetchTripsDetails(trip.id);
        });
        store.setTripOffset(store.tripOffset + 10);
        setPreviousFilter(selectedFilter);
      }
      if (selectedFilter === 'неделю') {
        filters = [
          {
            values: [
              moment().subtract(1, 'week').startOf('day').toISOString()
            ],
            key: 'DATE_START'
          },
          {
            values: [
              moment().endOf('day').toISOString()
            ],
            key: 'DATE_END'
          }
        ];
        const response = await CarService.getTravels(actualCarId, filters, 10, store.tripOffset);
        setTravels((prevData) => ({
          headers: response?.data.headers,
          rows: [...(prevData?.rows || []), ...response.data.rows],
          total: response?.data.total,
        }));
        setTravelsCount(response.data.total);
        response.data.rows.forEach((trip: TravelsRow) => {
          fetchTripsDetails(trip.id);
        });
        store.setTripOffset(store.tripOffset + 10);
        setPreviousFilter(selectedFilter);
      }
      if (selectedFilter === 'месяц') {
        filters = [
          {
            values: [
              moment().subtract(1, 'month').startOf('day').toISOString()
            ],
            key: 'DATE_START'
          },
          {
            values: [
              moment().endOf('day').toISOString()
            ],
            key: 'DATE_END'
          }
        ];
        const response = await CarService.getTravels(actualCarId, filters, 10, store.tripOffset);
        setTravels((prevData) => ({
          headers: response?.data.headers,
          rows: [...(prevData?.rows || []), ...response.data.rows],
          total: response?.data.total,
        }));
        setTravelsCount(response.data.total);
        response.data.rows.forEach((trip: TravelsRow) => {
          fetchTripsDetails(trip.id);
        });
        store.setTripOffset(store.tripOffset + 10);
        setPreviousFilter(selectedFilter);
      }
      if (selectedFilter === 'год') {
        filters = [
          {
            values: [
              moment().subtract(1, 'year').startOf('day').toISOString()
            ],
            key: 'DATE_START'
          },
          {
            values: [
              moment().endOf('day').toISOString()
            ],
            key: 'DATE_END'
          }
        ];
        const response = await CarService.getTravels(actualCarId, filters, 10, store.tripOffset);
        setTravels((prevData) => ({
          headers: response?.data.headers,
          rows: [...(prevData?.rows || []), ...response.data.rows],
          total: response?.data.total,
        }));
        setTravelsCount(response.data.total);
        response.data.rows.forEach((trip: TravelsRow) => {
          fetchTripsDetails(trip.id);
        });
        store.setTripOffset(store.tripOffset + 10);
        setPreviousFilter(selectedFilter);
      }
      if (selectedFilter === 'период') {
        filters = [
          {
            values: [
              selectedDates[0].$d.toISOString()
            ],
            key: 'DATE_START'
          },
          {
            values: [
              selectedDates[1].$d.toISOString()
            ],
            key: 'DATE_END'
          }
        ];
        const response = await CarService.getTravels(actualCarId, filters, 10, store.tripOffset);
        setTravels((prevData) => ({
          headers: response?.data.headers,
          rows: [...(prevData?.rows || []), ...response.data.rows],
          total: response?.data.total,
        }));
        setTravelsCount(response.data.total);
        response.data.rows.forEach((trip: TravelsRow) => {
          fetchTripsDetails(trip.id);
        });
        store.setTripOffset(store.tripOffset + 10);
        setPreviousFilter(selectedFilter);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const handleClick = (filter: string) => {
    setSelectedFilter(filter);
  };

  const debounceFilteredData = debounce(fetchTrips, 500);

  useEffect(() => {
    store.setTripOffset(0);
    // fetchTrips();
  }, []);

  useEffect(() => {
    if (selectedDates.length > 0 && selectedFilter === previousFilter) {
      setTravels(null);
      store.setTripOffset(0);
    }
    fetchTrips();
  }, [selectedFilter, selectedDates]);

  useEffect(() => {
    store.setCarId(actualCarId);
  }, []);

  return (
    <div className='trip-page-info'>
      <div className='trip-page-header'>
        <div className='trip-page-title'>
          <h2>Поездки </h2>
          {selectedFilter === 'период' && selectedDates.length > 0 && (
              <span>
                ({travelsCount})
              </span>
          )}
          {selectedFilter !== 'период' && (
            <span>
              ({travelsCount})
            </span>
          )}
        </div>
      </div>
      <div className='trip-page-filters'>
        <ul>
          <li className={selectedFilter === 'все время' ? 'selected-filter' : ''} onClick={() => handleClick('все время')}>За все время</li>
          <li className={selectedFilter === 'сегодня' ? 'selected-filter' : ''} onClick={() => handleClick('сегодня')}>Сегодня</li>
          <li className={selectedFilter === 'вчера' ? 'selected-filter' : ''} onClick={() => handleClick('вчера')}>Вчера</li>
          <li className={selectedFilter === 'неделю' ? 'selected-filter' : ''} onClick={() => handleClick('неделю')}>За неделю</li>
          <li className={selectedFilter === 'месяц' ? 'selected-filter' : ''} onClick={() => handleClick('месяц')}>За месяц</li>
          <li className={selectedFilter === 'год' ? 'selected-filter' : ''} onClick={() => handleClick('год')}>За год</li>
          <li className={selectedFilter === 'период' ? 'selected-filter2' : ''} onClick={() => handleClick('период')}>
            <RangePicker size='small' onChange={handleDateChange} format={'DD.MM.YYYY'} className='trip-page-rangePicker'/>
          </li>
        </ul>
      </div>
      <div className='trip-page-trips-list'>
        <YMaps>
          <InfiniteScroll
          next={debounceFilteredData}
          hasMore={travels?.total !== undefined && travels.total > 10 && store.tripOffset < travels.total}
          loader={''}
          dataLength={travels ? travels.rows.length : 0}
          >
          {travels?.total !== 0 && travels?.rows && travels !== undefined ? travels.rows.map((trip: TravelsRow) => (
            <div className='trip-page-trip-container' key={trip.id}>
              <div className='trip-page-trip-container-info'>
                <h3 className='trip-page-trip-container-info-title'>Поездка №{trip.id}</h3>
                <div className='trip-page-trip-container-info-details'>
                  <div className='trip-info-details-container'>
                    <span className='trip-container-info-details-title'>Начало</span>
                    <span className='trip-container-info-details-value'>
                      {trip ? (new Date(trip.startDate).toLocaleString()).split(',').join(' в ') : <Skeleton.Input active />}
                    </span>
                  </div>
                  <div className='trip-info-details-container'>
                    <span className='trip-container-info-details-title'>Конец</span>
                    <span className='trip-container-info-details-value'>
                      {trip ? (new Date(trip.endDate).toLocaleString()).split(',').join(' в ') : <Skeleton.Input active/>}
                    </span>
                  </div>
                </div>
              </div>
              <div className='trip-page-trip-container-map'>
                {coordinatesMap[trip.id] !== undefined && coordinatesMap[trip.id] && coordinatesFullData?.pointsTotal && coordinatesFullData?.pointsTotal !== 0 && coordinatesFullData.points ? (
                  <Map
                    width={'100%'}
                    height={'216px'}
                    defaultState={{
                      center: coordinatesMap[trip.id] ? coordinatesMap[trip.id][0] : [0, 0],
                      zoom: 15,
                    }}
                    modules={['geoObject.addon.balloon', 'geoObject.addon.hint', 'layout.ImageWithContent']}
                  >
                    <GeoObject
                      geometry={{
                        type: 'LineString',
                        coordinates: coordinatesMap[trip.id],
                      }}
                      options={{
                        geodesic: true,
                        strokeWidth: 5,
                        strokeColor: '#F008',
                      }}
                    />
                  </Map>
                ) : (
                  coordinatesFullData?.pointsTotal !== 0 ? (
                    <div className='trip-not-found-img' style={{ backgroundImage: 'none' }}>
                      <Skeleton.Image active style={{ height: '216px', width: '100%' }}/>
                    </div>
                  ) : (
                    <div className='trip-not-found-img'>
                      <span>Нет данных</span>
                    </div>
                  )
                )}
              </div>
            </div>
          )) : <span>Поездок не найдено</span>}
          </InfiniteScroll>
        </YMaps>
        {travels?.total && travels?.total !== 0 && travels?.total > 10 && store.tripOffset < travels?.total ? (
          <div className='trip-page-trip-container'>
          <div className='trip-page-trip-container-info'>
            <h3 className='trip-page-trip-container-info-title'><Skeleton.Input active /></h3>
            <div className='trip-page-trip-container-info-details'>
              <div className='trip-info-details-container'>
                <span className='trip-container-info-details-title'>Начало</span>
                <span className='trip-container-info-details-value'>
                  <Skeleton.Input size='small' active />
                </span>
              </div>
              <div className='trip-info-details-container'>
                <span className='trip-container-info-details-title'>Конец</span>
                <span className='trip-container-info-details-value'>
                <Skeleton.Input size='small' active />
                </span>
              </div>
            </div>
          </div>
          <div className='trip-page-trip-container-map'>
            <div className='trip-not-found-img' style={{ backgroundImage: 'none' }}>
              <Skeleton.Image active style={{ height: '216px', width: '100%' }}/>
            </div>
          </div>
        </div>
        ) : <span></span>}
        {}
      </div>
    </div>
  );
};

export default observer(TripPage);
