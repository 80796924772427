/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useState, useEffect, ChangeEvent, useRef
} from 'react';
import './CodeInput.css';

interface SegmentedInputProps {
  value: string;
  onChange: (value: string) => void;
  onCodeComplete?: (code: string) => void;
}

const SegmentedInput: React.FC<SegmentedInputProps> = ({ value, onChange, onCodeComplete }) => {
  const [inputValues, setInputValues] = useState(['', '', '', '']);

  const handleInputChange = (index: number, newValue: string) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = newValue;
    setInputValues(newInputValues);
    onChange(newInputValues.join(''));

    if (index < 3 && newValue) {
      const nextSegment = index + 1;
      const nextInput = document.getElementById(`segment-${nextSegment}`);
      if (nextInput) {
        nextInput.focus();
      }

      if (index === 2 && onCodeComplete) {
        onCodeComplete(newInputValues.join(''));
      }
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, segmentIndex: number) => {
    if (e.key === 'Backspace' && !value[segmentIndex] && segmentIndex > 0) {
      const prevSegment = segmentIndex - 1;
      const prevInput = document.getElementById(`segment-${prevSegment}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const handleFocus = (index: number) => {
    const input = document.getElementById(`segment-${index}`) as HTMLInputElement;
    if (input) {
      if (input.value === '') {
        input.classList.add('empty');
      } else {
        input.classList.remove('empty');
      }
    }
  };

  useEffect(() => {
    const input = document.getElementById('segment-0');
    if (input) {
      input.focus();
    }
  }, []);

  useEffect(() => {
    const inputs: HTMLInputElement[] = Array.from(
      document.querySelectorAll('.code-input-container input')
    );
    inputs.forEach((input, index) => {
      input.addEventListener('input', (e) => {
        const target = e.target as HTMLInputElement;
        const valueInput = target.value;
        if (valueInput === '') {
          input.classList.add('empty');
          input.classList.remove('filled');
        } else {
          input.classList.remove('empty');
          input.classList.add('filled');
        }
      });
    });
  }, []);

  return (
    <div className='code-input-container'>
      {inputValues.map((InputValue, index) => (
        <input
          autoComplete={index < inputValues.length - 1 ? 'one-time-code' : 'off'}
          key={index}
          type="text"
          id={`segment-${index}`}
          value={InputValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onFocus={() => handleFocus(index)}
          maxLength={1}
          inputMode="numeric"
        />
      ))}
    </div>
  );
};

export default SegmentedInput;
