/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import './AddOrgStaff.css';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useParams } from 'react-router-dom';
import {
  arrowHeader, clearInput, closeModal, largeLoading, largeError, largeFinish, faq
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import PhoneInput from '../../Inputs/LoginInput/PhoneInput';
import OrgService from '../../../services/OrgService';
import MyCircleCheckbox from '../../Checkboxes/CheckboxCircle';

interface AddOrgStaffModalProps {
  onClose: () => void;
  isModalOpen: boolean;
}

const AddOrgStaffModal: React.FC<AddOrgStaffModalProps> = ({ onClose, isModalOpen }) => {
  const { orgId } = useParams<{ orgId: string }>();
  const actualOrgId = orgId || '';

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [surname, setSureName] = useState('');
  const [phone, setPhone] = useState('');
  const [job, setJob] = useState('');

  const [submitting, setSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [isJobDropdownOpen, setIsJobDropdownOpen] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [showRoleFaq, setShowRoleFaq] = useState(false);
  const jobDropdownRef = useRef(null);

  // Job

  const handleJobInputClick = () => {
    setIsJobDropdownOpen((prevIsJobDropdownOpen) => !prevIsJobDropdownOpen);
  };

  const handleJobSelect = (selected: string) => {
    setJob(selected);
    setIsJobDropdownOpen(false);
  };

  const handleJobInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setJob(e.target.value);
  };

  const roleMap = {
    сотрудник: '65c06cf9b492402b7662c36e',
    менеджер: '65c06ce7b492402b7662c36d',
    администратор: '65c06cd9b492402b7662c36c'
  };

  // Phone

  const simPhoneChange = (number: string) => {
    setPhone(number);
  };

  const handleSubmit = async () => {
    let parsePhone;
    if (phone) {
      parsePhone = parsePhoneNumber(phone)?.number.replace('+', '');
    }
    try {
      const requestBody = {
        firstName: firstName || undefined,
        lastName: lastName || undefined,
        roleId: roleMap[job.toLowerCase() as keyof typeof roleMap],
        phone: parsePhone || '',
        orgId: actualOrgId
      };
      setSubmitting(true);
      setStatusMessage('Добавляем пользователя');
      const response = await OrgService.addOrgStaff(requestBody, actualOrgId);
      if (response.status === 200) {
        setStatusMessage('Пользователь успешно добавлен');
        setSubmitting(false);
      }
    } catch (error: any) {
      setStatusMessage('Произошла ошибка при добавлении пользователя');
      setSubmitting(false);
    }
  };

  const handleRetry = () => {
    setStatusMessage('');
  };

  const handleClearInput = (setState: React.Dispatch<React.SetStateAction<string>>) => () => {
    setState('');
  };

  const handleClick = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  // faq

  const handleShowRoleFaqOpen = () => {
    setShowRoleFaq(true);
  };

  const handleShowRoleFaqClose = () => {
    setShowRoleFaq(false);
  };

  useEffect(() => {
    const isAllFieldsFilled =
      job.trim() !== '' &&
      phone.length === 18;
    setIsFormValid(isAllFieldsFilled);
  }, [job, phone]);

  return (
    <div className={`addOrgStaff-modal-background ${isModalOpen ? '' : 'addOrgStaff-modal-hidden'}`}>
      <div className="addOrgStaff-modal-content">
        <h1>Добавить пользователя</h1>
        <div className="addOrgStaff-modal-close" onClick={onClose}>{closeModal}</div>
        {submitting && (
          <div className='addOrgStaff-modal-notification-container'>
            {largeLoading}
            <h3 className='addOrgStaff-modal-notification-title'>Добавляем пользователя...</h3>
          </div>
        )}
        {!submitting && statusMessage && (
          <div>
            {statusMessage === 'Произошла ошибка при добавлении пользователя' ? (
              <div className='addOrgStaff-modal-notification-container'>
                {largeError}
                <h3 className='addOrgStaff-modal-notification-title'>{statusMessage}</h3>
                <div className='addOrgStaff-modal-error-buttons-container'>
                  <MediumButton text={'Попробовать снова'} onClick={handleRetry} backgroundColor={'--MyPrimary'} color={'--MyWhite'}/>
                  <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
                </div>
              </div>
            ) : (
              <div className='addOrgStaff-modal-notification-container'>
                {largeFinish}
                <h3 className='addOrgStaff-modal-notification-title'>Пользователь успешно добавлен</h3>
              </div>
            )}
          </div>
        )}
        {!statusMessage && (
        <form className='addOrgStaff-modal-form' onSubmit={() => handleClick()}>
        <div className='addOrgStaff-modal-form-inputs' >
            <label className='addOrgStaff-modal-label'>
            <span className='addOrgStaff-modal-label-title'>Фамилия</span>
              <input placeholder='Не указано'
              type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
            {lastName && (
                <div className='addOrgStaff-modal-label-clear' onClick={handleClearInput(setLastName)}>
                  {clearInput}
                </div>
            )}
            </label>
            <label className='addOrgStaff-modal-label'>
              <span className='addOrgStaff-modal-label-title'>Имя</span>
              <input placeholder='Не указано'
                type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
              {firstName && (
                <div className='addOrgStaff-modal-label-clear' onClick={handleClearInput(setFirstName)}>
                  {clearInput}
                </div>
              )}
            </label>
            {/* <label className='addOrgStaff-modal-label'>
              <span className='addOrgStaff-modal-label-title'>Отчество</span>
                <input placeholder='Не указано'
                type="text" value={surname} onChange={(e) => setSureName(e.target.value)} />
                {surname && (
                <div className='addOrgStaff-modal-label-clear' onClick={handleClearInput(setSureName)}>
                  {clearInput}
                </div>
                )}
            </label> */}
            <label className='addOrgStaff-modal-label'>
            <span className='addOrgStaff-modal-label-title'>
                Номер телефона
                <span style={{ color: '#4495D1' }}>*</span>
            </span>
              <PhoneInput
              padding='0px'
              value={phone}
              onChange={simPhoneChange}
              placeholder='+7 (___) ___-__-__'
            />
              {phone && (
                <div className='addOrgStaff-modal-label-clear' onClick={handleClearInput(setPhone)}>
                  {clearInput}
                </div>
              )}
            </label>
            <label className='addOrgStaff-modal-label addOrgStaff-modal-label-dropdown'>
              <span className='addOrgStaff-modal-label-title addOrgStaff-modal-label-withFaq'>Роль
                <span style={{ color: '#4495D1' }}>*</span>
                <span style={{ marginLeft: '8px' }}
                  onMouseEnter={handleShowRoleFaqOpen}
                  onMouseLeave={handleShowRoleFaqClose}
                >
                  {faq}
                </span>
              </span>
              <input
                readOnly
                placeholder='Не выбрано'
                type="text"
                value={job}
                onChange={handleJobInputChange}
                onClick={handleJobInputClick}
              />
              {!job ? (
                <div className='addOrgStaff-modal-label-arrow-down'>
                  {arrowHeader}
                </div>
              ) : (
                <div className='addOrgStaff-modal-label-clear' onClick={handleClearInput(setJob)}>
                  {clearInput}
                </div>
              )}
            </label>
            {isJobDropdownOpen && (
              <div
                className="addOrgStaff-modal-dropdown-container"
                style={{ display: isJobDropdownOpen ? 'block' : 'none' }}
                ref={jobDropdownRef}
              >
                <div className='addOrgStaff-modal-dropdown-content'>
                  <div onClick={() => handleJobSelect('Администратор')} className='addOrgStaff-modal-dropdown-option'>
                    <span><MyCircleCheckbox checked={job === 'Администратор'} onCheckboxChange={() => handleJobSelect('Администратор')} label={''}/></span>
                    <span>Администратор</span>
                  </div>
                  <div onClick={() => handleJobSelect('Менеджер')} className='addOrgStaff-modal-dropdown-option'>
                    <span><MyCircleCheckbox checked={job === 'Менеджер'} onCheckboxChange={() => handleJobSelect('Менеджер')} label={''}/></span>
                    <span>Менеджер</span>
                  </div>
                   <div onClick={() => handleJobSelect('Сотрудник')} className='addOrgStaff-modal-dropdown-option'>
                    <span><MyCircleCheckbox checked={job === 'Сотрудник'} onCheckboxChange={() => handleJobSelect('Сотрудник')} label={''}/></span>
                    <span>Сотрудник</span>
                  </div>
                </div>
              </div>
            )}
            {showRoleFaq && (
              <div className='addOrgStaff-modal-role-faq-container'>
                <div className='addOrgStaff-modal-role-faq-content'>
                  <div className='addOrgStaff-modal-role-faq-text'>
                    <span className='addOrgStaff-modal-role-faq-title'>Сотрудник организации:</span>
                    <span className='addOrgStaff-modal-role-faq-description'>
                      Не имеет доступа к разделу управления организацией.
                    </span>
                  </div>
                  <div className='addOrgStaff-modal-role-faq-text'>
                    <span className='addOrgStaff-modal-role-faq-title'>Менеджер организации:</span>
                    <span className='addOrgStaff-modal-role-faq-description'>
                      Может управлять данными организации, включая внесение изменений в ИНН и название, просмотр и управление списком сотрудников организации, создание новых сотрудников и редактирование данных существующих.
                    </span>
                  </div>
                  <div className='addOrgStaff-modal-role-faq-text'>
                    <span className='addOrgStaff-modal-role-faq-title'>Администратор организации:</span>
                    <span className='addOrgStaff-modal-role-faq-description'>
                      Помимо прав менеджера организации, имеет возможность создавать и изменять данные менеджеров и администраторов организации.
                    </span>
                  </div>
                </div>
              </div>)
            }
          </div>
          <div className='addOrgStaff-modal-footer'>
            <div className='addOrgStaff-modal-buttons'>
              <MediumButton disabled={!isFormValid} text={'Добавить пользователя'} color={'--MyWhite'} type='submit' backgroundColor={'--MyPrimary'}/>
              <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
            </div>
            <span><span style={{ color: '--MyPrimary' }}>*</span> Обязательные поля</span>
          </div>
        </form>)}
      </div>
    </div>
  );
};

export default AddOrgStaffModal;
