/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import './AddRateDetailedModal.css';
import {
  arrowHeader, clearInput, closeModal, largeLoading, largeError, largeFinish,
  plus,
  plusAdd,
  bucket32
} from '../../../Icons/Icons';
import 'dayjs/locale/ru';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import MediumButton from '../../buttons/MediumButton';
import LargeButtonWithIcon from '../../buttons/LargeButtonWithIcon';
import ChargeService from '../../../services/ChargeService';

interface AddRateDetailedModalProps {
  onClose: () => void;
  isModalOpen: boolean;
}

const AddRateDetailedModal: React.FC<AddRateDetailedModalProps> = ({ onClose, isModalOpen }) => {
  const [org, setOrg] = useState('');
  const [start, setStart] = useState('');
  const [parking, setParking] = useState('');
  const [rateName, setRateName] = useState('');
  const [firstTimer1, setFirstTimer1] = useState('');
  const [firstTimer2, setFirstTimer2] = useState('');
  const [secondTimer1, setSecondTimer1] = useState('');
  const [secondTimer2, setSecondTimer2] = useState('');
  const [firstTimerPrice, setFirstTimerPrice] = useState('');
  const [secondTimerPrice, setSecondTimerPrice] = useState('');
  const [showAdditionalBlock, setShowAdditionalBlock] = useState(false);
  const [blocksCount, setBlocksCount] = useState(1);

  const [submitting, setSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const [isOrgDropdownOpen, setIsOrgDropdownOpen] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false);

  const modelDropdownRef = useRef(null);


  const handleAddBlock = () => {
    setShowAdditionalBlock(true);
    setBlocksCount((prev) => prev + 1);
  };

  const handleDeleteBlock = () => {
    setShowAdditionalBlock(false);
    setBlocksCount((prev) => prev - 1);
    setSecondTimer1('');
    setSecondTimer2('');
    setSecondTimerPrice('');
  };

  // Org

  enum OrgNames {
    org1 = 'Организация №1',
    org2 = 'Организация №2',
    org3 = 'Организация №3'
  }

  const handleOrgInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrg(e.target.value);
  };

  const handleOrgInputClick = () => {
    setIsOrgDropdownOpen((prevIsModelDropdownOpen) => !prevIsModelDropdownOpen);
  };

  const handleOrgSelect = (selectedModel: string) => {
    setOrg(selectedModel);
    setIsOrgDropdownOpen(false);
  };

  // Time Rate

  const inputRef = useRef<HTMLInputElement>(null);

  const handleFocusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleSubmit = async () => {
    try {
      const elements = [
        {
          price_components: [
            {
              type: 'ENERGY',
              price: firstTimerPrice,
              step_size: 2
            }
          ],
          restrictions: {
            start_time: firstTimer1,
            end_time: firstTimer2,
            start_date: moment(Date.now()).format('YYYY-MM-DD'),
            end_date: moment(Date.now()).format('YYYY-MM-DD'),
          }
        }
      ];
      if (secondTimerPrice.trim() !== '' && secondTimer1.trim() !== '' && secondTimer2.trim() !== '') {
        elements.push({
          price_components: [
            {
              type: 'ENERGY',
              price: secondTimerPrice,
              step_size: 2
            }
          ],
          restrictions: {
            start_time: secondTimer1,
            end_time: secondTimer2,
            start_date: moment(Date.now()).format('YYYY-MM-DD'),
            end_date: moment(Date.now()).format('YYYY-MM-DD'),
          }
        });
      }

      const requestBody = {
        title: rateName,
        elements
      };

      setSubmitting(true);
      setStatusMessage('Добавляем тариф');
      const response = await ChargeService.createRate(requestBody);
      if (response.status === 200) {
        setStatusMessage('Тариф успешно создан');
        setSubmitting(false);
      }
    } catch (error: any) {
      setStatusMessage('Произошла ошибка при создании тарифа');
      setSubmitting(false);
    }
  };


  const handleRetry = () => {
    setStatusMessage('');
  };

  const handleClearInput = (setState: React.Dispatch<React.SetStateAction<string>>) => () => {
    setState('');
  };

  const handleClick = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  useEffect(() => {
    const isAllFieldsFilled =
      // org.trim() !== '' &&
      // start.trim() !== '' &&
      // parking.trim() !== '' &&
      firstTimer1.trim() !== '' &&
      firstTimer2.trim() !== '' &&
      firstTimerPrice.trim() !== '' &&
      rateName.trim() !== '';

    setIsFormValid(isAllFieldsFilled);
  }, [rateName, firstTimer1, firstTimer2, firstTimerPrice]);

  return (
    <div className={`addRateDetailed-modal-background ${isModalOpen ? '' : 'addRateDetailed-modal-hidden'}`}>
      <div className="addRateDetailed-modal-content">
        <h1>Создать тариф</h1>
        <div className="addRateDetailed-modal-close" onClick={onClose}>{closeModal}</div>
        {submitting && (
          <div className='addRateDetailed-modal-notification-container'>
            {largeLoading}
            <h3 className='addRateDetailed-modal-notification-title'>Создаем тариф...</h3>
          </div>
        )}
        {!submitting && statusMessage && (
          <div>
            {statusMessage === 'Произошла ошибка при создании тарифа' ? (
              <div className='addRateDetailed-modal-notification-container'>
                {largeError}
                <h3 className='addRateDetailed-modal-notification-title'>{statusMessage}</h3>
                <div className='addRateDetailed-modal-error-buttons-container'>
                  <MediumButton text={'Попробовать снова'}
                  onClick={handleRetry} backgroundColor={'--MyPrimary'} color={'--MyWhite'}/>
                  <MediumButton text={'Отмена'} color={'--MyPrimary'}
                  backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
                </div>
              </div>
            ) : (
              <div className='addRateDetailed-modal-notification-container'>
                {largeFinish}
                <h3 className='addRateDetailed-modal-notification-title'>Тариф успешно создан</h3>
              </div>
            )}
          </div>
        )}
        {!statusMessage && (
        <form className='addRateDetailed-modal-form' onSubmit={() => handleClick()}>
        <div className='addRateDetailed-modal-form-inputs' >
          <label className='addRateDetailed-modal-label'>
          <span className='addRateDetailed-modal-label-title'>
            Название
          </span>
            <input placeholder='Введите название'
            type="text" value={rateName} onChange={(e) => setRateName(e.target.value)} maxLength={20}/>
          {rateName && (
            <div className='addRateDetailed-modal-label-clear' onClick={handleClearInput(setRateName)}>
              {clearInput}
            </div>
          )}
          </label>
          <div className='addRateDetailed-modal-timeCost-container'>
            <div className='addRateDetailed-modal-time-container'>
              <span className='addRateDetailed-modal-time-container-title'>Время</span>
              <div className='addRateDetailed-modal-time-inputs-container'>
                <div className="addRateDetailed-modal-time-input-container" onClick={handleFocusInput}>
                  <span className='addRateDetailed-modal-time-title-label'>C</span>
                  <div className='addRateDetailed-modal-time-input'>
                    <TimePicker
                      format={'HH:mm'}
                      placeholder='00:00'
                      showSecond={false}
                      defaultOpenValue={moment().startOf('day')}
                      onChange={(time) => {
                        setFirstTimer1(time?.format('HH:mm') || '');
                      }}
                    />
                  </div>
                </div>
                <div className="addRateDetailed-modal-time-input-container" onClick={handleFocusInput}>
                  <span className='addRateDetailed-modal-time-title-label'>До</span>
                  <div className='addRateDetailed-modal-time-input'>
                    <TimePicker
                      placeholder='00:00'
                      format={'HH:mm'}
                      showSecond={false}
                      defaultOpenValue={moment().startOf('day')}
                      onChange={(time) => {
                        setFirstTimer2(time?.format('HH:mm') || '');
                      }}
                      />
                  </div>
                </div>
              </div>
            </div>
            <div className='addRateDetailed-modal-cost-container'>
              <span className='addRateDetailed-modal-time-container-title'>Цена тарифа</span>
              <div className='addRateDetailed-modal-time-inputs-container'>
                <div className="addRateDetailed-modal-time-input-container" onClick={handleFocusInput}>
                  <span className='addRateDetailed-modal-time-title-label'>Цена</span>
                  <div className='addRateDetailed-modal-time-input'>
                    <input placeholder='0 ₽/кВт•ч'
                    type="text" value={firstTimerPrice}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.length <= 5) {
                        if (/^\d*\.?\d*$/.test(value)) {
                          setFirstTimerPrice(value);
                        }
                      }
                    }}
                    maxLength={5}/>
                  </div>
                </div>
              </div>
            </div>
            {blocksCount !== 2 &&
              <LargeButtonWithIcon
                  LeftIcon={plusAdd}
                  height='44px'
                  width='44px'
                  text={''}
                  backgroundColor={'--MySecondaryBlue'}
                  onClick={handleAddBlock}
                  color={'--MyWhite'}
              />}
          </div>
          {showAdditionalBlock && <div className='addRateDetailed-modal-timeCost-container'>
            <div className='addRateDetailed-modal-time-container'>
              <span className='addRateDetailed-modal-time-container-title'>Время</span>
              <div className='addRateDetailed-modal-time-inputs-container'>
                <div className="addRateDetailed-modal-time-input-container" onClick={handleFocusInput}>
                  <span className='addRateDetailed-modal-time-title-label'>C</span>
                  <div className='addRateDetailed-modal-time-input'>
                    <TimePicker
                      format={'HH:mm'}
                      placeholder='00:00'
                      defaultOpenValue={moment().startOf('day')}
                      showSecond={false}
                      onChange={(time) => {
                        setSecondTimer1(time?.format('HH:mm') || '');
                      }}
                    />
                  </div>
                </div>
                <div className="addRateDetailed-modal-time-input-container" onClick={handleFocusInput}>
                  <span className='addRateDetailed-modal-time-title-label'>До</span>
                  <div className='addRateDetailed-modal-time-input'>
                    <TimePicker
                      placeholder='00:00'
                      format={'HH:mm'}
                      defaultOpenValue={moment().startOf('day')}
                      showSecond={false}
                      onChange={(time) => {
                        setSecondTimer2(time?.format('HH:mm') || '');
                      }}
                      />
                  </div>
                </div>
              </div>
            </div>
            <div className='addRateDetailed-modal-cost-container'>
              <span className='addRateDetailed-modal-time-container-title'>Цена тарифа</span>
              <div className='addRateDetailed-modal-time-inputs-container'>
                <div className="addRateDetailed-modal-time-input-container" onClick={handleFocusInput}>
                  <span className='addRateDetailed-modal-time-title-label'>Цена</span>
                  <div className='addRateDetailed-modal-time-input'>
                    <input placeholder='0 ₽/кВт•ч'
                    type="text" value={secondTimerPrice}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.length <= 5) {
                        if (/^\d*\.?\d*$/.test(value)) {
                          setSecondTimerPrice(value);
                        }
                      }
                    }}
                    maxLength={5} />
                  </div>
                </div>
              </div>
            </div>
            <LargeButtonWithIcon
                LeftIcon={bucket32}
                height='44px'
                width='44px'
                text={''}
                backgroundColor={'--MySecondaryBlue'}
                onClick={handleDeleteBlock}
                color={'--MyWhite'}
              />
          </div>}
          {/* <label className='addRateDetailed-modal-label'>
            <span className='addRateDetailed-modal-label-title'>
              Стоимость старта<span style={{ color: '#4495D1' }}>*</span>
            </span>
            <input placeholder='0 ₽'
              type="text" value={start} onChange={(e) => setStart(e.target.value)}
            />
            {start && (
              <div className='addRateDetailed-modal-label-clear' onClick={handleClearInput(setStart)}>
                {clearInput}
              </div>
            )}
          </label>
          <label className='addRateDetailed-modal-label'>
            <span className='addRateDetailed-modal-label-title'>
              Стоимость паркинга<span style={{ color: '#4495D1' }}>*</span>
            </span>
            <input placeholder='0 ₽/мин' type="text" value={parking} onChange={(e) => setParking(e.target.value)} />
            {parking && (
              <div className='addRateDetailed-modal-label-clear' onClick={handleClearInput(setParking)}>
                {clearInput}
              </div>
            )}
          </label>
          <label className='addRateDetailed-modal-label addRateDetailed-modal-label-dropdown'>
            <span className='addRateDetailed-modal-label-title'>
              Организация
              <span style={{ color: '#4495D1' }}>*</span>
            </span>
            <input
              readOnly
              placeholder='Выберите организацию'
              type="text"
              value={org}
              onChange={handleOrgInputChange}
              onClick={handleOrgInputClick}
              // onBlur={() => setIsModelDropdownOpen(false)}
            />
            {!org ? (
              <div className='addRateDetailed-modal-label-arrow-down'>
                {arrowHeader}
              </div>
            ) : (
              <div className='addRateDetailed-modal-label-clear' onClick={handleClearInput(setOrg)}>
                {clearInput}
              </div>
            )}
          </label> */}
          {isOrgDropdownOpen && (
              <div className="addRateDetailed-modal-dropdown addRateDetailed-modal-dropdown-long"
                style={{ display: isOrgDropdownOpen ? 'block' : 'none' }}
                ref={modelDropdownRef}
              >
                {Object.values(OrgNames).map((carName) => (
                  <div key={carName} onClick={() => handleOrgSelect(carName)}>{carName}</div>
                ))}
              </div>
          )}
        </div>
          <div className='addRateDetailed-modal-footer'>
            <div className='addRateDetailed-modal-buttons'>
                <MediumButton
                  disabled={!isFormValid}
                  text={'Создать'}
                  color={'--MyWhite'}
                  type='submit'
                  backgroundColor={'--MyPrimary'}
                />
              <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
            </div>
          </div>
        </form>)}
      </div>
    </div>
  );
};

export default AddRateDetailedModal;
