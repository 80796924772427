/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
import React, {
  useCallback,
  useContext,
  useEffect, useState
} from 'react';
import { observer } from 'mobx-react-lite';
import './CarsPage.css';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import MediumButton from '../../components/buttons/MediumButton';
import Select from '../../components/Select/Select';
import MyTable from '../../components/Table/MyTable';
import CarService from '../../services/CarService';
import { FilterResponse, CarIdResponse } from '../../models/response/CarResponse';
import LargeButtonWithIcon from '../../components/buttons/LargeButtonWithIcon';
import { addCar, transferCar } from '../../Icons/Icons';
import AddCarModal from '../../components/Modal/AddCar/AddCarModal';
import { Context } from '../..';
import TransferCarsRequestModal from '../../components/Modal/TransferCarsRequest/TransferCarsRequest';
import TransferCarModal from '../../components/Modal/TransferCar/TransferCarModal';

interface CarResponse {
  headers: any[];
  rows: any[];
  total?: number
}

const CarsPage: React.FC = () => {
  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: string[] }>({});
  const [textValues, setTextValues] = useState<{ [key: string]: string }>({});
  const [booleanValues, setBooleanValues] = useState<{ [key: string]: boolean | undefined}>({});
  const [odometerSort, setOdometerSort] = useState<{ [key: string]: any[] | string } | undefined | null>(null);
  const [prevOdometerSort, setPrevOdometerSort] = useState<{ [key: string]: any[] | string } | undefined | null>(null);

  const [showCheckboxColumn, setShowCheckboxColumn] = useState(false);

  const [carData, setCarData] = useState<CarResponse | null>(null);
  const [filteredCarData, setFilteredCarData] = useState<CarResponse | null>(null);
  const [carFiltersResponseData, setCarFiltersResponseData] = useState<FilterResponse>([]);
  const [loading, setLoading] = useState(false);
  const [prevFilters, setPrevFilters] = useState<any[]>([]);

  const [offset, setOffset] = useState(0);
  const [filteredOffset, setFilteredOffset] = useState(0);

  const [isAddCarModalOpen, setIsAddCarModalOpen] = useState(false);
  const [isTransferCarsRequestModalOpen, setIsTransferCarsRequestModalOpen] = useState(false);
  const [isTransferCarsModalOpen, setIsTransferCarsModalOpen] = useState(false);

  const navigate = useNavigate();
  const { store } = useContext(Context);

  const handleTextChange = debounce((text: string, key: string) => {
    setTextValues((prevTextValues) => ({
      ...prevTextValues,
      [key]: text,
    }));
  }, 1500);

  const handleOptionSelect = debounce((selected: string[], filterKey: string) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [filterKey]: selected,
    }));
  }, 500);

  const handleBooleanChange = debounce((value: boolean, key: string) => {
    if (booleanValues[key] !== value) {
      setBooleanValues((prevBooleanValues) => ({
        ...prevBooleanValues,
        [key]: value,
      }));
    }
  }, 500);

  const handleClearClick = debounce((filterKey: string) => {
    setTextValues((prevValues) => ({ ...prevValues, [filterKey]: '' }));
  }, 1500);

  const handleBooleanClear = debounce((filterKey: string) => {
    if (booleanValues[filterKey] !== undefined) {
      setBooleanValues((prevValues) => ({
        ...prevValues,
        [filterKey]: undefined,
      }));
    }
  }, 0);

  const handleSelectClear = debounce((filterKey: string) => {
    setSelectedOptions((prevValues) => ({
      ...prevValues,
      [filterKey]: [],
    }));
  }, 500);

  const handleToggleCheckboxColumn = () => {
    setShowCheckboxColumn((prev) => !prev);
    store.selectedIds = [];
    store.selectedCars = [];
  };

  const handleCheckboxChange = (row: CarIdResponse) => {
    store.toggleSelectedId(row._id);

    const selectedCar = {
      carId: row._id,
      carModel: row.carModel.name,
      carVin: row.vin
    };

    const existingIndex = store.selectedCars.findIndex((car) => car.carId === selectedCar.carId);
    if (existingIndex !== -1) {
      store.selectedCars.splice(existingIndex, 1);
    } else {
      store.selectedCars.push(selectedCar);
    }
  };

  // Модалка добавление машины

  const handleAddCarModalOpen = () => {
    setIsAddCarModalOpen(true);
  };

  const handleAddCarModalClose = () => {
    setIsAddCarModalOpen(false);
  };

  // Модалка переноса машин

  const handleTransferCarRequestCarModalOpen = () => {
    setIsTransferCarsRequestModalOpen(true);
  };

  const handleTransferCarsRequestModalNext = () => {
    setIsTransferCarsRequestModalOpen(false);
    setIsTransferCarsModalOpen(true);
  };

  const handleTransferCarsRequestModalClose = () => {
    setIsTransferCarsRequestModalOpen(false);
    setIsTransferCarsModalOpen(false);
  };

  const handleTransferCarModalClose = () => {
    setIsTransferCarsModalOpen(false);
    setShowCheckboxColumn(false);
  };

  // Фильтры

  const fetchFilters = async () => {
    setLoading(true);
    try {
      const response = await CarService.getFilters();
      if (response.status !== 401) {
        setCarFiltersResponseData(response.data);
      }
    } catch (error) {
      // console.error('Ошибка при загрузке фильтров:', error);
    } finally {
      setLoading(false);
    }
  };

  const filteredData = async () => {
    setLoading(true);
    try {
      const newFilters: any = [];
      const uniqueFilters = new Set();

      for (const filter of carFiltersResponseData) {
        if (
          (filter.type === 'MULTISELECT' && selectedOptions[filter.key]?.length > 0) ||
          (filter.type === 'SELECT' && selectedOptions[filter.key]?.length > 0) ||
          (filter.type === 'TEXT' && textValues[filter.key]) ||
          (filter.type === 'BOOLEAN' && booleanValues[filter.key] !== undefined)
        ) {
          const filterKey = filter.key;

          if (!uniqueFilters.has(filterKey)) {
            uniqueFilters.add(filterKey);

            let values;
            if (filter.type === 'MULTISELECT') {
              values = selectedOptions[filter.key];
            } else if (filter.type === 'SELECT') {
              values = selectedOptions[filter.key];
            } else if (filter.type === 'TEXT') {
              values = [textValues[filter.key]];
            } else if (filter.type === 'BOOLEAN') {
              values = [booleanValues[filter.key]];
            }

            newFilters.push({
              key: filter.key,
              values,
            });
          }
        }
      }

      if (odometerSort) {
        newFilters.push(odometerSort);
      }

      store.setCarFilters(newFilters);

      const filtersChanged = !isEqual(prevFilters, newFilters);

      const odometerSortChanged = odometerSort !== prevOdometerSort;

      if (filtersChanged || odometerSortChanged || odometerSort === undefined) {
        setFilteredCarData(null);
        setFilteredOffset(0);
      }

      const newOffset = filtersChanged || odometerSortChanged || odometerSort === undefined ? 0 : filteredOffset;

      if (newFilters.length > 0 || odometerSort) {
        const responseFilter = await CarService.getCars(newFilters, 50, newOffset);
        if (responseFilter) {
          setOffset(0);
          setCarData(null);
          setFilteredCarData((prevData) => ({
            headers: responseFilter?.data.headers,
            rows: [...(filtersChanged || odometerSortChanged ? [] : prevData?.rows || []), ...responseFilter.data.rows],
            total: responseFilter?.data.total,
          }));
          setFilteredOffset((prev) => prev + 50);
          if (odometerSort === undefined) {
            setOdometerSort(null);
            setPrevOdometerSort(odometerSort);
          }
        }
      } else {
        setLoading(true);
        const response = await CarService.getCars([], 50, offset);
        if (response) {
          setFilteredOffset(0);
          setFilteredCarData(null);
          setCarData((prevData) => ({
            headers: response?.data.headers,
            rows: [...(prevData?.rows || []), ...response.data.rows],
            total: response?.data.total,
          }));
          setOffset((prev) => prev + 50);
        }
      }
      setPrevOdometerSort(odometerSort);
    } catch (error) {
      //
    } finally {
      setLoading(false);
    }
  };

  const handleOdometerFilter = () => {
    setOdometerSort((prevSort) => {
      if (!prevSort) {
        return {
          values: ['ODOMETER', -1],
          key: 'SORT',
        };
      }
      if (prevSort.values[1] === -1) {
        return {
          values: ['ODOMETER', 1],
          key: 'SORT',
        };
      }
      return undefined;
    });
  };

  useEffect(() => {
    setPrevFilters(store.carFilters);
  }, [store.carFilters]);

  useEffect(() => {
    fetchFilters();
    filteredData();
  }, []);

  useEffect(() => {
    setPrevOdometerSort(odometerSort);
  }, [odometerSort]);

  const handleReset = () => {
    store.setShouldWipeFilters(true);
    setTextValues({});
    setSelectedOptions({});
    setBooleanValues({});
    // setOdometerSort(null);
    setFilteredOffset(0);
    setOffset(0);
  };

  const debounceFilteredData = debounce(filteredData, 500);

  const areAllSelectedOptionsEmpty = Object.values(selectedOptions).every((array) => array.length === 0);
  const isAnyTextValueNotEmpty = Object.values(textValues).some((value) => value.trim() !== '');
  const isAnyBooleanValueNotEmpty = Object.values(booleanValues).some((value) => value !== undefined && value !== null);

  const shouldShowResetFilters =
  !areAllSelectedOptionsEmpty ||
  isAnyTextValueNotEmpty ||
  isAnyBooleanValueNotEmpty;

  useEffect(() => {
    if (
      (Object.keys(selectedOptions).length > 0 && areAllSelectedOptionsEmpty) ||
      Object.keys(selectedOptions).length > 0 ||
      (Object.keys(textValues).length > 0 && isAnyTextValueNotEmpty) ||
      Object.keys(textValues).length > 0 ||
      (isAnyBooleanValueNotEmpty && Object.keys(booleanValues).length > 0) ||
      Object.keys(booleanValues).length > 0 || odometerSort
    ) {
      debounceFilteredData();
      store.setShouldWipeFilters(false);
    }
  }, [selectedOptions, textValues, booleanValues, odometerSort]);

  return (
    <>
      <div className="carsPage-topLine">
        <div className='carsPage-topLine-title'>
          <h2>Автомобили</h2>
          {carData && carData?.total ? <span>{`(${carData.total})`}</span> : ''}
          {filteredCarData && filteredCarData?.total ? <span>{`(${filteredCarData.total})`}</span> : ''}
        </div>
        <div className='carsPage-topLine-buttons-group'>
          {/* <MediumButton text={'Отчет передачи'} color={MyPrimary} backgroundColor={MySecondaryBlue}/>
          <MediumButton text={'Отчет дилера'} color={MyPrimary} backgroundColor={MySecondaryBlue}/>
          <MediumButton text={'Отчет зарядки'} color={MyPrimary} backgroundColor={MySecondaryBlue}/>
          <MediumButton text={'Отчет поездки'} color={MyPrimary} backgroundColor={MySecondaryBlue}/> */}
          {!showCheckboxColumn ? (
            <>
            {!store.isLoading && (
              <>
                <LargeButtonWithIcon
                  color={'--MyWhite'}
                  backgroundColor={'--MyPrimary'}
                  text={'Сменить владельца'}
                  iconMarginRight='8px'
                  LeftIcon={transferCar}
                  onClick={handleToggleCheckboxColumn}
                  padding='12px 8px'
                />
                {store.user && store.user.buttons && store.user.buttons.addCar && (
                  <LargeButtonWithIcon
                    color={'--MyWhite'}
                    backgroundColor={'--MyPrimary'}
                    text={'Добавить'}
                    LeftIcon={addCar}
                    iconMarginRight='8px'
                    onClick={handleAddCarModalOpen}
                    padding='12px 8px'
                  />
                )}
              </>
            )}
              {isAddCarModalOpen && (
                <AddCarModal onClose={handleAddCarModalClose} isModalOpen={isAddCarModalOpen} />
              )}
            </>
          ) : (
            <>
              <MediumButton text={'Отменить'}
                color={'--MyPrimary'}
                backgroundColor={'--MySecondaryBlue'}
                onClick={handleToggleCheckboxColumn}/>
              <MediumButton
                text={`Передать (${store.selectedIds.length})`}
                disabled={store.selectedIds.length === 0}
                onClick={handleTransferCarRequestCarModalOpen}
                backgroundColor={'--MyPrimary'}
                color={'--MyWhite'}/>
              {isTransferCarsRequestModalOpen && (
                <TransferCarsRequestModal
                  onClose={handleTransferCarsRequestModalClose}
                  onNext={handleTransferCarsRequestModalNext}
                  isModalOpen={isTransferCarsRequestModalOpen}
                  title={`Передать автомобили (${store.selectedIds.length})`}
                  description={'Удостоверьтесь, что выбраны правильные автомобили'}
                  accessOption={'Далее'}
                  selectedCars={store.selectedCars.map((car) => ({
                    carId: car.carId,
                    carModel: car.carModel,
                    carVin: car.carVin,
                  }))}
                />
              )}
              {isTransferCarsModalOpen && (
                <TransferCarModal
                successTransferMessage={'Автомобили успешно переданы'}
                onClose={handleTransferCarModalClose}
                isModalOpen={isTransferCarsModalOpen}
                  title={'Передать автомобили'}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div style={{ marginBottom: '20px' }}></div>
      <div style={{ display: (carData?.total !== 0 || filteredCarData?.rows) ? '' : 'none' }} className="carsPage-filters-buttons">
        {carFiltersResponseData && carFiltersResponseData.length > 0 && (
          <>
            {carFiltersResponseData.map((filter, index) => (
            <div key={index}>
            {filter.type === 'MULTISELECT' && (
              <Select
                disabled={loading}
                label={filter.name || filter.key}
                options={filter.options}
                selectedOptions={selectedOptions[filter.key] || []}
                onOptionSelect={(selected) => handleOptionSelect(selected, filter.key)}
                type={filter.type}
                filterKey={filter.key}
              />
            )}
            {filter.type === 'TEXT' && (
              <Select
                disabled={loading}
                label={filter.name || filter.key}
                options={[]}
                selectedOptions={[]}
                onClearClick={() => handleClearClick(filter.key)}
                onOptionSelect={(value: string) => handleTextChange(value, filter.key)}
                type={filter.type}
                filterKey={filter.key}
              />
            )}
            {filter.type === 'BOOLEAN' && (
              <Select
                disabled={loading}
                label={filter.name || filter.key}
                options={[]}
                onClearClick={() => handleBooleanClear(filter.key)}
                onOptionSelect={(value: boolean) => handleBooleanChange(value, filter.key)}
                type={filter.type}
                filterKey={filter.key}
              />
            )}
            {filter.type === 'SELECT' && (
              <Select
                disabled={loading}
                label={filter.name || filter.key}
                options={filter.options}
                onClearClick={() => handleSelectClear(filter.key)}
                onOptionSelect={(selected) => handleOptionSelect(selected, filter.key)}
                type={filter.type}
                filterKey={filter.key}
              />
            )}
          </div>
            ))}
          </>
        )}
        {shouldShowResetFilters && (
          <LargeButtonWithIcon
            onClick={handleReset}
            text={'Сбросить фильтры'}
            color={'--MyPrimary'}
            backgroundColor={'--MySecondaryBlue'}
            padding='7px 12px'
          />
        )}
      </div>
      <div style={{ marginBottom: '24px', display: ((carData?.total !== 0 || filteredCarData?.rows)) ? '' : 'none' }}></div>
      <div className='carsPage-table'>
        {carData && (
          <div>
            <MyTable
              hasMore={carData.total !== undefined && carData.total > carData.rows.length}
              // hasMore={false}
              fetchMore={debounceFilteredData}
              headers={carData.headers}
              data={carData.rows}
              showCheckboxes={showCheckboxColumn}
              selectedIds={store.selectedIds}
              onRowClick={(rowData, event) => {
                if (showCheckboxColumn) {
                  event?.preventDefault();
                  handleCheckboxChange(rowData);
                } else {
                  const href = `/fleet/car/${rowData._id}`;
                  if (event?.ctrlKey || event?.metaKey || event?.button === 1) {
                    event.stopPropagation();
                  } else {
                    event?.preventDefault();
                    navigate(href);
                  }
                }
              }}
              tableHref={(rowData) => `/fleet/car/${rowData._id}`}
              onCheckboxChange={(row) => handleCheckboxChange(row)}
              loading={loading}
              onOdometerClick={handleOdometerFilter}
            />
            <div id="cars-table-bottom-id" style={{ height: '10px' }}></div>
          </div>)}
        {filteredCarData && (
          <div>
            <MyTable
              hasMore={filteredCarData.total !== undefined
                && filteredCarData.total > filteredCarData.rows.length}
              fetchMore={debounceFilteredData}
              headers={filteredCarData.headers}
              data={filteredCarData.rows}
              showCheckboxes={showCheckboxColumn}
              selectedIds={store.selectedIds}
              onRowClick={(rowData, event) => {
                if (showCheckboxColumn) {
                  event?.preventDefault();
                  handleCheckboxChange(rowData);
                } else {
                  const href = `/fleet/car/${rowData._id}`;
                  if (event?.ctrlKey || event?.metaKey || event?.button === 1) {
                    event.stopPropagation();
                  } else {
                    event?.preventDefault();
                    navigate(href);
                  }
                }
              }}
              tableHref={(rowData) => `/fleet/car/${rowData._id}`}
              onCheckboxChange={(row) => handleCheckboxChange(row)}
              loading={loading}
              onOdometerClick={handleOdometerFilter}
            />
            <div id="cars-table-bottom-id" style={{ height: '10px' }}></div>
          </div>)}
      </div>
    </>
  );
};

export default observer(CarsPage);
