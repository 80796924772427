/* eslint-disable no-lone-blocks */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import './EditStationModal.css';
import { isEqual } from 'lodash';
import {
  clearInput, closeModal, largeLoading, largeError, largeFinish,
  plusAdd,
  connectors28pxIconsForChargeCreate,
  minus,
  arrowHeader
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import LargeButtonWithIcon from '../../buttons/LargeButtonWithIcon';
import {
  ChargeVoltage, ChargeSocketType, ChargerCreate, Station,
  EVSECreate,
  Evse,
  ConnectorCreate,
  Connector
} from '../../../models/response/ChargeResponse';
import { OCPIConnectorsMap, OCPIConnectorsMapReversed } from '../../Table/MyTable';
import ChargeService from '../../../services/ChargeService';


interface EditStationModalModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  stationInfo: Station;
}

const EditStationModal: React.FC<EditStationModalModalProps> = ({ onClose, isModalOpen, stationInfo }) => {
  const [initialEVSEState, setInitialEVSEState] = useState<any | null>(stationInfo);
  const [stationId, setStationId] = useState<string>(stationInfo.identifier);
  const [stationAddress, setStationAddress] = useState<string>(stationInfo.address);
  const [stationName, setStationName] = useState<string>(stationInfo.name);
  const [stationModel, setStationModel] = useState<string>(stationInfo.model);
  const [stationVendor, setStationVendor] = useState<string>(stationInfo.vendor);
  const [stationPrice, setStationPrice] = useState<string>(String(stationInfo.incomePrice));
  const [stationCity, setStationCity] = useState<string>(stationInfo.city);
  const [stationLong, setStationLong] = useState<string>(stationInfo.location.longitude);
  const [stationLat, setStationLat] = useState<string>(stationInfo.location.latitude);

  const [selectedEvseIndex, setSelectedEvseIndex] = useState<number | null>(null);
  const [selectedConnectorIndex, setSelectedConnectorIndex] = useState<number | null>(null);

  const [isConnectorDropdownOpen, setIsConnectorDropdownOpen] = useState(false);
  const [isConnectorVoltageDropdownOpen, setIsConnectorVoltageDropdownOpen] = useState(false);
  const [isConnectorSocketTypeDropdownOpen, setIsConnectorSocketTypeDropdownOpen] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const [isFormValid, setIsFormValid] = useState(false);
  const connectorDropdownRef = useRef(null);
  const connectorVoltageDropdownRef = useRef(null);
  const connectorSocketTypeDropdownRef = useRef(null);

  const [EVSECount, setEVSECount] = useState<number>(stationInfo.evses.length);
  const [connectorCounts, setConnectorCounts] = useState<Record<number, number>>(() => {
    const counts: Record<number, number> = {};
    stationInfo.evses.forEach((evse, index) => {
      counts[index] = evse.connectors.length;
    });
    return counts;
  });

  const initialEvsesRef = useRef(stationInfo.evses);

  const [selectedConnectors, setSelectedConnectors] = useState<Record<string, string | null>>(() => {
    const connectors: Record<string, string | null> = {};
    stationInfo.evses.forEach((evse, evseIndex) => {
      evse.connectors.forEach((connector, connectorIndex) => {
        connectors[`${evseIndex}-${connectorIndex}`] = connector.type;
      });
    });
    return connectors;
  });

  const [connectorPowers, setConnectorPowers] = useState<Record<number, string[]>>(() => {
    const powers: Record<number, string[]> = {};
    stationInfo.evses.forEach((evse, index) => {
      powers[index] = evse.connectors.map((connector) => connector.power.toString());
    });
    return powers;
  });

  const [connectorTypes, setConnectorTypes] = useState<string[][]>(() => stationInfo.evses.map((evse) => evse.connectors.map((connector) => connector.type)));

  const [connectorVoltageType, setConnectorVoltageType] = useState<string[][]>(() => stationInfo.evses.map((evse) => evse.connectors.map((connector) => connector.powerType)));

  const [connectorSocketType, setConnectorSocketType] = useState<string[][]>(() => stationInfo.evses.map((evse) => evse.connectors.map((connector) => connector.format)));

  // const [connectorId, setConnectorId] = useState<string[][]>(() => stationInfo.evses.map((evse) => evse.connectors.map((connector) => connector._id)));



  const handlePowerChange = (evseIndex: number, connectorIndex: number, value: string) => {
    const sanitizedValue = value.replace(/[^0-9.-]/g, '');

    setConnectorPowers((prevPowers) => ({
      ...prevPowers,
      [evseIndex]: prevPowers[evseIndex].map((power, index) => (index === connectorIndex ? sanitizedValue : power)),
    }));
  };

  const handleConnectorInputClick = (evseIndex: number, connectorIndex: number) => {
    setSelectedEvseIndex(evseIndex);
    setSelectedConnectorIndex(connectorIndex);
    setIsConnectorDropdownOpen((prevIsOpen) => !prevIsOpen);
    setIsConnectorVoltageDropdownOpen(false);
    setIsConnectorSocketTypeDropdownOpen(false);
  };

  const handleVoltageInputClick = (evseIndex: number, connectorIndex: number) => {
    setSelectedEvseIndex(evseIndex);
    setSelectedConnectorIndex(connectorIndex);
    setIsConnectorVoltageDropdownOpen((prevIsOpen) => !prevIsOpen);
    setIsConnectorDropdownOpen(false);
    setIsConnectorSocketTypeDropdownOpen(false);
  };

  const handleSocketTypeInputClick = (evseIndex: number, connectorIndex: number) => {
    setSelectedEvseIndex(evseIndex);
    setSelectedConnectorIndex(connectorIndex);
    setIsConnectorSocketTypeDropdownOpen((prevIsOpen) => !prevIsOpen);
    setIsConnectorDropdownOpen(false);
    setIsConnectorVoltageDropdownOpen(false);
  };

  const newEVSEState = {
    evses: [...Array(EVSECount)].map((_, evseIndex) => ({
      uid: initialEVSEState.evses[evseIndex]?.uid || undefined,
      connectors: [...Array(connectorCounts[evseIndex] || 1)].map((empty, connectorIndex) => ({
        id: initialEVSEState.evses[evseIndex]?.connectors[connectorIndex]?.id || undefined,
        standard: connectorTypes[evseIndex][connectorIndex],
        max_electric_power: Number(connectorPowers[evseIndex][connectorIndex]),
        power_type: connectorVoltageType[evseIndex][connectorIndex],
        format: connectorSocketType[evseIndex][connectorIndex],
      }))
    }))
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    const hasEVSEChanged = JSON.stringify(newEVSEState.evses.map((evse) => ({
      connectors: evse.connectors.map((connector) => ({
        standard: connector.standard,
        max_electric_power: connector.max_electric_power,
        power_type: connector.power_type,
        format: connector.format,
      }))
    }))) !== JSON.stringify(initialEVSEState.evses.map((evse: Evse) => ({
      connectors: evse.connectors.map((connector: Connector) => ({
        standard: connector.type,
        max_electric_power: connector.power,
        power_type: connector.powerType,
        format: connector.format,
      }))
    })));

    const station: ChargerCreate = {
      coordinates:
        stationLat !== stationInfo.location.latitude || stationLong !== stationInfo.location.longitude
          ? {
            latitude: parseFloat(stationLat),
            longitude: parseFloat(stationLong),
          }
          : undefined,
      model: stationModel !== stationInfo.model ? stationModel : undefined,
      vendor: stationVendor !== stationInfo.vendor ? stationVendor : undefined,
      identifier: stationId !== stationInfo.identifier ? stationId : undefined,
      city: stationCity !== stationInfo.city ? stationCity : undefined,
      name: stationName !== stationInfo.name ? stationName : undefined,
      address: stationAddress !== stationInfo.address ? stationAddress : undefined,
      incomePrice: stationPrice !== String(stationInfo.incomePrice) ? stationPrice : undefined
    };
    if (hasEVSEChanged) {
      station.evses = newEVSEState.evses;
    }
    try {
      const response = await ChargeService.editStation(stationInfo._id, station);
      if (response.status === 200 || response.status === 201) {
        setStatusMessage('Станция успешно отредактирована');
        setSubmitting(false);
      }
    } catch (error) {
      setStatusMessage('Произошла ошибка при редактировании станции');
      setSubmitting(false);
    }
  };

  const handleRetry = () => {
    setStatusMessage('');
  };

  const handleClearInput = (setState: React.Dispatch<React.SetStateAction<string>>) => () => {
    setState('');
  };

  // Коннекторы

  const handleConnectorClick = (key: string, rowIndex: number) => {
    setSelectedConnectors((prevState) => ({
      ...prevState,
      [rowIndex]: key === prevState[rowIndex] ? null : key,
    }));
  };

  const handleAddEVSE = () => {
    setEVSECount((prevCount) => {
      const newEVSECount = prevCount + 1;

      setConnectorCounts((prevCounts) => ({
        ...prevCounts,
        [newEVSECount - 1]: 1,
      }));

      setConnectorPowers((prevPowers) => ({
        ...prevPowers,
        [newEVSECount - 1]: [''],
      }));

      setConnectorTypes((prevTypes) => {
        const newTypes = [...prevTypes];
        newTypes[newEVSECount - 1] = [''];
        return newTypes;
      });

      setConnectorVoltageType((prevVoltageTypes) => {
        const newVoltageTypes = [...prevVoltageTypes];
        newVoltageTypes[newEVSECount - 1] = [''];
        return newVoltageTypes;
      });

      setConnectorSocketType((prevSocketTypes) => {
        const newSocketTypes = [...prevSocketTypes];
        newSocketTypes[newEVSECount - 1] = [''];
        return newSocketTypes;
      });

      return newEVSECount;
    });
  };

  const handleAddConnector = (evseIndex: number) => {
    setConnectorCounts((prevCounts) => ({
      ...prevCounts,
      [evseIndex]: (prevCounts[evseIndex] || 1) + 1,
    }));

    setConnectorPowers((prevPowers) => {
      const newPowers = Array.isArray(prevPowers[evseIndex]) ? [...prevPowers[evseIndex]] : [];
      newPowers.push('');
      return {
        ...prevPowers,
        [evseIndex]: newPowers,
      };
    });

    setConnectorTypes((prevTypes) => {
      const newTypes = Array.isArray(prevTypes) ? [...prevTypes] : [];
      if (!Array.isArray(newTypes[evseIndex])) {
        newTypes[evseIndex] = [];
      }
      newTypes[evseIndex].push('');
      return newTypes;
    });

    setConnectorSocketType((prevSocketType) => {
      const newSocketTypes = Array.isArray(prevSocketType) ? [...prevSocketType] : [];
      if (!Array.isArray(newSocketTypes[evseIndex])) {
        newSocketTypes[evseIndex] = [];
      }
      newSocketTypes[evseIndex].push('');
      return newSocketTypes;
    });

    setConnectorVoltageType((prevVoltageType) => {
      const newVoltageTypes = Array.isArray(prevVoltageType) ? [...prevVoltageType] : [];
      if (!Array.isArray(newVoltageTypes[evseIndex])) {
        newVoltageTypes[evseIndex] = [];
      }
      newVoltageTypes[evseIndex].push('');
      return newVoltageTypes;
    });
  };

  const handleRemoveConnector = (evseIndex: number, connectorIndex: number) => {
    setConnectorCounts((prevCounts) => {
      const newCount = (prevCounts[evseIndex] || 1) - 1;
      if (newCount <= 0) {
        const { [evseIndex]: _, ...restCounts } = prevCounts;
        return restCounts;
      }
      return {
        ...prevCounts,
        [evseIndex]: newCount,
      };
    });

    setConnectorPowers((prevPowers) => {
      const newPowers = Array.isArray(prevPowers[evseIndex]) ? [...prevPowers[evseIndex]] : [];
      if (connectorIndex < newPowers.length) {
        newPowers.splice(connectorIndex, 1);
      }
      return {
        ...prevPowers,
        [evseIndex]: newPowers,
      };
    });

    setConnectorTypes((prevTypes) => {
      const newTypes = Array.isArray(prevTypes) ? [...prevTypes] : [];
      if (Array.isArray(newTypes[evseIndex])) {
        newTypes[evseIndex].splice(connectorIndex, 1);
      }
      return newTypes;
    });

    setConnectorSocketType((prevSocketType) => {
      const newSocketTypes = Array.isArray(prevSocketType) ? [...prevSocketType] : [];
      if (Array.isArray(newSocketTypes[evseIndex])) {
        newSocketTypes[evseIndex].splice(connectorIndex, 1);
      }
      return newSocketTypes;
    });

    setConnectorVoltageType((prevVoltageType) => {
      const newVoltageTypes = Array.isArray(prevVoltageType) ? [...prevVoltageType] : [];
      if (Array.isArray(newVoltageTypes[evseIndex])) {
        newVoltageTypes[evseIndex].splice(connectorIndex, 1);
      }
      return newVoltageTypes;
    });
  };

  const handleRemoveEVSE = (evseIndex: number) => {
    if (EVSECount > 1) {
      setEVSECount((prevCount) => prevCount - 1);

      setConnectorCounts((prevCounts) => {
        const updatedCounts = { ...prevCounts };
        delete updatedCounts[evseIndex];

        const newCounts = Object.keys(updatedCounts)
          .map((key) => Number(key))
          .sort((a, b) => a - b)
          .reduce((acc, key, idx) => {
            acc[idx] = updatedCounts[key];
            return acc;
          }, {} as Record<number, number>);

        return newCounts;
      });

      setConnectorPowers((prevPowers) => {
        const updatedPowers = { ...prevPowers };
        delete updatedPowers[evseIndex];

        const newPowers = Object.keys(updatedPowers)
          .map((key) => Number(key))
          .sort((a, b) => a - b)
          .reduce((acc, key, idx) => {
            acc[idx] = updatedPowers[key];
            return acc;
          }, {} as Record<number, string[]>);

        return newPowers;
      });

      setConnectorTypes((prevTypes) => {
        const updatedTypes = [...prevTypes];
        updatedTypes.splice(evseIndex, 1);
        return updatedTypes;
      });

      setConnectorSocketType((prevTypes) => {
        const updatedTypes = [...prevTypes];
        updatedTypes.splice(evseIndex, 1);
        return updatedTypes;
      });

      setConnectorVoltageType((prevTypes) => {
        const updatedTypes = [...prevTypes];
        updatedTypes.splice(evseIndex, 1);
        return updatedTypes;
      });
    }
  };

  const handleConnectorTypeSelect = (evseIndex: number, connectorIndex: number, type: string) => {
    setConnectorTypes((prev) => {
      const newConnectorTypes = [...prev];
      if (!newConnectorTypes[evseIndex]) {
        newConnectorTypes[evseIndex] = [];
      }
      if (connectorIndex >= newConnectorTypes[evseIndex].length) {
        newConnectorTypes[evseIndex].length = connectorIndex + 1;
      }
      newConnectorTypes[evseIndex][connectorIndex] = type;
      return newConnectorTypes;
    });
    setIsConnectorDropdownOpen(false);
  };

  const handleSocketTypeSelect = (evseIndex: number, connectorIndex: number, type: string) => {
    setConnectorSocketType((prev) => {
      const newSocketTypes = [...prev];
      if (!newSocketTypes[evseIndex]) {
        newSocketTypes[evseIndex] = [];
      }
      if (connectorIndex >= newSocketTypes[evseIndex].length) {
        newSocketTypes[evseIndex].length = connectorIndex + 1;
      }
      newSocketTypes[evseIndex][connectorIndex] = type;
      return newSocketTypes;
    });
    setIsConnectorSocketTypeDropdownOpen(false);
  };

  const handleVoltageTypeSelect = (evseIndex: number, connectorIndex: number, type: string) => {
    setConnectorVoltageType((prev) => {
      const newVoltageTypes = [...prev];
      if (!newVoltageTypes[evseIndex]) {
        newVoltageTypes[evseIndex] = [];
      }
      if (connectorIndex >= newVoltageTypes[evseIndex].length) {
        newVoltageTypes[evseIndex].length = connectorIndex + 1;
      }
      newVoltageTypes[evseIndex][connectorIndex] = type;
      return newVoltageTypes;
    });
    setIsConnectorVoltageDropdownOpen(false);
  };

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const sanitizedValue = value.replace(/[^0-9.-]/g, '');
    setter(sanitizedValue);
  };

  useEffect(() => {
    if (stationInfo) {
      const evsesCopy = JSON.parse(JSON.stringify(stationInfo.evses));

      setInitialEVSEState({
        evses: evsesCopy.map((evse: Evse) => ({
          uid: evse.uid,
          connectors: evse.connectors.map((connector: Connector) => ({
            standard: connector.type,
            max_electric_power: connector.power,
            power_type: connector.powerType,
            format: connector.format,
            id: connector._id
          }))
        }))
      });
    }
  }, [stationInfo]);

  useEffect(() => {
    const isAllFieldsFilled =
      stationAddress.trim() !== '' &&
      stationCity.trim() !== '' &&
      stationLat.trim() !== '' &&
      stationLong.trim() !== '' &&
      stationName.trim() !== '' &&
      stationId.trim() !== '' &&
      stationModel.trim() !== '' &&
      stationVendor.trim() !== '' &&
      String(stationPrice).trim() !== '' &&
      Object.values(connectorPowers).flat().every((power) => power.trim() !== '') &&
      Object.values(connectorTypes).flat().every((type) => type.trim() !== '') &&
      Object.values(connectorSocketType).flat().every((socket) => socket.trim() !== '') &&
      Object.values(connectorVoltageType).flat().every((voltage) => voltage.trim() !== '');

    const initialLat = String(stationInfo.location.latitude);
    const initialLong = String(stationInfo.location.longitude);
    const currentLat = String(stationLat);
    const currentLong = String(stationLong);

    const hasEVSEChanged = JSON.stringify(newEVSEState.evses) !== JSON.stringify(initialEVSEState.evses);
    const hasCoordinatesChanged =
    currentLat !== initialLat ||
    currentLong !== initialLong;

    setIsFormValid(isAllFieldsFilled && (
      hasEVSEChanged ||
    stationModel !== stationInfo.model ||
    stationVendor !== stationInfo.vendor ||
    stationId !== stationInfo.identifier ||
    stationCity !== stationInfo.city ||
    stationName !== stationInfo.name ||
    stationAddress !== stationInfo.address ||
    stationPrice !== String(stationInfo.incomePrice) ||
    hasCoordinatesChanged
    ));
  }, [
    stationAddress,
    stationCity,
    stationLat,
    stationLong,
    stationName,
    stationId,
    stationModel,
    stationVendor,
    stationPrice,
    connectorPowers,
    connectorTypes,
    connectorSocketType,
    connectorVoltageType,
    initialEVSEState,
    newEVSEState,
  ]);

  return (
    <div className={`editStation-modal-background ${isModalOpen ? '' : 'editStation-modal-hidden'}`}>
      <div className='editStation-modal'>
        <div className="editStation-modal-content">
          <h2>Редактировать станцию</h2>
          <div className="editStation-modal-close" onClick={onClose}>{closeModal}</div>
          {submitting && (
            <div className='editStation-modal-notification-container'>
              {largeLoading}
              <h3 className='editStation-modal-notification-title'>Вносим изменения в станцию</h3>
            </div>
          )}
          {!submitting && statusMessage && (
            <div>
              {statusMessage === 'Произошла ошибка при редактировании станции' ? (
                <div className='editStation-modal-notification-container'>
                  {largeError}
                  <h3 className='editStation-modal-notification-title'>{statusMessage}</h3>
                  <div className='editStation-modal-error-buttons-container'>
                    <MediumButton text={'Попробовать снова'} onClick={handleRetry} backgroundColor={'--MyPrimary'} color={'--MyWhite'} />
                    <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
                  </div>
                </div>
              ) : (
                <div className='editStation-modal-notification-container'>
                  {largeFinish}
                  <h3 className='editStation-modal-notification-title'>Станция успешно отредактирована</h3>
                </div>
              )}
            </div>
          )}
          {!statusMessage && !submitting && (
            <form className='editStation-modal-form' onSubmit={handleSubmit}>
              <div className='editStation-modal-form-inputs'>
                <label className='editStation-modal-label'>
                  <span className='editStation-modal-label-title'>Адрес</span>
                  <input
                    placeholder='Введите адрес'
                    type="text"
                    value={stationAddress}
                    onChange={(e) => setStationAddress(e.target.value)}
                  />
                  {stationAddress && (
                    <div className='editStation-modal-label-clear' onClick={handleClearInput(setStationAddress)}>
                      {clearInput}
                    </div>
                  )}
                </label>
                <label className='editStation-modal-label'>
                  <span className='editStation-modal-label-title'>Город</span>
                  <input
                    placeholder='Введите город'
                    type="text"
                    value={stationCity}
                    onChange={(e) => setStationCity(e.target.value)}
                  />
                  {stationCity && (
                    <div className='editStation-modal-label-clear' onClick={handleClearInput(setStationCity)}>
                      {clearInput}
                    </div>
                  )}
                </label>
                <div className='editStation-modal-label-row'>
                  <label className='editStation-modal-label editStation-modal-label-short'>
                    <span className='editStation-modal-label-title'>
                      Координаты
                    </span>
                  </label>
                  <div className='editStation-modal-label-CoordinatesRow'>
                    <label className='editStation-modal-labelWithDes'>
                      <span className='editStation-modal-labelWithDes-title'>Долгота</span>
                      <span className='editStation-modal-labelWithDes-value'>
                        <input
                          placeholder='00.0000'
                          type="text"
                          value={stationLat}
                          onChange={handleInputChange(setStationLat)}
                        />
                      </span>
                    </label>
                    <span>-</span>
                    <label className='editStation-modal-labelWithDes'>
                      <span className='editStation-modal-labelWithDes-title'>Широта</span>
                      <span className='editStation-modal-labelWithDes-value'>
                        <input
                          placeholder='00.0000'
                          type="text"
                          value={stationLong}
                          onChange={handleInputChange(setStationLong)}
                        />
                      </span>
                    </label>
                  </div>
                </div>
                <label className='editStation-modal-label'>
                  <span className='editStation-modal-label-title'>Название станции</span>
                  <input
                    placeholder='Введите название'
                    type="text"
                    value={stationName}
                    onChange={(e) => setStationName(e.target.value)}
                  />
                  {stationName && (
                    <div className='editStation-modal-label-clear' onClick={handleClearInput(setStationName)}>
                      {clearInput}
                    </div>
                  )}
                </label>
                <label className='editStation-modal-label'>
                  <span className='editStation-modal-label-title'>Серийный номер</span>
                  <input
                    placeholder='Введите номер'
                    type="text"
                    value={stationId}
                    onChange={(e) => setStationId(e.target.value)}
                  />
                  {stationId && (
                    <div className='editStation-modal-label-clear' onClick={handleClearInput(setStationId)}>
                      {clearInput}
                    </div>
                  )}
                </label>
                <label className='editStation-modal-label'>
                  <span className='editStation-modal-label-title'>Модель</span>
                  <input
                    placeholder='Введите модель'
                    type="text"
                    value={stationModel}
                    onChange={(e) => setStationModel(e.target.value)}
                  />
                  {stationModel && (
                    <div className='editStation-modal-label-clear' onClick={handleClearInput(setStationModel)}>
                      {clearInput}
                    </div>
                  )}
                </label>
                <label className='editStation-modal-label'>
                  <span className='editStation-modal-label-title'>Вендор</span>
                  <input
                    placeholder='Введите вендор'
                    type="text"
                    value={stationVendor}
                    onChange={(e) => setStationVendor(e.target.value)}
                  />
                  {stationVendor && (
                    <div className='editStation-modal-label-clear' onClick={handleClearInput(setStationVendor)}>
                      {clearInput}
                    </div>
                  )}
                </label>
                <label className='editStation-modal-label'>
                  <span className='editStation-modal-label-title'>Цена, ₽</span>
                  <input
                    placeholder='Введите цену за электричество'
                    type="text"
                    value={stationPrice}
                    onChange={(e) => setStationPrice(e.target.value)}
                  />
                  {stationPrice && (
                    <div className='editStation-modal-label-clear' onClick={handleClearInput(setStationPrice)}>
                      {clearInput}
                    </div>
                  )}
                </label>
                <div className='editStation-modal-label-row-connectors'>
                {[...Array(EVSECount)].map((_, evseIndex) => (
                  <div key={`evse-${evseIndex}`} className='editStation-modal-label-row'>
                    <div className='editStation-modal-label-grid-container-short'>
                      <label className='editStation-modal-label editStation-modal-label-short'>
                        <span className='editStation-modal-label-title'>
                          EVSE {evseIndex + 1}
                        </span>
                      </label>
                      {EVSECount > 1 && (
                        <LargeButtonWithIcon
                          text={'Удалить EVSE'}
                          backgroundColor={'--MySecondaryBlue'}
                          color={'--MyPrimary'}
                          fontSize='12px'
                          height='32px'
                          onClick={() => handleRemoveEVSE(evseIndex)}
                          type='button'
                          padding='8px 12px'
                        />
                      )}
                    </div>
                    <div className='editStation-modal-label-connectors-grid-list'>
                      {[...Array(connectorCounts[evseIndex] || 1)].map((connectorCount, connectorIndex) => (
                        <div key={`connector-${evseIndex}-${connectorIndex}`} className='editStation-modal-label-connectors-grid'>
                          <span className='editStation-modal-label-connector-index'>Коннектор {connectorIndex + 1}</span>
                          <div className='editStation-modal-label-connectors-grid-container'>
                            <label className='editStation-modal-labelWithDes'>
                              <span className='editStation-modal-labelWithDes-title'>Мощность, кВт</span>
                              <span className='editStation-modal-labelWithDes-value'>
                                <input
                                  placeholder='кВт'
                                  type="text"
                                  value={connectorPowers[evseIndex][connectorIndex]}
                                  onChange={(e) => handlePowerChange(evseIndex, connectorIndex, e.target.value)}
                                />
                              </span>
                            </label>
                            <div className='editStation-modal-label-connectors-grid'>
                              <label className='editStation-modal-label editStation-modal-label-dropdown'>
                                {connectorTypes[evseIndex][connectorIndex] && (
                                  <span dangerouslySetInnerHTML={{ __html: connectors28pxIconsForChargeCreate[connectorTypes[evseIndex][connectorIndex]] }} style={{ height: '28px' }} />
                                )}
                                <input
                                  readOnly
                                  placeholder='Коннектор'
                                  type="text"
                                  value={connectorTypes[evseIndex][connectorIndex]}
                                  onClick={() => handleConnectorInputClick(evseIndex, connectorIndex)}
                                />
                                <div className='editStation-modal-label-arrow-down'>
                                  {arrowHeader}
                                </div>
                              </label>
                              {isConnectorDropdownOpen && selectedEvseIndex === evseIndex && selectedConnectorIndex === connectorIndex && (
                                <div
                                  className="editStation-modal-dropdown-container"
                                  style={{ display: isConnectorDropdownOpen ? 'block' : 'none' }}
                                  ref={connectorDropdownRef}
                                >
                                  <div className='editStation-modal-dropdown-content'>
                                    {Object.keys(connectors28pxIconsForChargeCreate).map((type) => (
                                      <div
                                        key={`dropdown-${type}`}
                                        className='editStation-modal-dropdown-option'
                                        onClick={() => handleConnectorTypeSelect(evseIndex, connectorIndex, type)}
                                      >
                                        <span dangerouslySetInnerHTML={{ __html: connectors28pxIconsForChargeCreate[type] }} />
                                        <span>{type}</span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='editStation-modal-label-connectors-grid-container'>
                            <div className='editStation-modal-label-grid-container-short'>
                              <label className='editStation-modal-labelWithDes editStation-modal-labelWithDes-dropDown'>
                                <input
                                  readOnly
                                  placeholder='Тип'
                                  type="text"
                                  value={connectorSocketType[evseIndex][connectorIndex]}
                                  onClick={() => handleSocketTypeInputClick(evseIndex, connectorIndex)}
                                />
                                <div className='editStation-modal-label-arrow-down'>
                                  {arrowHeader}
                                </div>
                              </label>
                              {isConnectorSocketTypeDropdownOpen && selectedEvseIndex === evseIndex && selectedConnectorIndex === connectorIndex && (
                                <div
                                  className="editStation-modal-dropdown-container-labelWithDes"
                                  style={{ display: isConnectorSocketTypeDropdownOpen ? 'block' : 'none' }}
                                  ref={connectorSocketTypeDropdownRef}
                                >
                                  <div className='editStation-modal-dropdown-content'>
                                    {Object.keys(ChargeSocketType).map((type) => (
                                      <div
                                        key={`dropdown-${type}`}
                                        className='editStation-modal-dropdown-option'
                                        onClick={() => handleSocketTypeSelect(evseIndex, connectorIndex, type)}
                                      >
                                        <span>{type}</span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className='editStation-modal-label-connectors-grid'>
                              <label className='editStation-modal-label editStation-modal-label-dropdown'>
                                <input
                                  readOnly
                                  placeholder='Тип напряжения'
                                  type="text"
                                  value={connectorVoltageType[evseIndex][connectorIndex]}
                                  onClick={() => handleVoltageInputClick(evseIndex, connectorIndex)}
                                />
                                <div className='editStation-modal-label-arrow-down'>
                                  {arrowHeader}
                                </div>
                              </label>
                              {isConnectorVoltageDropdownOpen && selectedEvseIndex === evseIndex && selectedConnectorIndex === connectorIndex && (
                                <div
                                  className="editStation-modal-dropdown-container"
                                  style={{ display: isConnectorVoltageDropdownOpen ? 'block' : 'none' }}
                                  ref={connectorVoltageDropdownRef}
                                >
                                  <div className='editStation-modal-dropdown-content'>
                                    {Object.keys(ChargeVoltage).map((type) => (
                                      <div
                                        key={`dropdown-${type}`}
                                        className='editStation-modal-dropdown-option'
                                        onClick={() => handleVoltageTypeSelect(evseIndex, connectorIndex, type)}
                                      >
                                        <span>{type}</span>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {connectorCounts[evseIndex] > 1 && (
                            <LargeButtonWithIcon
                              text={'Удалить коннектор'}
                              backgroundColor={'--MySecondaryBlue'}
                              color={'--MyPrimary'}
                              fontSize='12px'
                              height='32px'
                              onClick={() => handleRemoveConnector(evseIndex, connectorIndex)}
                              type='button'
                              padding='8px 12px'
                            />
                          )}
                        </div>
                      ))}
                      <div className='editStation-modal-addConnector-buttons'>
                        <LargeButtonWithIcon
                          text={'Добавить коннектор'}
                          LeftIcon={plusAdd}
                          iconMarginRight='8px'
                          backgroundColor={'--MySecondaryBlue'}
                          color={'--MyPrimary'}
                          fontSize='12px'
                          height='32px'
                          onClick={() => handleAddConnector(evseIndex)}
                          type='button'
                          padding='8px 12px'
                        />
                      </div>
                    </div>
                  </div>
                ))}
                  <div className='editStation-modal-addButton'>
                    <LargeButtonWithIcon
                      text={'Добавить EVSE'}
                      LeftIcon={plusAdd}
                      iconMarginRight='8px'
                      backgroundColor={'--MySecondaryBlue'}
                      color={'--MyPrimary'}
                      fontSize='12px'
                      height='32px'
                      onClick={handleAddEVSE}
                      type='button'
                      padding='8px 12px'
                    />
                  </div>
                </div>
              </div>
              <div className='editStation-modal-footer'>
                <div className='editStation-modal-buttons'>
                  <MediumButton
                    height='36px'
                    disabled={!isFormValid}
                    text={'Сохранить изменения'}
                    color={'--MyWhite'}
                    type='submit'
                    backgroundColor={'--MyPrimary'}
                  />
                  <MediumButton
                    height='36px'
                    text={'Отмена'}
                    color={'--MyPrimary'}
                    backgroundColor={'--MySecondaryBlue'}
                    onClick={onClose}
                  />
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditStationModal;
