/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './DeleteGroupModal.css';
// import { useParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  closeModal, largeError, largeFinish, largeLoading
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import AuthService from '../../../services/AuthService';

interface DeleteGroupModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  title: string;
  description: string;
  groupName: string | null;
  groupId: string;
  accessOption: string;
}

const DeleteGroupModal: React.FC<DeleteGroupModalProps> = ({
  onClose, isModalOpen, title, description, groupName, accessOption, groupId
}) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowSuccessMessage(true);
    try {
      if (groupName) {
        setSubmitting(true);
        const response = await AuthService.deleteGroup(groupId);
        if (response.status === 200) {
          setSubmitting(false);
          setStatusMessage('Группа успешно удалена');
          setShowSuccessMessage(false);
          setTimeout(() => {
            onClose();
          }, 500);
        }
      }
    } catch (error) {
      setSubmitting(false);
      setStatusMessage('Произошла ошибка при удалении группы');
      setShowSuccessMessage(false);
    }
  };

  const handleRetry = () => {
    setStatusMessage('');
  };

  return (
    <div className={`deleteGroup-modal-background ${isModalOpen ? '' : 'deleteGroup-modal-hidden'}`}>
      <div className="deleteGroup-modal-content">
        <h1>{title}</h1>
        <div className="deleteGroup-modal-close" onClick={onClose}>{closeModal}</div>
        {showSuccessMessage && (
          <div className='deleteGroup-modal-notification-container'>
            {largeLoading}
            <h3 className='deleteGroup-modal-notification-title'>Удаляем группу</h3>
          </div>
        )}
        {statusMessage && !showSuccessMessage && (
          <div>
            {!showSuccessMessage && statusMessage === 'Произошла ошибка при удалении группы' ? (
              <div className='deleteGroup-modal-notification-container'>
                {largeError}
                <h3 className='deleteGroup-modal-notification-title'>{statusMessage}</h3>
                <div className='deleteGroup-modal-error-buttons-container'>
                  <MediumButton text={'Попробовать снова'} onClick={handleRetry} backgroundColor={'--MyPrimary'} color={'--MyWhite'}/>
                  <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
                </div>
              </div>
            ) : (
              <div className='deleteGroup-modal-notification-container'>
                {largeFinish}
                <h3 className='deleteGroup-modal-notification-title'>Группа успешно удалена</h3>
              </div>
            )}
          </div>
        )}
        {!statusMessage && !showSuccessMessage && (<form className='deleteGroup-modal-form' onSubmit={handleSubmit}>
          <div className='deleteGroup-modal-form-description'>
            <span>
              {description} <span style={{ fontWeight: 500 }}>{groupName}</span>?
            </span>
          </div>
          <div className='deleteGroup-modal-buttons'>
            <MediumButton text={accessOption} color={'--MyWhite'} type="submit" backgroundColor={'--MyPrimary'} />
            <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
          </div>
        </form>)}
      </div>
    </div>
  );
};

export default DeleteGroupModal;
