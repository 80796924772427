import React, {
  FC, ReactNode, useEffect, useState, useRef
} from 'react';
import './ProgressBar.css';

interface ProgressBarProps {
  step: number;
  steps: number;
  height: number;
  filledColor: string;
  iconColor?: string;
  icon?: ReactNode;
}

export const ProgressBar: FC<ProgressBarProps> = ({
  step, steps, height, icon, filledColor = '#F6BD60', iconColor
}) => {
  const [width, setWidth] = useState(0);
  const barRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const newWidth = containerRef.current.offsetWidth;
      setWidth(newWidth);
    }
  }, []);

  useEffect(() => {
    if (barRef.current && width > 0) {
      const percentage = (step / steps) * 100;
      barRef.current.style.transform = `translateX(${percentage}%)`;
    }
  }, [step, steps, width]);

  return (
    <div className="progressBar-icon">
      {icon && React.cloneElement(icon as React.ReactElement, { fill: iconColor })}
      <div
        className="progressBar-container"
        style={{ borderRadius: height, height }}
        ref={containerRef}
      >
        <div
          className="progressBar-barView"
          ref={barRef}
          style={{
            height,
            backgroundColor: step !== 100 ? filledColor : '#EBEBEB',
            borderRadius: height,
            transform: 'translateX(0%)'
          }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
