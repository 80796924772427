/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './AddOrganizationModal.css';
import {
  clearInput, closeModal, largeLoading, largeError, largeFinish
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import OrgService from '../../../services/OrgService';

interface AddOrganizationModalProps {
  onClose: () => void;
  isModalOpen: boolean;
}

const AddOrganizationModal: React.FC<AddOrganizationModalProps> = ({ onClose, isModalOpen }) => {
  const [orgName, setOrgName] = useState<string>('');
  const [orgInn, setOrgInn] = useState<string>('');

  const [submitting, setSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const [isFormValid, setIsFormValid] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setSubmitting(true);
      setStatusMessage('Создаем организцаию');
      if (orgInn && orgName) {
        const response = await OrgService.addOrg(orgName, orgInn);
        if (response.status === 200) {
          setStatusMessage('Организация создана');
          setSubmitting(false);
        }
      }
    } catch (error: any) {
      setStatusMessage('Произошла ошибка при создании организации');
      setSubmitting(false);
    }
  };

  const handleRetry = () => {
    setStatusMessage('');
  };

  const handleClearInput = (setState: React.Dispatch<React.SetStateAction<string>>) => () => {
    setState('');
  };

  useEffect(() => {
    const isAllFieldsFilled =
      orgInn.trim() !== '' &&
      orgName.trim() !== '';
    setIsFormValid(isAllFieldsFilled);
  }, [orgInn, orgName]);

  return (
    <div className={`addDriver-modal-background ${isModalOpen ? '' : 'addDriver-modal-hidden'}`}>
      <div className="addDriver-modal-content">
        <h1>Новая организация</h1>
        <div className="addDriver-modal-close" onClick={onClose}>{closeModal}</div>
        {submitting && (
          <div className='addDriver-modal-notification-container'>
            {largeLoading}
            <h3 className='addDriver-modal-notification-title'>Создаем организацию</h3>
          </div>
        )}
        {!submitting && statusMessage && (
          <div>
            {statusMessage === 'Произошла ошибка при создании организации' ? (
              <div className='addDriver-modal-notification-container'>
                {largeError}
                <h3 className='addDriver-modal-notification-title'>{statusMessage}</h3>
                <div className='addDriver-modal-error-buttons-container'>
                  <MediumButton text={'Попробовать снова'} onClick={handleRetry}
                  backgroundColor={'--MyPrimary'} color={'--MyWhite'}/>
                  <MediumButton text={'Отмена'} color={'--MyPrimary'}
                  backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
                </div>
              </div>
            ) : (
              <div className='addDriver-modal-notification-container'>
                {largeFinish}
                <h3 className='addDriver-modal-notification-title'>Организация создана</h3>
              </div>
            )}
          </div>
        )}
        {!statusMessage && (
        <form className='addDriver-modal-form' onSubmit={handleSubmit}>
        <div className='addDriver-modal-form-inputs'>
          <label className='addCar-modal-label'>
              <span className='addCar-modal-label-title'>Название<span style={{ color: '#4495D1' }}>*</span></span>
              <input
                placeholder='Введите название'
                type="text"
                value={orgName}
                onChange={(e) => setOrgName(e.target.value)}
              />
              {orgName && (
                <div className='addCar-modal-label-clear' onClick={handleClearInput(setOrgName)}>
                  {clearInput}
                </div>
              )}
            </label>
            <label className='addCar-modal-label'>
              <span className='addCar-modal-label-title'>ИНН<span style={{ color: '#4495D1' }}>*</span></span>
              <input placeholder='Введине ИНН' type="text" value={orgInn} onChange={(e) => setOrgInn(e.target.value)} />
              {orgInn && (
                <div className='addCar-modal-label-clear' onClick={handleClearInput(setOrgInn)}>
                  {clearInput}
                </div>
              )}
            </label>
          </div>
          <div className='addCar-modal-footer'>
            <div className='addCar-modal-buttons'>
              <MediumButton disabled={!isFormValid} text={'Создать'}
              color={'--MyWhite'} type='submit' backgroundColor={'--MyPrimary'}/>
              <MediumButton text={'Отмена'} color={'--MyPrimary'}
              backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
            </div>
            <span><span style={{ color: '#4495D1' }}>*</span> Обязательные поля</span>
          </div>
        </form>)}
      </div>
    </div>
  );
};

export default AddOrganizationModal;
