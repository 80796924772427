/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, ReactNode, FC } from 'react';
import { createPortal } from 'react-dom';

interface BallonPortalProps {
  children: ReactNode;
  elementId: string;
  style?: React.CSSProperties;
}

export const BallonPortal: FC<BallonPortalProps> = ({ children, elementId, style }) => {
  const mount = document.getElementById(elementId);
  const el = document.createElement('div');

  useEffect(() => {
    if (mount) mount.appendChild(el);
    return () => {
      if (mount) mount.removeChild(el);
    };
  }, [el, mount]);

  if (!mount) return null;

  return createPortal(children, el);
};

