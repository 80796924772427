import React, { ReactNode } from 'react';
import './Buttons.css';

interface LargeButtonProps {
  text: string | ReactNode;
  backgroundColor: string;
  color: string;

  type?: 'button' | 'submit' | 'reset' | undefined;
  border?: string;
  width?: string;
  height?: string;
  fontSize?: string;
  borderRadius?: string;
  onClick?: () => void;
  disabled?: boolean;
  display?: string;
  alignItems?: string;
  textAlign?: string;
  maxWidth?: string;
}

const LargeButton: React.FC<LargeButtonProps> = ({
  width,
  maxWidth,
  height = '44px',
  fontSize = '15px',
  borderRadius = '10px',
  backgroundColor,
  color,
  display = 'flex',
  text,
  onClick,
  type,
  disabled
}) => {
  const buttonStyle: React.CSSProperties = {
    border: 'none',
    padding: '12px 16px',
    maxWidth,
    width,
    height,
    fontSize,
    borderRadius,
    backgroundColor: `var(${backgroundColor})`,
    color: `var(${color})`,
    display,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    opacity: disabled ? 0.6 : 1,
    pointerEvents: disabled ? 'none' : 'auto',
  };

  return (
    <button className='custom-button' type={type} style={buttonStyle} onClick={onClick} disabled={disabled}>
      {text}
    </button>
  );
};

export default LargeButton;
