/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './AccessesDriversModal.css';
// import { useParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { closeModal } from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import { DriversResponse } from '../../../models/response/CarResponse';
import CarService from '../../../services/CarService';
// import CarService from '../../../services/CarService';

interface AccessesDriversModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  title: string;
  description: string;
  driver: DriversResponse;
  accessOption: string;
}

function formatPhoneNumber(phoneNumber: string) {
  const cleaned = (`${phoneNumber}`).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
  }
  return phoneNumber;
}

const AccessesDriversModal: React.FC<AccessesDriversModalProps> = ({
  onClose, isModalOpen, title, description, driver, accessOption
}) => {
  const { carId } = useParams<{ carId: string }>();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (driver._id && carId) {
        if (driver.blocked) {
          await CarService.unblockDriver(carId, driver._id);
          onClose();
        } else {
          await CarService.blockDriver(carId, driver._id);
          onClose();
        }
      }
    } catch (error) {
      // console.error('Ошибка при отправке запроса:', error);
    }
  };

  return (
    <div className={`accessesDrivers-modal-background ${isModalOpen ? '' : 'accessesDrivers-modal-hidden'}`}>
      <div className="accessesDrivers-modal-content">
        <h1>{title}</h1>
        <div className="accessesDrivers-modal-close" onClick={onClose}>{closeModal}</div>
        <form className='accessesDrivers-modal-form' onSubmit={handleSubmit}>
          <div className='accessesDrivers-modal-form-description'>
          <span>
            {description} {driver.lastName ? driver.lastName : 'Водителя'} {driver.firstName ? driver.firstName : ''}
            {driver.phone ? ` ${formatPhoneNumber(driver.phone)}` : ' '}?
          </span>
          </div>
          <div className='accessesDrivers-modal-buttons'>
            <MediumButton text={accessOption} color={'--MyWhite'} type="submit" backgroundColor={'--MyPrimary'} />
            <MediumButton text={'Отмена'} color={'--MyPrimary'}
            backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AccessesDriversModal;
