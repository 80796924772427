/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line import/no-extraneous-dependencies
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AuthResponse } from '../models/response/AuthResponse';
import { API_AUTH } from './MyApi';
import { store } from '..';

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

function setErrorNameInStore(name: string) {
  store.setErrorName(name);
}

function setErrorMessageInStore(message: string) {
  store.setErrorMessage(message);
}

const refreshAndRetryQueue: RetryQueueItem[] = [];

let isRefreshing = false;

export function createAxiosInstance(baseURL: string): AxiosInstance {
  const instance = axios.create({ withCredentials: true, baseURL });
  instance.interceptors.request.use((config) => config);

  instance.interceptors.response.use(
    (config) => config,
    async (error) => {
      const originalRequest: AxiosRequestConfig = error.config;

      if (error?.response?.status !== 401) {
        setErrorNameInStore(error.response.data.name);
        setErrorMessageInStore(error.response.data.message);
      }

      if (error?.response?.status === 401) {
        if (isRefreshing) {
          return new Promise<void>((resolve, reject) => {
            refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
          });
        }

        isRefreshing = true;
        let refreshRes: any;

        try {
          refreshRes = await axios<AuthResponse>({
            method: 'POST',
            url: `${API_AUTH}/auth/refresh-token`,
            withCredentials: true,
          });
          localStorage.setItem('token', refreshRes.data.accessToken);
        } catch (err) {
          localStorage.clear();
          window.location.reload();
          return;
        } finally {
          isRefreshing = false;
        }

        setTimeout(() => {
          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            instance
              .request(config)
              .then(resolve)
              .catch((err: any) => {
                setErrorNameInStore(err?.response?.data?.name);
                setErrorMessageInStore(err?.response?.data?.message);
                return reject(err);
              });
          });
          refreshAndRetryQueue.length = 0;
        }, 500);

        return instance(originalRequest);
      }
      return Promise.reject(error);
    }
  );

  return instance;
}
