/* eslint-disable max-len */
// eslint-disable-next-line import/no-extraneous-dependencies
import { AxiosResponse } from 'axios';
import { createAxiosInstance } from '../http/http';
import {
  AddOrgStaff, MyOrgs, OrgInfo, OrgResponse
} from '../models/response/OrgResponse';
import { API_AUTH } from '../http/MyApi';

const $api = createAxiosInstance(API_AUTH);

export default class OrgService {
  static async myOrg(): Promise<AxiosResponse<MyOrgs>> {
    return $api.get<MyOrgs>('/org/my');
  }

  static async loginOrg(orgId: string): Promise<AxiosResponse<OrgResponse>> {
    return $api.post<OrgResponse>('/org/sign-in', { orgId });
  }

  static async logout(): Promise<AxiosResponse<OrgResponse>> {
    return $api.get<OrgResponse>('/org/logout');
  }

  // страница организаций

  static async getOrgs(filters?: object[], limit?: number, offset?: number): Promise<AxiosResponse<MyOrgs>> {
    return $api.post<MyOrgs>('/org/search', { filters, limit, offset });
  }

  static async getOrgInfo(orgId: string): Promise<AxiosResponse<OrgInfo>> {
    return $api.get<OrgInfo>(`/org/${orgId}`);
  }

  static async addOrg(name?: string, inn?: string): Promise<AxiosResponse> {
    return $api.post('/org', { name, inn });
  }

  static async deleteOrg(_id: string): Promise<AxiosResponse> {
    return $api.delete(`/org/${_id}`);
  }

  static async updateOrg(_id: string, name?: string, inn?: string): Promise<AxiosResponse> {
    return $api.put(`/org/${_id}`, { name, inn });
  }

  static async addOrgStaff(data: AddOrgStaff, orgId: string): Promise<AxiosResponse> {
    return $api.post(`/org/addUser/${orgId}`, data);
  }

  static async updateOrgStaff(data: AddOrgStaff, orgId: string): Promise<AxiosResponse> {
    return $api.post(`/org/updateUser/${orgId}`, data);
  }

  static async deleteOrgStaff(orgId: string, staffId: string): Promise<AxiosResponse> {
    return $api.post(`/org/removeUser/${orgId}`, { staffId });
  }
}
