/* eslint-disable max-len */
/* eslint-disable no-multi-spaces */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, {
  useState, useEffect, useRef, useContext
} from 'react';
import { observer } from 'mobx-react-lite';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import InputMask from 'react-input-mask';
import { DropdownArrowGray, clearInput } from '../../Icons/Icons';
import './Select.css';
import MyCircleCheckbox from '../Checkboxes/CheckboxCircle';
import MediumButton from '../buttons/MediumButton';
import Spinner from '../Spinner/Spinner';
import { Context } from '../..';
import MySwitch from '../Switch/Switch';

interface SelectProps {
  type: 'MULTISELECT' | 'TEXT' | 'BOOLEAN' | 'SELECT' | 'RANGE';
  label: string;
  options?: any[] | boolean[];
  selectedOptions?: string[] | never[] | undefined;
  disabled?: boolean;
  loading?: boolean;
  filterKey: string

  onOptionSelect: (value: any) => void;
  onClearClick?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClearFilters?: () => void;
  onClick?: () => void;
  onAcceptRange?: (value: any) => void;
}

const Select: React.FC<SelectProps> = ({
  label,
  options,
  onOptionSelect,
  type,
  onClearClick,
  onClearFilters,
  onAcceptRange,
  disabled,
  loading,
  filterKey
}) => {
  const { store } = useContext(Context);

  const [isOpen, setIsOpen] = useState(false);
  const [textValue, setTextValue] = useState('');
  const [booleanValue, setBooleanValue] = useState<boolean | null>(null);
  const [checkboxValues, setCheckboxValues] = useState<Record<string, boolean>>({});
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [selectedCount, setSelectedCount] = useState<number>(0);
  const [isSwitchDisabled, setIsSwitchDisabled] = useState(false);
  const [rangeValues, setRangeValues] = useState<[number, number]>([options && options[0] ? options[0].minValue : 0, options &&  options[0] ? options[0].maxValue : 100]);


  const handleDocumentClick = (e: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  const handleCheckboxChange = (option: string) => {
    const updatedCheckboxValues = { ...checkboxValues, [option]: !checkboxValues[option] };
    setCheckboxValues(updatedCheckboxValues);
    const updatedSelectedOptions = Object.keys(updatedCheckboxValues).filter((keyID) => updatedCheckboxValues[keyID]);
    onOptionSelect(updatedSelectedOptions);
  };

  const handleSelectChange = (option: string) => {
    const updatedCheckboxValues: Record<string, boolean> = {};
    updatedCheckboxValues[option] = true;

    setCheckboxValues(updatedCheckboxValues);
    setSelectedOption(option);
    onOptionSelect(option);
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    setTimeout(() => {
      if (buttonRef.current) {
        inputRef.current?.focus();
      }
    }, 0);
  };

  const handleTextChange = (text: string) => {
    setTextValue(text);
    onOptionSelect(text);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setIsOpen(false);
    }
  };

  const handleClearClick = () => {
    setSelectedOption(null);
    setCheckboxValues({});
    setTextValue('');
    setBooleanValue(false);
    if (onClearClick) {
      onClearClick();
    }
    if (onClearFilters) {
      onClearFilters();
    }
    setIsOpen(false);
  };

  const handleBooleanCheckboxChange = (value: boolean) => {
    if (booleanValue !== value) {
      setBooleanValue(value);
      onOptionSelect(value);
    }
    setIsOpen(false);
  };

  const handleRangeChange = (value: number | number[]) => {
    if (Array.isArray(value)) {
      setRangeValues(value as [number, number]);
      onOptionSelect({ minValue: value[0], maxValue: value[1] });
    } else {
      setRangeValues((prevValues) => {
        const newValues = [prevValues[0], prevValues[1]] as [number, number];
        return newValues;
      });
    }
  };

  const handleInputChange = (index: number, newValue: number) => {
    setRangeValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = newValue;
      if (index === 0) {
        updatedValues[0] = newValue;
      } else {
        updatedValues[1] = newValue;
      }
      return updatedValues as [number, number];
    });
    onOptionSelect(rangeValues);
  };

  // const toggleBooleanValue = () => {
  //   console.log('кнопка');
  //   setBooleanValue((prevBooleanValue) => !prevBooleanValue);
  //   onOptionSelect(!booleanValue);
  // };

  // const handleBooleanSelect = (value: boolean) => {
  //   setBooleanValue(value);
  //   onOptionSelect(value);
  // };

  const handleClearBoolean = () => {
    handleClearClick();
    setBooleanValue(null);
    setIsOpen(false);
  };

  const handleSwitchChange = (checked: boolean) => {
    setBooleanValue(checked);
    onOptionSelect(checked);
    // setIsSwitchDisabled(true);
    // setIsSwitchDisabled(false);
  };

  let placeholderText;

  if (label === 'Клиент') {
    placeholderText = 'Введите номер телефона';
  } else if (label !== 'VIN' && label !== 'IMEI') {
    placeholderText = `Введите ${label.toLocaleLowerCase()}`;
  } else {
    placeholderText = `Введите ${label}`;
  }

  // useEffect(() => {
  //   if (
  //     store.stationName &&
  //     Array.isArray(options) &&
  //     (options as string[]).includes(store.stationName)
  //   ) {
  //     handleCheckboxChange(store.stationName);
  //     console.log('select');
  //   }
  //   store.setStationName('');
  // }, [store.stationName, options]);

  useEffect(() => {
    const count = Object.values(checkboxValues).filter((value) => value).length;
    setSelectedCount(count);
  }, [checkboxValues]);

  useEffect(() => {
    if (filterKey === 'STATION' && store.stationName) {
      handleSelectChange(store.stationName);
    }
  }, [store.stationName, filterKey]);

  useEffect(() => {
    if (store.shouldWipeFilters) {
      handleClearClick();
      handleClearBoolean();
      if (type === 'RANGE' && options && options[0]) {
        setRangeValues([options[0].minValue, options[0].maxValue]);
      }
    }
    // console.log(booleanValue);
  }, [store.shouldWipeFilters]);

  return (
    <div className='multi-select' ref={containerRef}>
      <div className='boolean-with-close'>
        <button
          ref={buttonRef}
          onClick={toggleDropdown}
          className={type === 'BOOLEAN' && filterKey !== 'HEATER' ? 'boolean-select-button' : 'multi-select-button'}
          disabled={disabled}
        >
          {type === 'TEXT' ? (
            textValue ?
              (<>{label}&nbsp;<span className="multi-select-button-value">({textValue})</span></>) :
              label
          ) :
            label
          }
          {filterKey === 'HEATER' && (
            <div className='dropdown-arrow'>
              {booleanValue !== null &&
                (<span className='multi-select-button-value'>{booleanValue ? ' (Да)' : ' (Нет)'}</span>)
              }
              <DropdownArrowGray isOpen={isOpen} />
            </div>
          )}
          {type === 'BOOLEAN' && filterKey !== 'HEATER' && (
            <div className='boolean-with-switch'>
               <MySwitch
                disabled={disabled || isSwitchDisabled}
                checked={booleanValue === true}
                onChange={handleSwitchChange}
              />
            </div>
          )}
          {type === 'MULTISELECT' && selectedCount > 0 && (
            <span className="multi-select-button-value">&nbsp;{`(${selectedCount})`}</span>
          )}
          {type === 'RANGE' && rangeValues && (
            <span className="multi-select-button-value">&nbsp;{`(${rangeValues[0]} - ${rangeValues[1]})`}</span>
          )}
          {type === 'SELECT' && selectedOption && (
            <span className="multi-select-button-value">&nbsp;{`(${selectedOption})`}</span>
          )}
          {(type === 'MULTISELECT' ||  type === 'SELECT' || type === 'RANGE') && <div className='dropdown-arrow'><DropdownArrowGray isOpen={isOpen} /></div>}
          {loading ? (
            <Spinner borderTop='1px solid #9A99A2' />
          ) : (
            textValue && (
              <span className="clear-button" onClick={handleClearClick}>
                {clearInput}
              </span>
            )
          )}
        </button>
      </div>
      {isOpen && type === 'BOOLEAN' && filterKey === 'HEATER' && (
        <div className="multi-select-dropdown dropdown-boolean">
          <ul>
            <li>
              <MyCircleCheckbox
                disabled={disabled}
                checked={booleanValue === true}
                onCheckboxChange={() => handleBooleanCheckboxChange(true)}
                label={'Да'}
                marginLeft='6px'
              />
            </li>
            <li>
              <MyCircleCheckbox
                disabled={disabled}
                checked={booleanValue === false}
                onCheckboxChange={() => handleBooleanCheckboxChange(false)}
                label={'Нет'}
                marginLeft='6px'
              />
            </li>
            {/* <li onClick={() => handleBooleanSelect(true)}>Да</li>
            <li onClick={() => handleBooleanSelect(false)}>Нет</li> */}
          </ul>
          <MediumButton
            disabled={disabled || booleanValue === null}
            text={'Сброс'}
            onClick={handleClearBoolean}
            padding='8px 12px'
            height='32px'
            backgroundColor={'--MyPrimary'}
            color={'--MyWhite'}
          />
        </div>
      )}
      {isOpen && type === 'SELECT' && Array.isArray(options) && options.length !== 0 && (
        <div className="multi-select-dropdown" aria-disabled={true}>
          <ul style={{ marginBottom: '8px' }}>
            {options.map((option) => (
              <li key={option}>
              <MyCircleCheckbox
                disabled={disabled}
                checked={checkboxValues[option] || false}
                onCheckboxChange={() => handleSelectChange(option)}
                label={option}
                marginLeft='6px'
              />
              </li>
            ))}
          </ul>
          <MediumButton
            disabled={disabled || !selectedOption}
            text={'Сброс'}
            onClick={handleClearClick}
            padding='8px 12px'
            height='32px'
            backgroundColor={'--MyPrimary'}
            color={'--MyWhite'}
          />
        </div>
      )}
      {isOpen && type === 'MULTISELECT' && Array.isArray(options) && options.length !== 0 && (
        <div className="multi-select-dropdown" aria-disabled={true}>
          <ul>
            {options.map((option) => (
              <li key={option}>
                <MyCircleCheckbox
                disabled={disabled}
                checked={checkboxValues[option] || false}
                onCheckboxChange={() => handleCheckboxChange(option)}
                label={option}
                marginLeft='6px'
                />
              </li>
            ))}
          </ul>
        </div>
      )}
      {isOpen && type === 'RANGE' && options && options[0] && (
        <div className="range-select-dropdown">
          <Slider
            range
            min={options[0].minValue}
            max={options[0].maxValue}
            value={rangeValues}
            onChange={handleRangeChange}
            defaultValue={[options[0].minValue, options[0].maxValue]}
            allowCross={false}
            trackStyle={[{ backgroundColor: 'var(--MyPrimary)' }]}
            handleStyle={[
              { borderColor: 'var(--MyPrimary)', backgroundColor: 'var(--MyWhite)' },
              { borderColor: 'var(--MyPrimary)', backgroundColor: 'var(--MyWhite)' },
            ]}
          />
          <div className="range-values-dropdown">
            <label className="range-values-labelWithDes">
              <span className="range-values-labelWithDes-title">Минимум</span>
              <span className="range-values-labelWithDes-value">
                <input
                  placeholder="0"
                  type="text"
                  value={rangeValues[0]}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value, 10) || 0;
                    handleInputChange(0, newValue);
                  }}
                />
              </span>
            </label>
            <label className="range-values-labelWithDes">
              <span className="range-values-labelWithDes-title">Максимум</span>
              <span className="range-values-labelWithDes-value">
                <input
                  placeholder="0"
                  type="text"
                  value={rangeValues[1]}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value, 10) || 0;
                    handleInputChange(1, newValue);
                  }}
                />
              </span>
            </label>
          </div>
          <div className="range-buttons-dropdown">
            <MediumButton
              disabled={rangeValues[0] > rangeValues[1]}
              text={'Применить'}
              backgroundColor={'--MyPrimary'}
              color={'--MyWhite'}
              height="32px"
              onClick={() => {
                if (onAcceptRange) {
                  onAcceptRange(rangeValues);
                }
                toggleDropdown();
              }}
            />
            <MediumButton
              text={'Отмена'}
              backgroundColor={'--MySecondaryBlue'}
              color={'--MyPrimary'}
              height="32px"
              onClick={toggleDropdown}
            />
          </div>
        </div>
      )}
      {isOpen && type === 'TEXT' && (
        <div className="multi-select-dropdown-input">
          <input
            disabled={disabled}
            type="text"
            placeholder={placeholderText}
            value={textValue}
            onChange={(e) => handleTextChange(e.target.value)}
            ref={inputRef}
            onKeyDown={handleKeyDown}
          />
        </div>
      )}
    </div>
  );
};

export default observer(Select);
