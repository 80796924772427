/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './DeleteRateModal.css';
// import { useParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  closeModal, largeError, largeFinish, largeLoading
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import ChargeService from '../../../services/ChargeService';

interface DeleteRateModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  title: string;
  description: string;
  rateId: string | null;
  rateTitle: string;
  accessOption: string;
}

const DeleteRateModal: React.FC<DeleteRateModalProps> = ({
  onClose, isModalOpen, title, description, rateId, accessOption, rateTitle
}) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowSuccessMessage(true);
    try {
      if (rateId) {
        await ChargeService.deleteRate(rateId);
        setStatusMessage('Тариф успешно удален');
        setShowSuccessMessage(false);
        await ChargeService.getStations([], 30, 0);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (error) {
      setStatusMessage('Произошла ошибка при удалении тарифа');
      setShowSuccessMessage(false);
    }
  };

  const handleRetry = () => {
    setStatusMessage('');
  };

  return (
    <div className={`deleteRate-modal-background ${isModalOpen ? '' : 'deleteRate-modal-hidden'}`}>
      <div className="deleteRate-modal-content">
        <h1>{title}</h1>
        <div className="deleteRate-modal-close" onClick={onClose}>{closeModal}</div>
        {showSuccessMessage && (
          <div className='addDriver-modal-notification-container'>
            {largeLoading}
            <h3 className='addDriver-modal-notification-title'>Удаляем тариф</h3>
          </div>
        )}
        {statusMessage && (
          <div>
            {!showSuccessMessage && statusMessage === 'Произошла ошибка при удалении тарифа' ? (
              <div className='addCar-modal-notification-container'>
                {largeError}
                <h3 className='addCar-modal-notification-title'>{statusMessage}</h3>
                <div className='addCar-modal-error-buttons-container'>
                  <MediumButton text={'Попробовать снова'} onClick={handleRetry} backgroundColor={'--MyPrimary'} color={'--MyWhite'}/>
                  <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
                </div>
              </div>
            ) : (
              <div className='addCar-modal-notification-container'>
                {largeFinish}
                <h3 className='addCar-modal-notification-title'>Тариф успешно удален</h3>
              </div>
            )}
          </div>
        )}
        {!showSuccessMessage && !statusMessage && (<form className='deleteRate-modal-form' onSubmit={handleSubmit}>
          <div className='deleteRate-modal-form-description'>
          <span>
            {description} <span style={{ fontWeight: 500 }}>{rateTitle || ''}</span>?
          </span>
          </div>
          <div className='deleteRate-modal-buttons'>
            <MediumButton text={accessOption} color={'--MyWhite'} type="submit" backgroundColor={'--MyPrimary'} />
            <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
          </div>
        </form>)}
      </div>
    </div>
  );
};

export default DeleteRateModal;
