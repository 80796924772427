/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import './EditOrgStaffDropdownModal.css';
import { useParams } from 'react-router-dom';
import {
  arrowHeader, clearInput, closeModal, faq
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import OrgService from '../../../services/OrgService';
import { Context } from '../../..';
import MyCircleCheckbox from '../../Checkboxes/CheckboxCircle';

interface EditOrgStaffDropdownModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  field1: string;
  field2: string;
  field3: string;
  field4?: string;
  field1Value: any;
  field2Value: any;
  field3Value: any;
  field4Value?: any;
  onUpdateField?: (field: string, value: string) => void;
  staffId: string;
}

const EditOrgStaffDropdownModal: React.FC<EditOrgStaffDropdownModalProps> = ({
  onClose, isModalOpen, field1, field2, field3, field4, field1Value, field2Value, field3Value, field4Value, onUpdateField, staffId
}) => {
  const { orgId } = useParams<{ orgId: string }>();
  const actualOrgId = orgId || '';
  const [field1ValueState, setField1ValueState] = useState((field1Value || ''));
  const [field2ValueState, setField2ValueState] = useState((field2Value || ''));
  // const [field3ValueState, setField3ValueState] = useState((field3Value || ''));
  const [field4ValueState, setField4ValueState] = useState((field4Value || ''));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showRoleFaq, setShowRoleFaq] = useState(false);

  const [isFormChanged, setIsFormChanged] = useState(false);

  const getRoleName = (userGroupsIds: string | string[], roles: any[]) => {
    const role = roles.find((staffRole: { _id: any; }) => userGroupsIds.includes(staffRole._id));
    return role ? role.name : 'Роль не определена';
  };

  const roleMap = {
    сотрудник: '65c06cf9b492402b7662c36e',
    менеджер: '65c06ce7b492402b7662c36d',
    администратор: '65c06cd9b492402b7662c36c'
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const requestBody = {
        firstName: field2ValueState || undefined,
        lastName: field1ValueState || undefined,
        roleId: roleMap[field4ValueState.toLowerCase() as keyof typeof roleMap],
        staffId,
        orgId: actualOrgId
      };
      await OrgService.updateOrgStaff(requestBody, actualOrgId);
      onClose();
    } catch (error) {
      // console.error('Ошибка при отправке PUT-запроса:', error);
    }
  };

  const handleJobSelect = (selected: string) => {
    setField4ValueState(selected);
    setIsDropdownOpen(false);
    setIsFormChanged(true);
  };

  const handleDropdownInputClick = () => {
    setIsDropdownOpen((prevIsDropdownOpen) => !prevIsDropdownOpen);
  };

  const handleClearInput = (setState: React.Dispatch<React.SetStateAction<string>>) => () => {
    setState('');
    setIsFormChanged(true);
  };

  // faq

  const handleShowRoleFaqOpen = () => {
    setShowRoleFaq(true);
  };

  const handleShowRoleFaqClose = () => {
    setShowRoleFaq(false);
  };

  return (
    <div className={`editOrgStaff-modal-background ${isModalOpen ? '' : 'editOrgStaff-modal-hidden'}`}>
      <div className="editOrgStaff-modal-content">
        <h1>Редактировать пользователя</h1>
        <div className="editOrgStaff-modal-close" onClick={onClose}>{closeModal}</div>
        <form className='editOrgStaff-modal-form' onSubmit={handleSubmit}>
          <div className='editOrgStaff-modal-form-inputs'>
          <label className='editOrgStaff-modal-label'>
            <span className='editOrgStaff-modal-label-title'>{field1}</span>
              <input
                placeholder='Не указано'
                type="text"
                value={field1ValueState || ''}
                onChange={(e) => {
                  setField1ValueState(e.target.value);
                  setIsFormChanged(true);
                }}
              />
              {field1ValueState && (
                <div className='editOrgStaff-modal-label-clear' onClick={handleClearInput(setField1ValueState)}>
                  {clearInput}
                </div>
              )}
            </label>
            <label className='editOrgStaff-modal-label'>
              <span className='editOrgStaff-modal-label-title'>{field2}</span>
              <input
                placeholder='Не указано'
                type="text"
                value={field2ValueState || ''}
                onChange={(e) => {
                  setField2ValueState(e.target.value);
                  setIsFormChanged(true);
                }}
              />
              {field2ValueState && (
                <div className='editOrgStaff-modal-label-clear' onClick={handleClearInput(setField2ValueState)}>
                  {clearInput}
                </div>
              )}
            </label>
            {/* <label className='editOrgStaff-modal-label'>
              <span className='editOrgStaff-modal-label-title'>{field3}</span>
              <input
                placeholder='Не указано'
                type="text"
                value={field3ValueState || ''}
                onChange={(e) => setField3ValueState(e.target.value)}
              />
              {field3ValueState && (
                <div className='editOrgStaff-modal-label-clear' onClick={handleClearInput(setField3ValueState)}>
                  {clearInput}
                </div>
              )}
            </label> */}
            <label className='editOrgStaff-modal-label'>
              <span className='editOrgStaff-modal-label-title editOrgStaff-modal-label-withFaq'>{field4}
                  <span style={{ marginLeft: '8px' }}
                    onMouseEnter={handleShowRoleFaqOpen}
                    onMouseLeave={handleShowRoleFaqClose}
                  >
                    {faq}
                  </span>
                </span>
                <input
                  style={{ cursor: 'pointer' }}
                  readOnly
                  placeholder='Не выбрано'
                  type="text"
                  value={field4ValueState || ''}
                  onChange={(e) => setField4ValueState(e.target.value)}
                  onClick={handleDropdownInputClick}
                />
                {!field4ValueState ? (
                    <div className='editOrgStaff-modal-label-arrow-down'>
                    {arrowHeader}
                    </div>
                ) : (
                    <div className='editOrgStaff-modal-label-clear' onClick={handleClearInput(setField4ValueState)}>
                    {clearInput}
                    </div>
                )}
            </label>
            {isDropdownOpen && (
              <div className="editOrgStaff-modal-dropdown-container"
                style={{ display: isDropdownOpen ? 'block' : 'none' }}>
                <div className='editOrgStaff-modal-dropdown-content'>
                  <div onClick={() => handleJobSelect('Администратор')} className='editOrgStaff-modal-dropdown-option'>
                    <span><MyCircleCheckbox checked={field4ValueState === 'Администратор'} onCheckboxChange={() => handleJobSelect('Администратор')} label={''}/></span>
                    <span>Администратор</span>
                  </div>
                  <div onClick={() => handleJobSelect('Менеджер')} className='editOrgStaff-modal-dropdown-option'>
                    <span><MyCircleCheckbox checked={field4ValueState === 'Менеджер'} onCheckboxChange={() => handleJobSelect('Менеджер')} label={''}/></span>
                    <span>Менеджер</span>
                  </div>
                  <div onClick={() => handleJobSelect('Сотрудник')} className='editOrgStaff-modal-dropdown-option'>
                    <span><MyCircleCheckbox checked={field4ValueState === 'Сотрудник'} onCheckboxChange={() => handleJobSelect('Сотрудник')} label={''}/></span>
                    <span>Сотрудник</span>
                  </div>
                </div>
              </div>
            )}
            {showRoleFaq && (
              <div className='editOrgStaff-modal-role-faq-container'>
                <div className='editOrgStaff-modal-role-faq-content'>
                  <div className='editOrgStaff-modal-role-faq-text'>
                    <span className='editOrgStaff-modal-role-faq-title'>Сотрудник организации:</span>
                    <span className='editOrgStaff-modal-role-faq-description'>
                      Не имеет доступа к разделу управления организацией.
                    </span>
                  </div>
                  <div className='editOrgStaff-modal-role-faq-text'>
                    <span className='editOrgStaff-modal-role-faq-title'>Менеджер организации:</span>
                    <span className='editOrgStaff-modal-role-faq-description'>
                      Может управлять данными организации, включая внесение изменений в ИНН и название, просмотр и управление списком сотрудников организации, создание новых сотрудников и редактирование данных существующих.
                    </span>
                  </div>
                  <div className='editOrgStaff-modal-role-faq-text'>
                    <span className='editOrgStaff-modal-role-faq-title'>Администратор организации:</span>
                    <span className='editOrgStaff-modal-role-faq-description'>
                      Помимо прав менеджера организации, имеет возможность создавать и изменять данные менеджеров и администраторов организации.
                    </span>
                  </div>
                </div>
              </div>)
            }
          </div>
          <div className='editOrgStaff-modal-buttons'>
            <MediumButton disabled={!isFormChanged} text={'Сохранить изменения'} color={'--MyWhite'} type="submit" backgroundColor={'--MyPrimary'} />
            <MediumButton text={'Отмена'} color={'--MyPrimary'} backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditOrgStaffDropdownModal;

