import React, { ReactNode } from 'react';
import './Buttons.css';

interface LargeButtonWithIconProps {
  text: string | React.ReactNode;
  color: string;
  backgroundColor: string;
  RightIcon?: ReactNode;
  LeftIcon?: ReactNode;

  iconMarginRight?: string;
  iconMarginLeft?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  border?: string;
  width?: string;
  height?: string;
  fontSize?: string;
  borderRadius?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  display?: string;
  alignItems?: string;
  textAlign?: string;
  fontWeight?: string
  padding?: string
}

const LargeButtonWithIcon: React.FC<LargeButtonWithIconProps> = ({
  height = '36px',
  width,
  fontSize = '15px',
  fontWeight,
  borderRadius = '10px',
  backgroundColor,
  color,
  display = 'flex',
  text,
  onClick,
  padding,
  type,
  disabled,
  LeftIcon,
  RightIcon,
  iconMarginRight,
  iconMarginLeft
}) => {
  const buttonStyle: React.CSSProperties = {
    padding,
    border: 'none',
    fontWeight,
    height,
    width,
    fontSize,
    borderRadius,
    backgroundColor: `var(${backgroundColor})`,
    color: `var(${color})`,
    display,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    opacity: disabled ? 0.6 : 1,
    pointerEvents: disabled ? 'none' : 'auto',
  };

  const iconStyle: React.CSSProperties = {
    marginRight: iconMarginRight,
    marginLeft: iconMarginLeft,
    display: 'flex'
  };

  return (
    <button className='custom-button' type={type} style={buttonStyle} onClick={onClick} disabled={disabled}>
      <span style={iconStyle}>{LeftIcon}</span>
      {text}
      <span style={iconStyle}>{RightIcon}</span>
    </button>
  );
};

export default LargeButtonWithIcon;
