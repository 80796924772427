/* eslint-disable max-len */
import React from 'react';
import './Checkbox.css';
import { observer } from 'mobx-react-lite';
import { squareCheckbox20px, squareCheckboxChecked20px } from '../../Icons/Icons';

interface MyCheckboxProps {
  checked: boolean;
  onCheckboxChange: (newCheckedValue: boolean) => void;
  label?: React.ReactNode;
  marginLeft?: string;
  marginRight?: string;
  padding?: string;
}

const MyCheckbox20: React.FC<MyCheckboxProps> = ({
  checked, onCheckboxChange, label, marginLeft, padding, marginRight
}) => {
  const handleChange = () => {
    onCheckboxChange(!checked);
  };

  return (
    <div className='custom-checkbox'>
      <label className="custom-label">
        <input
          type="checkbox"
          className="my-checkbox-input"
          checked={checked}
          onChange={handleChange}
        />
        {checked ?
          squareCheckboxChecked20px
          : (
            squareCheckbox20px
          )}
        <span style={{ marginLeft, marginRight, padding }}>{label}</span>
      </label>
    </div>
  );
};

export default observer(MyCheckbox20);
