/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import './SelectedMenu.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const SelectedMenu: React.FC<{ selectedMenuItem: string }> = ({ selectedMenuItem }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { store } = useContext(Context);
  const [selectedSubItem, setSelectedSubItem] = useState<string>('');

  const getMenuItemsFor = (menuItem: string) => {
    switch (menuItem) {
      case 'Автопарк':
        return ['Автомобили'];
      case 'Сеть ЭЗС':
        return ['Зарядные станции', 'Группы', 'Тарифы', 'Зарядные сессии'];
      case 'Организации':
        return ['Организации'];
      case 'Аналитика':
        return ['Аналитика'];
      default:
        return null;
    }
  };

  const getPathForMenuItem = (subMenuItem: string) => {
    switch (subMenuItem) {
      case 'Зарядные станции':
        return '/charging';
      case 'Тарифы':
        return '/rate';
      case 'Группы':
        return '/groups';
      case 'Зарядные сессии':
        return '/chargingSessions';
      case 'Автомобили':
        return '/fleet/car';
      case 'Организации':
        return '/organizations';
      case 'Аналитика':
        return '/analytics';
      default:
        return '/not-found';
    }
  };

  const subMenuItems = selectedMenuItem ? getMenuItemsFor(selectedMenuItem) : [];

  const handleItemClick = (item: string) => {
    const path = getPathForMenuItem(item);
    setSelectedSubItem(item);
    navigate(path);
  };

  useEffect(() => {
    if (store.stationName) {
      setSelectedSubItem('Зарядные сессии');
    }
  }, [store.stationName]);

  // Авто
  const isOnCarsPage = /^\/fleet\/car$/.test(location.pathname);
  const isOnCarInfoPage = /^\/fleet\/car\/[\w-]+$/.test(location.pathname);
  const isOnTripPage = /^\/fleet\/car\/[\w-]+\/trip$/.test(location.pathname);
  const isOnTestPage = /^\/fleet\/car\/[\w-]+\/test$/.test(location.pathname);

  // Зарядные станции
  const isOnChargePage = /^\/charging$/.test(location.pathname);
  const isOnChargeInfoPage = /^\/charging\/[\w-]+$/.test(location.pathname);
  const isOnChargeLogsPage = /^\/charging\/[\w-]+\/logs$/.test(location.pathname);

  // Зарядные сессии
  const isOnChargeSessionsInfoPage = /^\/chargingSessions\/[\w-]+$/.test(location.pathname);
  const isOnChargeSessionsPage = /^\/chargingSessions$/.test(location.pathname);

  // Группы
  const isOnChargeGroupPage = /^\/groups$/.test(location.pathname);
  const isOnChargeGroupMembersPage = /^\/groups\/[\w-]+/.test(location.pathname);

  // Тарифы
  const isOnRatePage = /^\/rate$/.test(location.pathname);

  // Организации
  const isOnOrgPage = /^\/organizations$/.test(location.pathname);
  const isOnOrgInfoPage = /^\/organizations\/[\w-]+$/.test(location.pathname);

  // Карта
  const isOnMapPage = /^\/map$/.test(location.pathname);

  // Аналитика
  const isOnAnalyticsPage = /^\/analytics$/.test(location.pathname);

  useEffect(() => {
    if (isOnCarsPage || isOnCarInfoPage || isOnTripPage || isOnTestPage) {
      setSelectedSubItem('Автомобили');
    } else if (isOnChargePage || isOnChargeLogsPage || isOnChargeInfoPage) {
      setSelectedSubItem('Зарядные станции');
    } else if (isOnChargeSessionsInfoPage || isOnChargeSessionsPage) {
      setSelectedSubItem('Зарядные сессии');
    } else if (isOnOrgPage || isOnOrgInfoPage) {
      setSelectedSubItem('Организации');
    } else if (isOnRatePage) {
      setSelectedSubItem('Тарифы');
    } else if (isOnMapPage) {
      setSelectedSubItem('Карта');
    } else if (isOnChargeGroupPage || isOnChargeGroupMembersPage) {
      setSelectedSubItem('Группы');
    } else if (isOnAnalyticsPage) {
      setSelectedSubItem('Аналитика');
    } else {
      setSelectedSubItem('');
    }
  }, [location.pathname]);

  return (
    <>
      {!['Карта'].includes(selectedMenuItem) && (
        <div className='selected-menu'>
          <ul className='selected-menu-list'>
            {subMenuItems?.map((item, index) => (
              <li
                key={index}
                onClick={() => handleItemClick(item)}
                className={selectedSubItem === item ? 'selected-subItem' : ''}
              >
                <span>{item}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default SelectedMenu;
