/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './AddDriverModal.css';
import { useParams } from 'react-router-dom';
import { parsePhoneNumber } from 'libphonenumber-js';
import CarService from '../../../services/CarService';
import {
  clearInput, closeModal, largeLoading, largeError, largeFinish
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import PhoneInput from '../../Inputs/LoginInput/PhoneInput';

interface AddDriverModalProps {
  onClose: () => void;
  isModalOpen: boolean;
}

const AddDriverModal: React.FC<AddDriverModalProps> = ({ onClose, isModalOpen }) => {
  const { carId } = useParams<{ carId: string }>();
  const actualCarId = carId || '';
  const [driverPhone, setDriverPhone] = useState('');

  const [submitting, setSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  // const defaultPhoneFormatOptions = {
  //   regExp: /(.*)(\d{3})(\d{3})(\d{2})(\d{2})$/,
  //   format: '$1 ($2) $3-$4-$5',
  // };

  // const clearPhoneNumber =  (valueFormat: string) => (valueFormat ?? '').replace(/[^\\+0-9]/g, '');

  // const phoneFormat = (valueFormat: string, options = defaultPhoneFormatOptions) => {
  //   const { regExp, format } = options;
  //   return clearPhoneNumber(valueFormat).replace(regExp, format);
  // };

  // const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const inputValue = e.target.value;
  //   const formattedValue = phoneFormat(inputValue);
  //   setDriverPhone(formattedValue);
  // };

  const handlePhoneNumberChange = (number: string) => {
    setDriverPhone(number);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const parsePhone = parsePhoneNumber(driverPhone)?.number.replace('+', '');
      setSubmitting(true);
      setStatusMessage('Предоставляем доступ');
      if (actualCarId && parsePhone) {
        const response = await CarService.addDriver(actualCarId, parsePhone);
        if (response.status === 200) {
          setStatusMessage('Доступ открыт');
          setSubmitting(false);
        }
      }
    } catch (error: any) {
      setStatusMessage('Произошла ошибка при предоставлении доступа');
      setSubmitting(false);
    }
  };

  const handleRetry = () => {
    setStatusMessage('');
  };

  const handleClearInput = (setState: React.Dispatch<React.SetStateAction<string>>) => () => {
    setState('');
  };

  return (
    <div className={`addDriver-modal-background ${isModalOpen ? '' : 'addDriver-modal-hidden'}`}>
      <div className="addDriver-modal-content">
        <h1>Добавить водителя</h1>
        <div className="addDriver-modal-close" onClick={onClose}>{closeModal}</div>
        {submitting && (
          <div className='addDriver-modal-notification-container'>
            {largeLoading}
            <h3 className='addDriver-modal-notification-title'>Предоставляем доступ</h3>
          </div>
        )}
        {!submitting && statusMessage && (
          <div>
            {statusMessage === 'Произошла ошибка при предоставлении доступа' ? (
              <div className='addDriver-modal-notification-container'>
                {largeError}
                <h3 className='addDriver-modal-notification-title'>{statusMessage}</h3>
                <div className='addDriver-modal-error-buttons-container'>
                  <MediumButton text={'Попробовать снова'} onClick={handleRetry}
                  backgroundColor={'--MyPrimary'} color={'--MyWhite'}/>
                  <MediumButton text={'Отмена'} color={'--MyPrimary'}
                  backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
                </div>
              </div>
            ) : (
              <div className='addDriver-modal-notification-container'>
                {largeFinish}
                <h3 className='addDriver-modal-notification-title'>Доступ открыт</h3>
              </div>
            )}
          </div>
        )}
        {!statusMessage && (
        <form className='addDriver-modal-form' onSubmit={handleSubmit}>
        <div className='addDriver-modal-form-inputs'>
            <label className='addDriver-modal-label'>
              <span className='addDriver-modal-label-title'>Номер телефона:</span>
              <PhoneInput
              value={driverPhone}
              onChange={handlePhoneNumberChange}
              placeholder='+7 (___) ___-__-__'
            />
              {driverPhone && (
                <div className='addDriver-modal-label-clear' onClick={handleClearInput(setDriverPhone)}>
                  {clearInput}
                </div>
              )}
            </label>
          </div>
          <div className='addDriver-modal-buttons'>
            <MediumButton disabled={driverPhone.length !== 18} text={'Открыть доступ'} color={'--MyWhite'}
            type="submit" backgroundColor={'--MyPrimary'}/>
            <MediumButton text={'Отмена'} color={'--MyPrimary'}
            backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
          </div>
        </form>)}
      </div>
    </div>
  );
};

export default AddDriverModal;
