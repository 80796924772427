/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import './AddChargeGroupMemberModal.css';
import { useParams } from 'react-router-dom';
import { parsePhoneNumber } from 'libphonenumber-js';
import {
  clearInput, closeModal, largeLoading, largeError, largeFinish
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import MySwitch from '../../Switch/Switch';
import AuthService from '../../../services/AuthService';
import PhoneInput from '../../Inputs/LoginInput/PhoneInput';
import { GroupMember, GroupMemberWithoutId } from '../../../models/response/AuthResponse';

interface AddChargeGroupMemberModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  groupId: string;
}

const AddChargeGroupMemberModal: React.FC<AddChargeGroupMemberModalProps> = ({
  onClose, isModalOpen, groupId
}) => {
  const { chargePointId } = useParams<{ chargePointId: string }>();
  const actualChargePointId = chargePointId || '';

  const [groupMemberName, setGroupMemberName] = useState('');
  const [groupMemberLastName, setGroupMemberLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [activeStatus, setActiveStatus] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const [isFormValid, setIsFormValid] = useState(false);

  const handlePhoneChange = (number: string) => {
    setPhone(number);
  };

  const handleRetry = () => {
    setStatusMessage('');
  };

  const handleClearInput = (setState: React.Dispatch<React.SetStateAction<string>>) => () => {
    setState('');
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    let parsePhone;
    if (phone) {
      parsePhone = parsePhoneNumber(phone)?.number.replace('+', '');
    }
    try {
      if (groupId) {
        setSubmitting(true);
        setStatusMessage('Добавляем участника группы');
        if (parsePhone) {
          const response = await AuthService.addGroupsMembers(groupId, activeStatus, groupMemberName, groupMemberLastName, parsePhone);
          if (response.status === 200) {
            setStatusMessage('Участник группы успешно добавлен');
            setSubmitting(false);
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
        }
      }
    } catch (error: any) {
      setStatusMessage('Произошла ошибка при добавлении участника группы');
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const isAllFieldsFilled =
      groupMemberName.trim() !== '' &&
      groupMemberLastName.trim() !== '' &&
      phone.trim() !== '';

    setIsFormValid(isAllFieldsFilled);
  }, [groupMemberName, groupMemberLastName, phone]);

  return (
    <div className={`addChargeGroupMember-modal-background ${isModalOpen ? '' : 'addChargeGroupMember-modal-hidden'}`}>
      <div className="addChargeGroupMember-modal-content">
        <h1>Добавить участника группы</h1>
        <div className="addChargeGroupMember-modal-close" onClick={onClose}>{closeModal}</div>
        {submitting && (
          <div className='addChargeGroupMember-modal-notification-container'>
            {largeLoading}
            <h3 className='addChargeGroupMember-modal-notification-title'>Добавляем участника группы...</h3>
          </div>
        )}
        {!submitting && statusMessage && (
          <div>
            {statusMessage === 'Произошла ошибка при добавлении участника группы' ? (
              <div className='addChargeGroupMember-modal-notification-container'>
                {largeError}
                <h3 className='addChargeGroupMember-modal-notification-title'>{statusMessage}</h3>
                <div className='addChargeGroupMember-modal-error-buttons-container'>
                  <MediumButton
                    text={'Попробовать снова'}
                    onClick={handleRetry}
                    backgroundColor={'--MyPrimary'}
                    color={'--MyWhite'}
                  />
                  <MediumButton
                    text={'Отмена'}
                    color={'--MyPrimary'}
                    backgroundColor={'--MySecondaryBlue'}
                    onClick={onClose}
                  />
                </div>
              </div>
            ) : (
              <div className='addChargeGroupMember-modal-notification-container'>
                {largeFinish}
                <h3 className='addChargeGroupMember-modal-notification-title'>Участник группы успешно добавлен</h3>
              </div>
            )}
          </div>
        )}
        {!statusMessage && (
          <form className='addChargeGroupMember-modal-form' onSubmit={handleSubmit}>
            <div className='addChargeGroupMember-modal-form-inputs'>
              <label className='addChargeGroupMember-modal-label'>
                <span className='addChargeGroupMember-modal-label-title'>Имя</span>
                <input
                  placeholder='Иван'
                  type="text"
                  value={groupMemberName}
                  onChange={(e) => setGroupMemberName(e.target.value)}
                />
                {groupMemberName && (
                  <div className='addChargeGroupMember-modal-label-clear' onClick={handleClearInput(setGroupMemberName)}>
                    {clearInput}
                  </div>
                )}
              </label>
              <label className='addChargeGroupMember-modal-label'>
                <span className='addChargeGroupMember-modal-label-title'>Фамилия</span>
                <input
                  placeholder='Иванов'
                  type="text"
                  value={groupMemberLastName}
                  onChange={(e) => setGroupMemberLastName(e.target.value)}
                />
                {groupMemberLastName && (
                  <div className='addChargeGroupMember-modal-label-clear' onClick={handleClearInput(setGroupMemberLastName)}>
                    {clearInput}
                  </div>
                )}
              </label>
              <label className='addChargeGroupMember-modal-label'>
            <span className='addChargeGroupMember-modal-label-title'>
                Номер телефона
            </span>
              <PhoneInput
              padding='0px'
              value={phone}
              onChange={handlePhoneChange}
              placeholder='+7 (___) ___-__-__'
            />
              {phone && (
                <div className='addChargeGroupMember-modal-label-clear' onClick={handleClearInput(setPhone)}>
                  {clearInput}
                </div>
              )}
            </label>
            </div>
            <div className='addChargeGroupMember-switch-container'>
              <span>Активный статус</span>
              <MySwitch checked={activeStatus} onChange={() => setActiveStatus((prev) => !prev)}/>
            </div>
            <div className='addChargeGroupMember-modal-footer'>
              <div className='addChargeGroupMember-modal-buttons'>
                <MediumButton disabled={!isFormValid} text={'Добавить'} color={'--MyWhite'} type='submit'
                backgroundColor={'--MyPrimary'}/>
                <MediumButton text={'Отмена'} color={'--MyPrimary'}
                backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddChargeGroupMemberModal;
