/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import './OrganizationsPage.css';
import { useNavigate } from 'react-router-dom';
import LargeButtonWithIcon from '../../components/buttons/LargeButtonWithIcon';
import { plus } from '../../Icons/Icons';
import MyTable from '../../components/Table/MyTable';
import OrgService from '../../services/OrgService';
import { MyOrgs } from '../../models/response/OrgResponse';
import AddOrganizationModal from '../../components/Modal/AddOrganization/AddOrganizationModal';
import { Context } from '../..';

const OrganizationsPage = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);

  const [orgList, setOrgList] = useState<MyOrgs | null>(null);
  const [loading, setLoading] = useState(false);
  const [isAddOrgModalOpen, setIsAddOrgModalOpen] = useState(false);

  const fetchOrgs = async () => {
    setLoading(true);
    try {
      const response = await OrgService.getOrgs([]);
      if (response.status !== 401) {
        setOrgList(response.data);
      }
    } catch (error) {
      // console.error('Ошибка при загрузке фильтров:', error);
    } finally {
      setLoading(false);
    }
  };

  // Модалка

  const handleAddOrgModalOpen = () => {
    setIsAddOrgModalOpen(true);
  };

  const handleAddOrgModalClose = () => {
    setIsAddOrgModalOpen(false);
  };

  useEffect(() => {
    fetchOrgs();
  }, [isAddOrgModalOpen]);

  return (
    <>
      <div className="orgsPage-topLine">
        <div className='orgsPage-topLine-title'>
          <h2>Организации</h2>
          {orgList && orgList?.total ? <span>{`(${orgList.total})`}</span> : ''}
        </div>
        {store.user.buttons?.createOrg && <div className='carsPage-topLine-buttons-group'>
          <LargeButtonWithIcon
            text={'Новая организация'}
            iconMarginRight='8px'
            LeftIcon={plus}
            onClick={handleAddOrgModalOpen}
            color={'--MyWhite'}
            backgroundColor={'--MyPrimary'}
            padding='12px 8px'
          />
          {isAddOrgModalOpen &&
            <AddOrganizationModal onClose={handleAddOrgModalClose} isModalOpen={isAddOrgModalOpen}/>}
        </div>}
      </div>
      {orgList ? <div className='orgsPage-orgList-table'>
        <MyTable
          data={orgList.rows}
          onRowClick={(rowData, event) => {
            const href = `/organizations/${rowData._id}`;
            if (event?.ctrlKey || event?.metaKey || event?.button === 1) {
              event.stopPropagation();
            } else {
              event?.preventDefault();
              navigate(href);
            }
          }}
          loading={loading}
          tableHref={(rowData) => `/organizations/${rowData._id}`}
          // onRowClick={(rowData) => { navigate(`/organizations/${rowData._id}`); }}
          headers={orgList.headers}
          fetchMore={() => {}}
        />
      </div> : <p>Загрузка организаций</p>}
    </>
  );
};

export default OrganizationsPage;
