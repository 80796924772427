/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef } from 'react';
import './PhoneInput.css';
// import InputMask from 'react-input-mask';
import { AsYouType } from 'libphonenumber-js';
import { IMaskInput } from 'react-imask';

interface PhoneInputProps {
  placeholder?: string;

  numberLabel?: string;
  value?: string;
  type?: string;
  onChange: (value: string) => void;
  onClick?: () => void;
  padding?: string
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  placeholder, onChange, value, numberLabel, padding
}) => {
  const phoneInputStyle: React.CSSProperties = {
    padding
  };
  const inputRef = useRef(null);

  const handleChange = (inputValue: string) => {
    onChange(inputValue);
  };

  // const handleFocusInput = () => {
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // };

  // if (value) {
  //   const phoneNumber = parsePhoneNumber(value, 'RU');
  //   if (phoneNumber) {
  //     value = phoneNumber.formatInternational();
  //   }
  // }

  return (
    <div className="phone-input-container" style={phoneInputStyle}>
      {numberLabel && <span className='phone-number-label'>{numberLabel}</span>}
      <div className='phone-input'>
        <IMaskInput
          mask='+{7} (000) 000-00-00'
          value={value}
          onAccept={handleChange}
          placeholder={placeholder}
          maxLength={18}
          ref={inputRef}
        />
      </div>
    </div>
  );
};

export default PhoneInput;
