/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import './EditChargeGroupMemberModal.css';
import { useParams } from 'react-router-dom';
import {
  arrowHeader, clearInput, closeModal, largeLoading, largeError, largeFinish
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import ChargeService from '../../../services/ChargeService';
import { RateInfo } from '../../../models/response/RateResponse';
import MySwitch from '../../Switch/Switch';
import AuthService from '../../../services/AuthService';

interface EditChargeGroupModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  groupId: string;
  lastName: string;
  name: string;
  active: boolean;
  userId: string;
}

const EditChargeGroupModal: React.FC<EditChargeGroupModalProps> = ({
  onClose, isModalOpen, active, name, lastName, groupId, userId
}) => {
  const [memberName, setMemberName] = useState(name);
  const [memberLastName, setMemberLastName] = useState(lastName);
  const [activeStatus, setActiveStatus] = useState(active);

  const [submitting, setSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  const [isFormValid, setIsFormValid] = useState(false);

  const handleRetry = () => {
    setStatusMessage('');
  };

  const handleClearInput = (setState: React.Dispatch<React.SetStateAction<string>>) => () => {
    setState('');
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      setStatusMessage('Изменяем участника группы');
      const response = await AuthService.updateGroupsMembers(groupId, userId, activeStatus, memberName, memberLastName);
      if (response.status === 200) {
        setStatusMessage('Участник группы успешно изменён');
        setSubmitting(false);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (error: any) {
      setStatusMessage('Произошла ошибка при изменении участника группы');
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const isAllFieldsFilled =
      memberName.trim() !== '' &&
      memberLastName.trim() !== '';

    setIsFormValid(isAllFieldsFilled);
  }, [memberName, memberLastName]);

  return (
    <div className={`addChargeGroup-modal-background ${isModalOpen ? '' : 'addChargeGroup-modal-hidden'}`}>
      <div className="addChargeGroup-modal-content">
        <h1>Изменить участника группы</h1>
        <div className="addChargeGroup-modal-close" onClick={onClose}>{closeModal}</div>
        {submitting && (
          <div className='addChargeGroup-modal-notification-container'>
            {largeLoading}
            <h3 className='addChargeGroup-modal-notification-title'>Изменяем участника группы...</h3>
          </div>
        )}
        {!submitting && statusMessage && (
          <div>
            {statusMessage === 'Произошла ошибка при изменении участника группы' ? (
              <div className='addChargeGroup-modal-notification-container'>
                {largeError}
                <h3 className='addChargeGroup-modal-notification-title'>{statusMessage}</h3>
                <div className='addChargeGroup-modal-error-buttons-container'>
                  <MediumButton
                    text={'Попробовать снова'}
                    onClick={handleRetry}
                    backgroundColor={'--MyPrimary'}
                    color={'--MyWhite'}
                  />
                  <MediumButton
                    text={'Отмена'}
                    color={'--MyPrimary'}
                    backgroundColor={'--MySecondaryBlue'}
                    onClick={onClose}
                  />
                </div>
              </div>
            ) : (
              <div className='addChargeGroup-modal-notification-container'>
                {largeFinish}
                <h3 className='addChargeGroup-modal-notification-title'>Участник группы успешно изменён</h3>
              </div>
            )}
          </div>
        )}
        {!statusMessage && (
        <form className='addChargeGroup-modal-form' onSubmit={handleSubmit}>
          <div className='addChargeGroup-modal-form-inputs'>
            <label className='addChargeGroup-modal-label'>
              <span className='addChargeGroup-modal-label-title'>Имя</span>
              <input
                placeholder='Иван'
                type="text"
                value={memberName}
                onChange={(e) => setMemberName(e.target.value)}
              />
              {memberName && (
              <div className='addChargeGroup-modal-label-clear' onClick={handleClearInput(setMemberName)}>
                {clearInput}
              </div>
              )}
            </label>
            <label className='addChargeGroup-modal-label'>
              <span className='addChargeGroup-modal-label-title'>Фамилия</span>
              <input
                placeholder='Иванов'
                type="text"
                value={memberLastName}
                onChange={(e) => setMemberLastName(e.target.value)}
              />
              {memberLastName && (
              <div className='addChargeGroup-modal-label-clear' onClick={handleClearInput(setMemberLastName)}>
                {clearInput}
              </div>
              )}
            </label>
          </div>
          <div className='addChargeGroup-switch-container'>
            <span>Активный статус</span>
            <MySwitch checked={activeStatus} onChange={() => setActiveStatus((prev) => !prev)}/>
          </div>
          <div className='addChargeGroup-modal-footer'>
            <div className='addChargeGroup-modal-buttons'>
              <MediumButton disabled={!isFormValid} text={'Изменить'} color={'--MyWhite'} type='submit'
              backgroundColor={'--MyPrimary'}/>
              <MediumButton text={'Отмена'} color={'--MyPrimary'}
              backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
            </div>
          </div>
        </form>)}
      </div>
    </div>
  );
};

export default EditChargeGroupModal;
