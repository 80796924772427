/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './ChargeSessionInfoPage.css';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import ChargeService from '../../services/ChargeService';
import { SessionData } from '../../models/response/ChargeResponse';

const ChargeSessionInfoPage = () => {
  const { chargingSessionId } = useParams<{ chargingSessionId: string }>();
  const actualSessionId = chargingSessionId || '';
  const [sessionInfo, setSessionInfo] = useState<SessionData>();
  const [formattedTime, setFormattedTime] = useState('');

  function formatPhoneNumber(phoneNumber: string | undefined) {
    const cleaned = (`${phoneNumber}`).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
    }
    return phoneNumber;
  }

  const fetchSession = async () => {
    try {
      const response = await ChargeService.getSessionById(actualSessionId);
      setSessionInfo(response.data);
      const startedAt = moment(sessionInfo?.sessionDetails.chargingPeriod.startedAt);
      const endedAt = moment(sessionInfo?.sessionDetails.chargingPeriod.endedAt);
      const duration = moment.duration(endedAt.diff(startedAt));
      const durationFormatted = `${Math.floor(duration.asHours()).toString().padStart(2, '0')}:${duration.minutes().toString().padStart(2, '0')}:${duration.seconds().toString().padStart(2, '0')}`;
      setFormattedTime(durationFormatted);
    } catch {
      // console.log();
    }
  };

  useEffect(() => {
    fetchSession();
  }, [formattedTime]);

  return (
    <div className='session-page-info'>
      <div className='session-page-info-header'>
        <h2 className='session-page-info-title'>Сессия {actualSessionId}</h2>
        <div className='session-page-info-title-status-container'>
          <span className='session-page-info-title-status'>
          </span>
        </div>
      </div>
      <span className='session-page-info-header-partner'>Партнер: {sessionInfo?.memberDetails.partner}</span>
      <div className='session-page-info-container'>
        <div className='session-page-info-container-indicators'>
          <div className='session-info-container-small'>
            <div className='session-info-container'>
              <div className='session-info-indications-container'>
                <div className='session-info-indications-header'>
                  <div className='session-info-indications-title-container'>
                    <h2 className='session-info-indications-header-title'>{sessionInfo?.addressDetails.address}</h2>
                  </div>
                  <span>{sessionInfo?.addressDetails.chargerName}</span>
                </div>
                <div className='session-info-indications-list'>
                  <>
                    <div className='session-info-indication'>
                      <span>ID локации</span>
                      <span className='session-info-indication-value' title={sessionInfo?.addressDetails.locationId}>{sessionInfo?.addressDetails.locationId}</span>
                    </div>
                    <div className='session-info-indications-separator'></div>
                  </>
                  <>
                    <div className='session-info-indication'>
                      <span>ID EVSE</span>
                      <span className='session-info-indication-value' title={sessionInfo?.addressDetails.evseId}>{sessionInfo?.addressDetails.evseId}</span>
                    </div>
                    <div className='session-info-indications-separator'></div>
                  </>
                  <>
                    <div className='session-info-indication'>
                      <span>ID коннектора</span>
                      <span className='session-info-indication-value' title={sessionInfo?.addressDetails.connectorId}>{sessionInfo?.addressDetails.connectorId}</span>
                    </div>
                    <div className='session-info-indications-separator'></div>
                  </>
                  <>
                    <div className='session-info-indication'>
                      <span>ID локации партнера</span>
                      <span className='session-info-indication-value'>-</span>
                    </div>
                    <div className='session-info-indications-separator'></div>
                  </>
                  <>
                    <div className='session-info-indication'>
                      <span>ID EVSE партнера</span>
                      <span className='session-info-indication-value' title={sessionInfo?.addressDetails.partnerEvseId}>{sessionInfo?.addressDetails.partnerEvseId}</span>
                    </div>
                    <div className='session-info-indications-separator'></div>
                  </>
                  <>
                    <div className='session-info-indication'>
                      <span>ID коннектора партнера</span>
                      <span className='session-info-indication-value' title={sessionInfo?.addressDetails.partnerConnectorId}>{sessionInfo?.addressDetails.partnerConnectorId}</span>
                    </div>
                    <div className='session-info-indications-separator'></div>
                  </>
                  <>
                    <div className='session-info-indication'>
                      <span>Тип коннектора</span>
                      <span className='session-info-indication-value'>{sessionInfo?.addressDetails.connectorType}</span>
                    </div>
                    <div className='session-info-indications-separator'></div>
                  </>
                  <>
                    <div className='session-info-indication'>
                      <span>Номер коннектора</span>
                      <span className='session-info-indication-value'>{sessionInfo?.addressDetails.connectorNum}</span>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className='session-info-container-small'>
            <div className='session-info-container'>
              <div className='session-info-indications-container'>
                <div className='session-info-indications-header'>
                  <div className='session-info-indications-title-container'>
                    <h2 className='session-info-indications-header-title'>Данные о сессии</h2>
                  </div>
                </div>
                <div className='session-info-indications-list'>
                  <>
                    <div className='session-info-indication'>
                      <span>Начало – конец</span>
                      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'end' }}>
                        <span className='session-info-indication-value'>
                          {moment(sessionInfo?.sessionDetails.chargingPeriod.startedAt).format('HH:mm:ss')} - {sessionInfo?.sessionDetails.chargingPeriod.endedAt ? moment(sessionInfo?.sessionDetails.chargingPeriod.endedAt).format('HH:mm:ss') : '?'}
                        </span>
                        <span style={{ fontSize: '12px' }} className='session-info-indication-value'>
                          {moment(sessionInfo?.sessionDetails.chargingPeriod.startedAt).format('DD.MM.YYYY')}
                        </span>
                      </div>
                    </div>
                    <div className='session-info-indications-separator'></div>
                  </>
                  <>
                    <div className='session-info-indication'>
                      <span>Продолжительность</span>
                      <span className='session-info-indication-value'>{sessionInfo?.sessionDetails.chargingPeriod.endedAt ? formattedTime : '?'}</span>
                    </div>
                    <div className='session-info-indications-separator'></div>
                  </>
                  <>
                    <div className='session-info-indication'>
                      <span>Потребление</span>
                      <span className='session-info-indication-value'>{sessionInfo?.sessionDetails.loaded} кВт•ч</span>
                    </div>
                    <div className='session-info-indications-separator'></div>
                  </>
                  <>
                    <div className='session-info-indication'>
                      <span>Стоимость</span>
                      <span className='session-info-indication-value'>{sessionInfo?.sessionDetails.cost} ₽</span>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className='session-info-container-small'>
            <div className='session-info-container'>
              <div className='session-info-indications-container'>
                <div className='session-info-indications-header'>
                  <div className='session-info-indications-title-container'>
                    <h2 className='session-info-indications-header-title'>Данные клиента</h2>
                  </div>
                </div>
                <div className='session-info-indications-list'>
                    <div className='session-info-indication'>
                      <span>ID</span>
                      <span>{sessionInfo?.memberDetails.uuid}</span>
                    </div>
                    {sessionInfo?.memberDetails.phone &&
                      <>
                        <div className='session-info-indications-separator'></div>
                        <div className='session-info-indication'>
                          <span>Номер телефона</span>
                          <span>{formatPhoneNumber(sessionInfo?.memberDetails.phone)}</span>
                        </div>
                      </>
                    }
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='session-page-info-container-graphs'>
          <div className='session-page-info-graph'>
            <h2>График №1</h2>
          </div>
          <div className='session-page-info-graph'>
            <h2>График №2</h2>
          </div>
          <div className='session-page-info-graph'>
            <h2>График №3</h2>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ChargeSessionInfoPage;
