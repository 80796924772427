/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import { observer } from 'mobx-react-lite';
import './СhargingStationsPage.css';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import { debounce, isEqual } from 'lodash';
import Select from '../../components/Select/Select';
import MyTable from '../../components/Table/MyTable';
import ChargeService from '../../services/ChargeService';
import LargeButtonWithIcon from '../../components/buttons/LargeButtonWithIcon';
import { plus } from '../../Icons/Icons';
import AddStationModal from '../../components/Modal/AddStation/AddStationModal';
import { Context } from '../..';
import { FilterResponse } from '../../models/response/CarResponse';

interface ChargeData {
  rows: any[];
  headers: any[];
  total?: number
}

const ChargingStationsPage: React.FC = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);
  const [loading, setLoading] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: string[] }>({});
  const [textValues, setTextValues] = useState<{ [key: string]: string }>({});
  const [booleanValues, setBooleanValues] = useState<{ [key: string]: boolean | undefined }>({});

  const [chargeData, setChargeData] = useState<ChargeData | null>(null);
  const [chargeFilteredData, setChargeFilteredData] = useState<ChargeData | null>(null);
  const [filtersResponseData, setFiltersResponseData] = useState<FilterResponse>([]);
  const [prevFilters, setPrevFilters] = useState<any[]>([]);
  const [lastFilteredTime, setLastFilteredTime] = useState(0);

  const [offset, setOffset] = useState(0);
  const [filteredOffset, setFilteredOffset] = useState(0);

  const [isAddRateModalOpen, setAddRateModalOpen] = useState(false);

  const handleTextChange = debounce((text: string, key: string) => {
    if (text !== '+7 (___) ___-__-__') {
      setTextValues((prevTextValues) => ({
        ...prevTextValues,
        [key]: text,
      }));
    }
  }, 1500);

  const handleOptionSelect = debounce((selected: string[], filterKey: string) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [filterKey]: selected,
    }));
  }, 1000);

  const handleBooleanChange = debounce((value: boolean, key: string) => {
    if (booleanValues[key] !== value) {
      setBooleanValues((prevBooleanValues) => ({
        ...prevBooleanValues,
        [key]: value,
      }));
    }
  }, 500);

  const handleClearClick = debounce((filterKey: string) => {
    setTextValues((prevValues) => ({ ...prevValues, [filterKey]: '' }));
  }, 500);

  const handleBooleanClear = debounce((filterKey: string) => {
    if (booleanValues[filterKey] !== undefined) {
      setBooleanValues((prevValues) => ({
        ...prevValues,
        [filterKey]: undefined,
      }));
    }
  }, 500);

  // const fetchCharging = async () => {
  //   try {
  //     const response = await ChargeService.getStations([], 10, 0, false);
  //     if (response && response.data) {
  //       console.log(response.data);
  //       setChargeData(response.data);
  //     } else {
  //       console.error('Ответ не содержит данных.');
  //     }
  //   } catch (error) {
  //     console.error('Ошибка при загрузке станций:', error);
  //   }
  // };

  // const handleClearFilter = (key: string) => {
  //   setTextValues((prevTextValues) => {
  //     const updatedTextValues = { ...prevTextValues };
  //     delete updatedTextValues[key];
  //     return updatedTextValues;
  //   });
  //   setSelectedOptions((prevSelectedOptions) => {
  //     const updatedSelectedOptions = { ...prevSelectedOptions };
  //     delete updatedSelectedOptions[key];
  //     return updatedSelectedOptions;
  //   });
  //   setFiltersSelected(false);
  //   setSelectedFilters((prevSelectedFilters) => prevSelectedFilters.filter((filter) => !filter.includes(`${key}:`)));
  // };

  // const handleSearch = async () => {
  //   if (!chargeFiltersData) {
  //     return;
  //   }
  //   const filters = [];
  //   for (const filter of chargeFiltersData) {
  //     if (
  //       (filter.type === 'MULTISELECT' && selectedOptions[filter.key] && selectedOptions[filter.key].length > 0) ||
  //       (filter.type === 'TEXT' && textValues[filter.key]) ||
  //       (filter.type === 'BOOLEAN' && booleanValues[filter.key] !== undefined)
  //     ) {
  //       let values;
  //       if (filter.type === 'MULTISELECT') {
  //         values = selectedOptions[filter.key];
  //       } else if (filter.type === 'TEXT') {
  //         values = [textValues[filter.key]];
  //       } else if (filter.type === 'BOOLEAN') {
  //         values = [booleanValues[filter.key]];
  //       }
  //       filters.push({
  //         key: filter.key,
  //         values,
  //       });
  //     }
  //   }
  //   const requestBody = {
  //     filters,
  //     limit: ChargeData?.total || 10,
  //     offset: 0,
  //   };

  //   try {
  //     console.log(requestBody);
  //     const response = await
  //     ChargeService.getStations(requestBody.filters, requestBody.limit, requestBody.offset, false);
  //     setChargeData(response.data);
  //     setFiltersSelected(true);
  //     // setSelectedFilters(filters.map((filter) => {
  //     //   if (Array.isArray(filter.values)) {
  //     //     return `${filter.key}: ${filter.values.join(', ')}`;
  //     //   }
  //     //   return `${filter.key}: ${filter.values}`;
  //     // }));
  //   } catch (error) {
  //     console.error('Ошибка при отправке запроса:', error);
  //   }
  // };

  // const fetchMoreData = async () => {
  //   if (loading || !chargeFiltersData || !hasMoreData) {
  //     return;
  //   }
  //   setLoading(true);
  //   try {
  //     const newOffset = offset + 30;

  //     const filters = [];
  //     for (const filter of chargeFiltersData) {
  //       if (
  //         (filter.type === 'MULTISELECT' && selectedOptions[filter.key] && selectedOptions[filter.key].length > 0) ||
  //         (filter.type === 'TEXT' && textValues[filter.key]) ||
  //         (filter.type === 'BOOLEAN' && booleanValues)
  //       ) {
  //         let values;
  //         if (filter.type === 'MULTISELECT') {
  //           values = selectedOptions[filter.key];
  //         } else if (filter.type === 'TEXT') {
  //           values = [textValues[filter.key]];
  //         } else if (filter.type === 'BOOLEAN') {
  //           values = [booleanValues[filter.key]];
  //         }
  //         filters.push({
  //           key: filter.key,
  //           values,
  //         });
  //       }
  //     }

  //     const requestBody = {
  //       filters,
  //       limit: 30,
  //       offset: newOffset,
  //     };

  //     const response = await ChargeService.getStations(
  //       requestBody.filters,
  //       requestBody.limit,
  //       requestBody.offset,
  //       false
  //     );

  //     if (response.data.rows.length === 0) {
  //       setHasMoreData(false);
  //     } else {
  //       setChargeData((prevData) => ({
  //         headers: response.data.headers,
  //         rows: [...(prevData?.rows) || [], ...response.data.rows],
  //       }));
  //       setOffset(newOffset);
  //     }
  //   } catch (error) {
  //     // console.error('Ошибка при загрузке данных:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };



  // const handleIntersection = (entries: IntersectionObserverEntry[]) => {
  //   const target = entries[0];

  //   if (target.isIntersecting) {
  //     // fetchMoreData();
  //   }
  // };

  // const handleScroll = () => {
  //   const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
  //   const scrollHeight =
  //     (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
  //   const clientHeight = window.innerHeight;
  //   if (!loading && scrollTop + clientHeight > scrollHeight - 100 && hasMoreData) {
  //     // fetchMoreData();
  //   }
  // };

  // const fetchCarsIfFiltersEmpty = async () => {
  //   if (
  //     Object.values(selectedOptions).length === 0 &&
  //     Object.values(textValues).length === 0
  //   ) {
  //     console.log('Фильтры пустые');
  //     await handleSearch();
  //   }
  // };

  // useEffect(() => {
  //   observerRef.current = new IntersectionObserver(handleIntersection, {
  //     root: null,
  //     rootMargin: '0px',
  //     threshold: 1.0,
  //   });

  //   const target = document.getElementById('cars-table-bottom-id');
  //   if (target) {
  //     observerRef.current.observe(target);
  //   }

  //   return () => {
  //     if (observerRef.current) {
  //       observerRef.current.disconnect();
  //     }
  //   };
  // }, [handleIntersection]);

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // Модалка

  const handleAddRateModalOpen = () => {
    setAddRateModalOpen(true);
  };

  const handleAddRateModalClose = () => {
    setAddRateModalOpen(false);
  };

  // Фильтры

  const fetchFilters = async () => {
    setLoading(true);
    try {
      const response = await ChargeService.getFilters();
      if (response && response.data) {
        setFiltersResponseData(response.data);
      }
    } catch (error) {
      // console.error('Ошибка при загрузке фильтров:', error);
    } finally {
      setLoading(false);
    }
  };

  const filteredData = async () => {
    setLoading(true);
    try {
      const newFilters: any = [];
      const uniqueFilters = new Set();
      for (const filter of filtersResponseData) {
        if (
          (filter.type === 'MULTISELECT' && selectedOptions[filter.key]?.length > 0) ||
          (filter.type === 'TEXT' && textValues[filter.key]) ||
          (filter.type === 'BOOLEAN' && booleanValues[filter.key] !== undefined)
        ) {
          const filterKey = filter.key;

          if (!uniqueFilters.has(filterKey)) {
            uniqueFilters.add(filterKey);

            let values;
            if (filter.type === 'MULTISELECT') {
              values = selectedOptions[filter.key];
            } else if (filter.type === 'TEXT') {
              values = [textValues[filter.key]];
            } else if (filter.type === 'BOOLEAN') {
              values = [booleanValues[filter.key]];
            }

            newFilters.push({
              key: filter.key,
              values,
            });
          }
        }
      }

      store.setStationsFilters(newFilters);
      const filtersChanged = !isEqual(prevFilters, newFilters);

      if (filtersChanged) {
        setChargeFilteredData(null);
        setFilteredOffset(0);
      }

      if (newFilters.length > 0) {
        const responseFilter = await ChargeService.getStations(newFilters, 30, filtersChanged ? 0 : filteredOffset);
        if (responseFilter.status !== 401) {
          setOffset(filtersChanged ? 0 : offset);
          setChargeData(null);
          setChargeFilteredData((prevData) => ({
            headers: responseFilter?.data.headers,
            rows: [...(filtersChanged ? [] : prevData?.rows || []), ...responseFilter.data.rows],
            total: responseFilter?.data.total,
          }));
          setFilteredOffset((prev) => prev + 30);
        }
      } else {
        setLoading(true);
        const response = await ChargeService.getStations([], 30, offset);
        if (response.status !== 401) {
          setFilteredOffset(0);
          setChargeFilteredData(null);
          setChargeData((prevData) => ({
            headers: response?.data.headers,
            rows: [...(prevData?.rows || []), ...response.data.rows],
            total: response?.data.total,
          }));
          setOffset((prev) => prev + 30);
        }
      }
    } catch (error) {
      // console.error('Ошибка при отправке запроса:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    store.setShouldWipeFilters(true);
    setTextValues({});
    setSelectedOptions({});
    setBooleanValues({});
  };

  useEffect(() => {
    setPrevFilters(store.stationsFilters);
  }, [store.stationsFilters]);

  useEffect(() => {
    fetchFilters();
    filteredData();
  }, []);

  const debounceFilteredData = debounce(filteredData, 500);

  const areAllSelectedOptionsEmpty = Object.values(selectedOptions).every((array) => array.length === 0);
  const isAnyTextValueNotEmpty = Object.values(textValues).some((value) => value.trim() !== '');
  const isAnyBooleanValueNotEmpty =
    Object.values(booleanValues).some((value) => value !== undefined && value !== null);

  const shouldShowResetFilters =
  !areAllSelectedOptionsEmpty ||
  isAnyTextValueNotEmpty ||
  isAnyBooleanValueNotEmpty;

  useEffect(() => {
    if (
      (Object.keys(selectedOptions).length > 0 && areAllSelectedOptionsEmpty) ||
      Object.keys(selectedOptions).length > 0 ||
      (Object.keys(textValues).length > 0 && isAnyTextValueNotEmpty) ||
      Object.keys(textValues).length > 0 ||
      (isAnyBooleanValueNotEmpty && Object.keys(booleanValues).length > 0) ||
      Object.keys(booleanValues).length > 0
    ) {
      filteredData();
      store.setShouldWipeFilters(false);
    }
  }, [selectedOptions, textValues, booleanValues]);

  return (
    <>
      <div className="chargeStationPage-topLine">
        <div className='chargeStationPage-topLine-title'>
          <h2>Зарядные станции</h2>
          {chargeData && chargeData?.total ? <span>{`(${chargeData.total})`}</span> : ''}
          {chargeFilteredData && chargeFilteredData?.total ?
            <span style={{ color: '#9A99A2' }}>{`(${chargeFilteredData.total})`}</span> : ''}
        </div>
        <div className='chargeStationPage-topLine-buttons-group'>
          <LargeButtonWithIcon
            text={'Добавить станцию'}
            LeftIcon={plus}
            iconMarginRight='8px'
            onClick={handleAddRateModalOpen}
            color={'--MyWhite'}
            backgroundColor={'--MyPrimary'}
            padding='8px 12px'
            height='36px'/>
          {isAddRateModalOpen &&
          <AddStationModal
            onClose={handleAddRateModalClose}
            isModalOpen={isAddRateModalOpen}
          />}
        </div>
      </div>
      <div style={{ display: (chargeData?.total !== 0 || chargeData?.rows) ? '' : 'none', marginBottom: '24px' }} className="chargeStationPage-filters-buttons">
        {filtersResponseData && filtersResponseData.length > 0 && (
          <>
            {filtersResponseData.map((filter, index) => (
              <div key={index}>
                {filter.type === 'MULTISELECT' && (
                  <Select
                    label={filter.name || filter.key}
                    options={filter.options}
                    selectedOptions={selectedOptions[filter.key] || []}
                    onOptionSelect={(selected) => handleOptionSelect(selected, filter.key)}
                    type={filter.type}
                    filterKey={filter.key} />
                )}
                {filter.type === 'TEXT' && (
                  <Select
                    label={filter.name || filter.key}
                    options={[]}
                    selectedOptions={[]}
                    onClearClick={() => handleClearClick(filter.key)}
                    onOptionSelect={(value: string) => handleTextChange(value, filter.key)}
                    type={filter.type}
                    filterKey={filter.key}/>
                )}
                {filter.type === 'BOOLEAN' && (
                  <Select
                    disabled={loading}
                    label={filter.name || filter.key}
                    options={[]}
                    onClearClick={() => handleBooleanClear(filter.key)}
                    onOptionSelect={(value: boolean) => {
                      const currentBooleanValue = booleanValues[filter.key];
                      if (currentBooleanValue !== undefined) {
                        handleBooleanClear(filter.key);
                      } else {
                        handleBooleanChange(value, filter.key);
                      }
                    }}
                    type={filter.type}
                    filterKey={filter.key} />
                )}
              </div>
            ))}
          </>
        )}
        {shouldShowResetFilters && (
          <LargeButtonWithIcon
            onClick={handleReset}
            text={'Сбросить фильтры'}
            color={'--MyPrimary'}
            backgroundColor={'--MySecondaryBlue'}
            padding='7px 12px'
          />
        )}
      </div>
      <div className='chargeStationPage-table'>
        {chargeData && (
            <div>
              <MyTable
                hasMore={chargeData.total !== undefined && chargeData.total > chargeData.rows.length}
                fetchMore={debounceFilteredData}
                headers={chargeData.headers}
                data={chargeData.rows}
                onRowClick={(rowData, event) => {
                  const href = `/charging/${rowData._id}`;
                  if (event?.ctrlKey || event?.metaKey || event?.button === 1) {
                    event.stopPropagation();
                  } else {
                    event?.preventDefault();
                    navigate(href);
                  }
                }}
                loading={loading}
                tableHref={(rowData) => `/charging/${rowData._id}`}
              />
              <div id="cars-table-bottom-id" style={{ height: '10px' }}></div>
            </div>
        )}
        {chargeFilteredData && (
          <div>
            <MyTable
              hasMore={chargeFilteredData.total !== undefined
                && chargeFilteredData.total > chargeFilteredData.rows.length}
              fetchMore={debounceFilteredData}
              headers={chargeFilteredData.headers}
              data={chargeFilteredData.rows}
              // onRowClick={(rowData) => { navigate(`${rowData._id}`); }}
              onRowClick={(rowData, event) => {
                const href = `/charging/${rowData._id}`;
                if (event?.ctrlKey || event?.metaKey || event?.button === 1) {
                  event.stopPropagation();
                } else {
                  event?.preventDefault();
                  navigate(href);
                }
              }}
              tableHref={(rowData) => `/charging/${rowData._id}`}
              loading={loading}
            />
            <div id="cars-table-bottom-id" style={{ height: '10px' }}></div>
          </div>)}
      </div>
    </>
  );
};

export default observer(ChargingStationsPage);
