/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import './PrivateChargeStationModal.css';
import { useParams } from 'react-router-dom';
import {
  bucket,
  clearInput, closeModal, largeFinish,
  plusAdd
} from '../../../Icons/Icons';
import MediumButton from '../../buttons/MediumButton';
import MediumCircleButton from '../../buttons/MediumCircleButton';
import PhoneInput from '../../Inputs/LoginInput/PhoneInput';
import LargeButtonWithIcon from '../../buttons/LargeButtonWithIcon';
import DeletePrivateStationStaffModal from '../DeletePrivateStationStaff/DeletePrivateStationStaffModal';

interface PrivateChargeStationModalProps {
  onClose: () => void;
  isModalOpen: boolean;
  title: string;
  successTransferMessage: string;
}

const PrivateChargeStationModal: React.FC<PrivateChargeStationModalProps> = ({
  onClose, isModalOpen, title, successTransferMessage
}) => {
  const { station } = useParams<{ station: string }>();
  const actualStationId = station || '';
  const [activeSubMenu, setActiveSubMenu] = useState<number>(1);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showPhoneInput, setShowPhoneInput] = useState(false);
  const [isDeleteStaffModalOpen, setDeleteStaffModalOpen] = useState(false);

  // Form 1

  const [phoneNumberForm1, setPhoneNumberForm1] = useState('');

  const [isForm1Valid, setIsForm1Valid] = useState(false);

  const handlePhoneNumberChangeForm1 = (number: string) => {
    setPhoneNumberForm1(number);
  };

  const handleClearInput = (setState: React.Dispatch<React.SetStateAction<string>>) => () => {
    setState('');
  };

  const handleShowPhoneInput = () => {
    setShowPhoneInput((prev) => !prev);
  };

  const handlePrivateModalOpen = () => {
    setDeleteStaffModalOpen(true);
  };

  const handlePrivateModalClose = () => {
    setDeleteStaffModalOpen(false);
  };

  const handleSubmitForm1 = async (e: any) => {
    e.preventDefault();
    try {
      // console.log('click');
    } catch (error) {
      setShowSuccessMessage(false);
    }
  };

  useEffect(() => {
    const isAllFieldsFilled =
      phoneNumberForm1.trim() !== '';

    setIsForm1Valid(isAllFieldsFilled);
  }, [phoneNumberForm1]);

  const buttonStyles = {
    activeButton: {
      backgroundColor: '#4495D1',
      color: 'white',
    },
    inactiveButton: {
      backgroundColor: '#FFFFFF',
      color: 'black',
    },
  };

  return (
    <div className={`privateChargeStation-modal-background ${isModalOpen ? '' : 'privateChargeStation-modal-hidden'}`}>
      <div className="privateChargeStation-modal-content">
        <h1>{title}</h1>
        <div className="privateChargeStation-modal-close" onClick={onClose}>{closeModal}</div>
          <>
            <span className='privateChargeStation-modal-private-title'>Выберите приватность станции</span>
            <div className="privateChargeStation-modal-header">
              <MediumCircleButton
                border='1px solid #4495D1'
                style={activeSubMenu === 1 ? buttonStyles.activeButton : buttonStyles.inactiveButton}
                height='30px'
                backgroundColor={'--MyWhite'}
                text={'Публичная'}
                onClick={() => setActiveSubMenu(1)}
                color={'--MyPrimary'}
              />
              <MediumCircleButton
              border='1px solid #4495D1'
              style={activeSubMenu === 2 ? buttonStyles.activeButton : buttonStyles.inactiveButton}
              height='30px'
              backgroundColor={'--MyWhite'}
              text={'Приватная'}
              onClick={() => setActiveSubMenu(2)}
              color={'--MyPrimary'}/>
              {/* <MediumCircleButton
                style={activeSubMenu === 3 ? buttonStyles.activeButton : buttonStyles.inactiveButton}
                color='black'
                backgroundColor='#F8F9FF' width='100%'
                text={'Платформе'}
                onClick={() => setActiveSubMenu(3)}
              /> */}
          </div>
          {activeSubMenu === 2 && (
            <div className="sub-menu">
              <span className='privateChargeStation-modal-private-title'>Доступы</span>
              <div className='privateChargeStation-modal-private-list'>
                <div className='privateChargeStation-modal-private-staff-container'>
                  <span style={{ fontWeight: 500 }}>Иванов И.</span>
                  <span>+7 962 630 56 22</span>
                  <span style={{ cursor: 'pointer', opacity: 0.8 }}>{bucket}</span>
                </div>
                <div className='privateChargeStation-modal-private-staff-container'>
                  <span style={{ fontWeight: 500 }}>Иванов И.</span>
                  <span>+7 962 630 56 22</span>
                  <span style={{ cursor: 'pointer', opacity: 0.8 }}>{bucket}</span>
                </div>
                <div className='privateChargeStation-modal-private-staff-container'>
                  <span style={{ fontWeight: 500 }}>Иванов И.</span>
                  <span>+7 962 630 56 22</span>
                  <span style={{ cursor: 'pointer', opacity: 0.8 }} onClick={handlePrivateModalOpen}>{bucket}</span>
                </div>
                <LargeButtonWithIcon
                  onClick={handleShowPhoneInput}
                  text={'Добавить доступ'}
                  height='32px'
                  LeftIcon={plusAdd}
                  backgroundColor={'--MySecondaryBlue'}
                  color={'--MyPrimary'}
                  iconMarginRight='8px'
                  fontSize='12px'
                />
              </div>
              {isDeleteStaffModalOpen &&
                <DeletePrivateStationStaffModal
                  onClose={handlePrivateModalClose}
                  isModalOpen={isDeleteStaffModalOpen}
                  title={'Удаление доступа'}
                  description={'Вы уверены, что хотите удалить доступ для'}
                  staff={undefined}
                  accessOption={'Да, удалить'}
                  successDeleteMessage={'Доступ удален'}
                />}
              {showPhoneInput &&
              <div className='privateChargeStation-modal-private-addStaff-container'>
                <span className='privateChargeStation-modal-private-title'>Добавить доступ</span>
                <form className='privateChargeStation-modal-form' onSubmit={handleSubmitForm1}>
                  <div className='privateChargeStation-modal-form-inputs'>
                    <label className='privateChargeStation-modal-label'>
                      <span className='privateChargeStation-modal-label-title'>Номер телефона:</span>
                      <PhoneInput
                      value={phoneNumberForm1}
                      onChange={handlePhoneNumberChangeForm1}
                      placeholder='+7 (___) ___-__-__'
                    />
                      {phoneNumberForm1 && (
                        <div className='privateChargeStation-modal-label-clear'
                          onClick={handleClearInput(setPhoneNumberForm1)}
                        >
                          {clearInput}
                        </div>
                      )}
                    </label>
                  </div>
                </form>
                <MediumButton disabled={!isForm1Valid} text={'Добавить'} color={'--MyWhite'}
                height='32px' fontSize='12px' backgroundColor={'--MyPrimary'}/>
              </div>}
            </div>
          )}
            <div className='privateChargeStation-modal-buttons'>
              <MediumButton text={'Сохранить'} color={'--MyWhite'} backgroundColor={'--MyPrimary'}/>
              <MediumButton text={'Отмена'} color={'--MyPrimary'}
                backgroundColor={'--MySecondaryBlue'} onClick={onClose} />
            </div>
          </>
      </div>
    </div>
  );
};

export default PrivateChargeStationModal;
